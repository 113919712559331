import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';

import Button from './Button';

const SelectButton = styled(Button)`
  text-transform: uppercase;
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.bold};
  font-weight: bold;
  height: 50px;
  width: ${({ buttonName }) => buttonName === 'Reload CVM' || buttonName === 'Ignore' ? 'fit-content' : '335px'};
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  border-radius: 5px;
  border: 2px solid ${theme.colors.lightGray};
  ${({ buttonName, isDisabled }) => (buttonName === constants.SELECT_CUSTOMER
    || buttonName === constants.BUTTON_ENTER_VEHICLE_MANUALLY)
    && !isDisabled
    ? `background-color: ${theme.colors.green}, color: white`
    : `background-color: ${theme.colors.gray}`};
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
`;

export default SelectButton;
