import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import * as constants from '../../constants/Constants';

const ToggleButton = styled.div`
  cursor: pointer;
  position: absolute;
  text-align: center;
  align-content: center;
  justify-content: space-around;
  white-space: nowrap;
  ${({ margin }) => (margin && `margin:${margin}`)};
`;

const ToggleButtonSmall = styled(ToggleButton)`
${({ active }) => (active
    ? `
      background-color: ${theme.colors.white};
      z-index: 10;
      color: ${theme.colors.darkGray};
      font-family: ${theme.fontFamily.heavy};
      border: 1.5px solid ${theme.colors.lightGray};`
    : `
      background-color: ${theme.colors.opaque};
      z-index: 9;
      color: ${theme.colors.mediumGray};
      font-family: ${theme.fontFamily.normal};
      border: 1.5px solid ${theme.colors.darkGray};`)
};
  ${({ position, wrapperWidth }) => (position === constants.LEFT ? 'left: 0;' : `left: ${wrapperWidth / 2}px;`)};
  ${({ active, position, wrapperWidth }) => (active && position === constants.LEFT
    ? `width: ${(wrapperWidth * 0.56).toFixed(2)}px; border-radius: 8px;`
    : !active
      && position === constants.LEFT
      && `width: ${wrapperWidth / 2}px; left: 0; border-radius: 8px 0 0 8px;`)
};
  ${({
    active, position, wrapperWidth, size
  }) => (active && position === constants.RIGHT
    ? `width: ${(wrapperWidth * 0.56).toFixed(2)}px;
       left: ${(wrapperWidth * 0.44).toFixed(2)}px;
       border-radius: 8px;`
    : !active
      && position === constants.RIGHT
      && `width: ${wrapperWidth / 2}px;
          left: ${wrapperWidth / 2}px;
          border-radius: 0 8px 8px 0;`)
};

  font-size: ${theme.fontSize.large};
  height: 31.5px;
  padding: 5px;
`;

const ToggleButtonMedium = styled(ToggleButton)`
${({ active }) => (active
    ? `
      background-color: ${theme.colors.white};
      z-index: 10;
      color: ${theme.colors.darkGray};
      font-family: ${theme.fontFamily.heavy};
      border: 2px solid ${theme.colors.titleBlack}`
    : `
      background-color: ${theme.colors.mediumGray};
      z-index: 9;
      color: ${theme.colors.lightGray};
      font-family: ${theme.fontFamily.normal};`)
};
  ${({ position, wrapperWidth }) => (position === constants.LEFT ? 'left: 0;' : `left: ${wrapperWidth / 2}px;`)};
  ${({ active, position, wrapperWidth }) => (active && position === constants.LEFT
    ? `width: ${(wrapperWidth * 0.56).toFixed(2)}px; border-radius: 15px;`
    : !active
      && position === constants.LEFT
      && `width: ${wrapperWidth / 2}px; left: 0; border-radius: 15px 0 0 15px;`)
};
  ${({
    active, position, wrapperWidth, size
  }) => (active && position === constants.RIGHT
    ? `width: ${(wrapperWidth * 0.56).toFixed(2)}px;
       left: ${(wrapperWidth * 0.44).toFixed(2)}px;
       border-radius: 15px;`
    : !active
      && position === constants.RIGHT
      && `width: ${wrapperWidth / 2}px;
          left: ${wrapperWidth / 2}px;
          border-radius: 0 15px 15px 0;`)
};

  font-size: ${theme.fontSize.modalTitle};
  height: 50px;
  padding: 12px;
`;

const ToggleButtonLarge = styled(ToggleButton)`
  ${({ active }) => (active
    ? `
      background-color: ${theme.colors.white};
      z-index: 10;
      color: ${theme.colors.darkGray};
      font-family: ${theme.fontFamily.black};
      border: 2px solid ${theme.colors.titleBlack};`
    : `
      background-color: ${theme.colors.mediumGray};
      z-index: 9;
      color: ${theme.colors.lightGray};
      font-family: ${theme.fontFamily.bold};
      border: 2px solid ${theme.colors.lightGray2};`)
};
  ${({ position, wrapperWidth }) => (position === constants.LEFT ? 'left: 0;' : `left: ${wrapperWidth / 2}px;`)};
  ${({ active, position, wrapperWidth }) => (active && position === constants.LEFT
    ? `width: ${(wrapperWidth * 0.50).toFixed(2)}px; border-radius: 15px;`
    : !active
      && position === constants.LEFT
      && `width: ${wrapperWidth / 2}px; left: 0; border-radius: 15px 0 0 15px;`)
};
  ${({ active, position, wrapperWidth }) => (active && position === constants.RIGHT
    ? `width: ${(wrapperWidth * 0.56).toFixed(2)}px;
       left: ${(wrapperWidth * 0.46).toFixed(2)}px;
       border-radius: 15px;`
    : !active
      && position === constants.RIGHT
      && `width: ${wrapperWidth / 1.9}px;
          left: ${wrapperWidth / 2.1}px;
          border-radius: 0 15px 15px 0;`)
};

  font-size: ${theme.fontSize.modalTitle};
  height: 50px;
  padding: 12px;
`;

const getDataTestId = active => {
  return active ? constants.ENABLED : constants.DISABLED;
};

const Toggle = props => {
  const {
    active, name, position, size, wrapperWidth, toggleClick, margin
  } = props;
  let toggleContent = constants.EMPTY_STRING;

  switch (size) {
    case constants.TOGGLE_SIZE_SMALL:
      toggleContent = (<ToggleButtonSmall data-testid={getDataTestId(active)} wrapperWidth={wrapperWidth} name={name} position={position} size={size} active={active} margin={margin} onClick={(e) => toggleClick(e)}>{name}</ToggleButtonSmall>);
      break;
    case constants.TOGGLE_SIZE_MEDIUM:
      toggleContent = (<ToggleButtonMedium data-testid={getDataTestId(active)} wrapperWidth={wrapperWidth} name={name} position={position} size={size} active={active} margin={margin} onClick={(e) => toggleClick(e)}>{name}</ToggleButtonMedium>);
      break;
    case constants.TOGGLE_SIZE_LARGE:
      toggleContent = (<ToggleButtonLarge data-testid={getDataTestId(active)} wrapperWidth={wrapperWidth} name={name} position={position} size={size} active={active} margin={margin} onClick={(e) => toggleClick(e)}>{name}</ToggleButtonLarge>);
      break;
    default:
      break;
  }

  return (
    <>
      { toggleContent }
    </>
  );
};

export default Toggle;
