import { useContext } from 'react';
import styled from '../../../../helpers/esm-styled-components';
import Dropdown from '../../../Dropdown/Dropdown';
import { BreadCrumbQuestion } from '../../ModalUtils';
import * as constants from '../../../../constants/Constants';
import { CVMContext } from '../../../../components/CVM/CVM';
import { VehicleErrorText } from '../BreadCrumbContent';

const ModelDropDown = styled(Dropdown)`
  width: 335px;
  position: absolute;
`;

export const BreadCrumbModel = (props) => {
  // props
  const {
    vehicleErrors, models, standardVehicleFields, setStandardVehicleFields
  } = props;

  // context
  const { setCurrentBreadCrumb } = useContext(CVMContext);

  /**
   * Dropdown selection handler for Model
   * @param {Object} value
   */
  const updateModel = (value) => {
    const item = models.filter((option) => option.label === value.label);
    if (item) {
      delete standardVehicleFields.trim;
      delete standardVehicleFields.trimId;
      delete standardVehicleFields.trimLift;
      delete standardVehicleFields.trimLiftId;
      setStandardVehicleFields({ ...standardVehicleFields, model: item[0] });
      setCurrentBreadCrumb(3);
    }
  };

  // Properties for the Models Dropdown
  const modelsDropdownProps = {
    fieldName: 'model',
    placeholder: 'Model',
    options: models,
    onChange: updateModel,
    selectedOption: standardVehicleFields.model || constants.EMPTY_STRING,
    value: standardVehicleFields.model,
    required: false,
    dropdownHeight: '165',
    isVehicleModal: true
  };

  return (
    vehicleErrors ? <VehicleErrorText>{vehicleErrors.error}</VehicleErrorText> : (
      <>
        <BreadCrumbQuestion>{constants.ADD_NEW_VEHICLE_WHAT_MODEL}</BreadCrumbQuestion>
        <ModelDropDown {...modelsDropdownProps} />
      </>
    )
  );
};

export default BreadCrumbModel;
