import React, { useEffect } from 'react';
import styled from '../../../helpers/esm-styled-components';
import Input from '../../../UIComponents/Input/Input';
import TextArea from '../../../UIComponents/TextArea/TextArea';
import { CharCountLabel } from '../../../UIComponents/Modals/VehicleModals/StandardVehicle/AdditionalVehicleInformation';
import formatMileage from '../../../UIComponents/Modals/VehicleModals/StandardVehicle/VehicleHelper';


const DetailGrid = styled.form`
  display: grid;
  margin: 5px 0 0 40px;
  grid-row-gap: 25px;
  grid-template-columns: repeat(1, 450px);
  grid-template-rows: repeat(3);
  grid-template-areas:
    'mileagelocation'
    'condition'
    'notes';
`;
const MileageLocation = styled.div`
  grid-area: mileagelocation;
  display: inline-flex;
`;
const Mileage = styled(Input)`
  width: 90px;
  margin-right: 30px;
  font-size: 14px;
`;
const Location = styled(Input)`
  grid-area: location;
  width: 330px;
  font-size: 14px;
`;
const Condition = styled(TextArea)`
  grid-area: condition;
`;
const Notes = styled(TextArea)`
  grid-area: notes;
`;

const VehicleFormCounter = styled(CharCountLabel)`
  margin: -30px 0 0 0;
`;
const VehicleInfoForm = (props) => {
  const {
    details, setDetails
  } = props;

  useEffect(() => {
    const mileageEl = document.querySelector('[name="mileage"]');
    if (mileageEl) {
      mileageEl.focus();
    }

  },[]);



  return (
    <DetailGrid>
      <MileageLocation>
        <Mileage
          fieldName="mileage"
          inputValue={formatMileage(details?.mileage)}
          label="Mileage"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={setDetails}
          isDisabled={false}
          tabIndex={0}
          maxLength={8}
          height="40px"
          padding="0px 12px"
          fontSize="14px"
        />
        <Location
          fieldName="location"
          inputValue={details.location}
          label="Location"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={setDetails}
          isDisabled={false}
          tabIndex={0}
          height="40px"
          labelPadding="0px 6px"
          fontSize="14px"
          maxLength={255}
        />
      </MileageLocation>
      <Condition
        fieldName="condition"
        inputValue={details.condition}
        label="Condition"
        errorMessages={[]}
        warningMessages={[]}
        inputHandler={setDetails}
        isDisabled={false}
        tabIndex={0}
        height="65px"
        labelPadding="0px 6px"
        maxLength={255}
      />
      <Notes
        fieldName="comments"
        inputValue={details.comments}
        label="Notes"
        errorMessages={[]}
        warningMessages={[]}
        inputHandler={setDetails}
        isDisabled={false}
        tabIndex={0}
        maxLength={255}
        labelPadding="0px 6px"
      />
      <VehicleFormCounter>
        {details?.comments?.length}/255
      </VehicleFormCounter>
    </DetailGrid>
  );
};

export default VehicleInfoForm;
