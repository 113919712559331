import React from 'react';
import * as constants from '../../constants/Constants';
import SearchHeader from './SearchHeader';

const CustomerSummaryHeader = () => {
  const title = constants.TITLE_CUSTOMER_SUMMARY;

  return (
    <SearchHeader title={title}/>
  );
};

export default CustomerSummaryHeader;
