import React from 'react';
import styled from '../../../helpers/esm-styled-components';
import { theme } from '../../../helpers/GlobalStyles';
import TireTread from '../../../UIComponents/Tires/TireTread';
import * as constants from '../../../constants/Constants';

const TireInfoWrapper = styled.div`
  height: 421px;
  width: 719px;
  display: flex;
  margin-left: 12px;
  margin-top: 15px;
`;
const SideTires = styled.div`
    ${({ side }) => ((side === 'Left' && 'Spare') ? 'width: fit-content;' :  'width: 166px;')};
`;
const TireDiagram = styled.div`
  position: relative;
  width: 390px;
`;

const TireName = styled.div`
  box-sizing: border-box;
  height: 59.4px;
  width: 24.4px;
  border: 1.4px dashed ${theme.colors.mediumGray};
  color: ${theme.colors.lightGray};
  font-family: ${theme.fontFamily.normal};
  font-size: 10px;
  letter-spacing: -0.06px;
  line-height: 17px;
  text-align: center;
  padding-top: 20px;
  position: absolute;
  ${({ position }) => {
    switch (position) {
      case 'LF':
        return 'top: 35px;left: 30px;';
      case 'RF':
        return 'top: 35px;left: 205px;';
      case 'LR':
        return 'top: 225px;left: 30px;';
      case 'RR':
        return 'top: 225px;left: 205px;';
      case 'SP':
        return 'top: 325px;left: 105px; width: 59.4px; height: 24.4px; padding-top:2px;';
      case 'OLR':
        return 'top: 225px;left: 2px;';
      case 'IRR':
        return 'top: 225px;left: 205px;';
      case 'ILR':
        return 'top: 225px;left: 30px;';
      case 'ORR':
        return 'top: 225px;left: 233px;';
      default:
        return constants.EMPTY_STRING;
    }
  }};
`;
const Car = styled.div`
  position: relative;
  height: 316px;
  width: 142px;
  left: 60px;
  padding-right: 50px;
`;
const CarImage = styled.img``;
const RotationDiagram = styled.img`
  top: 45px;
  left: -55px;
  height: 350px;
  width: 250px;
  position: absolute;
  ${({ rotation }) => {
    switch (rotation) {
      case 'Standard':
        return 'top: -10px;';
      case 'Straight':
        return 'top: -10px;';
      case 'Side to Side':
        return 'top: -40px; height:400px;';
      default:
        return '';
    }
  }};
`;
const Rotation5TireDiagram = styled(RotationDiagram)`
  top: 60px;
  left: -55px;
  height: 280px;
  width: 250px;
  position: absolute;
`;

const VehicleDiagram = props => {
  const { treadDetails, selectedVehicle, setTreadDetails } = props;
  const isDually = selectedVehicle.vehicleDetails?.isDualRearWheel || selectedVehicle.vehicleDetails?.isDually;
  const hasTireRotation = () => {
    const rotation = treadDetails?.rotationPattern;
    if (isDually) {
      switch (rotation) {
        case 'None':
          return null;
        case 'Dually':
          return (
            <>
              <RotationDiagram rotation={rotation} src="/VehicleImages/dually-rotation-pattern.png" />
            </>
          );
        default:
          return null;
      }
    } else {
      switch (rotation) {
        case 'None':
          return null;
        case 'Standard':
          return (
            <>
              <RotationDiagram rotation={rotation} src="/VehicleImages/standard-rotation-pattern.png" />
            </>
          );
        case 'Straight':
          return (
            <>
              <RotationDiagram rotation={rotation} src="/VehicleImages/straight-rotation-pattern.png" />
            </>
          );
        case 'Side to Side':
          return (
            <>
              <RotationDiagram rotation={rotation} src="/VehicleImages/side-to-side-rotation-pattern.png" />
            </>
          );
        case '5 Tire':
          return (
            <>
              <Rotation5TireDiagram rotation={rotation} src="/VehicleImages/spare-rotation-pattern.png" />
            </>
          );
        default:
          return null;
      }
    }
  };
  const tireTreadRender = (tire) => {
    return (
      <>
        <TireTread
          dot={tire?.tireItem?.dotNumber}
          position={tire?.tireItem.tireLocation}
          treads={tire?.tireItem.treadDepthCollection || []}
          age={Math.floor(parseFloat(tire?.tireItem.tireAge))}
          treadDetails={treadDetails}
          setTreadDetails={setTreadDetails}
          tireIndex={treadDetails.tireCollection.findIndex((t) => t.tireItem.tireLocation === tire?.tireItem.tireLocation)}
          />
      </>
    );
  };
  const getSides = () => {
    const tireConfiguration = isDually ? constants.DUALLY_VTV : constants.STANDARD_VTV;
    const orderedLocation = [];
    tireConfiguration.forEach((location) => {
      const matchedLocation = treadDetails?.tireCollection.filter((tire) => tire?.tireItem.tireLocation === location)[0];
      orderedLocation.push(matchedLocation);
    });
    const leftTires = isDually ? [orderedLocation[0], orderedLocation[1], orderedLocation[2]] : [orderedLocation[0], orderedLocation[1]];
    const rightTires = isDually ? [orderedLocation[3], orderedLocation[4], orderedLocation[5]] : [orderedLocation[2], orderedLocation[3]];
    const spareTire = [orderedLocation[orderedLocation.length - 1]];
    return (
      <>
        <SideTires side="Left">
          {leftTires.map(tire => tireTreadRender(tire))}
        </SideTires>
        <TireDiagram>
          <TireName position="LF">LF</TireName>
          <TireName position="RF">RF</TireName>
          <Car>
            <CarImage src="/VehicleImages/MainCar.png" />
            {hasTireRotation()}
          </Car>
          {!isDually && (
            <>
              <TireName position="LR">LR</TireName>
            </>
          )}
          {isDually && (
            <>
              <TireName position="OLR">OLR</TireName>
              <TireName position="ILR">ILR</TireName>
            </>
          )}

          {!isDually && <TireName position="RR">RR</TireName>}
          {isDually && (
            <>
              <TireName position="IRR">IRR</TireName>
              <TireName position="ORR">ORR</TireName>
            </>
          )}
          <TireName position="SP">SP</TireName>
          {tireTreadRender(spareTire[0])}
        </TireDiagram>
        <SideTires side="Right">
          {rightTires.map(tire => tireTreadRender(tire))}
        </SideTires>
      </>
    );

  };


  return (
    <>
      <TireInfoWrapper>
        {getSides()}
      </TireInfoWrapper>
    </>
  );
};

export default VehicleDiagram;
