import Vehicle from '../../../components/Details/Vehicle/Vehicle';
import * as constants from '../../../constants/Constants';
import {
  AddAdditionalVehicleInfo, CloseIcon, ContentContainer,
  ModalContainer, ModalTitle, ReturnToCustomerSummary, WizardButtons,
} from '../ModalUtils';

const IsDeactivatedVehicle = (props) => {
  const {
    vehicle, returnToModule, cancelDeactivate, deactivateVehicle,
  } = props;
  return (
    <ModalContainer modalType={constants.MODAL_TYPE_DEACTIVATE_VEHICLE}>
      <CloseIcon onClick={returnToModule} />
      <ContentContainer>
        <ModalTitle margin="0 0 10px 0">{constants.DEACTIVATE_VEHICLE_MODAL_TITLE}</ModalTitle>
        <Vehicle isModalView vehicle={vehicle} noClickEvent/>
        <WizardButtons margin="10px 0 0 0">
          <ReturnToCustomerSummary margin="0" buttonName={constants.DEACTIVATE_VEHICLE_MODAL_CANCEL_BUTTON} onButtonClick={cancelDeactivate}/>
          <AddAdditionalVehicleInfo margin="0" buttonName={constants.DEACTIVATE_VEHICLE_MODAL_CONFIRM_BUTTON} onButtonClick={deactivateVehicle}/>
        </WizardButtons>
      </ContentContainer>
    </ModalContainer>
  );
};
export default IsDeactivatedVehicle;
