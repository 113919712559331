import React, { useContext } from 'react';
import styled from '../../../../helpers/esm-styled-components';
import Dropdown from '../../../Dropdown/Dropdown';
import { BreadCrumbQuestion } from '../../ModalUtils';
import * as constants from '../../../../constants/Constants';
import { CVMContext } from '../../../../components/CVM/CVM';
import { VehicleErrorText } from '../BreadCrumbContent';

const MakeDropDown = styled(Dropdown)`
  width: 335px;
  position: absolute;
`;

export const BreadCrumbMake = (props) => {
  // props
  const {
    vehicleErrors, makes, standardVehicleFields, setStandardVehicleFields
  } = props;

  // context
  const { setCurrentBreadCrumb } = useContext(CVMContext);

  /**
   * Dropdown selection handler for Make
   * @param {Object} value
   */
  const updateMake = (value) => {
    const item = makes.filter((option) => option.label === value.label);
    if (item) {
      delete standardVehicleFields.model;
      delete standardVehicleFields.trim;
      delete standardVehicleFields.trimId;
      delete standardVehicleFields.tiresize;
      delete standardVehicleFields.wheel;
      setStandardVehicleFields({ ...standardVehicleFields, make: item[0] });
      setCurrentBreadCrumb(2);
    }
  };

  // Properties for the Make Dropdown
  const makeDropdownProps = {
    fieldName: 'make',
    placeholder: 'Make',
    options: makes,
    onChange: updateMake,
    selectedOption: standardVehicleFields.make || constants.EMPTY_STRING,
    value: standardVehicleFields.make,
    required: false,
    dropdownHeight: '165',
    isVehicleModal: true
  };

  return (
    vehicleErrors ? <VehicleErrorText>{vehicleErrors.error}</VehicleErrorText> : (
      <>
        <BreadCrumbQuestion>{constants.ADD_NEW_VEHICLE_WHAT_MAKE}</BreadCrumbQuestion>
        <MakeDropDown {...makeDropdownProps} />
      </>
    )
  );
};

export default BreadCrumbMake;
