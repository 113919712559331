import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import { FaRegTimesCircle } from 'react-icons/fa';
import * as constants from '../../constants/Constants';
import {
  DropdownContainer, DropdownHeader, DropdownPlaceholder, DropdownRequired, ArrowIcon,
  DropdownItems, DropdownItem, InputField, NoDropdownItem, Close,
} from './DropdownUtil';
import { simulateAction } from '../../helper/simulateActions';
import { isEmptyArray, isNullOrUndefined } from '../../helper/validationHelper';
import { theme } from '../../helpers/GlobalStyles';
import { CVMContext } from '../../components/CVM/CVM';
import Message from '../Error/Message';


const Dropdown = props => {
  const {
    options,
    placeholder,
    fieldName,
    selectedOption,
    onChange,
    dropdownHeight,
    required,
    disabled,
    tabIndex,
    className,
    positionStyle,
    inputHeight,
    smallDropdown,
    displayValue,
    errorMessage,
    provinceOrState,
    maxLength,
    hasCustomInput,
    handleOnBlur,
    // Remove this condition if UX wants same functionality on all dropdowns in future
    isVehicleModal,
    onClickClear,
  } = props;

  const { currentViewElement } = useContext(CVMContext);

  const ref = useRef(null);
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchItem, setSearchItem] = useState(constants.EMPTY_STRING);
  const [isSearching, setIsSearching] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [inputValue, setInputValue] = useState(constants.EMPTY_STRING);

  useEffect(() => {
    // If we need the auto select in future comment out instances of isVehicleModal
    if (isOpen && isVehicleModal) {
      setFocusedIndex(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (options && isVehicleModal && searchItem) {
      const formattedSearch = searchItem.toLowerCase();
      const isSearchItemFound = options.some(option => {
        const formattedOption = option.label.toLowerCase();
        return formattedOption.includes(formattedSearch);
      });

      const indexOfOption = options.findIndex(option => {
        const formattedOption = option.label.toLowerCase();
        return formattedOption.includes(formattedSearch);
      });

      if (isSearchItemFound) {
        setFocusedIndex(indexOfOption);
      }

      if (searchItem && !isSearchItemFound) {
        setFocusedIndex(-1);
      }
    }

  }, [searchItem, options, isVehicleModal]);

  const handleBlur = (event) => {
    if (event.relatedTarget?.classList.contains('dd-item')) {
      event.preventDefault();
    } else {
      setIsSearching(false);
      setIsOpen(false);
      if (hasCustomInput) {
        handleOnBlur(fieldName, searchItem);
        setSearchItem(constants.EMPTY_STRING);
      } else {
        setSearchItem(selectedOption?.label);
      }
    }
  };

  //  opens/closes the dropdown in response to mouse and keyboard
  const toggleDropdown = (event) => {
    setSearchItem(constants.EMPTY_STRING);
    setIsSearching(true);
    setIsOpen(true);
  };

  //  Filter the list possible option values based on any search string.
  const getFilteredOptions = (opts) => {
    if (searchItem?.length > 0) {
      const formattedSearch = searchItem.toLowerCase();
      return opts.filter(c => {
        const formattedLabel = c.label.toLowerCase();
        return formattedLabel.includes(formattedSearch);
      });
    }
    return opts;
  };

  //  Function to handle up/down arrow navigation in the DD
  const navigateDropdown = (event) => {
    const direction = event.keyCode === constants.UP_ARROW_KEY_CODE ? -1 : 1;
    const ddItems = document.getElementsByClassName(`${fieldName}-dd-items`);
    if (isOpen && ddItems.length > 0) {
      event.preventDefault();
      let newIndex = focusedIndex + direction;
      newIndex = Math.max(0, Math.min(newIndex, ddItems.length - 1));
      if (ddItems[newIndex]) {
        ddItems[newIndex].focus(); // Focus on the selected item
      }
      setFocusedIndex(newIndex);
    }
  };
  const handleOptionClicked = (event, value) => {
    event.preventDefault();
    onChange(value, fieldName, options);
    setIsSearching(false);
    setInputValue(value.label);
    setSearchItem(constants.EMPTY_STRING);
    setIsOpen(false);
    setFocusedIndex(isVehicleModal ? 0 : -1);
  };

  //  Invoked by input field in response to value changes (user input).
  const handleChange = (event) => {
    if (event.keyCode === constants.UP_ARROW_KEY_CODE || event.keyCode === constants.DOWN_ARROW_KEY_CODE) {
      navigateDropdown(event);
      return;
    }
    if (isVehicleModal && (event.keyCode === constants.ENTER_KEY_CODE || event.keyCode === constants.TAB_KEY_CODE)) {
      event.preventDefault();
      if (focusedIndex !== -1) {
        handleOptionClicked(event, options[focusedIndex], fieldName);
      }

      if (fieldName === 'trim') {
        if (focusedIndex !== -1) {
          handleOptionClicked(event, options[focusedIndex], fieldName);
          // Timeout to shift focus after the click event
          setTimeout(() => {
            const inputs = document.getElementsByTagName('input');
            const currentInputIndex = Array.prototype.indexOf.call(inputs, event.target);
            if (currentInputIndex !== -1 && inputs[currentInputIndex + 1]) {
              inputs[currentInputIndex + 1].focus();
            }
          }, 0);
        }
      }
    }
    if (event.keyCode === constants.ENTER_KEY_CODE) {
      simulateAction(constants.CLICK, constants.MOUSE_EVENT, document.activeElement);
    } else {
      setIsSearching(true);
      setSearchItem(event.target.value);
    }
  };

  //  Invoked to create the individual <option>s for the dropdown.
  const generateDropdownOptions = () => {
    const filteredItems = getFilteredOptions(options);
    if (filteredItems.length === 0) {
      return <NoDropdownItem>{ !hasCustomInput ? constants.NO_RESULT_FOUND : searchItem }</NoDropdownItem>;
    }
    return filteredItems.map((option, index) => {
      return (
        <DropdownItem
          className={`dd-item ${fieldName}-dd-items`}
          tabIndex={-1}
          aria-labelledby={option.label}
          onKeyDown={(e) => handleChange(e, option, fieldName, index)}
          onClick={(e) => handleOptionClicked(e, option, fieldName)}
          key={index}
          fieldName={fieldName}
          isSelected={options[focusedIndex]?.value === option?.value}
        >
          {!displayValue ? option.label : option.value}
        </DropdownItem>
      );
    });
  };
  return (
    <DropdownContainer onBlur={handleBlur} ref={ref} className={className}>
      <DropdownHeader theme={theme} isOpen={isOpen} smallDropdown={smallDropdown}>
        <InputField
          onFocus={toggleDropdown}
          onMouseDown={toggleDropdown}
          onKeyDown={handleChange}
          onBlur={handleBlur}
          value={isOpen ? searchItem : selectedOption?.label || constants.EMPTY_STRING}
          ref={inputRef}
          errorMessage={errorMessage}
          tabIndex={tabIndex}
          onChange={handleChange}
          disabled={disabled}
          fieldName={fieldName}
          name={fieldName}
          inputHeight={inputHeight}
          maxLength={maxLength}
          autoComplete="off"
        />
        {selectedOption?.value && onClickClear ? (
          <Close>
            <FaRegTimesCircle onClick={onClickClear} />
          </Close>
        ) : (
          <ArrowIcon>{isOpen ? <IoChevronUpOutline /> : <IoChevronDownOutline />}</ArrowIcon>
        )}
        { errorMessage && !isEmptyArray(errorMessage) && <Message ref={inputRef} errorMessages={errorMessage} type={constants.ERROR} />}
        <DropdownPlaceholder
          isOpen={isOpen}
          disabled={disabled}
          selectedOption={selectedOption?.value === constants.EMPTY_STRING
            ? constants.EMPTY_STRING
            : selectedOption}
          fieldName={fieldName}
          hasSearchItem={isNullOrUndefined(searchItem)
            ? constants.EMPTY_STRING
            : searchItem.toString().length > 0}
          smallDropdown={smallDropdown}
          provinceOrState={provinceOrState}
        >
          <DropdownRequired required={required}>*</DropdownRequired>
          {placeholder}
        </DropdownPlaceholder>
        {isOpen && (
          <DropdownItems
            id={`${fieldName}-dd-item-parent`}
            position={inputRef.current?.getBoundingClientRect()}
            dropdownHeight={dropdownHeight}
            innerHeight={document?.querySelectorAll(`.${currentViewElement}`)[0]?.getBoundingClientRect().bottom}
            positionStyle={positionStyle}
            isOpen={isOpen}
            smallDropdown={smallDropdown}
          >
            {generateDropdownOptions()}
          </DropdownItems>
        )}
      </DropdownHeader>
    </DropdownContainer>
  );
};

export default Dropdown;
