import React from 'react';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import CustomerForm from './SearchByCustomer';

const CreateModule = styled.div``;
const Search = () => {
  return (
    <CreateModule>
      <CustomerForm
        detailTitle={constants.TITLE_NEW_CUSTOMER}
        formButtonName={constants.BUTTON_CREATE_CUSTOMER}
        isCreate
      />
    </CreateModule>
  );
};

export default Search;
