import { useState, useEffect } from 'react';
import styled from '../../../helpers/esm-styled-components';
import { theme } from '../../../helpers/GlobalStyles';
import * as constants from '../../../constants/Constants';
import {
  OEBadge,
  OEBadgeWrapper,
  SelectedVehicleCheck,
  SelectedVehicleLabel,
  SelectedVehicleEditLabel,
  SelectedVehicleLabelWrapper,
  FactoryTireSizeWrapper,
  FactoryTireSizeLabel,
  FactoryTireSize,
  EditIcon,
  SmallVerticalBar,
  WheelSize,
  WheelSizeLabel,
} from './VehicleDetails';
import VehicleInfoForm from './VehicleInfoForm';
import CreateCustomerButton from '../Search/CreateButton';
import { isNullEmptyUndefined } from '../../../helper/validationHelper';
import genericVehicleImageHelper from '../../../helper/genericVehicleImageHelper';
import { isGenericVehicle } from '../../../helper/vehicleFormValidation';
import SelectButton from '../../../UIComponents/Button/SelectButton';

const AddImportedVehicle = styled(SelectButton)``;
const VehicleContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
  width: fit-content;
  ${({ isDetailView, isVTVAircheck }) => isDetailView && isVTVAircheck && 'margin: 0px; width: 100%'};
  ${({ isModalView }) => isModalView && 'width:458px'};
  ${({ noClickEvent }) => noClickEvent && 'cursor: none; pointer-events:none;'};
`;
const VehicleImageWrapper = styled.div``;
const VehicleImage = styled.img`
  margin-right: 30px;
  ${({ isAutoSync }) => isAutoSync && 'transform: scaleX(-1)'};
  ${({ isDetailView }) => `height: ${isDetailView ? '162px' : '130px'}`};
  ${({ isDetailView }) => `width: ${isDetailView ? '302px' : '237px'}`};
  ${({ isVTVAircheck }) => isVTVAircheck && 'width:241.66px; height:129.94px; margin:24px 0 0 17px;'};
  ${({ noVehicle }) => noVehicle && 'height: 160px; padding-left:10px'};
`;
const VehicleContent = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const VehicleInfoWrapper = styled.div`
  min-width: 240px;
  max-width: 300px;
  ${({ isDetailView }) => isDetailView && 'margin-right: 15px;'}
  ${({ isVTVAircheck }) => isVTVAircheck && 'margin: 0px; min-width:auto;'};
`;
const GenericVehicleLabel = styled.div`
  margin-bottom: -25px;
  display: flex;
  align-items: center;
  font-family: ${theme.fontFamily.bold};
  color: ${theme.colors.lightGray};
`;

const CarIcon = styled.img`
  height: 1em;
  width: 1em;
  border-radius: 2px;
  margin-right: 8px;
  color: ${theme.colors.lightGray};
`;

const VehicleYearMake = styled.p`
  color: ${theme.colors.darkGray};
  font-weight: ${theme.fontWeight.bold};
  font-family: ${theme.fontFamily.black};
  text-transform: uppercase;
  ${({ incompleteVehicle }) => incompleteVehicle && 'margin-top: 0'};
  ${({ isDetailView }) => (isDetailView
    ? `font-size: ${theme.fontSize.vehicleYearMakeDetailsView};`
    : `font-size: ${theme.fontSize.xLarge}`)};
  ${({ isVTVAircheck }) => isVTVAircheck && `font-size:${theme.fontSize.xLarge};margin: 11px 0 0 0;`};
`;

const VehicleModelTrim = styled.div`
  margin-top: -25px;
  max-width: 260px;
  column-gap: 10px;
  font-size: ${theme.fontSize.infoSubtitle};
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;
  font-family: ${theme.fontFamily.normal};
  ${({ isDetailView }) => isDetailView &&
    `font-size:${theme.fontSize.vehicleModelDetailsView};margin-top: -35px;`};
  ${({ isVTVAircheck }) => isVTVAircheck &&
    `font-size:${theme.fontSize.infoSubtitle}; margin: -6px 0 4px 0;`};
`;
const VehicleDetail = styled.div``;

const VehicleTrimLift = styled.div`
  margin: 2px 2px 2px 0;
  border-top: 1px solid ${theme.colors.veryLightGray};
  padding-top: 3px;
  display: block;
  text-transform: uppercase;
  font-family: ${theme.fontFamily.normal};
  ${({ isDetailView }) => isDetailView &&
    `font-size:${theme.fontSize.vehicleModelDetailsView};margin-top: -35px;`};
`;

const VehicleColor = styled.p`
  font-family: ${theme.fontFamily.bold};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.large};
  text-transform: capitalize;
  margin-top: 10px;
  ${({ isDetailView }) => isDetailView && `font-size: ${theme.fontSize.editLabel};`}
  ${({ isVTVAircheck }) => isVTVAircheck && `margin-top:-6px; font-size: ${theme.fontSize.large};`};
`;
const VehicleColorLabel = styled.span`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.large};
  font-weight: normal;
  ${({ isDetailView }) => isDetailView &&
    `margin-right: 0px; font-size: ${theme.fontSize.editLabel};`};
  ${({ isVTVAircheck }) => isVTVAircheck && `margin-top:-6px; font-size: ${theme.fontSize.large};`};
`;
const LicensePlate = styled.p`
  font-family: ${theme.fontFamily.bold};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.large};
  margin-top: -10px;
  ${({ isDetailView }) => isDetailView &&
    `margin-right: 0px;font-size: ${theme.fontSize.editLabel};`};
  ${({ isVTVAircheck }) => isVTVAircheck && `margin-top:-6px; font-size: ${theme.fontSize.large};`};
`;
const LicensePlateLabel = styled.span`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.large};
  font-weight: normal;
  ${({ isDetailView }) => isDetailView &&
    `margin-right: 0px;font-size: ${theme.fontSize.editLabel};`};
  ${({ isVTVAircheck }) => isVTVAircheck && `margin-top:-6px; font-size: ${theme.fontSize.large};`};
`;
// VTVAircheck Styling
const SelectedVehicleWrapper = styled(SelectedVehicleLabelWrapper)`
  margin: 6px 0 0 18px;
`;
const SelectedLabel = styled(SelectedVehicleLabel)`
  font-family: ${theme.fontFamily.bold};
  font-size: ${theme.fontSize.large};
`;
export const IncompleteVehicleLabel = styled.p`
  margin: 15px 0 8px 0;
  background: ${theme.colors.orange};
  padding: 3px 0;
  border-radius: 4px;
  width: 120px;
  height: 24px;
  font-size: 14px;
  font-family: ${theme.fontFamily.normal};
  font-weight: 800;
  text-align: center;
`;
const TireInfoContainer = styled.div`
  position: relative;
  display: inline-flex;
  height: 48px;
  margin-right: 30px;
`;
const BadgeWrapper = styled(OEBadgeWrapper)`
  margin: 6px 9px 0 0;
  position: relative;
  width: 35px;
  height: 27px;
  text-align: center;
`;
const Badge = styled(OEBadge)`
  padding: 6px 0;
  margin: 0;
  font-size: ${theme.fontSize.medium};
`;
const TireSizeWrapper = styled(FactoryTireSizeWrapper)`
  margin: 0;
`;
const TireSize = styled(FactoryTireSize)`
  margin: 0;
  font-size: ${theme.fontSize.infoSubtitle};
`;
const TireSizeLabel = styled(FactoryTireSizeLabel)`
  margin: 0;
  font-size: ${theme.fontSize.medium2};
`;
const VIN = styled(LicensePlate)`
  margin: ${({ isStaggered }) => (isStaggered ? '50px' : '1px')} 0 0 40px;
`;
const VINLabel = styled(LicensePlateLabel)``;
const AddVehicleButton = styled(CreateCustomerButton)`
  border-bottom: 1px solid black;
  font-size: ${theme.fontSize.modalTitle};
  padding: 5px 0 15px 0;
  width: fit-content;
`;

const Vehicle = (props) => {
  const {
    vehicle,
    isDetailView,
    isModalView,
    isVTVAircheck,
    onSelect,
    editVehicle,
    isHidden,
    inspectionDetails,
    setInspectionDetails,
    assemblyDetails,
    hasImportedVehicle,
  } = props;
  const isGeneric =
    isGenericVehicle(vehicle) ||
    vehicle?.overrideDescription === constants.VEHICLE_TYPE_GENERIC_VEHICLE;
  const genericYMM =
    vehicle?.overrideYear !== 0 &&
    vehicle?.overrideYear &&
    vehicle?.overrideMake &&
    vehicle?.overrideModel &&
    isGeneric;
  const [selectedAssembly, setSelectedAssembly] = useState(
    vehicle?.assemblyCollection?.filter(
      (ac) => ac.assemblyItem.currentAssembly
    )[0]?.assemblyItem || vehicle?.assemblyCollection?.[0]?.assemblyItem
  );
  const [isStaggered, setIsStaggered] = useState(vehicle?.isStaggered);
  const [isOE, setIsOE] = useState(
    (vehicle?.assemblyCollection?.filter(
      (ac) => ac.assemblyItem.currentAssembly
    )[0]?.assemblyItem?.assemblyType ||
      vehicle?.assemblyCollection?.[0]?.assemblyItem.assemblyType) ===
      constants.VEHICLE_OE_ASSEMBLY_TYPE
  );
  const [isAfterMarket, setIsAfterMarket] = useState(
    (vehicle?.assemblyCollection?.filter(
      (ac) => ac.assemblyItem.currentAssembly
    )[0]?.assemblyItem.assemblyType ||
      vehicle?.assemblyCollection?.[0]?.assemblyItem.assemblyType) ===
      constants.VEHICLE_ALT_ASSEMBLY_TYPE
  );
  useEffect(() => {
    setSelectedAssembly(
      vehicle?.assemblyCollection?.filter(
        (ac) => ac.assemblyItem.currentAssembly
      )[0]?.assemblyItem || vehicle?.assemblyCollection?.[0]?.assemblyItem
    );
    setIsStaggered(vehicle?.isStaggered);
    setIsOE(
      (vehicle?.assemblyCollection?.filter(
        (ac) => ac.assemblyItem.currentAssembly
      )[0]?.assemblyItem?.assemblyType ||
        vehicle?.assemblyCollection?.[0]?.assemblyItem.assemblyType) ===
        constants.VEHICLE_OE_ASSEMBLY_TYPE
    );
    setIsAfterMarket(
      (vehicle?.assemblyCollection?.filter(
        (ac) => ac.assemblyItem.currentAssembly
      )[0]?.assemblyItem.assemblyType ||
        vehicle?.assemblyCollection?.[0]?.assemblyItem.assemblyType) ===
        constants.VEHICLE_ALT_ASSEMBLY_TYPE
    );
  }, [vehicle]);

  return (
    <VehicleContent>
      {vehicle ? (
        <>
          <VehicleContainer
            isDetailView={isDetailView}
            isVTVAircheck={isVTVAircheck}
            isModalView={isModalView}
            onClick={
              !isDetailView
                ? (e) => vehicle?.vehicleDetails?.vehicleId !== vehicle.vehicleId &&
                    onSelect(e, vehicle)
                : () => {}
            }
          >
            <VehicleImageWrapper>
              <VehicleImage
                isDetailView={isDetailView}
                isVTVAircheck={isVTVAircheck}
                isAutoSync={vehicle.imageUrl?.indexOf('googleapis') > -1}
                src={
                  !isNullEmptyUndefined(vehicle?.imageUrl)
                    ? vehicle?.imageUrl
                    : isGeneric
                      ? genericVehicleImageHelper(
                        vehicle?.overrideVehicleCategory?.toLowerCase() ||
                          vehicle?.overrideVehicleSubCategory?.toLowerCase()
                      )
                      : constants.DEFAULT_VEHICLE_IMAGE
                }
              />
              {isVTVAircheck && (
                <VIN isStaggered={isStaggered}>
                  <VINLabel>{constants.VEHICLE_VIN_LABEL}: </VINLabel>
                  {vehicle?.vin?.toUpperCase() || constants.NOT_APPLICABLE}
                </VIN>
              )}
            </VehicleImageWrapper>
            <VehicleInfoWrapper
              isDetailView={isDetailView}
              isVTVAircheck={isVTVAircheck}
            >
              {isVTVAircheck && (
                <SelectedVehicleWrapper>
                  <SelectedLabel>
                    <SelectedVehicleCheck data-testid="selectedVehicle" />
                    {constants.VEHICLE_SELECTED_VEHICLE_LABEL}
                  </SelectedLabel>
                  <SelectedVehicleEditLabel
                    isHidden={isHidden}
                    onClick={editVehicle}
                  >
                    {constants.VEHICLE_SELECTED_VEHICLE_EDIT_LABEL}
                    <EditIcon />
                  </SelectedVehicleEditLabel>
                </SelectedVehicleWrapper>
              )}
              {isGeneric && isDetailView && !isVTVAircheck && (
                <GenericVehicleLabel>
                  <CarIcon
                    src="./VehicleImages/car.png"
                    alt="generic vehicle icon"
                  />
                  {constants.GENERIC_VEHICLE_LABEL}
                </GenericVehicleLabel>
              )}
              {vehicle.isIncomplete && (
                <IncompleteVehicleLabel>
                  {constants.INCOMPLETE_VEHICLE_LABEL}
                </IncompleteVehicleLabel>
              )}
              <VehicleYearMake
                incompleteVehicle={vehicle.isIncomplete}
                data-testid="vehicleYearMake"
                isDetailView={isDetailView}
                isVTVAircheck={isVTVAircheck}
              >
                {!isGeneric
                  ? `${vehicle.year || constants.EMPTY_STRING} ${
                    vehicle.make || constants.EMPTY_STRING
                  }`
                  : !genericYMM
                    ? `${vehicle.overrideVehicleCategory}`
                    : `${vehicle.overrideYear || constants.EMPTY_STRING} ${
                      vehicle.overrideMake || constants.EMPTY_STRING
                    }`}
              </VehicleYearMake>
              <VehicleModelTrim
                data-testid="vehicleModel"
                isDetailView={isDetailView}
                isVTVAircheck={isVTVAircheck}
                isSingleWord={!vehicle.overrideTrim?.indexOf(' ') >= 0}
              >
                <VehicleDetail>
                  {!isGeneric
                    ? `${vehicle.model || constants.EMPTY_STRING}`
                    : genericYMM
                      ? `${vehicle?.overrideModel || constants.EMPTY_STRING}`
                      : vehicle?.overrideVehicleSubCategory ||
                      constants.EMPTY_STRING}
                </VehicleDetail>
                {isGeneric ? (
                  genericYMM && (
                    <VehicleDetail>
                      {vehicle.overrideTrim || constants.EMPTY_STRING}
                    </VehicleDetail>
                  )
                ) : (
                  <VehicleDetail>
                    {vehicle?.trimDescription || constants.EMPTY_STRING}
                  </VehicleDetail>
                )}
              </VehicleModelTrim>
              {vehicle?.fitmentLiftedId && (
                <VehicleTrimLift
                  data-testid="vehicleTrim"
                  isVTVAircheck={isVTVAircheck}
                >
                  {vehicle?.fitmentLiftedId || constants.EMPTY_STRING}
                </VehicleTrimLift>
              )}
              {isVTVAircheck &&
                !isNullEmptyUndefined(selectedAssembly) &&
                (!isStaggered ? (
                  <TireInfoContainer>
                    {!isGeneric && (
                      <BadgeWrapper>
                        {!isAfterMarket && !isGeneric ? (
                          <Badge
                            name={
                              selectedAssembly?.assemblyType !==
                              constants.VEHICLE_WTR_ASSEMBLY_TYPE
                                ? constants.VEHICLE_OE_LABEL
                                : constants.VEHICLE_WTR_LABEL
                            }
                          >
                            {selectedAssembly?.assemblyType !==
                            constants.VEHICLE_WTR_ASSEMBLY_TYPE
                              ? constants.VEHICLE_OE_LABEL
                              : constants.VEHICLE_WTR_LABEL}
                          </Badge>
                        ) : selectedAssembly?.assemblyType ===
                          constants.VEHICLE_WTR_ASSEMBLY_TYPE ? (
                            <Badge name={constants.VEHICLE_WTR_LABEL}>
                              {constants.VEHICLE_WTR_LABEL}
                            </Badge>
                          ) : (
                            <Badge
                              name={
                              constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL
                            }
                          >
                              {selectedAssembly.sizeQualifier}
                            </Badge>
                          )}
                      </BadgeWrapper>
                    )}
                    <TireSizeWrapper>
                      <TireSize>
                        {`${assemblyDetails?.tireConstruction[0] === constants.ASSEMBLY_TIRECONSTRUCTION ? '' : (assemblyDetails?.tireConstruction[0] || constants.EMPTY_STRING)}${selectedAssembly?.frontTireWidth}/${
                          selectedAssembly?.frontTireAspectRatio
                        } R${selectedAssembly?.frontTireDiameter} ${assemblyDetails?.loadIndex[0] || constants.EMPTY_STRING}${assemblyDetails?.speedRating[0] || constants.EMPTY_STRING} ${assemblyDetails?.construct[0] || constants.EMPTY_STRING}
                        ` || constants.NOT_APPLICABLE}
                      </TireSize>
                      <TireSizeLabel data-testid="tireSize">
                        {!isGeneric
                          ? isAfterMarket
                            ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_LABEL
                            : constants.VEHICLE_FACTORY_TIRE_SIZE_LABEL
                          : constants.VEHICLE_TIRE_WHEEL_LABEL}
                      </TireSizeLabel>
                    </TireSizeWrapper>
                  </TireInfoContainer>
                ) : (
                  <>
                    <TireInfoContainer>
                      {!isGeneric && (
                        <BadgeWrapper>
                          {!isAfterMarket ? (
                            <Badge
                              name={
                                selectedAssembly?.assemblyType !==
                                constants.VEHICLE_WTR_ASSEMBLY_TYPE
                                  ? constants.VEHICLE_OE_LABEL
                                  : constants.VEHICLE_WTR_LABEL
                              }
                            >
                              {selectedAssembly?.assemblyType !==
                              constants.VEHICLE_WTR_ASSEMBLY_TYPE
                                ? constants.VEHICLE_OE_LABEL
                                : constants.VEHICLE_WTR_LABEL}
                            </Badge>
                          ) : selectedAssembly?.assemblyType ===
                            constants.VEHICLE_WTR_ASSEMBLY_TYPE ? (
                              <Badge name={constants.VEHICLE_WTR_LABEL}>
                                {constants.VEHICLE_WTR_LABEL}
                              </Badge>
                            ) : (
                              <Badge
                                name={
                                constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL
                              }
                            >
                                {selectedAssembly.sizeQualifier}
                              </Badge>
                            )}
                        </BadgeWrapper>
                      )}
                      <TireSizeWrapper>
                        <TireSize>
                          {`${assemblyDetails?.tireConstruction[0] === constants.ASSEMBLY_TIRECONSTRUCTION ? '' : (assemblyDetails?.tireConstruction[0] || constants.EMPTY_STRING)}${selectedAssembly.frontTireWidth}/${selectedAssembly.frontTireAspectRatio} R${selectedAssembly.frontTireDiameter} ${assemblyDetails?.loadIndex[0] || constants.EMPTY_STRING}${assemblyDetails?.speedRating[0] || constants.EMPTY_STRING} ${assemblyDetails?.construct[0] || constants.EMPTY_STRING}` ||
                            selectedAssembly?.factoryTireSize ||
                            constants.NOT_APPLICABLE}
                        </TireSize>
                        <TireSizeLabel data-testid="tireSize">
                          {!isGeneric
                            ? isAfterMarket
                              ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_FRONT_LABEL
                              : constants.VEHICLE_FACTORY_TIRE_SIZE_FRONT_LABEL
                            : constants.VEHICLE_TIRE_WHEEL_LABEL}
                        </TireSizeLabel>
                      </TireSizeWrapper>
                    </TireInfoContainer>
                    <TireInfoContainer>
                      {!isGeneric && (
                        <BadgeWrapper>
                          {!isAfterMarket ? (
                            <Badge
                              name={
                                selectedAssembly?.assemblyType !==
                                constants.VEHICLE_WTR_ASSEMBLY_TYPE
                                  ? constants.VEHICLE_OE_LABEL
                                  : constants.VEHICLE_WTR_LABEL
                              }
                            >
                              {selectedAssembly?.assemblyType !==
                              constants.VEHICLE_WTR_ASSEMBLY_TYPE
                                ? constants.VEHICLE_OE_LABEL
                                : constants.VEHICLE_WTR_LABEL}
                            </Badge>
                          ) : selectedAssembly?.assemblyType ===
                            constants.VEHICLE_WTR_ASSEMBLY_TYPE ? (
                              <Badge name={constants.VEHICLE_WTR_LABEL}>
                                {constants.VEHICLE_WTR_LABEL}
                              </Badge>
                            ) : (
                              <Badge
                                name={
                                constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL
                              }
                            >
                                {selectedAssembly.sizeQualifier}
                              </Badge>
                            )}
                        </BadgeWrapper>
                      )}
                      <TireSizeWrapper>
                        <TireSize>
                          {`${assemblyDetails?.tireConstruction[1] === constants.ASSEMBLY_TIRECONSTRUCTION ? '' : (assemblyDetails?.tireConstruction[1] || constants.EMPTY_STRING)}${selectedAssembly.rearTireWidth}/${
                            selectedAssembly.rearTireAspectRatio
                          } R${selectedAssembly.rearTireDiameter} ${assemblyDetails?.loadIndex[1] || constants.EMPTY_STRING}${assemblyDetails?.speedRating[1] || constants.EMPTY_STRING} ${assemblyDetails?.construct[1] || constants.EMPTY_STRING}` ||
                            selectedAssembly?.factoryTireSize ||
                            constants.NOT_APPLICABLE}
                        </TireSize>
                        <TireSizeLabel data-testid="tireSize">
                          {!isGeneric
                            ? isAfterMarket
                              ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_REAR_LABEL
                              : constants.VEHICLE_FACTORY_TIRE_SIZE_REAR_LABEL
                            : constants.VEHICLE_TIRE_WHEEL_LABEL}
                        </TireSizeLabel>
                      </TireSizeWrapper>
                    </TireInfoContainer>
                  </>
                ))}
              {
                  !hasImportedVehicle ? (
                    <>
                      <VehicleColor
                        isDetailView={isDetailView}
                        isVTVAircheck={isVTVAircheck}
                  >
                        <VehicleColorLabel
                          data-testid="vehicleColor"
                          isDetailView={isDetailView}
                          isVTVAircheck={isVTVAircheck}
                    >
                          {constants.VEHICLE_COLOR_LABEL}:{' '}
                        </VehicleColorLabel>
                        {vehicle.color || constants.NOT_APPLICABLE}
                      </VehicleColor>
                      <LicensePlate
                        isDetailView={isDetailView}
                        isVTVAircheck={isVTVAircheck}
                  >
                        <LicensePlateLabel
                          data-testid="vehicleLicensePlate"
                          isDetailView={isDetailView}
                          isVTVAircheck={isVTVAircheck}
                    >
                          {constants.VEHICLE_LICENSE_PLATE_LABEL}:{' '}
                        </LicensePlateLabel>
                        {vehicle.licensePlateNumber?.toUpperCase() ||
                      vehicle.license?.toUpperCase() ||
                      constants.NOT_APPLICABLE}
                      </LicensePlate>
                    </>
                  ) : (
                    <AddImportedVehicle
                      buttonType={constants.DEFAULT}
                      buttonName="Add This Vehicle"
                      onButtonClick={() => {}}
                />
                  )
}
            </VehicleInfoWrapper>
          </VehicleContainer>
        </>
      ) : (
        <VehicleContainer>
          <VehicleImageWrapper>
            <VehicleImage noVehicle src="/VehicleImages/novehicle.png" />
          </VehicleImageWrapper>
          <VehicleInfoWrapper>
            <VehicleYearMake>No vehicle selected</VehicleYearMake>
            <AddVehicleButton
              buttonName={constants.BUTTON_ADD_VEHICLE}
              buttonClick={() => {}}
              buttonType={constants.BUTTON_TYPE_CREATE}
            />
          </VehicleInfoWrapper>
        </VehicleContainer>
      )}
      {isVTVAircheck && (
        <VehicleInfoForm
          details={inspectionDetails}
          setDetails={setInspectionDetails}
        />
      )}
    </VehicleContent>
  );
};

export default Vehicle;
