import React, { useContext } from 'react';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';
import { CVMContext } from '../CVM/CVM';
import Toggle from '../../UIComponents/Toggle/Toggle';
import SearchHeaderButtons from './SearchHeaderButtons';

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1570px;
  margin: 0 auto;
`;
const SearchTitle = styled.div`
  height: 40px;
  width: fit-content;
  font-weight: ${theme.fontWeight.xBold};
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.moduleTitle};
  letter-spacing: 0;
`;
const ToggleWrapper = styled.div`
  left: 0;
  right: 0;
  position: absolute;
  width: 356px;
  margin: auto;
  height: 50px;
  border-radius: 15px;
  padding: 0px;
  background-color: ${theme.colors.offWhite};
  display: fixed;
`;

const SearchHeader = (props) => {
  const {
    isToggle,
    searchOrigin,
    setSearchOrigin,
    setVtvSelected,
    setAircheckSelected,
    moduleName,
  } = useContext(CVMContext);
  const { title } = props;
  const toggleClick = (e, name) => {
    setVtvSelected(null);
    setAircheckSelected(null);
    name === constants.VTV && setSearchOrigin(constants.SEARCH_ORIGIN_VTV);
    name === constants.AIRCHECK && setSearchOrigin(constants.SEARCH_ORIGIN_AIRCHECK);
  };
  return isToggle ? (
    <HeaderContent>
      <SearchTitle>{title}</SearchTitle>
      {moduleName === constants.MODULE_VEHICLE_SEARCH && (
      <ToggleWrapper searchOrigin={searchOrigin}>
        <Toggle
          wrapperWidth={356}
          active={searchOrigin === constants.SEARCH_ORIGIN_VTV}
          position={constants.LEFT}
          size={constants.TOGGLE_SIZE_MEDIUM}
          name={constants.VTV}
          toggleClick={(e) => toggleClick(e, constants.VTV)}
        />
        <Toggle
          wrapperWidth={356}
          active={searchOrigin === constants.SEARCH_ORIGIN_AIRCHECK}
          position={constants.RIGHT}
          size={constants.TOGGLE_SIZE_MEDIUM}
          name={constants.AIRCHECK}
          toggleClick={(e) => toggleClick(e, constants.AIRCHECK)}
        />
      </ToggleWrapper>
      )}
      <SearchHeaderButtons />
    </HeaderContent>
  ) : (
    <HeaderContent>
      <SearchTitle>{!title ? constants.TITLE_SEARCH : title}</SearchTitle>
      <SearchHeaderButtons />
    </HeaderContent>
  );
};

export default SearchHeader;
