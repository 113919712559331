import { useContext } from 'react';
import styled from '../../helpers/esm-styled-components';
import { TitleVBar } from '../../components/Details/DetailSection';
import { theme } from '../../helpers/GlobalStyles';
import { CVMContext } from '../../components/CVM/CVM';
import VehicleWizard from '../Modals/VehicleModals/StandardVehicle/VehicleWizard';

const BreadCrumbContainer = styled.div`
  text-align: right;
  margin: 25px 15px 0 0;
  position: relative;
  float: right;
`;

const BreadCrumb = styled.p`
  color: ${({ active }) => (active ? theme.colors.titleBlack : theme.colors.lightGray)};
  font-family: ${({ active }) => (active ? theme.fontFamily.heavy : theme.fontFamily.normal)};
  ${({ filled }) => filled && `color: ${theme.colors.editBlue};`};
  cursor: pointer;
  display: inline-flex;
  margin-top: -15px;
  ${({ disabled }) => disabled && (`color: ${theme.colors.lightGray}; font-family: ${theme.fontFamily.normal};`)}
`;

const BreadCrumbSeparator = styled(TitleVBar)`
  display: inline;
  margin: 0 8px 0 5px;
`;

const WizardContentWrapper = styled.div`
  padding-bottom: ${({ currentBreadCrumb }) => (currentBreadCrumb !== 5 ? '25px' : '0')};
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Wizard = (props) => {
  const {
    breadCrumbs, arChargeFields, errorMessage, isEdit, setStandardVehicleFields, standardVehicleFields, revertBackToManualEntry, onClickConfirmButton
  } = props;
  const { currentBreadCrumb, setCurrentBreadCrumb } = useContext(CVMContext);

  const navigate = (e, b) => {
    breadCrumbs.forEach((c, index) => {
      if (c.name === b.name) {
        if (breadCrumbs[index === 0 ? 0 : index - 1]?.filled) {
          setCurrentBreadCrumb(index);

        }
      }
    });
  };
  return (
    <>
      <WizardContentWrapper currentBreadCrumb={currentBreadCrumb}>
        <BreadCrumbContainer>
          {
          breadCrumbs.map((c, index) => {
            return (
              <BreadCrumb disabled={c.disabled} filled={c.filled} active={currentBreadCrumb === index} key={index} onClick={(e) => !c.disabled && navigate(e, c)}>
                {c.name} { index !== (breadCrumbs.length - 1) && <BreadCrumbSeparator /> }
              </BreadCrumb>
            );
          })
        }
        </BreadCrumbContainer>
        <VehicleWizard arChargeFields={arChargeFields} errorMessage={errorMessage} isEdit={isEdit} revertBackToManualEntry={revertBackToManualEntry} setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields} onClickConfirmButton={onClickConfirmButton}/>
      </WizardContentWrapper>
    </>
  );
};

export default Wizard;
