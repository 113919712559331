import React from 'react';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import CreateCustomerButton from '../../components/Details/Search/CreateButton';
import { theme } from '../../helpers/GlobalStyles';

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const RadioLabel = styled.label`
  font-size:${theme.fontSize.infoSubtitle};
  font-family: ${theme.fontFamily.black};
  display:flex;
  gap:5px;
  ${({ generic }) => generic && `font-family:${theme.fontFamily.normal}; font-size:${theme.fontSize.large}; align-items:center;`};
  ${({ isOneOption }) => isOneOption && 'margin:30px 0 10px 0'};
`;
const RadioButton = styled.input`
  width: 21px;
  height: 21px;
  background: none;
  &:checked:after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height:14px;
      margin: 3.5px;
      background: ${theme.colors.green};
    }
`;
const RadioDetailWrapper = styled.div`
  margin: 14px 0 0 38px;
  ${({ isOneOption }) => isOneOption && 'margin:10px auto;'};
`;
const RadioDetails = styled.div`
`;
const RadioEditButton = styled(CreateCustomerButton)`
  text-transform: lowercase;
  color: ${theme.colors.blue2};
`;
const Radio = (props) => {
  const {
    name, value, label,
    title, subtitle, isEdit,
    defaultChecked, className, editAction,
    generic, isOneOption,
  } = props;
  return (
    <RadioContainer className={className}>
      <RadioLabel isOneOption={isOneOption} generic={generic}>
        {!isOneOption
        && (
        <RadioButton type="radio"
          name={name}
          value={value}
          defaultChecked={defaultChecked}/>
        )}
        {label}
      </RadioLabel>
      {subtitle && (
        <RadioDetailWrapper isOneOption={isOneOption}>
          <RadioDetails>{title}</RadioDetails>
          <RadioDetails>{subtitle}</RadioDetails>
          {isEdit && (
          <RadioEditButton
            buttonClick={editAction}
            buttonType={constants.BUTTON_TYPE_EDIT}
            buttonName={constants.BUTTON_TYPE_EDIT}/>
          )}
        </RadioDetailWrapper>
      )}
    </RadioContainer>
  );
};
export default Radio;
