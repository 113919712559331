import {
  useState, useEffect, useContext, useMemo
} from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { BreadCrumbQuestion, BreadCrumbSubText } from '../../ModalUtils';
import * as constants from '../../../../constants/Constants';
import Assembly from '../../../Assembly/Assembly';
import styled from '../../../../helpers/esm-styled-components';
import { theme } from '../../../../helpers/GlobalStyles';
import Wheel from '../../../Assembly/Wheel';
import { isNullEmptyUndefined } from '../../../../helper/validationHelper';
import AdditionalWheelSizeOptions from '../StandardVehicle/AdditionalWheelSizeOptions';
import { VehicleErrorText } from '../BreadCrumbContent';
import { APIRouter } from '../../../../Data/APIRouter';
import { CVMContext } from '../../../../components/CVM/CVM';
import { getAutoSyncData } from '../StandardVehicle/VehicleHelper';

export const SeeMoreSizesLabel = styled.p`
  color: ${({ disabled }) => (disabled ? theme.colors.lightGray : theme.colors.editBlue)};
  font-family: ${theme.fontFamily.medium};
  font-size: ${theme.fontSize.dot};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
`;

const WheelSizeSelectLabel = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.medium};
  color: ${theme.colors.lightGray};
  margin-top: 15px;
  margin-bottom: 20px;
`;

const BreadCrumbTireSize = (props) => {
  // props
  const {
    vehicleErrors, isDually, standardVehicleFields, setStandardVehicleFields, oeTireSizes, isEdit
  } = props;

  // context
  const { setLoader } = useContext(CVMContext);

  // states
  const [moreTireSizesOpen, setMoreTireSizesOpen] = useState(false);
  const [additionalWheelSizeSelected, setAdditionalWheelSizeSelected] = useState(null);
  const [currentAfterMarketAssemblyOptions, setCurrentAfterMarketAssemblyOptions] = useState(null);
  const [currentOE, setCurrentOE] = useState(null);
  const [plusSize, setPlusSize] = useState(null);
  const [moreTireSizesDisabled, setMoreTireSizesDisabled] = useState(true);
  const [amTireSizes, setAMTireSizes] = useState(null);
  const [wheelSizes, setWheelSizes] = useState(null);
  const [sizeQualifiers, setSizeQualifiers] = useState(null);
  const [colorsWithImages, setColorsWithImages] = useState(null);

  /**
   * Updates the selected assembly when the user clicks on an O.E. during Adding a Vehicle
   * @param {Object} value
   */
  const updateSelectedAssembly = (value) => {
    const assemblyCollection = [];
    let existingOEAssemblyId;
    Object.assign(assemblyCollection, standardVehicleFields.assemblyCollection);

    // remove existing OE in the list if selecting a new one
    assemblyCollection.forEach((ac, index) => {
      if (ac.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE) {
        existingOEAssemblyId = ac.assemblyItem.assemblyId;
        assemblyCollection.splice(index, 1);
      }
    });

    // check if assembly is staggered
    if (Array.isArray(value.assembly)) {
      // update the assemblyCollection
      assemblyCollection.push({
        assemblyItem: {
          assemblyCode: value.assembly[0].assemblyCode,
          assemblyId: existingOEAssemblyId || value.assembly[0].assemblyId,
          assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
          fitmentId: value.assembly[0].fitmentId,
          frontRimSize: value.assembly[0].wheelSize?.toString(),
          frontTireAspectRatio: value.assembly[0].aspectRatio,
          frontTireDiameter: value.assembly[0].wheelSize,
          frontTireWidth: value.assembly[0].crossSection,
          rearRimSize: value.assembly[1].wheelSize?.toString(),
          rearTireAspectRatio: value.assembly[1].aspectRatio,
          rearTireDiameter: value.assembly[1].wheelSize,
          rearTireWidth: value.assembly[1].crossSection,
          sizeQualifier: '0',
          tireSize: value.assembly[0].tireSize,
          wheelSize: value.assembly[0].wheelSize,
          isOE: true,
          isAfterMarket: false
        }
      });
      setStandardVehicleFields({
        ...standardVehicleFields,
        autoSyncData: {
          autoSync: colorsWithImages,
        },
        assemblyCollection,
        selectedAssembly: [
          {
            assemblyCode: value.assembly[0].assemblyCode,
            assemblyId: existingOEAssemblyId || value.assembly[0].assemblyId,
            assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
            fitmentId: value.assembly[0].fitmentId,
            frontRimSize: value.assembly[0].wheelSize?.toString(),
            frontTireAspectRatio: value.assembly[0].aspectRatio,
            frontTireDiameter: value.assembly[0].wheelSize,
            frontTireWidth: value.assembly[0].crossSection,
            rearRimSize: value.assembly[1].wheelSize?.toString(),
            rearTireAspectRatio: value.assembly[1].aspectRatio,
            rearTireDiameter: value.assembly[1].wheelSize,
            rearTireWidth: value.assembly[1].crossSection,
            sizeQualifier: '0',
            tireSize: value.assembly[0].tireSize,
            wheelSize: value.assembly[0].wheelSize,
            isOE: true,
            isAfterMarket: false,
          },
          {
            assemblyCode: value.assembly[1].assemblyCode,
            assemblyId: existingOEAssemblyId || value.assembly[1].assemblyId,
            assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
            fitmentId: value.assembly[0].fitmentId,
            frontRimSize: value.assembly[0].wheelSize?.toString(),
            frontTireAspectRatio: value.assembly[0].aspectRatio,
            frontTireDiameter: value.assembly[0].wheelSize,
            frontTireWidth: value.assembly[0].crossSection,
            rearRimSize: value.assembly[1].wheelSize?.toString(),
            rearTireAspectRatio: value.assembly[1].aspectRatio,
            rearTireDiameter: value.assembly[1].wheelSize,
            rearTireWidth: value.assembly[1].crossSection,
            sizeQualifier: '0',
            tireSize: value.assembly[1].tireSize,
            wheelSize: value.assembly[1].wheelSize,
            isOE: true,
            isAfterMarket: false
          }
        ],
        chassisId: value.assembly[0].chassisId
      });
    } else {
      assemblyCollection.push({
        assemblyItem: {
          assemblyCode: value.assembly.assemblyCode,
          assemblyId: existingOEAssemblyId || value.assembly.assemblyId,
          assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
          fitmentId: value.assembly.fitmentId,
          frontRimSize: value.assembly.wheelSize?.toString(),
          frontTireAspectRatio: value.assembly.aspectRatio,
          frontTireDiameter: value.assembly.wheelSize,
          frontTireWidth: value.assembly.crossSection,
          rearRimSize: value.assembly.wheelSize?.toString(),
          rearTireAspectRatio: value.assembly.aspectRatio,
          rearTireDiameter: value.assembly.wheelSize,
          rearTireWidth: value.assembly.crossSection,
          sizeQualifier: '0',
          tireSize: value.assembly.tireSize,
          wheelSize: value.assembly.wheelSize,
          isOE: true,
          isAfterMarket: false
        }
      });
      setStandardVehicleFields({
        ...standardVehicleFields,
        autoSyncData: {
          autoSync: colorsWithImages,
        },
        assemblyCollection,
        selectedAssembly: {
          assemblyCode: value.assembly.assemblyCode,
          assemblyId: existingOEAssemblyId || value.assembly.assemblyId,
          assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
          fitmentId: value.assembly.fitmentId,
          frontRimSize: value.assembly.wheelSize?.toString(),
          frontTireAspectRatio: value.assembly.aspectRatio,
          frontTireDiameter: value.assembly.wheelSize,
          frontTireWidth: value.assembly.crossSection,
          rearRimSize: value.assembly.wheelSize?.toString(),
          rearTireAspectRatio: value.assembly.aspectRatio,
          rearTireDiameter: value.assembly.wheelSize,
          rearTireWidth: value.assembly.crossSection,
          sizeQualifier: '0',
          tireSize: value.assembly.tireSize,
          wheelSize: value.assembly.wheelSize,
          isOE: true,
          isAfterMarket: false,
        },
        chassisId: value.assembly.chassisId
      });
    }
  };

  /**
   * Click handler for selecting an assembly
   * @param {Event} e
   * @param {Object} value
   */
  const selectTireAssembly = (e, value) => {
    setMoreTireSizesDisabled(true);
    setAMTireSizes(null);
    setWheelSizes(null);
    setCurrentAfterMarketAssemblyOptions(null);
    setMoreTireSizesOpen(false);
    updateSelectedAssembly(value);
  };

  /**
   * Click handler for displaying the additional vehicle assemblies based on the selected additional wheel size
   * @param {Event} e
   * @param {Integer} c
   */
  const loadAdditionalWheelSizeOptions = (e, c) => {
    const amAssembliesArr = [];
    amTireSizes.forEach(pc => {
      // determine if staggered
      if (Array.isArray(pc)) {
        if (pc[0].sizeQualifier === c) {
          amAssembliesArr.push({
            assembly: [
              {
                assemblyId: pc[0].assemblyId,
                assemblyCode: pc[0].assemblyCode,
                assemblyType: pc[0].assemblyType,
                aspectRatio: pc[0].aspectRatio,
                crossSection: pc[0].crossSection,
                sizeQualifier: pc[0].sizeQualifier,
                tireSize: pc[0].tireSize,
                wheelSize: pc[0].wheelSize,
                matchQualifier: pc[0].matchQualifier
              },
              {
                assemblyId: pc[1].assemblyId,
                assemblyCode: pc[1].assemblyCode,
                assemblyType: pc[1].assemblyType,
                aspectRatio: pc[1].aspectRatio,
                crossSection: pc[1].crossSection,
                sizeQualifier: pc[1].sizeQualifier,
                tireSize: pc[1].tireSize,
                wheelSize: pc[1].wheelSize,
                matchQualifier: pc[1].matchQualifier
              }
            ]
          });
        }
      } else if (pc.sizeQualifier === c) {
        amAssembliesArr.push({
          assembly: {
            assemblyId: pc.assemblyId,
            assemblyCode: pc.assemblyCode,
            assemblyType: pc.assemblyType,
            aspectRatio: pc.aspectRatio,
            crossSection: pc.crossSection,
            sizeQualifier: pc.sizeQualifier,
            tireSize: pc.tireSize,
            wheelSize: pc.wheelSize,
            matchQualifier: pc.matchQualifier
          }
        });
      }
    });
    setPlusSize(Array.isArray(amAssembliesArr[0].assembly) ? amAssembliesArr[0].assembly[0].sizeQualifier : amAssembliesArr[0].assembly.sizeQualifier);
    setCurrentAfterMarketAssemblyOptions(amAssembliesArr);
    setAdditionalWheelSizeSelected(c);
  };

  useEffect(() => {
    setCurrentOE(oeTireSizes);

    return () => {
      setCurrentOE(null);
    };
  }, [oeTireSizes]);

  /**
   * Grab the color and vehicle image information
   */
  useMemo(() => {
    const getVehicleColorsAndImages = async () => {
      const {
        year, make, model, trimId
      } = standardVehicleFields;
      const autoSyncResult = await getAutoSyncData({
        year: year.value, make: make.value, model: model.value, trimId
      }, setLoader);
      setColorsWithImages(autoSyncResult);
    };

    getVehicleColorsAndImages();
  }, [standardVehicleFields.trimId]);

  //
  // When an O.E. assembly is selected, load the applicable additional assemblies and wheel sizes
  //
  useEffect(async () => {
    if (standardVehicleFields.selectedAssembly.isAfterMarket) return;

    const am = [];
    if (Object.keys(standardVehicleFields.selectedAssembly).length !== 0 && (Array.isArray(standardVehicleFields.selectedAssembly) ? !standardVehicleFields.selectedAssembly[0].isAfterMarket : !standardVehicleFields.selectedAssembly.isAfterMarket)) {
      const assemblyCode = Array.isArray(standardVehicleFields.selectedAssembly) ? standardVehicleFields.selectedAssembly[0].assemblyCode : standardVehicleFields.selectedAssembly.assemblyCode;

      const res = await APIRouter('FIT', 'getVehicleFitmentSizes', ({
        year: standardVehicleFields.year.value, make: standardVehicleFields.make.value, model: standardVehicleFields.model.value, trim: standardVehicleFields.trim, assembly: assemblyCode
      }), setLoader, true);

      if (res?.productCollection) {
        res.productCollection?.forEach(pc => {
          // if has data, then it's staggered
          if (pc.productItem.rearAssociatedSizeCollection) {
            let staggeredAssembly = [];
            pc.productItem.rearAssociatedSizeCollection.forEach(rasc => {
              staggeredAssembly.push(
                {
                  assemblyType: constants.VEHICLE_ALT_ASSEMBLY_TYPE,
                  aspectRatio: pc.productItem.frontArticleAspectRatio,
                  crossSection: pc.productItem.frontArticleCrossSection,
                  tireSize: `${pc.productItem.frontArticleCrossSection}/${pc.productItem.frontArticleAspectRatio} ${constants.ADD_NEW_ASSEMBLY_TIRE_CONSTRUCTION_RADIAL}${pc.productItem.frontArticleRimSize}`,
                  wheelSize: pc.productItem.frontArticleRimSize,
                  sizeQualifier: pc.productItem.plusSize,
                  matchQualifier: pc.productItem.matchQualifier,
                  assemblyCode,
                  assemblyId: pc.productItem.assemblyId
                }
              );
              staggeredAssembly.push(
                {
                  assemblyType: constants.VEHICLE_ALT_ASSEMBLY_TYPE,
                  aspectRatio: rasc.rearAssociatedSizeItem.articleAspectRatio,
                  crossSection: rasc.rearAssociatedSizeItem.articleCrossSection,
                  tireSize: `${rasc.rearAssociatedSizeItem.articleCrossSection}/${rasc.rearAssociatedSizeItem.articleAspectRatio} ${constants.ADD_NEW_ASSEMBLY_TIRE_CONSTRUCTION_RADIAL}${rasc.rearAssociatedSizeItem.articleRimSize}`,
                  wheelSize: rasc.rearAssociatedSizeItem.articleRimSize,
                  sizeQualifier: pc.productItem.plusSize,
                  matchQualifier: rasc.rearAssociatedSizeItem.matchQualifier,
                  assemblyCode,
                  assemblyId: pc.productItem.assemblyId
                }
              );
              am.push(staggeredAssembly);
              staggeredAssembly = [];
            });
          } else {
            am.push(
              {
                assemblyType: constants.VEHICLE_ALT_ASSEMBLY_TYPE,
                aspectRatio: pc.productItem.frontArticleAspectRatio,
                crossSection: pc.productItem.frontArticleCrossSection,
                tireSize: `${pc.productItem.frontArticleCrossSection}/${pc.productItem.frontArticleAspectRatio} ${constants.ADD_NEW_ASSEMBLY_TIRE_CONSTRUCTION_RADIAL}${pc.productItem.frontArticleRimSize}`,
                wheelSize: pc.productItem.frontArticleRimSize,
                sizeQualifier: pc.productItem.plusSize,
                matchQualifier: pc.productItem.matchQualifier,
                assemblyCode,
                assemblyId: pc.productItem.assemblyId
              }
            );
          }
        });
      }

      // create a wheel size array based on the after market assemblies based on the O.E. selected
      const wheelSizesTempArr = [];
      const sizeQualifierTempArr = [];
      if (am) {
        am.forEach(am => {
          let wheelSize,
            sizeQualifier;
          try {
            wheelSize = parseInt(am.wheelSize, 10);
            sizeQualifier = parseInt(am.sizeQualifier, 10);
            am.wheelSize = wheelSize;
            am.sizeQualifier = sizeQualifier;
          } catch (e) {
            console.error(e);
          }
          // staggered options
          if (Array.isArray(am)) {
            if (!wheelSizesTempArr.includes(am[0].wheelSize)) {
              wheelSizesTempArr.push(am[0].wheelSize);
            }
            if (!sizeQualifierTempArr.includes(am[0].sizeQualifier)) {
              sizeQualifierTempArr.push(am[0].sizeQualifier);
            }
          } else {
            if (!wheelSizesTempArr.includes(am.wheelSize)) {
              wheelSizesTempArr.push(am.wheelSize);
            }
            if (!sizeQualifierTempArr.includes(am.sizeQualifier)) {
              sizeQualifierTempArr.push(am.sizeQualifier);
            }
          }
        });

        setAMTireSizes(am);
        setWheelSizes(wheelSizesTempArr);
        setSizeQualifiers(sizeQualifierTempArr);
      }

      setMoreTireSizesDisabled(Object.keys(standardVehicleFields.selectedAssembly).length === 0 || am?.length === 0 || !am);
    }
  }, [standardVehicleFields.selectedAssembly]);

  const handleKeyDown = (event, c) => {
    // Check if the pressed key is "Enter" (key code 13) or "Space" (key code 32)
    if (
      (event?.keyCode && event.keyCode === constants.ENTER_KEY_CODE) ||
      (event?.keyCode && event.keyCode === constants.ENTER_SPACE_CODE)
    ) {
      setMoreTireSizesOpen(c);
    }
  };

  return (
    vehicleErrors ? <VehicleErrorText>{vehicleErrors.error}</VehicleErrorText> :
      currentOE && (
      <>
        <BreadCrumbQuestion>{constants.ADD_NEW_VEHICLE_WHAT_TIRE_SIZE}</BreadCrumbQuestion>
        <BreadCrumbSubText>{constants.ADD_NEW_VEHICLE_WHAT_TIRE_SIZE_SUBTEXT}</BreadCrumbSubText>
        <Assembly standardVehicleFields={standardVehicleFields} isDually={isDually} assemblies={currentOE} selectTireAssembly={(e, c) => selectTireAssembly(e, c)} />
        <BreadCrumbQuestion>{constants.ADD_NEW_VEHICLE_AFTER_MARKET_TIRE_SIZES}</BreadCrumbQuestion>
        <BreadCrumbSubText>{constants.ADD_NEW_VEHICLE_AFTER_MARKET_SUBTEXT}</BreadCrumbSubText>
        <SeeMoreSizesLabel tabIndex={0} onKeyDown={(e) => !moreTireSizesDisabled && handleKeyDown(e, !moreTireSizesOpen)} disabled={moreTireSizesDisabled} onClick={() => !moreTireSizesDisabled && setMoreTireSizesOpen(!moreTireSizesOpen)}>
          see more tire sizes&nbsp;&nbsp;{!moreTireSizesDisabled && (moreTireSizesOpen ? <AiOutlineMinus /> : <AiOutlinePlus />)}
        </SeeMoreSizesLabel>
        {
          moreTireSizesOpen && (
            <>
              <WheelSizeSelectLabel>
                {constants.ADD_NEW_VEHICLE_AFTER_MARKET_SELECT_WHEEL_SIZE}
              </WheelSizeSelectLabel>
              {
                wheelSizes?.map((c, index) => {
                  return (<Wheel selected={sizeQualifiers[index] === additionalWheelSizeSelected} sizeQualifier={sizeQualifiers[index]} loadAdditionalWheelSizeOptions={(e, c) => loadAdditionalWheelSizeOptions(e, c)} key={index} wheelSize={c} />);
                })
              }
              {(!isNullEmptyUndefined(additionalWheelSizeSelected) && currentAfterMarketAssemblyOptions) && (
                <AdditionalWheelSizeOptions
                  plusSize={plusSize}
                  setStandardVehicleFields={setStandardVehicleFields}
                  standardVehicleFields={standardVehicleFields}
                  isDually={isDually}
                  additionalTireSizeOptions={currentAfterMarketAssemblyOptions}
                  oeTireSizes={oeTireSizes}
                  isEdit={isEdit}
                />
              )}
            </>
          )
}
      </>
      )
  );
};

export default BreadCrumbTireSize;
