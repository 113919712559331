import {
  forwardRef, useContext, useEffect, useState
} from 'react';
import Input, { RequiredSpan } from '../../../UIComponents/Input/Input';
import { CheckboxWrapper, DetailSection } from '../DetailSection';
import * as constants from '../../../constants/Constants';
import styled from '../../../helpers/esm-styled-components';
import Dropdown from '../../../UIComponents/Dropdown/Dropdown';
import CustomerDetail from './CustomerDetail';
import { FlagContainer } from './CustomerInformation';
import Flag from '../../../UIComponents/Flag/Flag';
import {
  countryOptions, getStateOrProvincePlaceholder, getZipCodeLabel, suffixTypeOptions,
} from '../../../helper/baseDataHelper';
import { APIContext } from '../../../Data/API';
import {
  AddAdditionalVehicleInfo, HorizontalDivider, ReturnToCustomerSummary,
} from '../../../UIComponents/Modals/ModalUtils';
import PhoneCountryDropdown from '../../../UIComponents/Dropdown/PhoneCountryDropdown';
import {
  SRPMICCheckbox, SRPMICInput, SRPMICLabel, SRPMICWrapper
} from './SearchByCustomer';
import {
  upperCaseFirstLetter, isNullEmptyUndefined, isNullOrUndefined, isEmptyObject, checkRequiredFields
} from '../../../helper/validationHelper';
import { CVMContext } from '../../CVM/CVM';
import Message from '../../../UIComponents/Error/Message';

const ContactSection = styled(DetailSection)`
width:90%;
height: auto;
`;
const CustomerEditForm = styled.div`
width:87%;
display:grid;
margin: 0 -165px 0 5px;
grid-column-gap: 15px;
grid-row-gap: 15px;
grid-template-columns: 300px 220px 90px 235px;
grid-template-areas: ${({ hideCompanyName }) => (!hideCompanyName ?
    `'name last suffix contact '
  'company employee . .'
  'associations AAA srpmic .'`
    :
    `'name last suffix contact '
  'employee . . .'
  'associations AAA srpmic .'`)
};
  ${({ fieldErrors, }) => `
    grid-row-gap: ${fieldErrors && '40px;'};
  `};
  height: auto;
`;

const ContactEditForm = styled.div`
  padding:20px 0 10px 30px;
  display:grid;
  grid-template-columns: 494px 220px ;
  grid-template-areas:
  'addressheader addressheader'
  'address1 address2'
  'zipcitystate country'
  'phoneheader phoneheader'
  'mobilework home'
  'emailheader emailheader'
  'email email'
  'divider divider'
  'buttons buttons'
  ;
  grid-column-gap: 15px;
  grid-row-gap: 18px;
${({ fieldErrors }) => `
    grid-row-gap: ${fieldErrors ? '18px' : '45px'};
  `};
${({ isSummaryViewEdit }) => isSummaryViewEdit && `
  grid-template-columns: 400px 170px;
  padding:0;
  grid-template-areas:
  'addressheader addressheader'
  'address1 address2'
  'zipcitystate country'
  'buttons .';
  `};
  height: auto;
`;
const FirstMiddleInputs = styled.div`
  grid-area: name;
  display: flex;
  gap:5%;
`;
const FirstName = styled(Input)`
  width: 75%;
`;
const MiddleName = styled(Input)`
  width:20%;
`;
const LastName = styled(Input)`
  grid-area: last;
`;
const Suffix = styled(Dropdown)`
  grid-area: suffix;
`;
const CompanyName = styled(Input)`
  grid-area:company;
`;
const EmployeeIDWrapper = styled.div`
  grid-area:employee;
  display: flex;
  align-items: center;
`;
const Associations = styled.div`
grid-area: associations;
display: flex;
justify-content: space-between;
gap:5%;
margin-top: 5%;
`;
const AAAMembership = styled(Input)`
grid-area: AAA;
`;
// Edit Contact Info
const AddressHeader = styled.div`
grid-area: addressheader;
border-bottom: 1px solid #EAEAEA;
display:flex;
align-items: center;
gap: 20px;
`;

const AddressLine1 = styled(Input)`
grid-area: address1;
`;
const AddressLine2 = styled(Input)`
grid-area: address2;
`;
const ZipCityState = styled.div`
grid-area: zipcitystate;
display:flex;
`;
const ZipCode = styled(Input)`
width:130px;
`;
const City = styled(Input)`
width:215px;
margin-left:20px;
`;
const State = styled(Dropdown)`
width:155px;
margin-left:59px;
${({ isSummaryViewEdit }) => isSummaryViewEdit &&  'margin-left:15px;'};
`;
const Country = styled(Dropdown)`
grid-area: country;
`;
const RequiredWrapper = styled.div`
display:flex;
`;
const PhoneNumbersHeader = styled.div`
display:flex;
justify-content: space-between;
margin-top: 10px;
grid-area:phoneheader;
border-bottom: 1px solid #EAEAEA;
`;

const EditVehicleContainer = styled.div`
    left: 0em;
    position: absolute;
    top: 10em;
`;
const MobileWorkWrapper = styled.div`
grid-area: mobilework;
display: flex;
justify-content: space-evenly;
gap:0px;
`;
const WorkExt = styled(Input)`
width: 100px;
margin:0;
`;

const EmailHeader = styled.div`
margin-top: 10px;
grid-area:emailheader;
border-bottom: 1px solid #EAEAEA;
`;
const Email = styled(Input)`
grid-area: email;
width:455px;
`;
const Divider = styled.div`
grid-area:divider;
`;
const ButtonContainer = styled.div`
display:flex;
justify-content: flex-end;
gap:20px;
padding: 10px 0;
`;
const CancelButton = styled(ReturnToCustomerSummary)`
margin:0;
padding:0;
width:250px;
white-space:nowrap;
`;
const UpdateButton = styled(AddAdditionalVehicleInfo)`
margin:0;
padding:0;
width:250px;
white-space:nowrap;
${({ isSummaryViewEdit }) => isSummaryViewEdit &&  `
  margin-top:10px;
  height:40px;
  width: 270px;`}
`;

const EditCheckboxWrapper = styled(CheckboxWrapper)`
margin:0;`;

const MilitaryWrapper = styled(SRPMICWrapper)`
margin-bottom: 10px;
`;

const CustomerEdit = forwardRef(({
  formType, formName, editButtonClick, fields, handleInput,
  updateDropdown, cancelClick, confirmClick, fieldErrors, requiredMet,
  checked, setChecked, clearAlternateEdit, isPrimaryAlternateEdit, srpmicOpen, renderSRPMIC, isSRPMIC, isAAA, contactInfoErrorClicked, isSummaryViewEdit, clearSuffix, clearState,
}, ref) => {

  const {
    getStateOptions,
  } = useContext(APIContext);
  const {
    customerInfo,
  } = useContext(CVMContext);
  const defaultCountry = countryOptions[0];
  const inputProps = {
    type: constants.TEXT,
    height: '40px',
    labelPadding: isSummaryViewEdit ? '0px 1px' : '0px 2.5px',
    isDisabled: false,
    tabIndex: 0,
    inputHandler: handleInput,
  };
  const suffixDropdownOptions = {
    fieldName: 'suffix',
    placeholder: 'Suffix',
    options: suffixTypeOptions,
    selectedOption: fields?.suffix,
    onChange: updateDropdown,
    positionStyle: 'absolute',
    dropdownHeight: 250,
    inputHeight: '38', // Should be this for all
    smallDropdown: true,
    maxLength: 10,
    onClickClear: clearSuffix,
  };
  const countryDropdownProps = {
    fieldName: 'country',
    placeholder: 'Country',
    options: countryOptions,
    onChange: updateDropdown,
    selectedOption: countryOptions.filter(country => country?.label === fields?.country?.label)[0],
    value: fields?.country?.label,
    required: true,
    positionStyle: 'absolute',
    dropdownHeight: 250,
    inputHeight: '38',
    smallDropdown: true,
  };

  const stateDropdownProps = {
    fieldName: 'state',
    placeholder: getStateOrProvincePlaceholder(fields?.country?.value ? fields?.country?.value : fields?.country?.value || defaultCountry.value),
    options: getStateOptions(fields?.country?.value || defaultCountry.value),
    onChange: updateDropdown,
    selectedOption: fields?.state,
    value: fields?.state?.value,
    required: true,
    positionStyle: 'absolute',
    dropdownHeight: 250,
    inputHeight: '38',
    smallDropdown: true,
    provinceOrState: true,
    onClickClear: clearState,
  };
  const phoneCountryDropdown = {
    fieldName: 'phoneCountryCode',
    onChange: updateDropdown,
    selectedOption: fields?.phoneCountryCode,
    value: fields?.phoneCountryCode,
  };
  const mobileCountryDropdown = {
    fieldName: 'mobileCountryCode',
    onChange: updateDropdown,
    selectedOption: fields?.mobileCountryCode,
    value: fields?.mobileCountryCode,
  };
  const workCountryDropdown = {
    fieldName: 'workCountryCode',
    onChange: updateDropdown,
    selectedOption: fields?.workCountryCode,
    value: fields?.workCountryCode,
  };
  const employeeId = customerInfo?.alternateIdentifierCollection?.find(c => c.alternateIdentifierItem?.name === 'EMPLOYEE ID')?.alternateIdentifierItem?.value;
  const isEmployeeId = isNullEmptyUndefined(employeeId) ? constants.NOT_APPLICABLE : employeeId;
  const [isFocused, setIsFocused] = useState(false);
  const firstNameEl = document.querySelector('[name="firstName"]');
  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const placeCursor = () => {
    if (!isFocused) {
      firstNameEl.focus();

    }
  };

  useEffect(() => {
    contactInfoErrorClicked && editButtonClick();
  }, [contactInfoErrorClicked]);

  useEffect(() => {
    if (firstNameEl) {
      placeCursor();
    }
  }, [firstNameEl]);

  const customerEditForm = () => {
    const hideCompanyName = fields?.employeeID !== constants.EMPTY_STRING && fields?.customerType === constants.STANDARD_CUSTOMER_TYPE;
    const altForm = constants.CUSTOMER_EDIT_ALTERNATE_FORM;
    switch (formType) {
      case constants.CUSTOMER_EDIT_INFO_FORM:
        return (
          <>
            <CustomerEditForm hideCompanyName={hideCompanyName} fieldErrors={fieldErrors && !isEmptyObject(fieldErrors)}>
              <FirstMiddleInputs>
                <FirstName
                  {...inputProps}
                  fieldName="firstName"
                  inputValue={fields.firstName}
                  errorMessage={fieldErrors?.firstName}
                  label="Primary Contact - First Name"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  maxLength={40}
                  minLength={2}
                  required />
                <MiddleName
                  {...inputProps}
                  fieldName="middleName"
                  inputValue={fields.middleName}
                  label="MI"
                  maxLength={1}
              />
              </FirstMiddleInputs>
              <LastName {...inputProps}
                {...inputProps}
                fieldName="lastName"
                inputValue={fields?.lastName}
                errorMessage={fieldErrors?.lastName}
                label="Primary Contact - Last Name"
                maxLength={40}
                required />
              <Suffix {...suffixDropdownOptions} />
              { !hideCompanyName && <CompanyName {...inputProps} fieldName="companyName" inputValue={fields?.companyName} label="Company Name" maxLength={35} errorMessage={fieldErrors?.companyName} /> }
              { employeeId && (
                <EmployeeIDWrapper>
                  <CustomerDetail additional infoName={constants.DT_EMPLOYEE_LABEL} margin="0" />
                  {'\u00a0\u00a0'}
                  <CustomerDetail additional isBold infoName={isEmployeeId} margin="0" />
                </EmployeeIDWrapper>
              )}
              <Associations>
                <CustomerDetail isBold underline additional infoName={constants.ASSOCIATIONS_LABEL} margin="0 5px 0 0"  />
                <FlagContainer>
                  {fields.DTCC && <Flag flagName={constants.DTCC_FLAG} />}
                  <MilitaryWrapper edit >
                    <EditCheckboxWrapper onClick={() => handleInput(!fields?.isMilitary, 'isMilitary')} >
                      <SRPMICCheckbox edit checked={fields?.isMilitary} />
                      <SRPMICLabel  >
                        {constants.MILITARY_FLAG}
                      </SRPMICLabel>

                    </EditCheckboxWrapper>
                  </MilitaryWrapper>
                </FlagContainer>
              </Associations>
              {isAAA && <AAAMembership {...inputProps} inputValue={fields?.AAA} fieldName="AAA" label="AAA Membership" maxLength={16} />}
              {isSRPMIC && (
              <SRPMICWrapper edit isClosed={!srpmicOpen} >
                <EditCheckboxWrapper onClick={renderSRPMIC} >
                  <SRPMICCheckbox edit checked={srpmicOpen} />
                  <SRPMICLabel edit >
                    {constants.SRPMIC_LABEL}
                  </SRPMICLabel>
                </EditCheckboxWrapper>
                { srpmicOpen && (
                <SRPMICInput
                  edit
                  {...inputProps}
                  type={constants.TEXT}
                  required={false}
                  fieldName="SRPMICNumber"
                  inputValue={fields?.SRPMICNumber}
                  label="SRPMIC #"
                  errorMessages={[]}
                  warningMessages={[]}
                  inputHandler={handleInput}
                  isDisabled={false}
                  maxLength={20}/>
                )}
              </SRPMICWrapper>
              )}
            </CustomerEditForm>
          </>
        );
      case constants.CUSTOMER_EDIT_PRIMARY_FORM:
      case constants.CUSTOMER_EDIT_ALTERNATE_FORM:
        fields.zip = fields.zip.split('-')[0];
        const props = !isSummaryViewEdit ? {
          key: formType === constants.CUSTOMER_EDIT_PRIMARY_FORM
            ? constants.CUSTOMER_EDIT_PRIMARY_FORM
            : constants.CUSTOMER_EDIT_ALTERNATE_FORM,
          titleButtonType: formType !== altForm ? constants.BUTTON_TYPE_EDIT : constants.BUTTON_TYPE_CREATE,
          titleButtonName: formType !== altForm ? constants.BUTTON_TYPE_EDIT : 'Clear Form',
          noVBar: true,
          noPadding: true,
          onButtonClick: !isPrimaryAlternateEdit ? editButtonClick : clearAlternateEdit,
          checkbox: true,
          checkboxLabel: constants.TITLE_PRIMARY_CONTACT,
          checked,
          setChecked
        } : { noPadding: true };

        return (
          <ContactSection {...props}
          >
            <ContactEditForm fieldErrors={isEmptyObject(fieldErrors)} isSummaryViewEdit={isSummaryViewEdit}>
              <AddressHeader>
                <CustomerDetail additional isBold infoName={constants.CUSTOMER_ADDRESS} />
                <RequiredWrapper>
                  <RequiredSpan required>*</RequiredSpan>
                  <CustomerDetail small infoName={constants.TITLE_REQUIRED_ADDRESS}/>
                </RequiredWrapper>
              </AddressHeader>
              <AddressLine1
                {...inputProps}
                fieldName="address1"
                inputValue={upperCaseFirstLetter(fields.address1)}
                errorMessage={fieldErrors?.address1}
                label="Address Line 1"
                maxLength={60}
                required
              />
              <AddressLine2
                {...inputProps}
                fieldName="address2"
                inputValue={upperCaseFirstLetter(fields.address2)}
                label="Address Line 2 (optional)"
                maxLength={40}
              />
              <ZipCityState>
                <ZipCode
                  {...inputProps}
                  fieldName="zip"
                  label={getZipCodeLabel(fields?.country?.value, true)}
                  inputValue={fields.zip}
                  errorMessage={fieldErrors?.zip}
                  maxLength={fields?.country.label === constants.UNITED_STATES ? 5 : 10}
                  required
                />
                <City
                  {...inputProps}
                  fieldName="city"
                  inputValue={upperCaseFirstLetter(fields.city)}
                  errorMessage={fieldErrors?.city}
                  label="City"
                  maxLength={40}
                  required
                />
                <State
                  {...stateDropdownProps}
                  isSummaryViewEdit={isSummaryViewEdit}
                />
              </ZipCityState>
              <Country
                {...countryDropdownProps}
              />
              {!isSummaryViewEdit && (
              <>
                <PhoneNumbersHeader>
                  <CustomerDetail additional isBold infoName={constants.CUSTOMER_PHONE_NUMBERS}/>
                  <RequiredWrapper>
                    <RequiredSpan required>*</RequiredSpan>
                    <CustomerDetail small infoName={constants.CUSTOMER_PHONE_NUMBERS_SUBTITLE}/>
                  </RequiredWrapper>
                </PhoneNumbersHeader>
                <MobileWorkWrapper>
                  <PhoneCountryDropdown
                    {...inputProps}
                    fieldName="mobileNumber"
                    type={constants.TELEPHONE}
                    inputValue={fields?.mobileNumber}
                    errorMessage={fieldErrors?.mobileNumber}
                    label="Mobile"
                    countryDropdown={mobileCountryDropdown}
                    isEdit
                />
                  <PhoneCountryDropdown
                    {...inputProps}
                    fieldName="workNumber"
                    type={constants.TELEPHONE}
                    errorMessage={fieldErrors?.workNumber}
                    inputValue={fields?.workNumber}
                    label="Work"
                    countryDropdown={workCountryDropdown}
                    isEdit
                />
                  <CustomerDetail margin="9px 0 0 0" infoName="-"/>
                  {'\u00a0\u00a0'}
                  <WorkExt
                    {...inputProps}
                    fieldName="workExt"
                    inputValue={fields?.workExt}
                    label="Ext."
                    fontSize="14px"
                    padding="0"
                    labelPadding="0 3px"
                />
                </MobileWorkWrapper>
                <PhoneCountryDropdown
                  {...inputProps}
                  fieldName="phoneNumber"
                  type={constants.TELEPHONE}
                  errorMessage={fieldErrors?.phoneNumber}
                  inputValue={fields?.phoneNumber}
                  label="Home"
                  countryDropdown={phoneCountryDropdown}
                  isEdit
                />
                <EmailHeader>
                  <CustomerDetail additional isBold infoName={constants.CUSTOMER_EMAIL} />
                </EmailHeader>
                <Email
                  {...inputProps}
                  fieldName="email"
                  errorMessage={fieldErrors?.email}
                  inputValue={fields?.email}
                  maxLength={50}
              />
              </>
              )}
              <Divider>
                <HorizontalDivider lightGray />
              </Divider>
              <ButtonContainer ref={ref}>{!isSummaryViewEdit ? (
                <>
                  <CancelButton buttonName={constants.BUTTON_CANCEL} onButtonClick={cancelClick} />
                  <UpdateButton buttonName={formName === constants.CUSTOMER_EDIT_PRIMARY_FORM
                    ? constants.BUTTON_UPDATE_PRIMARY_CONTACT : constants.BUTTON_UPDATE_ALTERNATE_CONTACT} isDisabled={!requiredMet} onButtonClick={!requiredMet ? () => { } : confirmClick} />
                </>
              ) : (
                <EditVehicleContainer>
                  <UpdateButton isSummaryViewEdit buttonName={constants.BUTTON_UPDATE_ADDRESS} isDisabled={!requiredMet} onButtonClick={!requiredMet ? () => { } : confirmClick} />
                </EditVehicleContainer>
              )}
              </ButtonContainer>
            </ContactEditForm>
          </ContactSection>
        );
      default:
        return (
          <ContactSection
            vehicles
            custoEdit
            key={formName}
            title={formName}
            titleButtonType={constants.BUTTON_TYPE_EDIT}
            titleButtonName={constants.BUTTON_TYPE_EDIT.toLowerCase()}
            noVBar
            checkbox
            checkboxLabel={constants.TITLE_PRIMARY_CONTACT}
            onButtonClick={editButtonClick}
            checked={checked}
            setChecked={setChecked}
            fields={fields}
          >
            {
              !isNullOrUndefined(contactInfoErrorClicked) && customerInfo.customerType === constants.STANDARD_CUSTOMER_TYPE && (!customerInfo.isGKValid && contactInfoErrorClicked === false) && <Message errorMessages="Please enter missing Customer Information before proceeding" type={constants.WARNING} manualWidth="94%" />
            }
            {fields.address1 ? (
              <>
                <CustomerDetail infoName={upperCaseFirstLetter(fields.address1)} />
                {fields.address2 && <CustomerDetail infoName={upperCaseFirstLetter(fields.address2)} />}
                <CustomerDetail infoName={`${upperCaseFirstLetter(fields.city)}, ${fields.state?.value} ${fields.zip}`} />
                <CustomerDetail infoName={fields?.country?.label} />
              </>
            ) : <CustomerDetail margin="0 0 80px 0" infoName={constants.CUSTOMER_NO_ADDRESS} />}
            <HorizontalDivider lightGray width="50%" margin="10px 0 10px 0" />
            <CustomerDetail testId="primary_mobile" infoName={`${constants.CONTACT_MOBILE_LABEL} ${fields.mobileNumber ? fields.mobileNumber : constants.NOT_APPLICABLE}`} />
            <CustomerDetail testId="primary_work" infoName={`${constants.CONTACT_WORK_LABEL} ${fields.workNumber
              ? `${fields.workNumber}${fields.workExt && ` ext. ${fields.workExt}`}` : constants.NOT_APPLICABLE}`} />
            <CustomerDetail testId="primary_home" infoName={`${constants.CONTACT_HOME_LABEL} ${fields.phoneNumber ? fields.phoneNumber : constants.NOT_APPLICABLE}`} />
            <HorizontalDivider lightGray width="50%" margin="10px 0 10px 0"/>
            <CustomerDetail testId="primary_email" infoName={`${constants.EMAIL_LABEL} ${fields.email ? fields.email : constants.NOT_APPLICABLE}`} />
          </ContactSection>
        );
    }
  };
  return (
    <>
      {fields && customerEditForm()}
    </>
  );
});
export default CustomerEdit;
