import moment from 'moment';
import * as constants from '../constants/Constants';

export const simulateAction = (event, eventType, elem) => {
  switch (eventType) {
    case constants.MOUSE_EVENT:
      let evt = new MouseEvent(event, {
        bubbles: true,
        cancelable: true,
        view: window,
      });

      if (elem.length === 0) {
        return;
      }

      elem.dispatchEvent(evt);
      break;
    default:
      break;
  }
};

export const copyToClipboard = (text, setToastrOn, error) => {
  const datetime = moment().toDate() + "\r\n\n";
  navigator.clipboard.writeText(error ? datetime : constants.EMPTY_STRING + text);
  setToastrOn(true);
};
