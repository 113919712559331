import React, {
  forwardRef, useEffect, useRef, useState
} from 'react';
import moment from 'moment';
import {
  CalendarRequiredLabel, CalendarRequiredSpan, CustomCalendarInput, CustomMonthContainer, StyledCalendarIcon, StyledContainer, StyledDatePicker
} from './CalendarStyle';
import * as constants from '../../constants/Constants';

const CalendarDatePicker = (props) => {
  const {
    handleDateChange, selectedDate, disabledDates, currentDate
  } = props;
  const [inputValue, setInputValue] = useState(moment(selectedDate).format('MM/DD/YYYY'));
  const inputRef = useRef(null);

  /* Retain focus on the input */
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputValue]); // Focus input when value changes

  /* If the date is changed via calendar reflect value */
  useEffect(() => {
    if (selectedDate) setInputValue(moment(selectedDate).format('MM/DD/YYYY'));
  }, [selectedDate]);

  const handleKeyboardSubmit = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (disabledDates(new Date(inputValue))) {
        setInputValue(moment(selectedDate).format('MM/DD/YYYY'));
      } else {
        handleDateChange(new Date(inputValue)); // Submit the selected date
      }
    }
  };

  const handleInputChange = (e) => {
    const dashIndex = [1, 4];
    let { value } = e.target;
    if (inputValue.length === dashIndex[0] || inputValue.length === dashIndex[1]) {
      value += '/';
    }
    setInputValue(value);
  };
  const CustomInput = forwardRef(({
    onClick
  }, ref) => {
    const handleClick = (e) => {
      e.target.select();
      if (onClick) {
        onClick(e);
      }
    };
    return (
      <CustomCalendarInput
        onClick={handleClick}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyboardSubmit}
        m
        ref={(input) => {
          inputRef.current = input;
          if (ref) {
            ref(input);
          }
        }} />
    );
  });

  return (
    <StyledContainer>
      <CalendarRequiredSpan required>*</CalendarRequiredSpan>
      <CalendarRequiredLabel>From (mm/dd/yyyy)</CalendarRequiredLabel>
      <StyledCalendarIcon/>
      <StyledDatePicker selected={selectedDate}
        onChange={handleDateChange}
        showPopperArrow={false}
        formatWeekDay={date => date.substring(0, 1)}
        filterDate={date => !disabledDates(date)}
        maxDate={currentDate}
        calendarContainer={CustomMonthContainer}
        customInput={(<CustomInput/>)}
        />
    </StyledContainer>
  );
};

export default CalendarDatePicker;
