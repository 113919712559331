import React from 'react';
import { FaCheckSquare } from 'react-icons/fa';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
`;

export const Label = styled(FaCheckSquare)`
  position: absolute;
  width: 26px;
  height: 26px;
  margin-top: -4px;
  margin-left:-0.6px;
  background-color: transparent;
  color: ${theme.colors.blue};
  background-position: center center;
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: -3px;
  top: 50%;
  right: 0;
  border-radius: 4px;
  border: 2px solid ${theme.colors.lightGray};
  background: transparent;
  color:${theme.colors.white};
  transition: 0.2s;
`;
const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} readOnly={true} />
    <Label htmlFor={HiddenCheckbox} checked={checked} readOnly={true}/>
    <StyledCheckbox checked={checked} readOnly={true} />
  </CheckboxContainer>
);

export default Checkbox;
