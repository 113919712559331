import { React } from 'react';
import * as constants from '../../constants/Constants';
import SearchHeader from './SearchHeader';

const CustomerVehiclesHeader = () => {
  return (
    <SearchHeader title={constants.CUSTOMER_VEHICLES}/>
  );
};

export default CustomerVehiclesHeader;