import React from 'react';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { FaRegEdit, FaRegEye } from 'react-icons/fa';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import { theme } from '../../../helpers/GlobalStyles';

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  height: 19px;
  color: ${theme.colors.blue};
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.semiBold};
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  ${({ isHidden }) => isHidden && 'color: grey; cursor: not-allowed;'}
  -webkit-user-select: none;
  user-select: none;
`;
const CreateIcon = styled(AiOutlinePlusSquare)`
  margin-right: 0.5em;
`;
const EditIcon = styled(FaRegEdit)`
  margin-right: 0.5em;
`;
const ViewIcon = styled(FaRegEye)`
  margin-right: 0.5em;
`;

const CreateCustomerButton = props => {
  const {
    buttonName, buttonClick, buttonType, className, isBlocked
  } = props;

  const buttonIconType = () => {
    switch (buttonType) {
      case constants.BUTTON_TYPE_CREATE:
        return <CreateIcon />;
      case constants.BUTTON_TYPE_EDIT:
        return <EditIcon />;
      case constants.BUTTON_TYPE_VIEW:
        return <ViewIcon />;
      default:
        return null;
    }
  };

  const handleKeyDown = (event) => {
    // Check if the pressed key is "Enter" (key code 13) or "Space" (key code 32)
    if (
      (event?.keyCode && event.keyCode === constants.ENTER_KEY_CODE) ||
      (event?.keyCode && event.keyCode === constants.ENTER_SPACE_CODE)
    ) {
      buttonClick();
    }
  };

  return (
    <ButtonDiv tabIndex={0} onKeyDown={handleKeyDown} isHidden={isBlocked} onClick={isBlocked ? null : buttonClick} className={className}>
      {buttonIconType()}
      {buttonName}
    </ButtonDiv>
  );
};
export default CreateCustomerButton;
