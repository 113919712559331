import { useContext, useEffect, useState } from 'react';
import styled from '../../../../helpers/esm-styled-components';
import Dropdown from '../../../Dropdown/Dropdown';
import { BreadCrumbQuestion } from '../../ModalUtils';
import * as constants from '../../../../constants/Constants';
import { CVMContext } from '../../../../components/CVM/CVM';
import { VehicleErrorText } from '../BreadCrumbContent';

const BreadCrumbTrimAlign = styled.div`
  display: inline-block;
`;

const TrimDetailsDropDownContainer = styled.div`
  display: inline-block;
  float: right;
`;

const TrimDropDown = styled(Dropdown)`
  width: 335px;
`;

const TrimDetailsDropDown = styled(Dropdown)`
  width: 335px;
`;

export const BreadCrumbTrim = (props) => {
  // context
  const { setCurrentBreadCrumb } = useContext(CVMContext);

  // props
  const {
    vehicleErrors, trims, standardVehicleFields, setStandardVehicleFields, isEdit
  } = props;

  // states
  const [trimItem, setTrimItem] = useState(
    standardVehicleFields.trimId
      ? {
        trim: standardVehicleFields.trim,
        trimId: standardVehicleFields.trimId,
        imageUrl: standardVehicleFields.imageUrl,
        isDually: standardVehicleFields.isDually,
        selectedAssembly: {},
      }
      : {}
  );
  const [trimLiftItem, setTrimLiftItem] = useState(
    standardVehicleFields.trimLift
      ? {
        trimLift: standardVehicleFields.trimLift,
        trimLiftId: standardVehicleFields.trimLiftId,
      }
      : {}
  );
  const [trimHasLift, setTrimHasLift] = useState(false);

  /**
   * Dropdown selection handler for Trims
   * @param {Object} value
   */
  const updateTrim = (value) => {
    let item = constants.EMPTY_STRING;
    item = trims.filter((option) => option.trim === value.label && option.trimId === value.trimId);
    if (item) {
      delete standardVehicleFields.tiresize;
      delete standardVehicleFields.wheel;

      if (item[0].lifts?.length > 0) {
        // update local trim states
        setTrimItem(item[0]);
        setTrimLiftItem({});
        setTrimHasLift(true);
        setStandardVehicleFields({
          ...standardVehicleFields,
          trim: item[0].trim,
          trimId: item[0].trimId,
          imageUrl: item[0].imageUrl,
          isDually: item[0].isDually,
          selectedAssembly: {},
        });
      } else {
        setStandardVehicleFields({
          ...standardVehicleFields,
          trim: item[0].trim,
          trimId: item[0].trimId,
          imageUrl: item[0].imageUrl,
          isDually: item[0].isDually,
          selectedAssembly: {},
        });
        setTrimHasLift(false);
        setCurrentBreadCrumb(4);
      }
    }
  };

  /**
   * Dropdown selection handler Trim Lift
   * @param {Object} value
   */
  const updateTrimLift = (value, onLoad) => {
    setStandardVehicleFields({
      ...standardVehicleFields,
      trim: trimItem.trim,
      trimId: trimItem.trimId,
      trimLift: value.value === 'No customizations / Stock height' ? constants.EMPTY_STRING : value.value,
      trimLiftId: value.value === 'No customizations / Stock height' ? constants.EMPTY_STRING : trims?.filter(t => t.trim === trimItem.trim && t.trimId === trimItem.trimId)[0].lifts.filter(l => l.lift === value.value)[0].liftId
    });

    !onLoad && setCurrentBreadCrumb(4);
  };

  // Properties for the Trims Dropdown
  const trimsDropdownProps = {
    fieldName: 'trim',
    placeholder: 'Trim',
    options: trims?.map(t => ({ value: t.trim, label: t.trim, trimId: t.trimId })),
    onChange: updateTrim,
    selectedOption: trimItem.trim ? { value: trimItem.trim, label: trimItem.trim } : constants.EMPTY_STRING,
    value: trimItem.trim && { value: trimItem.trim, label: trimItem.trim },
    required: false,
    dropdownHeight: '165',
    isVehicleModal: true
  };

  // Properties for the Trim Lifts Dropdown
  const trimsDetailsDropdownProps = {
    fieldName: 'trimDetails',
    placeholder: 'Ride Height',
    options: (() => {
      const mappedTrims = trims?.filter(t => t.trim === trimItem.trim && t.trimId === trimItem.trimId)[0]?.lifts?.map(l => ({ value: l.lift, label: l.lift }));
      mappedTrims?.unshift({ value: 'No customizations / Stock height', label: 'No customizations / Stock height' });
      return mappedTrims;
    })(),
    onChange: (value) => updateTrimLift(value),
    selectedOption: trimLiftItem.trimLift ? { value: trimLiftItem.trimLift, label: trimLiftItem.trimLift } : constants.EMPTY_STRING,
    value: trimLiftItem.trimLift && { value: trimLiftItem.trimLift, label: trimLiftItem.trimLift },
    required: false,
    dropdownHeight: '165',
    isVehicleModal: true
  };

  // Determines if the trim lift dropdown needs to be shown on load (applies to Edit scenario)
  useEffect(() => {
    if (trims) {
      const trimItem = trims.filter((option) => option.trim === standardVehicleFields.trim && option.trimId === standardVehicleFields.trimId);
      setTrimHasLift(trimItem ? trimItem[0]?.lifts?.length > 0 : false);
    }
  }, []);

  return vehicleErrors ? (<VehicleErrorText>{vehicleErrors.error}</VehicleErrorText>) : (
    <>
      <BreadCrumbTrimAlign>
        <BreadCrumbQuestion>{constants.ADD_NEW_VEHICLE_WHAT_TRIM}{standardVehicleFields.model?.label}?</BreadCrumbQuestion>
        <TrimDropDown {...trimsDropdownProps} />
      </BreadCrumbTrimAlign>
      {(trimItem.trim && trimHasLift) && (
        <TrimDetailsDropDownContainer>
          <BreadCrumbQuestion>What is your {standardVehicleFields.model.label}&apos;s ride height?</BreadCrumbQuestion>
          <TrimDetailsDropDown {...trimsDetailsDropdownProps} />
        </TrimDetailsDropDownContainer>
      )}
    </>
  );
};

export default BreadCrumbTrim;
