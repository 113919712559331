import React, { useContext, useEffect } from 'react';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import { CVMContext } from '../../CVM/CVM';
import Table from '../../../UIComponents/Table/Table';
import { checkForEmptyObject } from '../../../helper/validationHelper';

const ResultsContainer = styled.div`
  width: 1570px;
`;

const Results = (props) => {
  const { searchResults, onSelect } = props;
  const {
    searchOrigin, setIsToggle, previousOrigin
  } = useContext(CVMContext);
  const isPreviousOrigin = !checkForEmptyObject(previousOrigin);
  let headers = [],
    tableType = constants.EMPTY_STRING;
  const getHeaders = () => {
    const tableOrigin = searchOrigin || previousOrigin?.origin;
    switch (tableOrigin) {
      case constants.SEARCH_ORIGIN_CUSTOMER:
      default:
        headers = constants.CUSTOMER_SEARCH_RESULTS_HEADERS;
        tableType = constants.CUSTOMER_RESULTS_TABLE;
        break;
      case constants.SEARCH_ORIGIN_VTV:
        headers = isPreviousOrigin;
        headers = constants.VTV_SEARCH_RESULTS_HEADERS;
        tableType = constants.VTV_RESULTS_TABLE;
        break;
      case constants.SEARCH_ORIGIN_AIRCHECK:
        headers = constants.AIRCHECK_SEARCH_RESULTS_HEADERS;
        tableType = constants.AIRCHECK_RESULTS_TABLE;
        break;
      case constants.SEARCH_ORIGIN_LICENSE_PLATE:
      case constants.SEARCH_ORIGIN_VIN:
        headers = constants.VEHICLE_SEARCH_RESULTS_HEADERS;
        tableType = constants.VEHICLES_RESULTS_TABLE;
    }
  };
  useEffect(() => {
    if (searchOrigin === constants.SEARCH_ORIGIN_AIRCHECK
      || searchOrigin === constants.SEARCH_ORIGIN_VTV) {
      setIsToggle(true);
    }
    return () => {
      setIsToggle(false);
    };
  }, [searchOrigin]);
  getHeaders();
  return (
    <ResultsContainer>
      <Table
        headers={headers}
        detailHeaders={constants.CUSTOMER_SEARCH_DETAILS_TABLE_HEADERS}
        dataResults={searchResults}
        filter
        hasSearch
        tableType={tableType}
        onSelect={onSelect}
      />
    </ResultsContainer>
  );
};

export default Results;
