import React, { useState, useRef } from 'react';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';

const TextAreaWrap = styled.div`
  background: none;
  position: relative;
  z-index: 1;
  outline: 0;
`;

const AnimatedLabel = styled.label`
  white-space: nowrap;
  padding: ${({ labelPadding }) => (labelPadding || '2px 6px')};
  background-color: #fff;
  position: absolute;
  font-size: 14px;
  pointer-events: none;
  transition: 0.2s ease all;
  color: ${({ theme }) => theme.colors.lightGray};
  opacity: 1;
  ${({ isDepthInput }) => isDepthInput && 'display: none;'}
  ${({ inputValue, isDisabled }) => (inputValue || typeof inputValue === constants.NUMBER
    ? `
      left: 10px;
      top: -9px;
      font-size: 13px;
      background-image: ${isDisabled ? 'linear-gradient(#fff 60%, #eaeaea 40%)' : '#fff'};
    `
    : `
      left: 8px;
      top: 13px;
      background-color: ${isDisabled ? '#eaeaea' : '#fff'};
  `)};
`;

export const RequiredSpan = styled.span`
  ${({ required, theme }) =>  (required
    ? `
      color: ${theme.colors.primaryRed};
      font-size: 14px;
      padding-right: 5px;
      `
    : 'display: none')};
`;

const TextAreaField = styled.textarea`
  padding: 12px 16px;
  box-sizing: border-box;
  height: ${({ height }) => (height || '80px')};
  width: 100%;
  resize: none;
  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  letter-spacing: -0.15px;
  line-height: 19px;
  overflow: hidden;
  text-align: left;

  &:focus ~ label {
    left: 10px;
    top: -9px;
    font-size: 13px;
  }
  &:focus-visible {
    outline: none;
    border: ${({ theme }) => `1px solid ${theme.colors.lightBlue}`};
  }
  &:disabled {
    border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    background-color: #eaeaea;
  }

  ${({ isInsideTable }) => isInsideTable
    && `
    box-sizing: border-box;
    height: 50px;
    width: 100%;
  `};

  ${({ isDepthInput, theme }) => isDepthInput
    && `
    box-sizing: border-box;
    width: 83px;
    font-family: ${theme.fontFamily.bold};
    font-size: 16px;
    margin-right: 5px;
    text-align: right;
  `};

  ${({ isError }) => `
    border: ${isError && '1px solid #FFA10D'};
    border-radius: ${isError ? '2px 2px 0 0' : '2px'};
  `}
`;

const TextArea = props => {
  const {
    type,
    label,
    isDisabled,
    inputValue,
    fieldName,
    required,
    inputHandler,
    index,
    height,
    labelPadding,
    maxLength,
    spellCheck,
    tabIndex = null,
    className,
  } = props;

  const [keyEvent, setKeyEvent] = useState({});
  const ref = useRef();

  //  handles initial value from props
  const setInputValue = (value) => {
    return value;
  };

  const handleKeyDown = (e) => setKeyEvent(e);

  const autoResize = (inputValue, scrollHeight) => {
    if (!inputValue || scrollHeight <= 80) {
      ref.current.style.height = '80px';
    } else {
      ref.current.style.height = scrollHeight + 'px';
    }
  };

  //  processes individual updates
  const handleChange = (event, type, index) => {
    const inputValue = event.target.value;
    const LINE_ITEMS_NOTES_REGEX = new RegExp(/$/mg);
    const isInputValid = inputValue.match(LINE_ITEMS_NOTES_REGEX);

    if (inputValue && (!isInputValid || inputValue.length > maxLength)) {
      event.preventDefault();
    } else {
      fieldName && inputHandler(inputValue, fieldName, required, index);
      autoResize(inputValue, event.target.scrollHeight);
    }
  };

  const focusHandler = (event) => {};

  return (
    <TextAreaWrap className={className}>
      <label htmlFor={fieldName}>
        <TextAreaField
          ref={ref}
          onFocus={focusHandler}
          disabled={isDisabled}
          name={fieldName}
          maxLength={maxLength}
          tabIndex={tabIndex}
          height={height}
          onChange={(event) => handleChange(event, type, index)}
          onBlur={(event) => handleChange(event, type, index)}
          onKeyDown={(e) => handleKeyDown(e)}
          value={setInputValue(inputValue)}
          spellCheck={spellCheck}
        />
        <AnimatedLabel labelPadding={labelPadding} isDisabled={isDisabled} inputValue={inputValue}>
          <RequiredSpan required={required}>*</RequiredSpan>
          {label}
        </AnimatedLabel>
      </label>
    </TextAreaWrap>
  );
};

export default TextArea;
