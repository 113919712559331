import { constant } from 'lodash';
import { useRef, useState } from 'react';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import * as constants from '../../constants/Constants';
import { countryOptions } from '../../helper/baseDataHelper';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import Input from '../Input/Input';
import {
  SmVerticalBar,
} from './AssemblyDropdown';
import Message from '../Error/Message';
import { isEmptyArray } from '../../helper/validationHelper';

export const ArrowIcon = styled.span`
  color: gray;
`;

export const EditAssemblyWrapper = styled.div`
  border: 1px solid ${theme.colors.lightGray};
`;
export const DropdownItems = styled.ul`
  background-color: white;
  border: 1px solid ${theme.colors.lightGray};
  position: absolute;
  top: 32px;
  width: 100%;
  padding-left: 0;
  z-index: 10;
  display: ${({ isOpen }) => (isOpen ? 'inherit' : 'none')};
  text-align: left;
`;

export const DropdownItem = styled.li`
  border-bottom: 1px solid #999999;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.veryLightGray};
  }
  ${({ isSelected }) => (isSelected && `border: 1.5px solid ${theme.colors.lightBlue}; color:${theme.colors.lightBlue}; outline: none;`)}
`;

const PhoneCountryWrapper = styled(EditAssemblyWrapper)`
  cursor: none;
  margin:0;
  width:335px;
  ${({ isEdit, errorMessage }) => (isEdit && !errorMessage && 'height:40px; width:210px; margin-right:5px')}
  ${({ errorMessage, theme }) => `
    border-top: ${errorMessage && `2px solid ${theme.colors.orange}`};
    border-right: ${errorMessage && `2px solid ${theme.colors.orange}`};
    border-left: ${errorMessage && `2px solid ${theme.colors.orange}`};
    border-bottom: ${errorMessage && 'none'};
    border-radius: ${errorMessage ? '2px 2px 0 0' : '2px'};
  `}
`;
const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width:fit-content;
  cursor: pointer;
  ${({ isEdit }) => (isEdit && 'height:40px')}
`;
const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left:11px;
  margin-right:0;
  outline: 0;
`;
const Flag = styled.img`
  width:24px;
  height:13px;
  ${({ option }) => (option
    && ' margin-left:11px; margin-right:14px')
};
`;
const Arrow = styled(ArrowIcon)`
  margin:0;
`;
const DropdownOption = styled(DropdownItem)`
display:flex;
align-items: center;
`;
const VerticalDivider = styled(SmVerticalBar)`
margin-right:-1px;
`;
const CountryDropdownItems = styled(DropdownItems)`
  max-height: none;
  ${({ isEdit }) => (isEdit && 'margin-top:6px;')}
`;
const CountryLabel = styled.p`
margin-right: 6px;
`;
const PhoneCountryDropdown = (props) => {
  const {
    inputValue, label, warningMessages, fieldName,
    inputHandler, isDisabled, tabIndex, isCreate, isPhone,
    required, errorMessage, countryDropdown, isEdit,
  } = props;
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [flagHasFocus, setFlagHasFocus] = useState(false);
  const [phoneInputHasFocus, setPhoneInputHasFocus] = useState(false);
  const getFlag = (countryCode) => {
    if (!countryCode) return './CountryFlags/unitedstatesflag.png';
    switch (countryCode) {
      case constants.CANADA:
      case constants.CANADA_PHONE_CODE:
        return './CountryFlags/canadaflag.png';
      case constants.MEXICO:
      case constants.MEXICO_PHONE_CODE:
        return './CountryFlags/mexicoflag.png';
      case constants.UNITED_STATES:
      default:
        return './CountryFlags/unitedstatesflag.png';
    }
  };

  const getCountryCode = (countryName) => {
    if (countryName === constants.MEXICO) {
      return '52';
    }
    if (countryName === constants.CANADA) {
      return '1';
    }
    return constants.UNITED_STATES;
  };
  const onClick = () => {
    setPhoneInputHasFocus((phoneInputHasFocus) => !phoneInputHasFocus);
    setIsOpen(!isOpen);
  };
  const toggleClose = (e) => {
    setFlagHasFocus(false);
    if (e.type === constants.CLICK) {
      if (document.activeElement.className.indexOf('PhoneCountryDropdown__FlagWrapper') > -1) {
        if (!flagHasFocus) {
          setIsOpen(!isOpen);
        }
      }
    } else if (e.type === constants.BLUR) {
      setIsOpen(false);
    } else if (e.type === constants.FOCUS) {
      setFlagHasFocus(true);
      setIsOpen(true);
    }
  };
  return (
    <>
      <PhoneCountryWrapper errorMessage={errorMessage} isEdit={isEdit}>
        <FlagContainer onBlur={(e) => toggleClose(e)} onClick={(e) => toggleClose(e)} isEdit={isEdit}>
          <FlagWrapper onFocus={(e) => toggleClose(e)} tabIndex={isCreate ? 3 : 0}>
            <Flag src={getFlag(countryDropdown.selectedOption)} />
            <Arrow>{isOpen ? <IoChevronUpOutline /> : <IoChevronDownOutline />}</Arrow>
            <VerticalDivider/>
          </FlagWrapper>
          <Input
            phoneInputHasFocus={phoneInputHasFocus}
            type={constants.TELEPHONE}
            isCreate={isCreate}
            fieldName={fieldName || 'phoneNumber'}
            inputValue={inputValue}
            inputHandler={inputHandler}
            label={label}
            tabIndex={tabIndex}
            warningMessages={warningMessages}
            isDisabled={isDisabled}
            countryCode={countryDropdown?.selectedOption}
            required={required}
            labelPadding={isEdit && '0 2px'}
            isEdit={isEdit}
        />
        </FlagContainer>
        <CountryDropdownItems isEdit={isEdit} isOpen={isOpen} >
          {countryOptions.slice(0).reverse().map((country, index) => {
            return (
              <DropdownOption
                key={index}
                onMouseDown={() => {
                  countryDropdown.onChange(getCountryCode(country.label), countryDropdown.fieldName);
                  onClick();
                }}>
                <Flag option src={getFlag(country.label)}/>
                <CountryLabel>{country.label}</CountryLabel>
                <CountryLabel> +{country.label === constants.MEXICO
                  ? constants.MEXICO_PHONE_CODE : constants.CANADA_PHONE_CODE}
                </CountryLabel>
              </DropdownOption>
            );
          })}
        </CountryDropdownItems>
        { errorMessage && !isEmptyArray(errorMessage) && <Message isPhone ref={inputRef} errorMessages={errorMessage} type={constants.ERROR} />}
      </PhoneCountryWrapper>
    </>

  );

};
export default PhoneCountryDropdown;
