import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import API from 'dt-cvm-api';
import { FaRegIdCard, FaRegUserCircle } from 'react-icons/fa';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import * as constants from '../../constants/Constants';
import { CVMContext } from '../../components/CVM/CVM';
import {
  checkForEmptyObject, checkIfObjectHasKey, getPrimaryInfo, isNullEmptyUndefined, isNullOrUndefined, phoneCustomerCycle, upperCaseFirstLetter,
} from '../../helper/validationHelper';
import {
  isVehicleActive,
  isVehicleInactive
} from '../Modals/VehicleModals/StandardVehicle/VehicleHelper';
import Flag from '../Flag/Flag';
import Message from '../Error/Message';
import { countryCodeOptions, removeLeadingZeros } from '../../helper/baseDataHelper';
import { genericYMM, getVehicleInfo, isGenericVehicle } from '../../helper/vehicleFormValidation';
import { customerVehicleDetails } from '../../helper/vehicleDetailsHelper';
import { getFullName } from '../../helper/CustomerHelper';


const TableContainer = styled.div`
  min-width: 1400px;
  position: relative;
  max-height: ${({ isInGK }) => (isInGK ? '550px' : '590px')};
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  ${({ tableType }) => tableType === constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE && 'min-width:1450px;'};
`;
const MessageContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;
const AccountClosed = styled.div`
  display: flex;
`;
const TableCellWrapper = styled.div`
  border-bottom: 1px solid ${theme.colors.gray};
  position: relative;
  &:hover {
    cursor: pointer;
    background-color: ${({ $active }) => ($active ? `${theme.colors.lightBlue}` : `${theme.colors.hoverGray}`)};
  }
  ${({ $active }) => ($active ? `background-color: ${theme.colors.lightBlue}; color:${theme.colors.white};   font-family: ${theme.fontFamily.semiBold};` : `background-color: ${theme.colors.white}; color:${theme.colors.darkGray}; font-family: ${theme.fontFamily.normal};`)};
  ${({ tableType }) => tableType === constants.DEACTIVATED_VEHICLES_TABLE && 'margin: 0 60px'};
`;
const ResultsTableBody = styled.tbody``;
const ResultsTable = styled.table`
  width: 1440px;
  margin: 0 50px;
  border-collapse: collapse;
  table-layout: fixed;
`;
const ResultsTableRow = styled.tr`
  height: 73px;
  font-size: ${theme.fontSize.infoSubtitle};
  text-align: left;
  //contitional tableType cell adjustments
  ${({ tableType, $active, previousOrigin }) => {
    switch (tableType) {
      case constants.CUSTOMER_RESULTS_TABLE:
        return `td:nth-child(1) {width: 40%;}
        td:nth-child(2) {width: 14%;}
        td:nth-child(3) {width: 26%;}
        td:nth-child(4) {width: 10%;text-align:center;}
        td:nth-child(5) {width: 10%;text-align:center;}`;
      case constants.VEHICLES_RESULTS_TABLE:
        return `td:nth-child(1) {width: 530px;}
        td:nth-child(2) {width: 200px;}
        td:nth-child(3) {width: 220px;}
        td:nth-child(4) {width: 155px;}
        td:nth-child(5) {width: 82px; text-align:left;}
        td:nth-child(6) {width: 140px; text-align:left;}
        td:nth-child(7) {width: 90px;}`;
      case constants.DEACTIVATED_VEHICLES_TABLE:
        return `
        height: 44px;
        font-family:${theme.fontFamily.normal};
        color: ${$active ? theme.colors.white : theme.colors.titleBlack};
        td:nth-child(1) {padding-left:30px;width:488px;}
        td:nth-child(2) {width:288px;}
        td:nth-child(3) {width:308px}`;
      case constants.ACTIVE_VEHICLES_TABLE:
        return `
        height: 44px;
        font-family:${theme.fontFamily.normal};
        color: ${$active ? theme.colors.white : theme.colors.titleBlack};
        td:nth-child(1) {padding-left:30px;width:435px;}
        td:nth-child(2) {width:260px;}
        td:nth-child(3) {width:200px;}
        td:nth-child(4) {width:auto}`;
      case constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE:
        return `
        height: 44px;
        font-family:${theme.fontFamily.normal};
        color: ${$active ? theme.colors.white : theme.colors.titleBlack};
        td:nth-child(1) {padding-left:30px;width:635px;}
        td:nth-child(2) {width:460px;}
        td:nth-child(3) {width:auto}`;
      case constants.VTV_RESULTS_TABLE:
        return previousOrigin ? `
        td:nth-child(1) {width: 430px;}
        td:nth-child(2) {width: 460px;}
        td:nth-child(3) {width: 145px;}
        td:nth-child(4) {width: 230px;}
        td:nth-child(5) {width: 120px;}
        td:nth-child(6) {width: 145px;}`
          :          `td:nth-child(1) {width: 430px;}
        td:nth-child(2) {width: 460px;}
        td:nth-child(3) {width: 205px;}
        td:nth-child(4) {width: 174px;}`;
      case constants.AIRCHECK_RESULTS_TABLE:
        return previousOrigin ? `
        td:nth-child(1) {width: 425px;}
        td:nth-child(2) {width: 400px;}
        td:nth-child(3) {width: 150px;}
        td:nth-child(4) {width: 295px;}
        td:nth-child(5) {width: 120px;}
        td:nth-child(6) {width: 145px;}`
          :          `td:nth-child(1) {width: 430px;}
        td:nth-child(2) {width: 460px;}
        td:nth-child(3) {width: 205px;}
        td:nth-child(4) {width: 174px;}`;
      default:
        return constants.EMPTY_STRING;
    }
  }}
`;
const ResultsTableBodyCell = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ alignment }) => `text-align: ${alignment}`};
  ${({ position }) => position && `position: ${position}`}
`;
const ToggleDetails = styled.div``;
const DetailsTableHeaders = styled.th`
  font-size: ${theme.fontSize.infoSubtitle};
  font-family: ${theme.fontFamily.heavy};
  padding-bottom: 7px;
  text-align: left;
  white-space: nowrap;
`;
const DetailsTableHeaderRow = styled.tr`
  border-bottom: 1px solid ${theme.colors.veryLightGray};
`;
const DetailsTableBodyRow = styled.tr``;
const DetailsTableBodyCell = styled.td`
  font-size: ${theme.fontSize.infoSubtitle};
  font-family: ${theme.fontFamily.semiBold};
  padding: 10px 0 22px 0;
  white-space: nowrap;
`;
// Button
const ViewButton = styled.div`
  font-family: ${theme.fontFamily.bold};
  font-size: ${theme.fontSize.large};
  line-height: ${theme.fontSize.semiLarge};
  position: absolute;
  top: 17px;
  color: ${({ $active }) => ($active ? `${theme.colors.white}` : `${theme.colors.lightBlue}`)};
  right: 45px;
  z-index: 3;
`;
// Icons
const CarIcon = styled.img`
  position: absolute;
  height: 24px;
  width: 24px;
  left: 15px;
  top: 17px;
  background-color: white;
  z-index: 3;
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${({ $active }) => ($active ? `${theme.colors.white}` : `${theme.colors.darkGray}`)};
  background-color: ${({ $active }) => ($active ? `${theme.colors.lightBlue}` : `${theme.colors.white}`)};
`;
const B2CIcon = styled(FaRegUserCircle)`
  position: absolute;
  left: 20px;
  top: 24px;
  background-color: white;
  z-index: 3;
  font-size: ${theme.fontSize.modalTitle};
  color: ${({ $active }) => ($active ? `${theme.colors.white}` : `${theme.colors.darkGray}`)};
  background-color: ${({ $active }) => ($active ? 'transparent;' : `${theme.colors.white}`)};
`;
export const PlusIcon = styled(AiOutlinePlus)`
  position: absolute;
  top: 30px;
  right: 25px;
  z-index: 3;
  color: ${({ $active }) => ($active ? `${theme.colors.white}` : `${theme.colors.lightBlue}`)};
`;
export const MinusIcon = styled(AiOutlineMinus)`
  display: none;
  position: absolute;
  top: 30px;
  right: 25px;
  z-index: 3;
  color: ${({ $active }) => ($active ? `${theme.colors.white}` : `${theme.colors.lightBlue}`)};
`;
const AccountYes = styled.span`
  &:after {
    content: 'Yes';
  }
`;
const IncompleteTableCellLabel = styled.span`
  background-color: ${theme.colors.white};
  padding: 4px 12px;
  margin-left: 15px;
  border-radius: 4px;
  font-size: 14px;
  color: ${theme.colors.titleBlack};
  font-family: ${theme.fontFamily.normal};
  font-weight: ${theme.fontWeight.bold};
`;

const TableBody = (props) => {
  const {
    data, detailHeaders, tableType, filter, isExpandedView, hasExpand, selectedValue, scrollHandler
  } = props;
  const {
    searchOrigin,
    setSelectedVehicle,
    setCSRSelected,
    setDVRSelected,
    setAddSelected,
    setVtvSelected,
    setAircheckSelected,
    setVehSelected,
    setIsManualVtv,
    errorMessageObj,
    setErrorMessageObj,
    setVtvCanSave,
    isExceedLimit,
    searchQuery,
    setLoader
  } = useContext(CVMContext);
  const [clickedOption, setClickedOption] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [previousIndex, setPreviousIndex] = useState(null);
  const limitExceeded = isExceedLimit ? {
    message: constants.RESULT_EXCEED_LIMIT,
    searchBy: searchOrigin,
    type: constants.WARNING,
  } : null;
  const noResults = {
    message: constants.NO_RESULTS_SEARCH,
    searchBy: searchOrigin,
    type: constants.WARNING,
  };
  useEffect(() => {
    setClickedOption(null);
    setCSRSelected(null);

  }, [selectedValue]);
  const accountYesContent = (index) => (
    <>
      <FaRegIdCard
        className="account-id"
        style={{
          marginTop: '3px',
          marginLeft: '-20px',
          position: 'absolute',
          color:
            clickedOption === index
              ? theme.colors.white
              : theme.colors.green,
        }}
      />
      <AccountYes className="account-yes" />
    </>
  );

  const toggleDetails = (e, c, index, isSelection, toggleAll) => {
    if (isSelection && !isNullOrUndefined(previousIndex) && previousIndex !== index) {
      toggleDetails(null, null, previousIndex, false);
    }
    const elems = document.getElementsByClassName(`dt-${index}`);
    Array.from(elems).forEach(item => {
      if (toggleAll) {
        isExpandedView ? item.style.display = 'table-row' : item.style.display = 'none';
        if (isSelection && tableType === constants.CUSTOMER_RESULTS_TABLE) {
          setClickedOption(index);
          setCSRSelected(data[index]);
        }
        if (isSelection && tableType === constants.VEHICLES_RESULTS_TABLE) {
          setClickedOption(index);
          setVehSelected(data[index]);
        }
      } else {
        item.style.display = item.style.display === 'none' ? 'table-row' : !isSelection && 'none';
      }
    });
    const elem = elems[0];
    if (elem && elem.style) {
      const vmBtn = document.getElementById(`vm-btn-${index}`);
      const vlBtn = document.getElementById(`vl-btn-${index}`);
      const minusIcon = document.getElementById(`minus-icon-${index}`);
      const plusIcon = document.getElementById(`plus-icon-${index}`);

      if (elem.style.display === 'none') {
        vmBtn.style.display = 'block';
        vlBtn.style.display = 'none';
        minusIcon.style.display = 'none';
        plusIcon.style.display = 'block';
      } else {
        vmBtn.style.display = 'none';
        vlBtn.style.display = 'block';
        minusIcon.style.display = 'block';
        plusIcon.style.display = 'none';
      }
    }
    isSelection && setPreviousIndex(index);
  };
  useEffect(() => {
    const isCustomerResultTable = tableType === constants.CUSTOMER_RESULTS_TABLE;
    const isVehicleResultTable = tableType === constants.VEHICLES_RESULTS_TABLE;
    const isAircheckResultTable = tableType ===  constants.AIRCHECK_RESULTS_TABLE;
    const isVtvResultTable = tableType === constants.VTV_RESULTS_TABLE;
    const isAdditionalVehicleTable = tableType === constants.ACTIVE_VEHICLES_TABLE;
    if (isAdditionalVehicleTable) return;
    setDVRSelected(null);
    setIsManualVtv(false);
    // There is an issue on the workflow from going to select vtv from summary setting this to null prevents the refresh of customer summary
    if (tableType === constants.VEHICLES_RESULTS_TABLE) setVehSelected(null);
    // setVehSelected(null);
    if (tableType !== constants.DEACTIVATED_VEHICLES_TABLE && tableType !== constants.ACTIVE_VEHICLES_TABLE && tableType !== constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE && tableType !== constants.VTV_RESULTS_TABLE && tableType !== constants.AIRCHECK_RESULTS_TABLE) {
      setCSRSelected(null);
    }
    setVtvCanSave(false);
    const yes = document.getElementsByClassName('account-yes');
    const ids = document.getElementsByClassName('account-id');
    if (isVtvResultTable || isAircheckResultTable) {
      setVtvSelected(null);
      setAircheckSelected(null);
    }
    const isDeactivatedVehicleTable = tableType === constants.DEACTIVATED_VEHICLES_TABLE;
    if (!isDeactivatedVehicleTable) {
      setClickedOption(null);
    } else if (currentFilter !== filter) {
      setClickedOption(null);
      setCurrentFilter(filter);
    }
    if (isExpandedView && hasExpand) {
      data.forEach((c, i) => {
        toggleDetails(null, data[i], i, clickedOption === i, true);
      });
    }
    if (!isExpandedView && hasExpand) {
      data.forEach((c, i) => {
        toggleDetails(null, data[i], i, clickedOption === i, true);
      });
    }
    if (data?.length === 1) {
      if (isCustomerResultTable || isVehicleResultTable) {
        if (isVehicleResultTable) {
          setClickedOption(0);
          setVehSelected(data[0]);
          toggleDetails(null, data[0], 0, true);
        } else {
          isCustomerResultTable ? setCSRSelected(data[0])
            : setVehSelected({
              ...customerVehicleDetails(data[0], { ...searchQuery }),
              ...getPrimaryInfo(data[0].addressCollection),
              ...getPrimaryInfo(data[0].emailCollection)
            });
          setClickedOption(0);
          toggleDetails(null, data[0], 0, true);
        }
      } else if (isVtvResultTable) {
        setVtvSelected(data[0]);
        setClickedOption(0);
      } else if (isAircheckResultTable) {
        setAircheckSelected(data[0]);
        setClickedOption(0);
      }
    } else {
      setClickedOption({});
      setVehSelected(null);
      setCSRSelected(null);
    }
    if (data?.length === 0) {
      setNoResultsFound(true);
      setErrorMessageObj(noResults);
    } else {
      setNoResultsFound(false);
      setErrorMessageObj({});
    }
    if (data?.length > 199) {
      setErrorMessageObj(limitExceeded);
    }
    setTimeout(() => {
      Array.from(yes).forEach((item, index) => {
        ids[index].style.left = `${item.offsetLeft - 25}px`;
      });
    }, 50);
    // Resets the position of the ID card in relation to 'Yes' after view size is changed
    window.addEventListener('resize', (e) => {
      Array.from(yes).forEach((item, index) => {
        ids[index].style.left = `${item.offsetLeft - 25}px`;
      });
    });

  }, [searchOrigin, data, isExpandedView, tableType]);

  const selectCustomer = (e, c, index) => {
    setClickedOption(index);
    setCSRSelected(c);
  };

  const selectVehicle = async (e, c, index) => {
    setClickedOption(index);
    setVehSelected(c);
  };

  const selectDeactivatedVehicle = (e, v, index) => {
    setClickedOption(index);
    setDVRSelected(v);
  };

  const selectAdditionalVehicle = async (v, index) => {
    setClickedOption(index);
    setAddSelected(v);
    const tireDetails = await getVehicleInfo(v, setLoader);

    if (tireDetails) {
      setSelectedVehicle({
        vehicleDetails: v,
        tireDetails,
      });
    }
  };
  const selectVTVAircheck = (v, index) => {
    setClickedOption(index);
    searchOrigin === constants.SEARCH_ORIGIN_VTV ? setVtvSelected(v) :    setAircheckSelected(v);

  };
  const renderData = () => {
    switch (tableType) {
      case constants.CUSTOMER_RESULTS_TABLE:
        return  data.map((c, index) => {
          // return defaults for address, email
          const isB2B = c.customerType === constants.BUSINESS_CUSTOMER_TYPE;
          const exceedsLength = c.email?.length > 31;
          const fleetInfo = c?.alternateIdentifierCollection?.find((aii) => aii.alternateIdentifierItem?.name === 'SAP NUMBER')?.alternateIdentifierItem?.value;
          return (
            <TableCellWrapper
              className={`details-table-wrapper ${index}`}
              key={index}
              $active={clickedOption === index || data.length === 1}
          >
              <>
                {c.customerType === constants.BUSINESS_CUSTOMER_TYPE  ? (
                  <>
                    <CarIcon
                      className="car-icon"
                      id={`car-icon-${index}`}
                      $active={clickedOption === index}
                      src={clickedOption === index ?  './VehicleImages/b2b.png' : './VehicleImages/b2b-black.png'}

                  />
                  </>
                ) : (
                  <B2CIcon $active={clickedOption === index} />
                )}
              </>
              <ResultsTable
                onClick={(e) => selectCustomer(e, c, index)}
                className={`details-table-row ${`rtr-${index}`}`}
            >
                <ResultsTableBody>
                  <ResultsTableRow tableType={tableType}>
                    <ResultsTableBodyCell colSpan={2}>
                      <AccountClosed>
                        {getFullName(c, true)}
                        {!isNullEmptyUndefined(c.blockedReasonCode) && !isNullEmptyUndefined(c.postingBlock) && <Flag inTable flagName={constants.ACCOUNT_STATUS} /> }
                        {!isNullEmptyUndefined(c.blockedReasonCode) && isNullEmptyUndefined(c.postingBlock)  && <Flag flagName={constants.AR_CHARGE_FLAG}/>}
                      </AccountClosed>
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell>
                      { searchQuery.workNumber
                        ? phoneCustomerCycle(searchQuery.workNumber, c) :
                        phoneCustomerCycle({ ...searchQuery }, c)}
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell
                      title={(exceedsLength && c?.email) || ''} data-delay="10" colSpan={3}>{c?.email || constants.EMPTY_STRING}
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell position="relative">
                      {c.isMyAccount ? accountYesContent(index) : 'No'}
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell>{removeLeadingZeros(fleetInfo) || constants.NOT_APPLICABLE}
                    </ResultsTableBodyCell>
                  </ResultsTableRow>
                  <DetailsTableHeaderRow style={{ display: 'none' }} className={`dt-${index}`}>
                    {detailHeaders.map((d, dIndex) => {
                      if (dIndex === 6 && !isB2B) return;
                      return (
                        <DetailsTableHeaders
                          c
                          id={`dt-header-cell-${index}`}
                          className={`dt-header-cells-${index}`}
                          key={dIndex}
                    >
                          {d}
                        </DetailsTableHeaders>
                      );
                    })}
                  </DetailsTableHeaderRow>
                  <DetailsTableBodyRow
                    style={{ display: 'none' }}
                    className={`details-table-row dt-${index}`}
                >
                    <DetailsTableBodyCell>{upperCaseFirstLetter(c?.addressLine1) || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{upperCaseFirstLetter(c?.addressLine2) || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{upperCaseFirstLetter(c?.city) || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{c?.region || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{c?.postalCode || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{countryCodeOptions.filter(country => country.value === c?.country)[0]?.label || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    {c?.contactCollection?.some((cust) => cust.contactItem.isSinglePointOfContactPurchasing) && c.customerType === 'B2B' && (
                    <DetailsTableBodyCell>{upperCaseFirstLetter(c.firstName)} {c?.middleName ? `${upperCaseFirstLetter(c.middleName)} ` : ''}{upperCaseFirstLetter(c.lastName)}</DetailsTableBodyCell>
                    )}
                  </DetailsTableBodyRow>
                </ResultsTableBody>
              </ResultsTable>
              <ToggleDetails
                className="toggle-details-cell"
                onClick={(event) => toggleDetails(event, c, index, false)}
            >
                <ViewButton
                  className="vm-btn"
                  id={`vm-btn-${index}`}
                  $active={clickedOption === index}
                  name="VIEW MORE"
              >
                  View
                  <br />
                  more
                </ViewButton>
                <ViewButton
                  className="vl-btn"
                  style={{ display: 'none' }}
                  id={`vl-btn-${index}`}
                  $active={clickedOption === index}
                  name="VIEW LESS"
              >
                  View
                  <br />
                  less
                </ViewButton>
                <MinusIcon
                  className="minus-icon"
                  id={`minus-icon-${index}`}
                  $active={clickedOption === index}
              />
                <PlusIcon
                  className="plus-icon"
                  id={`plus-icon-${index}`}
                  $active={clickedOption === index}
              />
              </ToggleDetails>
            </TableCellWrapper>
          );
        });

      case constants.VEHICLES_RESULTS_TABLE:
        return data.map((c, index) => {
          const customerInfo = { ...c };
          const isB2B = c.customerType === constants.BUSINESS_CUSTOMER_TYPE;
          const isGeneric = isGenericVehicle(c);
          // WIP: These values are not being exposed currently in vehicle search, will dynamically adjust when present
          const genericYMM = c?.overrideYear !== 0 && c?.overrideYear && c?.overrideMake && c?.overrideModel;
          return (
            <TableCellWrapper
              className={`details-table-wrapper ${index}`}
              key={index}
              $active={clickedOption === index}
          >
              <>
                {customerInfo.customerType === constants.BUSINESS_CUSTOMER_TYPE ? (
                  <>
                    <CarIcon
                      className="car-icon"
                      id={`car-icon-${index}`}
                      $active={clickedOption === index}
                      src={clickedOption === index ?  './VehicleImages/b2b.png' : './VehicleImages/b2b-black.png'}
                  />
                  </>
                ) : (
                  <B2CIcon $active={clickedOption === index} />
                )}
              </>
              <ResultsTable
                onClick={(e) => selectVehicle(e, customerInfo, index)}
                className={`details-table-row ${`rtr-${index}`}`}
            >
                <ResultsTableBody>
                  <ResultsTableRow tableType={tableType}>
                    <ResultsTableBodyCell colSpan={2}>
                      <AccountClosed>
                        {getFullName(customerInfo, true)}
                        {!isNullEmptyUndefined(c.blockedReasonCode) && !isNullEmptyUndefined(c.postingBlock) && <Flag inTable flagName={constants.ACCOUNT_STATUS} /> }
                        {!isNullEmptyUndefined(c.blockedReasonCode) && isNullEmptyUndefined(c.postingBlock)  && <Flag flagName={constants.AR_CHARGE_FLAG}/>}

                      </AccountClosed>
                    </ResultsTableBodyCell>
                    {!isGeneric ? <ResultsTableBodyCell>{`${c?.year || constants.EMPTY_STRING} ${upperCaseFirstLetter(c?.make) || constants.EMPTY_STRING} ${c?.model || constants.EMPTY_STRING}`}</ResultsTableBodyCell> :
                      genericYMM ? <ResultsTableBodyCell>{`${c?.overrideYear} ${upperCaseFirstLetter(c?.overrideMake)} ${upperCaseFirstLetter(c?.overrideModel)}`}</ResultsTableBodyCell> :
                      <ResultsTableBodyCell>{`${upperCaseFirstLetter(c?.overrideVehicleCategory) || constants.EMPTY_STRING} ${upperCaseFirstLetter(c?.overrideVehicleSubCategory) || constants.EMPTY_STRING}`}</ResultsTableBodyCell>}
                    <ResultsTableBodyCell colSpan={2}>{c?.vin?.toUpperCase()}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{c?.licensePlateNumber?.toUpperCase()}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{c?.licensePlateStateCode?.split('-')[1]}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{countryCodeOptions.filter(obj => obj.value
                    === c?.licensePlateCountryCode)[0]?.label}
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell position="relative" alignment="center">
                      {customerInfo.isMyAccount ? accountYesContent(index) : 'No'}
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell />

                    <ResultsTableBodyCell />
                  </ResultsTableRow>
                  <DetailsTableHeaderRow style={{ display: 'none' }} className={`dt-${index}`}>
                    {detailHeaders.map((d, dIndex) => {
                      if (dIndex === 6 && !isB2B) return;
                      return (
                        <DetailsTableHeaders
                          c
                          id={`dt-header-cell-${index}`}
                          className={`dt-header-cells-${index}`}
                          key={dIndex}
                          colSpan={(dIndex === 5 || dIndex === 6) && 2}
                    >
                          {d}
                        </DetailsTableHeaders>
                      );
                    })}
                  </DetailsTableHeaderRow>
                  <DetailsTableBodyRow
                    style={{ display: 'none' }}
                    className={`details-table-row dt-${index}`}
                >
                    <DetailsTableBodyCell>{upperCaseFirstLetter(c?.addressLine1) || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{upperCaseFirstLetter(c?.addressLine2) || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{upperCaseFirstLetter(c?.city) || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{c?.region || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell>{c?.postalCode || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    <DetailsTableBodyCell colSpan={2}>{countryCodeOptions.filter(country => country.value === c?.country)[0]?.label || constants.EMPTY_STRING}</DetailsTableBodyCell>
                    {c?.contactCollection?.some((cust) => cust.contactItem.isSinglePointOfContactPurchasing) && c.customerType === 'B2B' && (
                    <DetailsTableBodyCell>{upperCaseFirstLetter(c.firstName)} {c?.middleName ? `${upperCaseFirstLetter(c.middleName)} ` : ''}{upperCaseFirstLetter(c.lastName)}</DetailsTableBodyCell>
                    )}
                  </DetailsTableBodyRow>
                </ResultsTableBody>
              </ResultsTable>
              <ToggleDetails
                className="toggle-details-cell"
                onClick={(event) => toggleDetails(event, customerInfo, index, false)}
            >
                <ViewButton
                  className="vm-btn"
                  id={`vm-btn-${index}`}
                  $active={clickedOption === index}
                  name="VIEW MORE"
              >
                  View
                  <br />
                  more
                </ViewButton>
                <ViewButton
                  className="vl-btn"
                  style={{ display: 'none' }}
                  id={`vl-btn-${index}`}
                  $active={clickedOption === index}
                  name="VIEW LESS"
              >
                  View
                  <br />
                  less
                </ViewButton>
                <MinusIcon
                  className="minus-icon"
                  id={`minus-icon-${index}`}
                  $active={clickedOption === index}
              />
                <PlusIcon
                  className="plus-icon"
                  id={`plus-icon-${index}`}
                  $active={clickedOption === index}
              />
              </ToggleDetails>
            </TableCellWrapper>
          );
        });
      case constants.DEACTIVATED_VEHICLES_TABLE:
        return data?.map((v, index) => {
          const { type } = v;
          const genericYMM  = v.year && v.make && v.model && v.type === 'Generic';
          return (
            <TableCellWrapper
              className={`details-table-row ${`rtr-${index}`}`}
              key={index}
              $active={clickedOption === index}
              tableType={tableType}
          >
              <ResultsTable onClick={(e) => selectDeactivatedVehicle(e, v, index)}>
                <ResultsTableBody>
                  <ResultsTableRow tableType={tableType} $active={clickedOption === index}>
                    <ResultsTableBodyCell>
                      {type !== 'Generic' ? `${v.year || constants.EMPTY_STRING} ${v.make || constants.EMPTY_STRING} ${v.model || constants.EMPTY_STRING} ${checkIfObjectHasKey(v.trim, 'name') ? v.trim.name.label : v.trim || constants.EMPTY_STRING}`
                        : genericYMM ? `${v.year} ${upperCaseFirstLetter(v.make)} ${upperCaseFirstLetter(v.model)} ${upperCaseFirstLetter(v.trim || constants.EMPTY_STRING) || constants.EMPTY_STRING}`
                          : `${upperCaseFirstLetter(v.overrideVehicleCategory)} ${upperCaseFirstLetter(v.overrideVehicleSubCategory) || constants.EMPTY_STRING}`}
                      { v.isIncomplete && <IncompleteTableCellLabel>{constants.INCOMPLETE_VEHICLE_LABEL}</IncompleteTableCellLabel> }
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell>{v.type === 'Generic' ? 'Yes' : 'No'}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{v.color || constants.NOT_APPLICABLE}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{v.license || constants.NOT_APPLICABLE}</ResultsTableBodyCell>
                  </ResultsTableRow>
                </ResultsTableBody>
              </ResultsTable>
            </TableCellWrapper>
          );
        });
      case constants.ACTIVE_VEHICLES_TABLE:
        return data.filter(v => isVehicleActive(v)).map((v, index) => {
          if (isVehicleInactive(v)) {
            return;
          }

          const isGeneric = isGenericVehicle(v);
          const genericYMM = v?.overrideYear !== 0 && v?.overrideYear && v?.overrideMake && v?.overrideModel;
          return (
            <TableCellWrapper
              onClick={() => selectAdditionalVehicle(v, index)}
              className={`details-table-row ${`rtr-${index}`}`}
              key={index}
              $active={clickedOption === index}
              tableType={tableType}
            >
              <ResultsTable>
                <ResultsTableBody>
                  <ResultsTableRow tableType={tableType} $active={clickedOption === index}>
                    <ResultsTableBodyCell>
                      {!isGeneric ? `${v.year} ${upperCaseFirstLetter(v.make || constants.EMPTY_STRING)} ${v.model || constants.EMPTY_STRING} ${checkIfObjectHasKey(v.trim, 'name') ? v.trim.name.label || constants.EMPTY_STRING : v.trim || constants.EMPTY_STRING}`
                        : genericYMM ? `${v.overrideYear || constants.EMPTY_STRING} ${upperCaseFirstLetter(v.overrideMake || constants.EMPTY_STRING)} ${v?.overrideModel || constants.EMPTY_STRING} ${v?.overrideTrim || constants.EMPTY_STRING}`
                          : `${upperCaseFirstLetter(v.overrideVehicleCategory) || constants.EMPTY_STRING} ${upperCaseFirstLetter(v.overrideVehicleSubCategory) || constants.EMPTY_STRING}`}
                      {v.isIncomplete ? <IncompleteTableCellLabel>{constants.INCOMPLETE_VEHICLE_LABEL}</IncompleteTableCellLabel> : null}
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell>{isGeneric ? 'Yes' : 'No'}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{upperCaseFirstLetter(v.color) || constants.NOT_APPLICABLE}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{v.license || constants.NOT_APPLICABLE}</ResultsTableBodyCell>
                  </ResultsTableRow>
                </ResultsTableBody>
              </ResultsTable>
            </TableCellWrapper>
          );
        });
      case constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE:
        return data.filter(v => isVehicleActive(v)).map((v, index) => {
          if (isVehicleInactive(v)) {
            return;
          }

          const isGeneric = isGenericVehicle(v);
          const genericYMM = v?.overrideYear !== 0 && v?.overrideYear && v?.overrideMake && v?.overrideModel;
          return (
            <TableCellWrapper
              onClick={() => selectAdditionalVehicle(v, index)}
              className={`details-table-row ${`rtr-${index}`}`}
              key={index}
              $active={clickedOption === index}
              tableType={tableType}
            >
              <ResultsTable>
                <ResultsTableBody>
                  <ResultsTableRow tableType={tableType} $active={clickedOption === index}>
                    <ResultsTableBodyCell>
                      {!isGeneric ? `${v.year} ${upperCaseFirstLetter(v.make || constants.EMPTY_STRING)} ${v.model || constants.EMPTY_STRING} ${checkIfObjectHasKey(v.trim, 'name') ? v.trim.name.label || constants.EMPTY_STRING : v.trim || constants.EMPTY_STRING}`
                        : genericYMM ? `${v.overrideYear || constants.EMPTY_STRING} ${upperCaseFirstLetter(v.overrideMake || constants.EMPTY_STRING)} ${v?.overrideModel || constants.EMPTY_STRING} ${v?.overrideTrim || constants.EMPTY_STRING}`
                          : `${upperCaseFirstLetter(v.overrideVehicleCategory) || constants.EMPTY_STRING} ${upperCaseFirstLetter(v.overrideVehicleSubCategory) || constants.EMPTY_STRING}`}
                    </ResultsTableBodyCell>
                    <ResultsTableBodyCell>{upperCaseFirstLetter(v.color) || constants.NOT_APPLICABLE}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{v.license || constants.NOT_APPLICABLE}</ResultsTableBodyCell>
                  </ResultsTableRow>
                </ResultsTableBody>
              </ResultsTable>
            </TableCellWrapper>
          );
        });
      case constants.VTV_RESULTS_TABLE:
      case constants.AIRCHECK_RESULTS_TABLE:
        return data?.map((v, index) => {
          // TO DO: REMOVE this once the inspections begin being posted as company, this is old workaround -NL
          const isBusiness = v?.customer?.lastName === constants.BUSINESS_CUSTOMER_TYPE;
          const isUpdatedBusiness = v?.customer?.customerType === constants.BUSINESS_CUSTOMER_TYPE;
          const isGeneric = v?.vehicle.overrideVehicleCategory;
          const isGenericYMM = v.vehicle.overrideYear && v.vehicle.overrideMake && v.vehicle.overrideModel;
          return (
            <TableCellWrapper
              onClick={() => selectVTVAircheck(v, index)}
              className={`details-table-row ${`rtr-${index}`}`}
              key={index}
              $active={clickedOption === index}
              tableType={tableType}
          >
              <>
                {(!isBusiness && !isUpdatedBusiness) ? <B2CIcon $active={clickedOption === index} /> : (
                  <CarIcon
                    className="car-icon"
                    id={`car-icon-${index}`}
                    $active={clickedOption === index}
                    src={clickedOption === index ?  './VehicleImages/b2b.png' : './VehicleImages/b2b-black.png'}
                  />
                )}
              </>
              <ResultsTable>
                <ResultsTableBody>
                  <ResultsTableRow
                    tableType={tableType}
                    $active={clickedOption === index}
                >
                    <ResultsTableBodyCell colSpan={2}>{!isUpdatedBusiness ? `${upperCaseFirstLetter(v.customer?.firstName || constants.INCOMPLETE_VEHICLE_LABEL)} ${!isBusiness ? v.customer?.lastName || constants.EMPTY_STRING : constants.EMPTY_STRING}` : `${upperCaseFirstLetter(v.customer?.companyName)}${v.customer.companyName2 ? ` / ${upperCaseFirstLetter(v.customer.companyName2)}` : constants.EMPTY_STRING}`}</ResultsTableBodyCell>
                    {!isGeneric ?  (
                      <ResultsTableBodyCell>
                        {v.vehicle?.year !== '0' && v.vehicle?.year} {upperCaseFirstLetter(v.vehicle?.make)} {upperCaseFirstLetter(v.vehicle?.model)} {checkIfObjectHasKey(v.vehicle?.trim, 'name') ? upperCaseFirstLetter(v.trim.name?.label) : upperCaseFirstLetter(v?.trim)}
                      </ResultsTableBodyCell>
                    ) : (
                      <ResultsTableBodyCell>
                        {!isGenericYMM ? `${upperCaseFirstLetter(v.vehicle.overrideVehicleCategory)} ${v.vehicle.overrideVehicleSubCategory ? upperCaseFirstLetter(v.vehicle.overrideVehicleSubCategory) : constants.EMPTY_STRING}` : `${v.vehicle.overrideYear} ${v.vehicle.overrideMake} ${v.vehicle.overrideModel} ${v.vehicle.overrideTrim || constants.EMPTY_STRING}`}
                      </ResultsTableBodyCell>
                    )}
                    <ResultsTableBodyCell>{v.vehicle?.licensePlate}</ResultsTableBodyCell>
                    <ResultsTableBodyCell alignment="left">{v?.audit?.employeeNumber}</ResultsTableBodyCell>
                    <ResultsTableBodyCell>{moment(moment.utc(v?.auditDateTime)).local().format('hh:mm A')}
                    </ResultsTableBodyCell>
                  </ResultsTableRow>
                </ResultsTableBody>
              </ResultsTable>
            </TableCellWrapper>
          );
        });
      default:
        return null;
    }
  };

  return (
    <TableContainer isInGK={API.GK.hasPOS()} tableType={tableType} onScroll={
      scrollHandler ? (e) => scrollHandler(e) : () => {}
    }>
      { (noResultsFound || isExceedLimit)
      && tableType !== constants.ADDITIONAL_ACTIVE_VEHICLES_TABLE && tableType !== constants.ACTIVE_VEHICLES_TABLE ? (
        <MessageContainer>
          <Message errorMessages={errorMessageObj?.message} type={errorMessageObj?.type} fullWidth/>
        </MessageContainer>
        ) : null}
      {renderData()}
    </TableContainer>
  );
};

export default TableBody;
