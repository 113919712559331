import * as constants from '../constants/Constants';
import { countryCodeOptions } from './baseDataHelper';

const searchFilter = (searchOrigin, tableType, data, filter) => {
  return data.filter((c) => {
    const fullName = `${(searchOrigin === 'VTV' || searchOrigin === 'Aircheck') ? `${c?.customer.firstName} ${(c?.customer.middleName ?? constants.EMPTY_STRING)}${c?.customer.lastName}` : `${c?.firstName} ${(c?.middleName ?? constants.EMPTY_STRING)}${c?.lastName}`}`.trim();
    let fullVehicle = `${c?.vehicle?.year} ${c?.vehicle?.make} ${c?.vehicle?.model}`.trim();
    if (tableType === constants.DEACTIVATED_VEHICLES_TABLE) {
      fullVehicle = `${c.year} ${c.make} ${c.model}`.trim();
      const fieldToSearch = [
        c.year,
        c.make,
        c.model,
        c.trim,
        c.color,
        c.license,
        c.overrideVehicleCategory,
        c.overrideVehicleSubCategory,
        fullVehicle,
      ].map((field) => field?.toString().toLowerCase());
      return fieldToSearch.some(field => field?.includes(filter.toLowerCase()));
    }
    // NOTE: Added this to also check the label version of country not just label
    c.licensePlateCountry = c.licensePlateCountryCode ? countryCodeOptions.find(country => country.value === c.licensePlateCountryCode).label : '';
    const searchableFields = {
      [constants.SEARCH_ORIGIN_CUSTOMER]: [
        c?.firstName, c?.lastName, c?.companyName, c?.companyName2, c?.city, c?.region,
        c?.postalCode, c?.fleetNumber?.toString(), c?.addressLine1, c?.addressLine2, c?.email, fullName,
        c?.phoneNumber
      ],
      [constants.SEARCH_ORIGIN_CREATE_CUSTOMER]: [
        c?.year?.toString(), c?.make?.toString(), c?.model?.toString(), c?.trim?.toString(),
        c?.color?.toString(), c?.license?.toString(), c?.overrideVehicleCategory?.toString(),
        c?.overrideVehicleSubCategory?.toString(), fullVehicle
      ],
      [constants.SEARCH_ORIGIN_VTV]: [
        c?.customer?.firstName, c?.customer?.lastName, c?.vehicle?.model, c?.vehicle?.make,
        c?.vehicle?.year?.toString(), c?.vehicle?.license?.toString(), c?.vehicle?.licensePlate?.toString(),
        fullName, fullVehicle
      ],
      [constants.SEARCH_ORIGIN_AIRCHECK]: [
        c?.customer?.firstName, c?.customer?.lastName, c?.vehicle?.model, c?.vehicle?.make,
        c?.vehicle?.year?.toString(), c?.vehicle?.license?.toString(), c?.vehicle?.licensePlate?.toString(),
        fullName, fullVehicle
      ],
      [constants.SEARCH_ORIGIN_LICENSE_PLATE]: [
        c?.firstName, c?.lastName, c?.companyName, c?.companyName2, c?.city, c?.postalCode, c.region,
        c?.addressLine1, c?.addressLine2, c?.year?.toString(), c?.make?.toString(), c?.model?.toString(),
        c?.licensePlateNumber?.toString(), c?.licensePlateStateCode, c?.licensePlateCountryCode, c?.licensePlateCountry,
        c?.vin, c?.middleName, fullName, fullVehicle
      ],
      [constants.SEARCH_ORIGIN_VIN]: [
        c?.firstName, c?.lastName, c?.companyName, c?.companyName2, c?.city, c?.postalCode, c.region,
        c?.addressLine1, c?.addressLine2, c?.year?.toString(), c?.make?.toString(), c?.model?.toString(),
        c?.licensePlateNumber?.toString(), c?.licensePlateStateCode, c?.licensePlateCountryCode, c?.licensePlateCountry,
        c?.vin, c?.middleName, fullName, fullVehicle
      ],
    }[searchOrigin];

    if (!searchableFields) {
      return false;
    }
    return searchableFields.some(field => field?.toLowerCase()?.includes(filter.toLowerCase()));
  });
};

export default searchFilter;
