import styled from '../../helpers/esm-styled-components';
import { FaSpinner } from 'react-icons/fa';
import { theme } from '../../helpers/GlobalStyles';

const LoaderWrapper = styled.div`
  background: ${({ modal, loadingText }) => modal ? 'transparent' : `rgb(232 232 232 / ${loadingText ? '95%' : '75%'})`};
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 9999999999999;
`;

const Spinner = styled(FaSpinner)`
  margin: 0 auto;
  left: 50%;
  position: absolute;
  top: ${({ modal }) => modal ? '30%' : '415px'};
  font-size: 40px;

  //animate
  animation: spin infinite 1s linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
const LoadingText = styled.h1`
  margin: 470px auto;
  color: ${theme.colors.titleBlack};
  text-align: center;
`;

const Loader = ({isModal, loadingText }) => {
  return (
    <LoaderWrapper data-testid="spinner" modal={isModal} loadingText={loadingText}>
      <Spinner modal={isModal} />
      <LoadingText>{loadingText}</LoadingText>
    </LoaderWrapper>
  );
};

export default Loader;
