import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

import env from '@beam-australia/react-env';
import API from 'dt-cvm-api';
import debug from 'debug';

import StorageShim from 'node-storage-shim';

import packageJSON from '../package.json';

import Okta from '../components/Okta/Okta';

import { APIRouter } from './APIRouter';
import * as constants from '../constants/Constants';
import * as errors from '../constants/Errors';

/* eslint-disable import/no-cycle */
import { CVMContext } from '../components/CVM/CVM';
import { getVehicleAssemblies, getVehicleInfo } from '../helper/vehicleFormValidation';
import { isNullEmptyUndefined } from '../helper/validationHelper';
import {
  countryCodeOptions, countryOptions, getColorOption, getCachedCVData
} from '../helper/baseDataHelper';

/* eslint-enable import/no-cycle */

import { isOktaRedirect } from '../constants/Navigation';

import { AAAStoreCodeMap } from './aaaStoreData.js';
import { isGenericVehicleStandard } from '../UIComponents/Modals/VehicleModals/StandardVehicle/VehicleHelper.js';

const localStorage = globalThis.localStorage || new StorageShim();
const sessionStorage = globalThis.sessionStorage || new StorageShim();

const logger = debug('api');

const APIContext = createContext();

const APILoader = (props) => {

  const {
    navigate,
    NavigationParams,
    setModuleName,
    setSearchOrigin,
    setCSRSelected,
    setSelectedVehicle,
    setGkTransactionInfo,
    setGkTransactionCV,
    setApplicationError,
    setImportedVehicle,
  } = useContext(CVMContext);

  //  flag to hold child rendering until lookup tables load.
  const [loaded, setLoaded] = useState(false);

  //  "static" data for state/province lookups keyed by country.
  const [countryData, setCountryData] = useState({});

  //  processed value/label structures for state/province option lists
  const [stateOptions, setStateOptions] = useState({});

  //  latest vehicle category data (including subcategories)
  const [vehicleCategories, setVehicleCategories] = useState({});

  //  TODO: fitment lookup data (tire sizes etc)

  /**
   * @method getStateOptions
   * @summary Returns an array of key/value pairs of state codes and labels
   *     for the ISO country code provided. Note that the only country codes
   *     currently supported are US, CA, and MX (United States, Canada, and
   *     Mexico).
   * @param {String} countryCode A string country code, preferable a two-letter
   *     ISO-3166 country code.
   * @returns {Array.<Object>} A list of value/label pairs for the country.
   */
  const getStateOptions = (countryCode) => {

    if (Object.keys(stateOptions).length === 0) {
      return [];
    }

    switch (countryCode) {
      case constants.CA:
      case constants.CANADA:
      case constants.CAN:
        return stateOptions[constants.CA];
      case constants.MX:
      case constants.MEXICO:
        return stateOptions[constants.MX];
      case constants.US:
      case constants.USA:
      case constants.UNITED_STATES:
      default:
        return stateOptions[constants.US];
    }
  };


  /**
   * @method getStateOrProvince
   * @summary Returns a value/label pair for the countryCode and state/province
   *     key provided. NOTE that for historical reasons the pair is returned as
   *     a single item in an array.
   * @param {String} countryCode A string country code, preferable a two-letter
   *     ISO-3166 country code.
   * @param {Object|String} stateOrProvinceKey The state/province value to look
   *     up (or an object containing a 'value' slot with that value).
   * @returns {Array.<Object>} An array containing a key/value pair if found.
   */
  const getStateOrProvince = (countryCode, stateOrProvinceKey) => {

    let data;

    if (Object.keys(countryData).length === 0) {
      return [];
    }

    switch (countryCode) {
      case constants.CA:
      case constants.CAN:
        data = countryData[constants.CA];
        break;
      case constants.MX:
        data = countryData[constants.MX];
        break;
      case constants.US:
      case constants.USA:
      case constants.UNITED_STATES:
      default:
        //  NOTE: countryData is keyed by ISO-3166 two-letter code(s)
        data = countryData[constants.US];
        break;
    }

    if (API.utils.notValid(data)) {
      throw new Error(`Invalid or unsupported country code ${countryCode}`);
    }

    if (API.utils.notValid(stateOrProvinceKey)) {
      throw new Error(`Invalid state or province key ${stateOrProvinceKey}`);
    }

    //  TODO: shouldn't have to check for object as parameter, should force
    //  string value on the caller(s) instead.
    const value = typeof stateOrProvinceKey === 'object' ? stateOrProvinceKey.value : stateOrProvinceKey;

    const label = data[value];
    if (API.utils.isEmpty(label)) {
      throw new Error(`Unknown state or province ${value} for country ${countryCode}`);
    }

    //  TODO: shouldn't have to put this in an array but we currently do due to
    //  legacy "filter()" approach which returns an array with the item.
    return [{ value, label }];
  };


  /**
   * @method getVehicleCategories
   * @summary Returns a dictionary of value/label data for generic vehicle
   *     categories and their subcategories.
   * @returns {Object} A dictionary of value/label category information.
   */
  const getVehicleCategories = () => {

    if (Object.keys(vehicleCategories).length === 0) {
      return {};
    }

    return vehicleCategories;
  };


  /*
   * useContext data for the API component.
   *
   * "exports" here provide the public APIs the UX uses to access lookup data
   * after it's been loaded/transformed by the initial API load process.
   */
  const APIData = {
    getStateOptions,
    getStateOrProvince,
    getVehicleCategories,
  };


  //  NOTE: the useEffect call here loads lookup data used by SYNCHRONOUS calls
  //  "exported" via the context. Early development of the UI layer relied
  //  heavily on synchronous datasets for things like countries, states, vehicle
  //  categories, and zipcodes (when many of those are actually dynamic). This
  //  component and the useEffect call work around that limitation by preloading
  //  those datasets and providing synchronous calls that access their
  //  underlying data once it's been successfully loaded/formatted.

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(async () => {

    const logs = env('DEBUG') || env('API_LOG_PREFIXES') || 'api*';

    //  Enable logging if we're not running in prd/prod/production environment
    logger(`enabling ${logs} logging for ENV_NAME ${env('ENV_NAME')}. `);
    debug.enable(logs);

    //  Initialize the GK API so it's ready for invocations.
    API.GK.initialize(window);

    //  Are we running in an iframe?
    const runningInIFrame = window.location !== window.parent.location;

    //  Define an async function we can use to activate the lookup load calls.
    //  It needs to be async since we'll be calling async API endpoints.
    const startup = async () => {
      let info,
        suppliedParams,
        gkInfo,
        storeInfo,
        vehicles,
        assemblies,
        tireAttributes;
      // If we're coming from an Okta redirect clear the code/state params from
      // the URL so we don't preserve them in any bookmark history for this app
      // session. Also, remove any parameters that aren't part of URL API.
      const urlParams = new URL(window.location).searchParams;
      /*
       * (ss) commented out for now... this breaks in GK for some reason.
       *
      if (urlParams.keys().some(key => !NavigationParams.includes(key))) {
        // Remove any parameters that aren't part of the URL API.
        let path = window.location.pathname;
        if (urlParams.length > 2) {
          path += '?';
          urlSearchParams.forEach((value, key) => {
            if (NavigationParams.includes(key)) {
              path += `${key}=${value}&`;
            }
          });
          path = path.slice(0, -1);
        }
        window.location = path;
        return;
      }
      */

      // UI version output to help out QA and users to determine what's been deployed to what environment
      logger('CVM UI version: ', packageJSON?.version);

      info = await APIRouter('ADMIN', 'getVersionInfo');
      if (API.utils.isValid(info)) {
        logger(`API version(s): ${JSON.stringify(info)}`);
      }

      // Make sure we cleanse any "URL-driven" storage parameters prior to
      // processing any new parameters (or store info from the POS).
      localStorage.removeItem('AAA');
      localStorage.removeItem('SRPMIC');

      localStorage.removeItem('stateCode');

      let newurl;

      //  NB: We can use URLSearchParams objects as a set of entries.
      for (const [name, value] of urlParams) {
        //  Make sure to strip any embedded quotes from the search parameter
        //  names and values. But we also want to remove any where the name is
        //  quoted.
        if (/['"]+/g.test(name) || /['"]+/g.test(value)) {
          const nameToUse = name.replace(/['"]+/g, '');
          const valueToUse = value.replace(/['"]+/g, '');

          //  Delete the original
          urlParams.delete(name);

          urlParams.set(nameToUse, valueToUse);
        }
      }

      suppliedParams = sessionStorage.getItem('supplied_parameters');
      if (API.utils.notEmpty(suppliedParams)) {
        try {
          suppliedParams = JSON.parse(suppliedParams);
        } catch (e) {
          logger('Error parsing supplied parameters: ', e);
        }

        //  Construct a new URL that we can use below.

        if (API.utils.isValid(suppliedParams) && Object.keys(suppliedParams).length > 0) {
          /* eslint-disable no-restricted-syntax */
          for (const key of Object.keys(suppliedParams)) {
            urlParams.set(key, suppliedParams[key]);
          }
          /* eslint-enable no-restricted-syntax */

          newurl = `${window.location.protocol}//${
            window.location.host}${window.location.pathname}?${
            urlParams.toString()}`;
        } else {
          newurl = `${window.location.protocol}//${
            window.location.host}${window.location.pathname}`;
        }

        //  Use pushState() so that we don't actually navigate to it.
        window.history.pushState({ path: newurl }, '', newurl);
      } else {
        suppliedParams = {};
      }

      NavigationParams.forEach((name) => {
        const urlVal = urlParams.get(name);
        if (API.utils.isValid(urlVal)) {
          API._setenv(`url_${name}`, urlVal);
        }
      });

      //  ---
      //  countries/states/provinces
      //  ---

      info = await APIRouter('AVS', 'getCountryData');

      //  Ensure 3-letter key for US is in place in the data. Most of the UX
      //  lookups rely on "USA" as a key.
      info[constants.USA] = info[constants.US];
      setCountryData(info);

      //  Process country data into state options list variants for dropdowns.
      const options = {};
      Object.keys(info).forEach((key) => {
        const data = info[key];
        options[key] = Object.keys(data).map((val) => {
          return { value: val, label: data[val] };
        });
      });
      setStateOptions(options);

      //  ---
      //  vehicleCategories
      //  ---

      info = await APIRouter('FIT', 'getVehicleCategories');

      if (API.utils.isValid(info)) {
        info = info.map((key) => (
          {
            value: key.value, label: key.label, subCategories: key?.subCategory, image: key?.image
          }
        ));
        setVehicleCategories(info);
      } else {
        //  99% case there was an Okta problem or one of the backends is
        //  offline. We could continue but without vehicle categories the
        //  generic vehicle feature set will likely fail.
        //  TODO: do better here than an alert
        /* eslint-disable no-alert, no-restricted-globals */
        /*
        const nowWhat = confirm('Unable to access vehicle categories. Continue?');
        if (!nowWhat) {
          throw new Error('Unable to access backend service(s).');
        }
        */
        /* eslint-enable no-alert, no-restricted-globals */
      }

      //  ---
      //  GK POS data
      //  ---
      // TEST VEHICLE
      gkInfo = await APIRouter('GK', 'getTransactionInfo');

      if (!gkInfo) {
        //  TODO:   probably don't want hard-coded fallbacks
        gkInfo = {
          businessUnitGroupID: '100000000000000002',
          businessUnitID: 2080,   // Store ID for AZP 57
          isoCurrencyCode: 'USD',
          storeLanguage: 'en_US',
          tenantID: '001',
          userLanguage: 'en_US',
          workstationID: '1',
          version: constants.DEFAULT_SCHEMA_VERSION
        };
      }

      // Once GK starts supplying a version field it implies at least `1.0.5`.
      // If it's missing then we have to default to the older 1.0.4 value.
      if (API.utils.notValid(gkInfo.version)) {
        gkInfo.version = '1.0.4';
      } else {
        gkInfo.version = `${gkInfo.version}`;
      }

      //  Update CVMContext so it has the current GK session information.
      setGkTransactionInfo({ ...gkInfo });

      storeInfo = await APIRouter('AVS', 'getStoreInfo', gkInfo.businessUnitID);

      if (!storeInfo) {
        //  TODO:   probably don't want hard-coded fallbacks
        storeInfo = {
          storeCode: 'AZP 57',
          stateProvince: constants.SITE_STORE,
          isAaaSite: false,
          isSrpmic: false,
        };
        console.warn('defaulting storeInfo', storeInfo);
      }

      //  GK-only
      localStorage.setItem('siteId', gkInfo.businessUnitID);

      //  Schema version (as requested by GK POS). Note that this can be
      //  overridden by the schema_version query parameter value.
      localStorage.setItem('schemaVersion', gkInfo.version);

      // Temporary way of storing siteId for rendering SRPMIC comps, and Tax
      // exempt store location
      localStorage.setItem('storeCode', storeInfo.storeCode);
      localStorage.setItem('siteState', storeInfo.stateProvince);
      localStorage.setItem('AAA', storeInfo.isAaaSite);
      localStorage.setItem('SRPMIC', storeInfo.isSrpmic);

      gkInfo = await APIRouter('GK', 'getTransactionCV');

      if (!gkInfo) {
        // gkInfo = {
        //   customer: {
        //     customerId: '4862076'
        //   },
        //   vehicle: {
        //     customerVehicleID: '5290509'
        //   }
        // };
        gkInfo = {
          customer: null,
          vehicle: null,
          charge: null,
        };
      } else {
        if (!gkInfo.customer) {
          gkInfo = {
            ...gkInfo,
            customer: null,
          };
        }
        if (!gkInfo.charge) {
          gkInfo = {
            ...gkInfo,
            charge: null,
          };
        }
        if (!gkInfo.vehicle) {
          gkInfo = {
            ...gkInfo,
            vehicle: null,
          };
        }
      }

      const customerId = gkInfo?.customer?.customerId ||
          env('url_customer_id');
      const vehicleId = gkInfo?.vehicle?.customerVehicleID ||
          env('url_vehicle_id');

      let cachedCVData;

      if (API.utils.notEmpty(customerId) || API.utils.notEmpty(vehicleId)) {
        //  If we get customer & vehicle, check "Apply C/V" cache to see if
        //  we've got C/V cached for faster page load without server calls.
        cachedCVData = getCachedCVData(customerId, vehicleId);
      }

      //  Update CVMContext so it has the current GK customer and vehicle
      setGkTransactionCV(gkInfo);

      //  Adjust the CVM module based on customer/vehicle state
      if (API.utils.isValid(gkInfo?.customer)) {
        setModuleName(constants.MODULE_CUSTOMER_SUMMARY);

        //  TODO: this should be cleaned up... we just don't want to reset from
        //  GK and have it think we can return to search results.
        setSearchOrigin(constants.SEARCH_ORIGIN_CREATE_CUSTOMER);

        let csr;

        if (API.utils.isValid(cachedCVData)) {
          csr = { id: cachedCVData?.customer?.id || cachedCVData?.customer?.customerId };
        } else {
          csr = { id: gkInfo?.customer?.id || gkInfo?.customer?.customerId };
        }

        setCSRSelected(csr);

        //  Customer plus vehicle means we want to select that vehicle
        if (API.utils.isValid(gkInfo?.vehicle) && API.utils.notValid(cachedCVData)) {
          const vid = gkInfo?.vehicle?.customerVehicleID;
          const vSpec = { vehicleId: vid };
          const veh = await APIRouter('C360', 'getCustomerVehicle', [csr.id, vSpec]);

          // TODO: (ss) the 'veh' above has already gone through
          // normalizeVehicleDetail, can't we just use it without all the calls
          // below to get assemblies, attributes, etc. AGAIN?

          // TODO: (ss) As a followup to the above, note the isIncompleteVehicle
          // call currently does some of the same work... why not just call that
          // here if it's going to be necessary?

          if (veh) {
            assemblies = await APIRouter('FIT', 'getVehicleAssemblies', {
              year: veh.year,
              make: veh.make,
              model: veh.model,
              trim: veh.trimId,
            });
            if (API.utils.notValid(assemblies)) {
              assemblies = [];
            }

            if (Array.isArray(assemblies)) {

              const trims = await APIRouter('FIT', 'getVehicleTrims', ({ year: veh.year, make: veh.make, model: veh.model }));
              const trimLiftId = trims?.vehicleTrim?.liftCollection.filter(t => t.liftItem.chassisId === veh.chassisCode)[0]?.liftItem?.liftIdCollection?.filter(lc => lc.liftIdItem.liftDescription === veh.fitmentLiftedId)[0]?.liftIdItem?.liftId ?? null;
              veh.trimLiftId = trimLiftId;

              const assemblyId = assemblies?.[0]?.assemblyId;
              tireAttributes = await APIRouter('FIT', 'getVehicleAttributes', {
                year: veh.year,
                make: veh.make,
                model: veh.model,
                trim: veh.trimId,
                assembly: assemblyId
              });
              if (API.utils.notValid(tireAttributes)) {
                tireAttributes = null;
              }
            } else {
              //  TODO: defaults? what's our fallback here?
              tireAttributes = null;
            }

            //  If we can't get the full thing then don't set, just let the full
            //  list of vehicles be displayed rather than an error.
            if (veh && tireAttributes) {
              setSelectedVehicle({
                vehicleDetails: veh,
                tireDetails: tireAttributes
              });
            }
            // TO DO: This is last check generic not being selected tire info attributes nested in obj differently.
            // Refactor getVehicleInfo, but will be a catch all if normal fitment does not setSelected
            if (veh.type === 'Generic') {
              setSelectedVehicle({ vehicleDetails: veh, tireDetails: await getVehicleInfo(veh) });
            }
          }
        } else if (API.utils.isValid(cachedCVData?.vehicle)) {
          setSelectedVehicle({ ...cachedCVData?.vehicle });
        }

      // if we get a vehicle without a customer, build the vehicle and store for re-call in customer summary
      } else if (API.utils.isValid(gkInfo?.vehicle)) {
        const veh = { ...gkInfo.vehicle };


        let importedVehicleObj = null;
        if (veh.vehicleType === 'Fitment') {
          const neccessaryFields = ['vehicleYear', 'vehicleMake', 'vehicleModel', 'vehicleTrim', 'fitmentAssemblyId', 'fitmentTrimId', 'fitmentChassisId'];
          let invalid = false;
          neccessaryFields.forEach(field => {
            if (isNullEmptyUndefined(veh[field])) {
              invalid = true;
            }
          });
          if (!invalid) {
            const assemblies = await getVehicleAssemblies({
              year: veh.vehicleYear, make: veh.vehicleMake, model: veh.vehicleModel, trim: veh.vehicleTrim
            });

            let fitmentChassisIdTrimmed = constants.EMPTY_STRING;
            try {
              fitmentChassisIdTrimmed = parseInt(veh.fitmentChassisId, 10);
            } catch (err) {
              logger('error parsing fitment chassis id for imported vehicle');
            }

            let assemblyItem;
            if (assemblies[0].isStaggered) {
              assemblyItem = assemblies.find(a => a.assembly[0].chassisId == fitmentChassisIdTrimmed && a.assembly[0].assemblyCode === veh.fitmentAssemblyId);
            } else {
              assemblyItem =  assemblies.find(a => a.assembly.chassisId == fitmentChassisIdTrimmed && a.assembly.assemblyCode === veh.fitmentAssemblyId);
            }
            let selectedAssembly;

            if (assemblies[0].isStaggered) {
              selectedAssembly = [{
                assemblyCode: assemblyItem.assembly[0].assemblyCode,
                assemblyId: assemblyItem.assembly[0].fitmentId,
                assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
                fitmentId: assemblyItem.assembly[0].fitmentId,
                frontRimSize: assemblyItem.assembly[0].wheelSize,
                frontTireAspectRatio: assemblyItem.assembly[0].aspectRatio,
                frontTireDiameter: assemblyItem.assembly[0].wheelSize,
                frontTireWidth: assemblyItem.assembly[0].crossSection,
                rearRimSize: assemblyItem.assembly[1].wheelSize,
                rearTireAspectRatio: assemblyItem.assembly[1].aspectRatio,
                rearTireDiameter: assemblyItem.assembly[1].wheelSize,
                rearTireWidth: assemblyItem.assembly[1].crossSection,
                sizeQualifier: '0',
                tireSize: assemblyItem.assembly[0].tireSize,
                wheelSize: assemblyItem.assembly[0].wheelSize,
                isOE: true,
                isAfterMarket: false
              },
              {
                assemblyCode: assemblyItem.assembly[1].assemblyCode,
                assemblyId: assemblyItem.assembly[1].fitmentId,
                assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
                fitmentId: assemblyItem.assembly[1].fitmentId,
                frontRimSize: assemblyItem.assembly[1].wheelSize,
                frontTireAspectRatio: assemblyItem.assembly[1].aspectRatio,
                frontTireDiameter: assemblyItem.assembly[1].wheelSize,
                frontTireWidth: assemblyItem.assembly[1].crossSection,
                rearRimSize: assemblyItem.assembly[1].wheelSize,
                rearTireAspectRatio: assemblyItem.assembly[1].aspectRatio,
                rearTireDiameter: assemblyItem.assembly[1].wheelSize,
                rearTireWidth: assemblyItem.assembly[1].crossSection,
                sizeQualifier: '0',
                tireSize: assemblyItem.assembly[1].tireSize,
                wheelSize: assemblyItem.assembly[1].wheelSize,
                isOE: true,
                isAfterMarket: false,
              },
              ];
            } else {
              selectedAssembly = {
                assemblyCode: assemblyItem.assembly.assemblyCode,
                assemblyId: assemblyItem.assembly.fitmentId,
                assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
                fitmentId: assemblyItem.assembly.fitmentId,
                frontRimSize: assemblyItem.assembly.wheelSize?.toString(),
                frontTireAspectRatio: assemblyItem.assembly.aspectRatio,
                frontTireDiameter: assemblyItem.assembly.wheelSize,
                frontTireWidth: assemblyItem.assembly.crossSection,
                rearRimSize: assemblyItem.assembly.wheelSize,
                rearTireAspectRatio: assemblyItem.assembly.aspectRatio,
                rearTireDiameter: assemblyItem.assembly.wheelSize,
                rearTireWidth: assemblyItem.assembly.crossSection,
                sizeQualifier: '0',
                tireSize: assemblyItem.assembly.tireSize,
                wheelSize: assemblyItem.assembly.wheelSize,
                isOE: true,
                isAfterMarket: false,
              };
            }

            if (assemblies[0].isStaggered) {
              assemblyItem = {
                assemblyCode: assemblyItem.assembly[0].assemblyCode,
                assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
                fitmentId: assemblyItem.assembly[0].fitmentId,
                frontRimSize: assemblyItem.assembly[0].wheelSize,
                frontTireAspectRatio: assemblyItem.assembly[0].aspectRatio,
                frontTireDiameter: assemblyItem.assembly[0].wheelSize,
                frontTireWidth: assemblyItem.assembly[0].crossSection,
                rearRimSize: assemblyItem.assembly[1].wheelSize,
                rearTireAspectRatio: assemblyItem.assembly[1].aspectRatio,
                rearTireDiameter: assemblyItem.assembly[1].wheelSize,
                rearTireWidth: assemblyItem.assembly[1].crossSection,
                sizeQualifier: '0',
                tireSize: assemblyItem.assembly[0].tireSize,
                wheelSize: assemblyItem.assembly[0].wheelSize,
                isOE: true,
                isAfterMarket: false
              };
            } else {
              assemblyItem = {
                assemblyCode: assemblyItem.assembly.assemblyCode,
                assemblyType: constants.VEHICLE_OE_ASSEMBLY_TYPE,
                fitmentId: assemblyItem.assembly.fitmentId,
                frontRimSize: assemblyItem.assembly.wheelSize,
                frontTireAspectRatio: assemblyItem.assembly.aspectRatio,
                frontTireDiameter: assemblyItem.assembly.wheelSize,
                frontTireWidth: assemblyItem.assembly.crossSection,
                rearRimSize: assemblyItem.assembly.wheelSize,
                rearTireAspectRatio: assemblyItem.assembly.aspectRatio,
                rearTireDiameter: assemblyItem.assembly.wheelSize,
                rearTireWidth: assemblyItem.assembly.crossSection,
                sizeQualifier: '0',
                tireSize: assemblyItem.assembly.tireSize,
                wheelSize: assemblyItem.assembly.wheelSize,
                isOE: true,
                isAfterMarket: false
              };
            }
            if (assemblyItem) {

              // need to get vehicle image
              const trims = await APIRouter('FIT', 'getVehicleTrims', ({ year: veh.vehicleYear, make: veh.vehicleMake, model: veh.vehicleModel }));
              let imageUrl;
              if (trims) {
                const { trimCollection } = trims.vehicleTrim;
                imageUrl = trimCollection.find(tc => tc.trimItem.trimId === veh.fitmentTrimId)?.trimItem?.imageSmallUrl ||
                  constants.EMPTY_STRING;

                // if trim description comes in as the trim id, convert it back to the description
                if (!Number.isNaN(veh.vehicleTrim)) {
                  veh.vehicleTrim = trimCollection.find(tc => tc.trimItem.trimId === veh.fitmentTrimId)?.trimItem.trimName;
                }
              }
              importedVehicleObj = {
                vin: veh.vehicleVIN || constants.EMPTY_STRING,
                licensePlate: veh.vehicleLicensePlate || constants.EMPTY_STRING,
                licenseState: veh.vehicleLicensePlateState || constants.EMPTY_STRING,
                licenseCountry: typeof veh.vehicleLicensePlateCountry === 'string'
                  ? countryCodeOptions.find(option => option.value === veh.vehicleLicensePlateCountry)
                  : veh.vehicleLicensePlateCountry
                || countryOptions[0],
                isWizard: true,
                imageUrl: imageUrl || veh.imageUrl || constants.EMPTY_STRING,
                color: veh.vehicleColor,
                vehicleDetails: {},
                isManual: true,
                isCarryOut: veh.carryOut,
                isOneTime: veh.oneTimeVehicleIndicator,
                assemblyCollection: [{ assemblyItem }],
                selectedAssembly,
                year: veh.vehicleYear,
                make: veh.vehicleMake,
                model: veh.vehicleModel,
                trim: veh.vehicleTrim,
                trimDescription: veh.vehicleTrim,
                trimId: veh.fitmentTrimId,
                type: veh.vehicleType,
              };
            }
          }
        } else {
          // Catch all for checking the 3 values
          if (veh.genericVehicleCategory && veh.genericVehicleCategory?.includes('_'))veh.genericVehicleCategory = veh.genericVehicleCategory.replace('_', ' ');
          if (veh.genericVehicleSubcategory && veh.genericVehicleSubcategory?.includes('_')) veh.genericVehicleSubcategory = veh.genericVehicleSubcategory.replace('_', ' ');
          // NOTE: PB does not send a flag for staggered
          const isStandardGeneric = isGenericVehicleStandard(veh);
          // generic vehicle restructuring
          importedVehicleObj = {
            vehicleCategory: veh.genericVehicleCategory || constants.EMPTY_STRING,
            vehicleSubCategory: veh.genericVehicleSubcategory || constants.EMPTY_STRING,
            overrideVehicleCategory: veh.genericVehicleCategory || constants.EMPTY_STRING,
            overrideVehicleSubCategory: veh.genericVehicleSubcategory || constants.EMPTY_STRING,
            year: veh.vehicleYear || constants.EMPTY_STRING,
            make: veh.vehicleMake || constants.EMPTY_STRING,
            model: veh.vehicleModel || constants.EMPTY_STRING,
            overrideYear: veh.vehicleYear || constants.EMPTY_STRING,
            overrideMake: veh.vehicleMake || constants.EMPTY_STRING,
            overrideModel: veh.vehicleModel || constants.EMPTY_STRING,
            overrideTrim: veh.vehicleTrim || constants.EMPTY_STRING,
            color: getColorOption(veh.vehicleColor)[0]?.label || constants.EMPTY_STRING,
            licensePlateNumber: veh.vehicleLicensePlate || constants.EMPTY_STRING,
            licenseCountry: veh.vehicleLicensePlateCountry ? veh.vehicleLicensePlateCountry === constants.US ? constants.USA : veh.vehicleLicensePlateCountry : countryOptions[0].value,
            licenseState: veh.vehicleLicensePlateState || constants.EMPTY_STRING,
            vin: veh.vehicleVIN || constants.EMPTY_STRING,
            oneTimeVehicle: veh.oneTimeVehicleIndicator,
            type: constants.VEHICLE_TYPE_GENERIC,
            vehicleTireData: [{
              isDually: veh.isDually || false,
              isStaggered: !isStandardGeneric || false,
              frontTireAspectRatio: `${veh?.frontTireRatio || constants.EMPTY_STRING}`,
              frontTireDiameter: `${veh?.frontTireDiameter || constants.EMPTY_STRING}`,
              frontRimSize: `${veh?.frontRimSize || constants.EMPTY_STRING}`,
              frontTireWidth: `${veh?.frontTireWidth || constants.EMPTY_STRING}`,
              rearTireAspectRatio: `${veh?.rearTireRatio || constants.EMPTY_STRING}`,
              rearTireDiameter: `${veh?.rearTireDiameter || constants.EMPTY_STRING}`,
              rearRimSize: `${veh?.rearRimSize || constants.EMPTY_STRING}`,
              rearTireWidth: `${veh?.rearTireWidth || constants.EMPTY_STRING}`,
              frontTirePressure: `${veh?.frontTirePressureGeneric || constants.EMPTY_STRING}`,
              rearTirePressure: `${veh?.rearTirePressureGeneric || constants.EMPTY_STRING}`,
              boltPattern: veh.genericFrontBoltPattern || constants.EMPTY_STRING,
              lugNutSize: veh.genericFrontLugNut || constants.EMPTY_STRING,
              torque: veh.genericFrontTorque || constants.EMPTY_STRING,
              rearBoltPattern: veh.genericRearBoltPattern || constants.EMPTY_STRING,
              rearLugNutSize: veh.genericRearLugNut || constants.EMPTY_STRING,
              rearTorque: veh.genericRearTorque || constants.EMPTY_STRING,
            }],
          };
        }
        setImportedVehicle(importedVehicleObj);
        setModuleName(constants.MODULE_SEARCH);
      } else {
        // if we don't get any imported data, just go to search screen
        setModuleName(constants.MODULE_SEARCH);
      }

      //  ---
      //  finis
      //  ---

      //  Be sure we trigger re-rendering of child content (the real app :)).
      setLoaded(true);

      logger('Retrieving supplied parameters and mixing them with current URL parameters');

      const params = { ...suppliedParams };

      /* eslint-disable */
      for (const [key, val] of urlParams.entries()) {
        params[key] = val;
      }
      /* eslint-enable */

      // Remove "cache-buster" parameter.
      delete params.uuid;

      // Remove OAuth2 keys, they throw off navigation logic.
      delete params.code;
      delete params.state;

      // Still have parameters? We're being asked to navigate in that case...
      if (API.utils.notEmpty(params)) {
        const moduleName = urlParams.get('module') || suppliedParams?.module;

        logger('Removing supplied parameters');
        sessionStorage.removeItem('supplied_parameters');

        setTimeout(() => {
          navigate(moduleName, params);
        }, 0);
      }
    };  //  End of startup (definition).


    //  Are we simulating logins? If so, skip all of the auth stuff, but still
    //  work with URL parameters.

    if (!API.utils.isTrue(env('API_SIMULATE_LOGIN'))) {

      const urlParams = new URL(window.location).searchParams;

      //  Otherwise, do we detect a token in URL. If we have one, then we are
      //  using URLs for our parameters and we don't need to log in. All
      //  parameters will be stored in session storage.
      const hasTokenInURL = urlParams.has('token');

      if (runningInIFrame && hasTokenInURL) {

        //  We don't already have a token in session storage, but there's one
        //  on the URL - grab it.
        const val = urlParams.get('token');

        const tokenFaker = {
          accessToken: {
            accessToken: val
          }
        };

        sessionStorage.setItem(
          'okta-token-storage', JSON.stringify(tokenFaker));

        try {
          await startup();
        } catch (e) {
          logger(e);
        }

        return;
      }

      if (runningInIFrame && API.utils.isTrue(env('API_POSTMESSAGE_TOKEN'))) {

        let callback,
          timeoutID;

        //  If in iframe, listen for postMessage. When postMessages arrives,
        //  if it has params, those overwrite whatever we got on the URL in
        //  session storage.
        window.addEventListener('message',
          callback = async (evt) => {

            window.removeEventListener('message', callback, false);

            const uiData = evt.data;
            if (uiData.msg === 'TARGET_MODULE') {

              const navInfo = uiData.nav_info;

              const val = navInfo.token;

              const tokenFaker = {
                accessToken: {
                  accessToken: val
                }
              };

              sessionStorage.setItem(
                'okta-token-storage', JSON.stringify(tokenFaker));

              if (val) {
                window.clearTimeout(timeoutID);
              }

              const moduleName = navInfo.module;
              await navigate(moduleName, navInfo);
            }
          },
          false);

        timeoutID = setTimeout(() => {
          window.removeEventListener('message', callback, false);
          throw new Error('Timed out waiting for auth token');
        }, env('API_POSTMESSAGE_TIMEOUT'));

        try {
          await startup();
        } catch (e) {
          logger(e);
        }

        return;
      }

      // Check to make sure that we if we have a 'error=access_denied' on the
      // URL that we take the user back to the login screen.
      if (urlParams.get('error') === 'access_denied') {

        try {
          await Okta.shutItDown({
            issuer: env('API_OKTA_ISSUER'),
            clientId: env('API_OKTA_CLIENT_ID'),
            redirectUri: env('API_OKTA_REDIRECT_URI'),
            devMode: API.utils.isTrue(env('API_OKTA_DEVMODE')),
            tokenManager: {
              autoRenew: true,
              storage: 'sessionStorage'
            }
          },
          'okta access denied');
        } catch (e) {
          logger(e);

          // A problem with closing the Okta server-side session. Just throw
          // our hands up.
          setApplicationError({
            errorType: errors.ERROR_PAGE_TYPE_OKTA,
            message: errors.ERROR_PAGE_MESSAGE_OKTA,
            errorStack: e?.stack,
          });
        }

        return;
      }

      if (!isOktaRedirect(window.location)) {
        const vals = {};

        //  Make sure to stash away any URL parameters that are in the list of
        //  preserved parameter names above so that we can restore them when we
        //  come back from the Okta login.
        /* eslint-disable no-restricted-syntax */
        for (const key of NavigationParams) {
          if (urlParams.has(key)) {
            vals[key] = urlParams.get(key);
          }
        }
        /* eslint-enable no-restricted-syntax */

        if (API.utils.notEmpty(vals)) {
          logger('Storing supplied parameters in preparation for authentication');

          sessionStorage.setItem('supplied_parameters', JSON.stringify(vals));
        }
      }

      try {
        logger('Initializing Okta');

        await Okta.initialize({
          issuer: env('API_OKTA_ISSUER'),
          clientId: env('API_OKTA_CLIENT_ID'),
          redirectUri: env('API_OKTA_REDIRECT_URI'),
          devMode: API.utils.isTrue(env('API_OKTA_DEVMODE')),
          tokenManager: {
            autoRenew: true,
            storage: 'sessionStorage'
          }
        },
        startup);
      } catch (e) {
        logger('Error initializing Okta: ', e);

        setApplicationError({
          errorType: errors.ERROR_PAGE_TYPE_OKTA,
          message: errors.ERROR_PAGE_MESSAGE_OKTA,
          errorStack: e?.stack,
        });


      }
    } else {
      try {
        await startup();
      } catch (e) {
        logger(e);
      }
    }

  /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable */
  return (
    <APIContext.Provider value={APIData}>
      {loaded && props.children}
    </APIContext.Provider>
  )
  /* eslint-enable */
};

//  ---

const AAASiteNums = Object.values(AAAStoreCodeMap);

const isAAASite = (siteId) => {
  // URL-driven AAA status override gets checked first.
  const val = localStorage.getItem('AAA');
  if (API.utils.isValid(val)) {
    return val === 'true' || val === true;
  }

  if (!siteId) {
    return false;
  }

  return AAASiteNums.includes(parseInt(siteId, 10));
};

const isSRPMICSite = (siteId) => {
  // URL-driven SRPMIC status override gets checked first.
  const val = localStorage.getItem('SRPMIC');
  if (API.utils.isValid(val)) {
    return val === 'true' || val === true;
  }

  if (!siteId) {
    return false;
  }

  //  AZP20 is the only SRPMIC site (for now).
  return siteId.toString() === '1022';
};

//  ---

export {
  API,
  APIContext,
  APILoader,

  isAAASite,
  isSRPMICSite,
};

export default APILoader;
