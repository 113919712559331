import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';

import Button from './Button';

const SearchButton = styled(Button)`
  text-transform: uppercase;
  font-family: ${theme.fontFamily.bold};
  font-size: ${theme.fontSize.large};
  margin: 0;
  padding: 0 2em;
  border: 0;
  border-radius: 5px;
  width: 335px;
  ${({ width }) => width && `width:${width}px;`};
  ${({ buttonName, isDisabled }) => (buttonName && !isDisabled
    ? `background-color: ${theme.colors.green}; color:${theme.colors.white}`
    : `background-color: ${theme.colors.searchGray}; color:${theme.colors.lightGray}; pointer-events: none;`)};
`;

export default SearchButton;
