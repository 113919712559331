import React, { useRef, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import Message from '../Error/Message';
import { isEmptyArray, isEmptyObject } from '../../helper/validationHelper';

const InputWrap = styled.div`
  background: none;
  position: relative;
  z-index: 1;
  outline: 0;
`;

const AnimatedLabel = styled.label`
  white-space: nowrap;
  padding: ${(props) => (props.labelPadding || '2px 6px')};
  background-color: #fff;
  position: absolute;
  font-size: 14px;
  pointer-events: none;
  transition: 0.2s ease all;
  color: ${({ theme }) => theme.colors.lightGray};
  opacity: 1;
  ${({ isDepthInput }) => isDepthInput && 'display: none;'}
  ${({ inputValue, isDisabled, countryCode }) => (inputValue || typeof inputValue === constants.NUMBER
    ? `
      left: ${countryCode ? '-50px' : '10px'};
      top: -9px;
      font-size: 13px;
      background-image: ${isDisabled ? 'linear-gradient(#fff 60%, #eaeaea 40%)' : '#fff'};
      `
    : `
      left: 8px;
      top: 13px;
      background-color: ${isDisabled ? '#eaeaea' : '#fff'};
  `)}
`;

export const RequiredSpan = styled.span`
  ${({ required, theme }) => (required === true
    ? `
        color: ${theme.colors.primaryRed};
        font-size: 14px;
        padding-right: 5px;
        `
    : `display: none
    `)}
`;

const InputField = styled.input`
  padding: ${(props) => (props.padding || '12px 16px')};
  box-sizing: border-box;
  height: ${(props) => (props.height || '50px')};
  width: 100%;
  border: ${({ theme, border }) => border || `1px solid ${theme.colors.lightGray}`};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${(props) => (props.fontSize || '16px')};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  letter-spacing: -0.15px;
  line-height: 19px;
  text-align: ${(props) => (props.textAlign || 'left')};
  &:focus ~ label {
    left: ${({ countryCode }) => (countryCode ? '-50px' : '10px')};
    top: -9px;
    font-size: 13px;
  }
  &:focus-visible {
    outline: none;
    border: ${({ theme, noFocusOutline }) => !noFocusOutline && `1px solid ${theme.colors.lightBlue}`};
  }
  &:disabled {
    border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    background-color: #eaeaea;
  }
  ${({ capsLock }) => capsLock && 'text-transform: uppercase;'}
  ${({ isInsideTable }) => isInsideTable
    && `
    box-sizing: border-box;
    height: 50px;
    width: 100%;
  `}

  ${({ isDepthInput, theme }) => isDepthInput
    && `
    box-sizing: border-box;
    width: 83px;
    font-family: ${theme.fontFamily.bold};
    font-size: 16px;
    margin-right: 5px;
    text-align: right;
  `}

  ${({ errorMessage, theme }) => `
    border-top: ${errorMessage && `2px solid ${theme.colors.orange}`};
    border-right: ${errorMessage && `2px solid ${theme.colors.orange}`};
    border-left: ${errorMessage && `2px solid ${theme.colors.orange}`};
    border-bottom: ${errorMessage && 'none'};
    border-radius: ${errorMessage ? '2px 2px 0 0' : '2px'};
  `}
  ${({ countryCode }) => (countryCode && 'border:0; height:48px; width:263px;')}
  ${({ isEdit }) => (isEdit && 'border:0; height:38px; width:138px; margin-bottom:2px; font-size:14px; padding:1px 5px;')}
`;

const Input = (props) => {
  const {
    type,
    label,
    isDisabled,
    inputValue,
    fieldName,
    required,
    inputHandler,
    index,
    height,
    textAlign,
    padding,
    border,
    fontSize,
    labelPadding,
    maxLength,
    spellCheck,
    capsLock,
    tabIndex = null,
    className,
    onBlur,
    countryCode,
    isEdit,
    isCreate,
    phoneInputHasFocus,
    noFocusOutline,
    errorMessage
  } = props;
  const [keyEvent, setKeyEvent] = useState({});
  const inputRef = useRef();
  const isNumbers = /[0-9]/;
  const containsNumbers = isNumbers.test(inputValue);

  //  handles initial value from props
  const setInputValue = (value) => value;
  const handleKeyDown = (e) => setKeyEvent(e);

  const setCursorPositionToFront = () => {
    if (!containsNumbers) {
      if (inputRef.current) {
        // Set the selection range to the front of the input field
        inputRef.current.setSelectionRange(1, 1);
      }
    }

  };

  useEffect(() => {
    if (phoneInputHasFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 10);
    }
  }, [phoneInputHasFocus]);
  //  processes individual updates
  const handleChange = (event, type, index) => {
    const inputValue = event.target.value;
    fieldName && inputHandler(inputValue, fieldName, required, index);
  };

  const focusHandler = (event) => {
  };
  const getInputMask = () => {
    if (countryCode === constants.MEXICO_PHONE_CODE) {
      return '+52 (99) 9999-9999';
    } if (countryCode === constants.CANADA_PHONE_CODE) {
      return '+1 (999) 999-9999';
    }
    return isEdit || isCreate ? '+1 (999) 999-9999' : '(999) 999-9999';

  };
  const getInputCode = (type) => {
    switch (type) {
      case constants.TELEPHONE:
        return (
          <InputMask
            mask={getInputMask()}
            onChange={(event) => handleChange(event, type, index)}
            onBlur={(event) => handleChange(event, type, index)}
            onKeyDown={(e) => handleKeyDown(e)}
            disabled={isDisabled}
            onFocus={focusHandler}
            value={setInputValue(inputValue)}
          >
            {
              () => (
                <InputField
                  id="phone"
                  ref={inputRef}
                  name={fieldName}
                  onClick={setCursorPositionToFront}
                  onKeyUp={setCursorPositionToFront}
                  maxLength={maxLength}
                  tabIndex={tabIndex}
                  height={height}
                  spellCheck={spellCheck}
                  fontSize={fontSize}
                  textAlign={textAlign}
                  errorMessage={errorMessage}
                  countryCode={countryCode}
                  isEdit={isEdit}
                  noFocusOutline={noFocusOutline}
                  autoComplete="off"
                />
              )
            }
          </InputMask>
        );
      case constants.TEXT:
      default:
        return (
          <InputField
            type={constants.TEXT}
            onFocus={focusHandler}
            disabled={isDisabled}
            name={fieldName}
            maxLength={maxLength}
            tabIndex={tabIndex}
            height={height}
            onChange={(event) => handleChange(event, type, index)}
            onBlur={onBlur || ((event) => handleChange(event, type, index))}
            onKeyDown={(e) => handleKeyDown(e)}
            value={setInputValue(inputValue)}
            spellCheck={spellCheck}
            fontSize={fontSize}
            textAlign={textAlign}
            padding={padding}
            border={border}
            capsLock={capsLock}
            errorMessage={errorMessage}
            noFocusOutline={noFocusOutline}
            autoComplete="off"
          />

        );
    }
  };

  return (
    <InputWrap className={className}>
      <label htmlFor={fieldName}>
        {getInputCode(type)}
        { errorMessage && !isEmptyArray(errorMessage) && <Message errorMessages={errorMessage} type={constants.ERROR} />}
        <AnimatedLabel labelPadding={labelPadding} isDisabled={isDisabled} inputValue={inputValue} countryCode={countryCode}>
          <RequiredSpan required={required}>*</RequiredSpan>
          {label}
        </AnimatedLabel>
      </label>
    </InputWrap>
  );
};

export default Input;
