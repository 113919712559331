import { useContext } from 'react';
import * as constants from '../../constants/Constants';
import SearchButton from '../Button/SearchButton';
import styled from '../../helpers/esm-styled-components';
import Dropdown from '../Dropdown/Dropdown';
import Input from './Input';
import {
  countryOptions, getStateOrProvincePlaceholder,
} from '../../helper/baseDataHelper';
import { APIContext } from '../../Data/API';
import { CVMContext } from '../../components/CVM/CVM';

const SearchInput = styled(Input)`
  font-size: 12px;
`;
const SearchByPlate = styled(SearchButton)`
  grid-area: platesearch;
`;
const LicensePlate = styled(SearchInput)`
  grid-area: plate;
`;
const LicenseState = styled(Dropdown)`
  grid-area: state;
`;
const LicenseCountry = styled(Dropdown)`
  grid-area: country;
`;

const LicenseSearch = ({
  fields, setFields, handleInput, searchBy,
}) => {
  const { moduleName } = useContext(CVMContext);

  const { getStateOptions, getStateOrProvince } = useContext(APIContext);

  const defaultCountry = countryOptions[0];

  const updateCountry = (value) => {
    const item = countryOptions.filter((option) => option.label === value.label);
    if (item) {
      if (fields.licenseCountry.value !== item[0].value) {
        setFields({ ...fields, licenseCountry: item[0], licenseState: constants.EMPTY_STRING });
      } else {
        setFields({ ...fields, licenseCountry: item[0] });
      }
    }
  };

  const updateState = (value) => {
    const item = getStateOrProvince(fields.licenseCountry?.value, value);
    if (item) {
      setFields({ ...fields, licenseState: item[0] });
    }
  };
  const closeState = () => {
    setFields({...fields, licenseState: constants.EMPTY_STRING});
  };

  const countryDropdownProps = {
    fieldName: 'licenseCountry',
    placeholder: 'Country',
    options: countryOptions,
    onChange: updateCountry,
    selectedOption: fields.licenseCountry || defaultCountry,
    value: fields.licenseCountry?.label,
    required: false,
    dropdownHeight: moduleName === constants.MODULE_SEARCH ? 50 : 99,
    positionStyle: 'absolute',
  };

  const stateDropdownProps = {
    fieldName: 'licenseState',
    placeholder: getStateOrProvincePlaceholder(fields.licenseCountry?.value),
    options: getStateOptions(fields.licenseCountry?.value),
    onChange: updateState,
    selectedOption: fields.licenseState,
    value: fields.licenseState,
    required: false,
    dropdownHeight: moduleName === constants.MODULE_SEARCH ? 100 : 90,
    positionStyle: 'absolute',
    onClickClear: closeState,
  };

  return (
    <>
      <LicensePlate
        type={String}
        required={false}
        fieldName="licensePlate"
        inputValue={fields.licensePlate}
        label="License Plate Number"
        errorMessages={[]}
        warningMessages={[]}
        inputHandler={handleInput}
        isDisabled={false}
        tabIndex={17 || null}
        maxLength={10}
        capsLock
      />
      <LicenseState {...stateDropdownProps} tabIndex={18 || null} />
      <LicenseCountry {...countryDropdownProps} tabIndex={19 || null} />
      <SearchByPlate
        buttonName={constants.TITLE_SEARCH_BY_LICENSE}
        tabIndex={20 || null}
        onButtonClick={searchBy}
        isDisabled={!fields.licensePlate
          || fields.licensePlate.length > 10
          || !fields.licenseState
          || !fields.licenseCountry}
      />
    </>
  );
};

export default LicenseSearch;
