import { useContext } from 'react';
import * as errors from '../../constants/Errors';
import { CVMContext } from '../CVM/CVM';
import DefaultError from './Types/DefaultError';
import OktaError from './Types/OktaError';
import C360Error from './Types/C360Error';

const ErrorRouter = (props) => {
  const { errorCode, errorType, message, errorStack, isCustom, clickHandler } = props;

  const getErrors = () => {
    switch(errorType) {
      case errors.ERROR_PAGE_TYPE_OKTA:
        return <OktaError content={message} />;
      case errors.ERROR_PAGE_TYPE_C360:
        return <C360Error errorStack={errorStack} content={message} />;
      default:
        return <DefaultError clickHandler={clickHandler} />;
    }
  };

  return (
    getErrors()
  );
};

export default ErrorRouter;
