import moment from 'moment';
import { isNullEmptyUndefined } from './validationHelper';

// sort by integer number
export const numberSort = (data, columnDetails, direction, index) => {
  const tempArr = [];
  data.forEach(item => {
    tempArr.push({ ...item });
  });
  tempArr.sort((a, b) => {
    a = parseInt(a[!isNullEmptyUndefined(index) ? columnDetails().columnPropName[index] : columnDetails().columnPropName], 10);
    b = parseInt(b[!isNullEmptyUndefined(index) ? columnDetails().columnPropName[index] : columnDetails().columnPropName], 10);
    if (a < b) {
      return direction ? 1 : -1;
    }
    if (a > b) {
      return direction ? -1 : 1;
    }
    return 0;
  });

  return tempArr;
};

export const getSortedNumericAlphabetic = (a, b) => {
  // Extract numbers from the beginning of each string
  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);

  // Check if both are numbers
  const isANumber = !Number.isNaN(numA);
  const isBNumber = !Number.isNaN(numB);

  if (isANumber && isBNumber) {
    // If both are numbers, compare them
    return numA - numB;
  } if (isANumber) {
    // If only A is a number, A comes first
    return -1;
  } if (isBNumber) {
    // If only B is a number, B comes first
    return 1;
  }
  // If both are not numbers, or if numbers are equal, compare alphabetically
  return a.localeCompare(b);
};

export const getSortedNumericAlphabeticNestedkey = (obj, key) => {
  //  Array of nested object use the reference to key callback sort
  return (a, b) => {
    return getSortedNumericAlphabetic(a[obj][key], b[obj][key]);
  };
};

// sort by text
export const textSort = (data, columnDetails, direction, index) => {
  const tempArr = [];
  data.forEach(item => {
    tempArr.push({ ...item });
  });
  tempArr.sort((a, b) => {
    a = a[!isNullEmptyUndefined(index) ? columnDetails().columnPropName[index] : columnDetails().columnPropName]?.toLowerCase();
    b = b[!isNullEmptyUndefined(index) ? columnDetails().columnPropName[index] : columnDetails().columnPropName]?.toLowerCase();
    if (a < b) {
      return direction ? 1 : -1;
    }
    if (a > b) {
      return direction ? -1 : 1;
    }
    return 0;
  });

  return tempArr;
};

// sort by time
export const timeSort = (data, columnDetails, direction, index) => {
  const tempArr = [];
  data?.forEach(item => {
    tempArr.push({ ...item, [columnDetails().columnPropName]: moment(moment(`${moment().format('L')} ${item[columnDetails().columnPropName]}`)._i).valueOf() });
  });

  tempArr.sort((a, b) => {
    if (a[columnDetails().columnPropName] < b[columnDetails().columnPropName]) {
      return direction ? 1 : -1;
    }
    if (a[columnDetails().columnPropName] > b[columnDetails().columnPropName]) {
      return direction ? -1 : 1;
    }
    // need to return sorting direction
    return direction ? 1 : -1;
  });

  tempArr.forEach(item => {
    item[columnDetails().columnPropName] = moment(item[columnDetails().columnPropName]).format('LT');
  });

  return tempArr;
};

// split an array into separate array groups based on a property
export const splitArrayByObjProperty = (data, property, dataType) => {
  let currentValue,
    groupingArr = [],
    tempArr = [];
  data.forEach((c, index) => {
    if (index === 0) {
      tempArr.push(c);
      currentValue = c[property];
    } else if (dataType === 'Number' ? parseInt(currentValue, 10) !== parseInt(c[property], 10) : currentValue !== c[property]) {
      groupingArr.push(tempArr);
      currentValue = c[property];
      tempArr = [];
      tempArr.push(c);
    } else {
      tempArr.push(c);
    }

    if (index === (data.length - 1)) {
      groupingArr.push(tempArr);
    }
  });

  return groupingArr;
};
