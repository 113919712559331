import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';

export const DropdownContainer = styled.div`
  position: relative;
  ${({ isInsideTable }) => isInsideTable && 'width: 100%;'};
  height: 20px;
`;
export const DropdownHeader = styled.div`
  box-sizing: border-box;
  height: ${({ smallDropdown }) => (smallDropdown ? '40px' : '50px')};
  min-width: 100%;
  border-top: ${({ theme, isError, isOpen }) => `5px solid ${isError && !isOpen ? '#FFA10D' : theme.colors.lightGray}`};
  border-left: ${({ theme, isError, isOpen }) => `2px solid ${isError && !isOpen ? '#FFA10D' : theme.colors.lightGray}`};
  border-bottom: ${({ theme, isError, isOpen }) => `5px solid ${isError && !isOpen ? '#FFA10D' : theme.colors.lightGray}`};
  border-right: ${({ theme, isError, isOpen }) => `5px solid ${isError && !isOpen ? '#FFA10D' : theme.colors.lightGray}`};  border-radius: ${({ isOpen }) => (isOpen ? '2px 2px 0 0' : '2px')};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px ${({ isInsideTable }) => (isInsideTable ? '15px' : '18px')};
  border-radius: ${({ isOpen }) => (isOpen ? '2px 2px 0 0' : '2px')};
`;
const hidden = 'display: none;';
export const DropdownPlaceholder = styled.span`
  color: ${({ theme }) => theme.colors.lightGray};
  height: 22px;
  ${({ fieldName }) => ((fieldName === constants.ROTATION_PATTERN
    || fieldName === constants.TPMS
    || fieldName === constants.SPARE_USED) ? 'font-size: 14px' : 'font-size: 16px;')};
  letter-spacing: -0.15px;
  line-height:${({ smallDropdown }) => (smallDropdown ? '20px' : '22px')};
  margin-top:${({ provinceOrState }) => (provinceOrState && '-3px')};
  position: absolute;
  pointer-events: none;
  ${({ selectedOption, hasSearchItem }) => (selectedOption || hasSearchItem
    ? `
        position: absolute;
        top: -11px;
        left: 11px;
        font-size:13px;
        background: white;
        padding: 0px 10px;`
    : null)};
`;

export const DropdownRequired = styled.span`
  ${({ required, theme }) => (required ? `color: ${theme.colors.primaryRed}; font-size: 14px; padding-right: 5px` : hidden)}`;

export const ArrowIcon = styled.span`
  float: right;
  color: gray;
  height: 16px;
  width: 21.89px;
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-top: 2.5px;
  position: absolute;
  pointer-events: none;
  right: ${({ isInsideTable }) => (isInsideTable ? '10px' : '14px')};
`;

export const Close = styled.span`
  float: right;
  color: gray;
  height: 16px;
  width: 21.89px;
  color: '#75757C';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-top: 2.5px;
  position: absolute;
  cursor: pointer;
  right: ${({ isInsideTable }) => (isInsideTable ? '10px' : '14px')};
`;

export const DropdownItems = styled.ul`
  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  font-size: 17px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  padding: 0;
  margin: ${({ smallDropdown }) => (smallDropdown ? '28px 0 0 -18px;' : '35px 0 0 -18px;')};
  cursor: pointer;
  border-top: none;
  z-index: 300;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  ${({
    position, positionStyle, isOpen, dropdownHeight, innerHeight,
  }) => `
    border-radius: ${isOpen && '0 0 2px 2px'};
    width: ${position.width}px;
    ${dropdownHeight ? `max-height: ${dropdownHeight}px` : `max-height: ${innerHeight - position.bottom}px`};
    overflow-y: auto;
    position: ${positionStyle || 'fixed'};
`}`;

export const DropdownItem = styled.li`
  list-style: none;
  padding: 15px 15px;
  border-bottom: 1px solid #999999;
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ fieldName }) => ((fieldName === constants.ROTATION_PATTERN
    || fieldName === constants.TPMS
    || fieldName === constants.SPARE_USED) ? 'font-size: 14px' : 'font-size: 16px;')};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  letter-spacing: -0.15px;
  line-height: 19px;

  &:hover {
    color: #3483de;
  }

  &:last-child {
    border-bottom: none;
  }
  ${({ isSelected, theme }) => (isSelected && `border: 1.5px solid ${theme.colors.lightBlue}; color:${theme.colors.lightBlue}; outline: none;`)}
  &:focus-visible {
    outline: none;
    border: ${({ theme }) => `1.5px solid ${theme.colors.lightBlue}`};
  }

`;

export const DropdownItemPlaceholder = styled.li`
  list-style: none;
  padding: 15px 15px;
  border-bottom: 1px solid #999999;
  color: ${({ theme }) => theme.colors.lightGray};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fontFamily.normal};
  letter-spacing: -0.15px;
  line-height: 19px;

  &:hover {
    color: #3483de;
  }

  &:last-child {
    border-bottom: none;
  }
  &:focus-visible {
    outline: none;
    border: ${({ theme }) => `1px solid ${theme.colors.lightBlue}`};
  }
`;

export const NoDropdownItem = styled.li`
  list-style: none;
  cursor: auto;
  font-family: ${({ theme }) => theme.fontFamily.normal};
  padding: 15px 15px;
  letter-spacing: -0.15px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const InputField = styled.input`
  padding: 12px 32px 12px 16px;
  text-overflow: ellipsis;
  box-sizing: border-box;
  border: none;
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ fieldName }) => ((fieldName === constants.ROTATION_PATTERN
    || fieldName === constants.TPMS
    || fieldName === constants.SPARE_USED) ? 'font-size: 14px' : 'font-size: 16px;')};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  letter-spacing: -0.15px;
  line-height: 19px;
  width: calc(100% - 2px);
  height: ${({ inputHeight }) => (inputHeight ? `${inputHeight}px` : '48px')};
  position: absolute;
  top: 1px;
  left: 1px;
  &:disabled {
    background-color: #eaeaea;
  }
  &:focus-visible {
    outline: none;
    border: ${({ theme }) => `1px solid ${theme.colors.lightBlue}`};
  }
  ${({ errorMessage, theme }) => `
border-top: ${errorMessage && `2px solid ${theme.colors.orange}`};
border-right: ${errorMessage && `2px solid ${theme.colors.orange}`};
border-left: ${errorMessage && `2px solid ${theme.colors.orange}`};
border-bottom: ${errorMessage && 'none'};
border-radius: ${errorMessage ? '2px 2px 0 0' : '2px'};
`}
`;
