import React from 'react';
import { ThemeProvider } from 'styled-components';

export const theme = {
  colors: {
    darkGray: '#333333',
    primaryRed: '#ED1C24',
    veryLightGray: '#e6e6e6',
    mediumGray: '#999999',
    offWhite: '#cccccc',
    white: '#ffffff',
    lightBlue: '#4C8BF5',
    blue: '#064CB2',
    blue2: '#3483DE',
    blueGray: '#6699cc',
    opaque: '#EAEAEA',
    editBlue: '#2376D7',
    gray: '#eaeaea',
    lightGray: '#666666',
    lightGray2: '#979797',
    searchGray: '#bbbbbb',
    obsidian: '#000000',
    titleBlack: '#333333',
    background: '#3c3c3c',
    green: '#52A240',
    red: '#ed1c24',
    verticalDivider: '#d8d8d8',
    hoverGray: '#f4f4f4',
    orange: '#FFA10D',
  },
  fontFamily: {
    normal: 'Lato Regular',
    bold: 'Lato Bold',
    black: 'Lato Black',
    heavy: 'Lato Heavy',
    semiBold: 'Lato Semibold',
    medium: 'Lato Medium',
  },
  fontWeight: {
    bold: 'bold',
    semiBold: 600,
    xBold: 900,
    normal: 'normal',
  },
  fontSize: {
    xSmall: '8px',
    xSmall2: '9px',
    small: '10px',
    medium: '12px',
    medium2: '13px',
    tableBadge: '14px',
    dot: '14px',
    semiLarge: '15px',
    large: '16px',
    semiXLarge: '16.25px',
    xLarge: '22px',
    vehicleModelDetailsView: '23px',
    xxLarge: '25px',
    modalTitle: '20px',
    infoSubtitle: '18px ',
    editLabel: '19px',
    vehicleYearMakeDetailsView: '28px',
    moduleTitle: '33px',
  },
};

export const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

const IndexGlobalStyle = () => {
  return (
    <style jsx global>
      {`
        @font-face {
          font-family: 'Lato Heavy';
          src: url('/fonts/Lato-Heavy.ttf');
        }
        @font-face {
          font-family: 'Lato Black';
          src: url('/fonts/Lato-Black.ttf');
        }

        @font-face {
          font-family: 'Lato Bold';
          src: url('/fonts/Lato-Bold.ttf');
        }

        @font-face {
          font-family: 'Lato Regular';
          src: url('/fonts/Lato-Regular.ttf');
        }

        @font-face {
          font-family: 'Lato Semibold';
          src: url('/fonts/Lato-Semibold.ttf');
        }
        @font-face {
          font-family: 'Lato Medium';
          src: url('/fonts/Lato-Medium.ttf');
        }
        html {
          height: 834px;
          width: 1600px;
          background-color:  ${theme.colors.background};
          zoom: 91%;
        }

        body {
          overflow: hidden;
          margin: 0;
          padding: 0;
          font-family: 'Lato Regular';
          color: ${theme.colors.background};
          height: 100%;
          position: relative;
        }

        #__next {
          padding-bottom: 50px;
          height: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          background-color: ${theme.colors.background};
        }

        body[modulename="customer_vehicles"] > #__next {
          padding: 0;
        }

        body[modulename="customer_vehicles"] > #__next > div {
          height: 100%;
          flex-grow: 1;
          display: grid;
          grid-template-areas: "mainheader" "maincontent";
          grid-template-rows: 70px minmax(0, 1fr);
        }

        body[modulename="customer_vehicles"] [class*="HeaderContainer"] {
          grid-area: mainheader;
        }
        //TODO: look into rework so we don't push buttons off the page on customervehicle edit
        // body[modulename="customer_vehicles"] [class*="DetailsContainer"] {
        //   grid-area: maincontent;
        // }

        // SCROLL BAR STYLING
        /* width */
        ::-webkit-scrollbar {
          width: 12px;
          border: 1px solid black;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          border: 1px solid black;
          background-color: ${theme.colors.white};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${theme.colors.searchGray};
          border-radius: 10px;
          border: 3px solid transparent;
          background-clip: content-box;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #999999;
          border: 2px solid transparent;
          background-clip: content-box;
        }

        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
        input[type="radio"]:focus {
          outline: none;
      }
      `}
    </style>
  );
};

export default IndexGlobalStyle;
