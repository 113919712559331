import React from 'react';
import {
  FaRegIdCard, FaFlagUsa, FaPhoneAlt, FaRegEnvelope, FaBan
} from 'react-icons/fa';
import { MdPhoneIphone } from 'react-icons/md';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import * as constants from '../../constants/Constants';

// MyAccount
const MyAccountFlag = styled.div`
  display: flex;
  height: 25px;
  width: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: ${theme.colors.white};
  background-color: ${theme.colors.green};
  margin-left: 14px;
  ${({ notTable }) => notTable  && 'margin-bottom:25px'};
`;
const ArChageCode = styled.div`
  display: flex;
  height: 25px;
  width: 205px;
  padding-left: 6px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 14px;
  color: ${theme.colors.titleBlack};
  background-color: ${theme.colors.gray};
  ${({ notTable }) => notTable && 'margin-bottom:30px'};
  ${({ isVTVOrAirCheck }) => isVTVOrAirCheck  && 'margin-bottom:35px'};
`;
const AccountStatusFlag = styled.div`
  display: flex;
  height: 25px;
  width: 114px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${theme.colors.verticalDivider};
  margin-left: 14px;
  ${({ notTable }) => notTable && 'margin-bottom:25px'};
  color: ${theme.colors.titleBlack};
 `;
const MyAccountIcon = styled(FaRegIdCard)``;
const ArChargeIcon = styled(FaBan)``;
const MyAccountLabel = styled.div`
  font-family: ${theme.fontFamily.bold};
  font-size: ${theme.fontSize.tableBadge};
  font-weight: ${theme.fontWeight.bold};
  margin-left: 2px;
  line-height: 15px;
`;
// DTCC
const DTCCFlag = styled(MyAccountFlag)`
  color: ${theme.colors.titleBlack};
  background-color: ${theme.colors.gray};
  margin-left: 0;
  margin-right: 12px;
`;
const DTCCLabel = styled(MyAccountLabel)``;

// Military
const MilitaryFlag = styled(DTCCFlag)`
  background-color: ${theme.colors.gray};
`;
const MilitaryIcon = styled(FaFlagUsa)`
  height: 14px;
  margin-right: 2px;
`;
const MilitaryLabel = styled(MyAccountLabel)``;
// AAA
const AAAFlag = styled(DTCCFlag)``;
const AAALabel = styled(MyAccountLabel)``;
// Text
const TextFlag = styled(DTCCFlag)`
  height: 25px;
  width: 62px;
`;
const TextIcon = styled(MdPhoneIphone)``;
const CallIcon = styled(FaPhoneAlt)``;
const EmailIcon = styled(FaRegEnvelope)`

width:13px;
margin-right: 3px;
`;

const TextLabel = styled.div``;

const Flag = ({
  flagName, inTable, isVTVOrAirCheck, infoExpanded, isVTVAircheck, notTable
}) => {
  switch (flagName) {
    case constants.MY_ACCOUNT_FLAG:
      return (
        <MyAccountFlag data-testid="flag_my_account" notTable={notTable}>
          <MyAccountIcon />
          <MyAccountLabel>{flagName}</MyAccountLabel>
        </MyAccountFlag>
      );
    case constants.AR_CHARGE_FLAG:
      return (
        <ArChageCode data-testid="flag_ar_charge" notTable={notTable} isVTVOrAirCheck={!infoExpanded && isVTVAircheck && isVTVOrAirCheck}>
          <ArChargeIcon />
          <MyAccountLabel>{flagName}</MyAccountLabel>
        </ArChageCode>
      );
    case constants.DTCC_FLAG:
      return (
        <DTCCFlag data-testid="flag_dtcc">
          <DTCCLabel>{flagName}</DTCCLabel>
        </DTCCFlag>
      );
    case constants.MILITARY_FLAG:
      return (
        <MilitaryFlag data-testid="flag_military">
          <MilitaryIcon />
          <MilitaryLabel>{flagName}</MilitaryLabel>
        </MilitaryFlag>
      );
    case constants.AAA_FLAG:
      return (
        <AAAFlag data-testid="flag_aaa">
          <AAALabel>{flagName}</AAALabel>
        </AAAFlag>
      );
    case constants.TEXT_FLAG:
      return (
        <TextFlag data-testid="flag_text">
          <TextIcon/>
          <TextLabel>{flagName}</TextLabel>
        </TextFlag>
      );
    case constants.EMAIL_FLAG:
      return (
        <TextFlag data-testid="flag_email">
          <EmailIcon/>
          <TextLabel>{flagName}</TextLabel>
        </TextFlag>
      );
    case constants.CALL_FLAG:
      return (
        <TextFlag data-testid="flag_call">
          <CallIcon />
          <TextLabel>{flagName}</TextLabel>
        </TextFlag>
      );
    case constants.ACCOUNT_STATUS:
      return (
        <AccountStatusFlag inTable={inTable} data-testid="flag_account_status" notTable={notTable}>
          <MyAccountLabel>{flagName}</MyAccountLabel>
        </AccountStatusFlag>
      );
    default:
      return null;
  }
};

export default Flag;
