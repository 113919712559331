import React from 'react';
import { FaRegPlusSquare } from 'react-icons/fa';
import styled from '../../helpers/esm-styled-components';
import * as constant from '../../constants/Constants';
/* eslint-disable */
const ButtonComponent = styled.button`
  cursor: pointer;
  padding: 12px 16px;
  border-width: 0;
  width: 100%;
  height: 52px;
  font-size: 16px;
  letter-spacing: 0;
  text-align: center;
  border-radius: 2px;
  display:flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  ${({ buttonType, isDisabled, theme }) =>
    buttonType === constant.DEFAULT ?
      `
        line-height: 19px;
        color: ${isDisabled ? `${theme.colors.lightGray}` : `${theme.colors.white}`} ;
        ` : `
        line-height: 22px;
        color: ${theme.colors.primaryRed};
        border: 1px solid ${theme.colors.primaryRed};
        margin-left: 39px;
        margin-right: 35px;
        background-color: #fff;`
  }
  ${({ isDisabled, buttonName, theme, buttonType }) =>
    buttonName === constant.CANCEL_BUTTON ? `
      background-color: ${theme.colors.lightGray};
    ` : isDisabled ? `
        color: ${theme.colors.lightGray};
        background-color: #BBBBBB;
      ` : buttonType === constant.DEFAULT ? `
        color: ${theme.colors.darkGray};
        background-color: #fff;
        border: 1px solid ${theme.colors.darkGray};` : `
        color: ${theme.colors.white};
        background-color: #555555;`
  }
  ${({ buttonName, theme }) => buttonName === constant.BEGIN_NEW_SEARCH || buttonName === constant.RETURN_TO_RESULTS ? `padding: 12px 70px;font-size: ${theme.fontSize.large};` : `` }
  ${({ buttonName, theme }) => buttonName === constant.BEGIN_NEW_SEARCH && `background-color: ${theme.colors.lightGray}; color: #fff; font-size: ${theme.fontSize.large}`}
`;
const CreateIcon = styled(FaRegPlusSquare)`
  margin: 0px 4px 0 0;
`

/* eslint-enable */

const getDataTestId = (isDisabled, buttonName) => {
  let testId = buttonName;
  testId = isDisabled ? `${buttonName} Disabled` : `${buttonName} Enabled`;
  return testId;
};

const Button = props => {
  const {
    buttonType,
    buttonName,
    isDisabled,
    onButtonClick,
    onSubmit,
    tabIndex = null,
    isCreate,
    className,
  } = props;
  return (
    <ButtonComponent
      data-testid={getDataTestId(isDisabled, buttonName)}
      name={buttonName}
      aria-label={`${buttonName} button`}
      buttonType={buttonType}
      onSubmit={() => onSubmit()}
      onClick={(e) => onButtonClick(e, buttonName)}
      disabled={isDisabled}
      isDisabled={isDisabled}
      buttonName={buttonName}
      tabIndex={tabIndex}
      className={className}
    >
      {isCreate && <CreateIcon/>}{buttonName}
    </ButtonComponent>
  );
};

export default Button;
