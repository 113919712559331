import {
  useContext, useEffect, useRef, useState
} from 'react';
import * as constants from '../../../constants/Constants';
import styled from '../../../helpers/esm-styled-components';
import BreadCrumbYear from './BreadCrumbs/BreadCrumbYear';
import BreadCrumbMake from './BreadCrumbs/BreadCrumbMake';
import BreadCrumbModel from './BreadCrumbs/BreadCrumbModel';
import { CVMContext } from '../../../components/CVM/CVM';
import {
  checkIfObjectHasKey,
  isNullEmptyUndefined,
} from '../../../helper/validationHelper';
import BreadCrumbTrim from './BreadCrumbs/BreadCrumbTrim';
import BreadCrumbTireSize from './BreadCrumbs/BreadCrumbTireSize';
import AdditionalVehicleInformation from './StandardVehicle/AdditionalVehicleInformation';
import {
  breadCrumbs,
  convert1DArrayToDDArray,
} from '../../../helper/baseDataHelper';
import { theme } from '../../../helpers/GlobalStyles';
import { APIRouter } from '../../../Data/APIRouter';
import { getVehicleAssemblies } from '../../../helper/vehicleFormValidation';
import { getSortedNumericAlphabetic, getSortedNumericAlphabeticNestedkey } from '../../../helper/sortingHelper';
import { AddAdditionalVehicleInfo, ReturnToCustomerSummary, WizardButtons } from '../ModalUtils';
import { addUpdateCustomerVehicle } from './StandardVehicle/VehicleHelper';

const ContentContainer = styled.div`
  padding: ${({ isEdit }) => (isEdit ? '0 0' : '3% 0')}
    ${({ currentBreadCrumb }) => (currentBreadCrumb > 3 ? '20px' : '65px')} 0;
`;

export const VehicleErrorText = styled.p`
  color: ${theme.colors.red};
  font-weight: ${theme.fontWeight.bold};
`;

const BreadCrumbContent = (props) => {
  // props
  const {
    setStandardVehicleFields,
    standardVehicleFields,
    isEdit,
    errorMessage,
    arChargeFields,
    revertBackToManualEntry,
    onClickConfirmButton
  } = props;

  // context
  const {
    currentBreadCrumb,
    setCurrentBreadCrumb,
    setLoader,
  } = useContext(CVMContext);

  // states
  const [years, setYears] = useState(null);
  const [makes, setMakes] = useState(null);
  const [models, setModels] = useState(null);
  const [trims, setTrims] = useState(null);
  const [assemblies, setAssemblies] = useState([]);
  const [vehicleErrors, setVehicleErrors] = useState(null);
  const [fitmentImage, setFitmentImage] = useState(null);


  const getVehicleImage = async () => {
    const trimData = await APIRouter(
      'FIT',
      'getVehicleTrims',
      {
        year: standardVehicleFields.year.value,
        make: standardVehicleFields.make.value,
        model: standardVehicleFields.model.value,
      },
    );
    const trim = trimData?.vehicleTrim?.trimCollection.find(tc => tc.trimItem.trimId === standardVehicleFields.trimId);
    if (trim) {
      setFitmentImage(trim.trimItem.imageSmallUrl);
    }
  };

  useEffect(() => {
    const { year, make, model } = standardVehicleFields;
    if (year && make && model) {
      getVehicleImage();
    }
  }, []);


  const getCurrentBreadcrumb =   () => {
    switch (currentBreadCrumb) {
      case 0:
        if (years || vehicleErrors) {
          return (
            <BreadCrumbYear
              vehicleErrors={vehicleErrors}
              setStandardVehicleFields={setStandardVehicleFields}
              standardVehicleFields={standardVehicleFields}
              years={years}
            />
          );
        }
        break;
      case 1:
        if (makes || vehicleErrors) {
          return (
            <BreadCrumbMake
              vehicleErrors={vehicleErrors}
              setStandardVehicleFields={setStandardVehicleFields}
              standardVehicleFields={standardVehicleFields}
              makes={makes}
            />
          );
        }
        break;
      case 2:
        if (models || vehicleErrors) {
          return (
            <BreadCrumbModel
              vehicleErrors={vehicleErrors}
              setStandardVehicleFields={setStandardVehicleFields}
              standardVehicleFields={standardVehicleFields}
              models={models}
            />
          );
        }
        break;
      case 3:
        if (trims || vehicleErrors) {
          return (
            <BreadCrumbTrim
              vehicleErrors={vehicleErrors}
              setStandardVehicleFields={setStandardVehicleFields}
              standardVehicleFields={standardVehicleFields}
              trims={trims}
              isEdit={isEdit}
            />
          );
        }
        break;
      case 4:
        if (assemblies.oe?.length >= 0 || vehicleErrors) {
          return (
            <BreadCrumbTireSize
              vehicleErrors={vehicleErrors}
              setStandardVehicleFields={setStandardVehicleFields}
              standardVehicleFields={standardVehicleFields}
              oeTireSizes={assemblies.oe}
              isEdit={isEdit}
            />
          );
        }
        break;
      case 5:
        return (
          <AdditionalVehicleInformation
            arChargeFields={arChargeFields}
            errorMessage={errorMessage}
            setStandardVehicleFields={setStandardVehicleFields}
            standardVehicleFields={standardVehicleFields}
            fitmentImage={fitmentImage}
            isEdit={isEdit}
            tabIndex={0}
          />
        );
      default:
        return (
          <>
          </>
        );
    }
  };


  //
  // If the standard vehicle object wizard fields change, re-run this router to determine what breadcrumb to land on and what data to load
  //
  const getBreadCrumbOptions = async () => {
    setVehicleErrors(null);
    switch (currentBreadCrumb) {
      case 0:
        try {
          // years
          const allYears = await APIRouter('FIT', 'getVehicleYears');
          allYears.vehicleYears.sort((a, b) => {
            return a < b ? 1 : -1;
          });
          setYears(
            convert1DArrayToDDArray(
              standardVehicleFields.prior1982
                ? allYears.vehicleYears.filter((y) => y < 1982)
                : allYears.vehicleYears.filter((y) => y >= 1982)
            )
          );
        } catch (e) {
          setVehicleErrors({
            error: e.errors ? e.errors[0]?.message : 'Error occurred',
            currentBreadCrumb
          });
        }
        break;
      case 1:
        // makes
        if (checkIfObjectHasKey(standardVehicleFields, 'year')) {
          setMakes(null);
          try {
            const res = await APIRouter(
              'FIT',
              'getVehicleMakes',
              standardVehicleFields.year.value,
              setLoader,
              true
            );

            // temporary fix until IHS and Fitment are in alignment with data
            if (checkIfObjectHasKey(res, 'getVehicleMakes')) {
              revertBackToManualEntry();
              return;
            }
            res.sort(getSortedNumericAlphabetic);
            setMakes(convert1DArrayToDDArray(res));
          } catch (e) {
            setVehicleErrors({
              error: e.errors ? e.errors[0].message : 'Error Reading Makes',
              currentBreadCrumb,
            });
          }
        }
        break;
      case 2:
        // models
        if (checkIfObjectHasKey(standardVehicleFields, 'make')) {
          setModels(null);
          try {
            const params = [
              standardVehicleFields.year.value,
              standardVehicleFields.make.value,
            ];
            const res = await APIRouter(
              'FIT',
              'getVehicleModels',
              params,
              setLoader,
              true
            );

            // temporary fix until IHS and Fitment are in alignment with data
            if (checkIfObjectHasKey(res, 'getVehicleModels')) {
              revertBackToManualEntry();
              return;
            }
            res.sort(getSortedNumericAlphabetic);
            setModels(convert1DArrayToDDArray(res));
          } catch (e) {
            setVehicleErrors({
              error: e.errors ? e.errors[0].message : 'Error Reading Models',
              currentBreadCrumb,
            });
          }
        }
        break;
      case 3:
        // trims
        if (checkIfObjectHasKey(standardVehicleFields, 'model')) {
          setTrims(null);
          try {
            const trimData = await APIRouter(
              'FIT',
              'getVehicleTrims',
              {
                year: standardVehicleFields.year.value,
                make: standardVehicleFields.make.value,
                model: standardVehicleFields.model.value,
              },
              setLoader,
              true
            );
            // temporary fix until IHS and Fitment are in alignment with data
            if (checkIfObjectHasKey(trimData, 'getVehicleTrims')) {
              // const [index, breadcrumbArr] = incompleteVehicleBreadcrumb(standardVehicleFields, breadCrumbs);

              // setVehicleBreadCrumbs(breadcrumbArr);
              // setCurrentBreadCrumb(index);
              // revertBackToManualEntry();
              setVehicleErrors({
                error: 'Error reading trim data',
                currentBreadCrumb,
              });
              return;
            }
            // Specify the key you want to sort by
            const keyToSortBy = 'trimName';
            const objName = 'trimItem';

            // Access the array of objects and sort it using the custom function
            trimData.vehicleTrim.trimCollection = trimData.vehicleTrim.trimCollection.sort(getSortedNumericAlphabeticNestedkey(objName, keyToSortBy));

            // trim data extraction
            const trimDataReMapped = trimData.vehicleTrim.trimCollection.map(
              (t) => ({
                trim: t.trimItem.trimName,
                chassisId:
                  t.trimItem.assemblyCollection[0].assemblyItem.chassisId,
                trimId: t.trimItem.trimId,
                imageUrl: t.trimItem.imageSmallUrl,
                isDually: t.trimItem.isDually === 'true',
              })
            );
            if (!isNullEmptyUndefined(trimDataReMapped)) {
              trimDataReMapped.forEach((c) => {
                for (const l of trimData.vehicleTrim.liftCollection) {
                  if (l.liftItem.chassisId === c.chassisId) {
                    c.lifts = l.liftItem.liftIdCollection.map((lic) => ({
                      lift: lic.liftIdItem.liftDescription,
                      liftId: lic.liftIdItem.liftId,
                    }));
                  }
                }
              });
              setTrims(trimDataReMapped);
              setAssemblies([]);
            } else {
              // in case there's an issue with extracting the trim data from the object
              const errors = [];
              errors.push({
                message: 'Error extracting trim from vehicle object',
              });
              throw new Error({ errors });
            }
          } catch (e) {
            setVehicleErrors({
              error: e.errors ? e.errors[0].message : 'Error reading trim data',
              currentBreadCrumb,
            });
          }
        }
        break;
      case 4:
        // assemblies
        // prevent running getVehicleAssemblies again if selectedAssembly field is filled out
        if (checkIfObjectHasKey(standardVehicleFields, 'trim')) {
          try {
            const oe = await getVehicleAssemblies({
              year: standardVehicleFields.year.value,
              make: standardVehicleFields.make.value,
              model: standardVehicleFields.model.value,
              trim: standardVehicleFields.trimId || standardVehicleFields.trim || standardVehicleFields.trimDescription,
            });
            // sets the assemblies for the tire size breadcrumb
            setAssemblies({
              oe,
              am: null,
            });
          } catch (e) {
            setVehicleErrors({
              error: e.errors
                ? e.errors[0].message
                : 'Error reading assembly data',
              currentBreadCrumb,
            });
          }
        }
        break;
      default:
        break;
    }
  };

  const checkIfSubmitDisabled = () => {
    if (!arChargeFields) {
      return false;
    }
    if (standardVehicleFields.isCarryOut) {
      let disabled = false;
      for (let i = 0; i < arChargeFields.length; i++) {
        if (arChargeFields[i].field !== 'VIN #' && arChargeFields[i].field !== 'LIC PLATE STATE' && arChargeFields[i].field !== 'LICENSE PLATE #') {
          disabled = (isNullEmptyUndefined(arChargeFields[i].value()));

          if (disabled) {
            break;
          }
        }
      }
      return disabled;
    }
    return arChargeFields?.some(f => isNullEmptyUndefined(f.value()));
  };

  useEffect(() => {
    getBreadCrumbOptions();
  }, [currentBreadCrumb, standardVehicleFields]);

  return (
    <ContentContainer isEdit={isEdit} currentBreadCrumb={currentBreadCrumb}>
      {getCurrentBreadcrumb()}
    </ContentContainer>
  );
};

export default BreadCrumbContent;
