export const MODULE_SEARCH = 'search';
export const MODULE_CUSTOMER_SEARCH = 'customer_search';
export const MODULE_VEHICLE_SEARCH = 'vehicle_search';
export const MODULE_CUSTOMER_SUMMARY = 'customer_summary';
export const MODULE_CREATE_CUSTOMER = 'create_customer';
export const MODULE_CUSTOMER_VEHICLES = 'customer_vehicles';
export const MODULE_EDIT_CUSTOMER = 'edit_customer';

export const TITLE_SEARCH = 'Search';
export const TITLE_CUSTOMER_SEARCH = 'Customer Search';
export const TITLE_VTV_SEARCH = 'VTV Select';
export const TITLE_AIRCHECK_SEARCH = 'Aircheck Select';
export const TITLE_CUSTOMER_SUMMARY = 'Customer Summary';
export const TITLE_VEHICLE_SEARCH = 'Vehicle Search';
export const TITLE_CUSTOMER_EDIT = 'Customer Edit';
export const TITLE_AP_CONTACT = 'A/P Contact:';
export const TITLE_ALTERNATE = 'Alternate';
export const TITLE_PRIMARY_CONTACT = 'Use this contact for the transaction';

export const TITLE_SELECT_VTV_AIRCHECK = 'Select VTV / Air Check';
export const TITLE_SEARCH_BY_CUSTOMER = 'Search by Customer';
export const TITLE_CUSTOMER_CREATE = 'Create a new customer';
export const TITLE_NEW_CUSTOMER = 'New Customer Information';
export const TITLE_SEARCH_BY_VEHICLE = 'Search by Vehicle';

export const TITLE_SELECT_BY_VTV = 'Select a VTV';
export const TITLE_SELECT_BY_AIRCHECK = 'Select an AirCheck';
export const TITLE_SELECT_BY_MANUAL_VTV = 'Manual VTV';

export const BUTTON_TYPE_CREATE = 'create';
export const BUTTON_TYPE_EDIT = 'edit';
export const BUTTON_TYPE_REQUIRED = 'required';
export const TITLE_REQUIRED_FIELDS = 'required fields';
export const TITLE_REQUIRED_FIELDS_VEHICLE = 'fields required to complete vehicle';
export const TITLE_REQUIRED_ADDRESS = 'customer address fields required';
export const BUTTON_TYPE_VIEW = 'view';
export const BUTTON_TYPE_ADD_NOTES = 'Add notes';
export const CANCEL_BUTTON = 'Cancel Button';
export const FILTER = 'Filter';
export const FILTER_BY = 'Filter By';
export const CLEAR_FILTERS = 'Clear Filters';

// Customer Edit Form Types
export const CUSTOMER_EDIT_INFO_FORM = 'Edit Customer Information';
export const CUSTOMER_EDIT_PRIMARY_FORM = 'Primary Contact Info';
export const CUSTOMER_EDIT_ALTERNATE_FORM = 'Alternate Contact Info';
export const CUSTOMER_NO_ADDRESS = 'No Address entered';
export const CUSTOMER_ADDRESS = 'Address';
export const CUSTOMER_PHONE_NUMBERS = 'Phone Numbers';
export const CUSTOMER_PHONE_NUMBERS_SUBTITLE = 'One phone number is required for communication';
export const CUSTOMER_EMAIL = 'Email';


export const DT_EMPLOYEE_LABEL = 'DT Employee ID:';
export const ASSOCIATIONS_LABEL = 'Associations:';
export const TITLE_SEARCH_BY_VIN = 'Search by VIN';
export const TITLE_SEARCH_BY_LICENSE = 'Search by License Plate';

// Search Origins
export const SEARCH_ORIGIN_VTV = 'VTV';
export const SEARCH_ORIGIN_AIRCHECK = 'AirCheck';
export const SEARCH_ORIGIN_CUSTOMER = 'Customer';
export const SEARCH_ORIGIN_CUSTOMER_SUMMARY = 'Customer Summary';
export const SEARCH_ORIGIN_VIN = 'VIN';
export const SEARCH_ORIGIN_LICENSE_PLATE = 'LicensePlate';
export const SEARCH_ORIGIN_ADD_NEW_VEHICLE = 'AddNewVehicle';
export const SEARCH_ORIGIN_CREATE_CUSTOMER = 'CreateCustomer';

// Title
export const TITLE_CUSTOMER_INFORMATION = 'Customer Information';
export const TITLE_CREATE_CUSTOMER = 'Create a New Customer';
export const PREFERRED_CONTACT_METHOD = 'Preferred Contact Method:';
export const PURCHASING_CONTACT = 'Purchasing Contact:';
export const TITLE_TAX_EXEMPTION = 'Tax Exemption';
export const TITLE_VEHICLES = 'Vehicles';

export const TITLE_VTV_DETAILS = 'VTV Details';
export const TITLE_AIRCHECK_DETAILS = 'Aircheck Details';

export const TITLE_SHIPPING_ADDRESS = 'Shipping Address';
export const TITLE_EMAIL_ADDRESSES = 'Email Addresses';


export const BUTTON_CREATE_CUSTOMER = 'Create Customer';
export const BUTTON_EDIT_CUSTOMER = 'Edit Customer';
export const BUTTON_VIEW_CUSTOMER = 'View Customer';
export const BUTTON_TAX_EXEMPTION = 'Tax Exemption Application';
export const BUTTON_NEW_VEHICLE = 'Add New Vehicle';
export const BUTTON_ADD_VEHICLE = 'Add Vehicle';
export const BUTTON_EDIT_VIN_LICENSE_PLATE = 'Edit VIN/License Plate';
export const BUTTON_ENTER_VEHICLE_MANUALLY = 'ENTER VEHICLE MANUALLY';
export const BUTTON_RETURN_TO_VEHICLE_SEARCH = 'RETURN TO ADD VEHICLE SEARCH';
export const BUTTON_RETURN_TO_CUSTOMER_SUMMARY = 'RETURN TO CUSTOMER SUMMARY';
export const BUTTON_KEEP_VEHICLE_AS_ENTERED = 'Keep vehicle as entered';
export const BUTTON_CONTINUE_WITH_CORRECTED_VEHICLE = 'Continue with corrected vehicle';
export const BUTTON_EDIT_VEHICLE_AS_ENTERED = 'Edit vehicle as entered';
export const BUTTON_SAVE_WITH_CORRECTED_VEHICLE = 'Save vehicle with ihs correction';
export const BUTTON_ADD_ADDITIONAL_VEHICLE_INFO = 'ADD ADDITIONAL VEHICLE INFO';
export const BUTTON_ADD_VEHICLE_TO_CUSTOMER = 'ADD VEHICLE TO CUSTOMER';
export const BUTTON_SAVE_VEHICLE_EDITS = 'SAVE VEHICLE EDITS';
export const BUTTON_UPDATE_PRIMARY_CONTACT = 'UPDATE PRIMARY CONTACT';
export const BUTTON_UPDATE_ALTERNATE_CONTACT = 'UPDATE ALTERNATE CONTACT';
export const BUTTON_UPDATE_ADDRESS = 'UPDATE ADDRESS';

export const BUTTON_CANCEL = 'CANCEL';


export const CUSTOMER_VEHICLES = 'Customer Vehicles';
export const ADD_NEW_VEHICLE = 'ADD NEW VEHICLE';
export const VIEW_DEACTIVATED_VEHICLES = 'VIEW DEACTIVATED VEHICLES';

export const FRONT = 'Front';
export const REAR = 'Rear';
export const FRONTREAR = 'Front / Rear';

// Header Buttons
export const CLEAR_SEARCH = 'CLEAR SEARCH';
export const SELECT_CUSTOMER = 'SELECT CUSTOMER';
export const EDIT_SEARCH = 'EDIT SEARCH';
export const EXIT_SEARCH = 'NEW SEARCH';
export const BACK_TO_SEARCH = 'RETURN TO SEARCH';
export const BACK_TO_SEARCH_RESULTS = 'BACK TO SEARCH RESULTS';
export const BACK_TO_CUSTOMER_SUMMARY = 'BACK TO CUSTOMER SUMMARY';
export const APPLY_EDITS = 'APPLY EDITS';
export const APPLY_VTV_EDITS = 'APPLY VTV EDITS';
export const APPLY_AIRCHECK_EDITS = 'APPLY AIRCHECK EDITS';
export const REACTIVATE_VEHICLE = 'REACTIVATE VEHICLE';
export const BACK_TO_VEHICLES = 'BACK TO VEHICLES';
export const VTV = 'VTV';
export const AIRCHECK = 'Aircheck';
export const APPLY_CUSTOMER_VEHICLE = 'Apply Customer/Vehicle';

export const TOGGLE_TITLE_ADD_NEW_VEHICLE = 'Add New Vehicle';
export const TOGGLE_TITLE_ADD_GENERIC_VEHICLE = 'Add Generic Vehicle';
export const VTV_AIRCHECK_REFRESH = 'REFRESH';


// Toggle Sizes
export const TOGGLE_SIZE_SMALL = 'Small';
export const TOGGLE_SIZE_MEDIUM = 'Medium';
export const TOGGLE_SIZE_LARGE = 'large';

// Modals
export const MODAL_TYPE_REACTIVATE_VEHICLE = 'Reactivate Vehicle Modal';
export const MODAL_TYPE_SUMMARY = 'Summary Modal';
export const MODAL_TYPE_DEACTIVATE_VEHICLE = 'Deactivate Vehicle Modal';
export const MODAL_TYPE_SEARCH = 'Search Modal';
export const MODAL_TYPE_CARRY_OUT = 'Carry Out Modal';
export const MODAL_TYPE_ADD_NEW_VEHICLE = 'Add New Vehicle Modal';
export const MODAL_TYPE_GENERIC_VEHICLE = 'Add Generic Vehicle Modal';
export const MODAL_TYPE_ADDRESS = 'Address Verification';
export const MODAL_TYPE_ONE_ADDRESS = 'One Address Verification';

// imported vehicles without customer
export const IMPORTED_VEHICLE_MODAL = 'Imported Vehicle Modal';
export const IMPORTED_VEHICLE_MODAL_TITLE = 'Would you like to add this vehicle from Product Browse?';
export const IMPORTED_VEHICLE_MODAL_SUBTITLE = '';
export const IMPORTED_VEHICLE_MODAL_CANCEL_BUTTON = 'Don\'t Add This Vehicle';
export const IMPORTED_VEHICLE_MODAL_CONFIRM_BUTTON = 'Yes, Add This Vehicle';

// override vehicle modal
export const MODAL_OVERRIDE_SELECTED_VEHICLE = 'Override selected vehicle';
export const MODAL_OVERRIDE_SELECTED_VEHICLE_TITLE = 'Override selected vehicle?';
export const MODAL_OVERRIDE_SELECTED_VEHICLE_SUBTITLE_VTV = 'The VTV result selected contains a different vehicle than previously chosen.';
export const MODAL_OVERRIDE_SELECTED_VEHICLE_CANCEL_BUTTON_VTV = 'NO, RETURN TO VTV RESULTS';
export const MODAL_OVERRIDE_SELECTED_VEHICLE_CONFIRM_BUTTON = 'YES, OVERRIDE VEHICLE';
export const MODAL_OVERRIDE_SELECTED_VEHICLE_SUBTITLE_AIRCHECK = 'The aircheck result selected contains a different vehicle than previously chosen.';
export const MODAL_OVERRIDE_SELECTED_VEHICLE_CANCEL_BUTTON_AIRCHECK = 'NO, RETURN TO AIRCHECK RESULTS';

export const ADD_NEW_VEHICLE_SUBTITLE = 'Search by VIN or License plate to add a new vehicle to the customer.';
export const ADD_NEW_VEHICLE_MANUAL_ENTRY_SUBTITLE = 'Enter new vehicle info below to add to the customer.';
export const ADD_NEW_VEHICLE_MANUAL_ENTRY_SUBTITLE2 = 'For vehicles not found please enter the generic vehicle flow.';
export const ADD_NEW_VEHICLE_FIELDS_REQUIRED_NOTICE_LABEL = 'fields required to complete vehicle';
export const CLEAR_SEARCH_MODAL = 'CLEAR SEARCH';
export const CLEAR_SEARCH_MODAL_TITLE = 'Are you sure you want to clear all search data?';
export const CLEAR_SEARCH_MODAL_SUBTITLE = 'Any changes made to your search will be lost.';
export const CLEAR_SEARCH_MODAL_CANCEL_BUTTON = 'NO, RETURN TO SEARCH';
export const CLEAR_SEARCH_MODAL_CONFIRM_BUTTON = 'YES, CLEAR MY SEARCH';

export const ADDRESS_VERIFICATION_MODAL = 'Address Verification';
export const ADDRESS_VERIFICATION_MODAL_SUBTITLE = 'Please consider the USPS address suggestions below:';
export const ADDRESS_VERIFICATION_MODAL_USPS = 'USPS Corrected Address:';
export const ADDRESS_VERIFICATION_MODAL_ENTERED = 'Address as entered:';
export const ADDRESS_VERIFICATION_MODAL_CONFIRM_BUTTON = 'USE SELECTED ADDRESS';
export const CLEAR_EDIT_MODAL_TITLE = 'Are you sure you want to clear the alternate customer information';
export const CLEAR_EDIT_SUBTITLE = 'Any changes made to your alternate contact will be lost';
export const CLEAR_EDIT_CANCEL_BUTTON = 'No, Return To Customer Edit';
export const CLEAR_EDIT_CONFIRM_BUTTON = 'Yes, Remove Alternate Info';

export const ADDRESS_ONE_OPTION_MODAL_ENTERED = 'Entered Address could not be validated by USPS.';
export const ADDRESS_ONE_OPTION_MODAL_CONFIRM_BUTTON = 'USE ENTERED ADDRESS';

export const NEW_SEARCH_MODAL = 'NEW SEARCH';
export const NEW_SEARCH_MODAL_TITLE = 'Are you sure you want to start a new search?';
export const NEW_SEARCH_MODAL_SUBTITLE = 'Any information you have entered will be lost.';
export const NEW_SEARCH_MODAL_CANCEL_BUTTON = 'NO, RETURN TO SEARCH RESULTS';
export const TO_CUSTOMER_SUMMARY = 'NO, RETURN TO CUSTOMER SUMMARY';

export const NEW_SEARCH_MODAL_CONFIRM_BUTTON = 'YES, BEGIN NEW SEARCH';

export const CUSTOMER_BACK_TO_SEARCH_MODAL = 'BACK TO SEARCH';
export const CUSTOMER_BACK_TO_SEARCH_MODAL_TITLE = 'Are you sure you want to return to search results page?';
export const CONTINUE_WITHOUT_SAVING = 'All progress will not be saved';
export const CUSTOMER_BACK_TO_SEARCH_MODAL_CANCEL_BUTTON = 'NO, STAY ON THIS PAGE';
export const CONTINUE_EDITING = 'Continue Editing';
export const CUSTOMER_BACK_TO_SEARCH_MODAL_CONFIRM_BUTTON = 'YES, RETURN TO SEARCH';
export const BACK_TO_CUSTOMER_SUMMARY_NO_SAVE =  'continue without saving';

export const NO_CUSTOMER_MODAL = 'NO CUSTOMER';
export const NO_CUSTOMER_MODAL_TITLE = 'No customer record';
export const CHANGES_NO_SAVE = 'Any changes made will be lost. Are you sure you want to continue';
export const NO_CUSTOMER_MODAL_SUBTITLE = 'Return to search to try additional search criteria or create a new customer.';
export const NO_CUSTOMER_MODAL_CANCEL_BUTTON = 'NO, RETURN TO SEARCH';
export const NO_CUSTOMER_MODAL_CONFIRM_BUTTON = 'CREATE NEW CUSTOMER';

export const CUSTOMER_EDIT_MODAL = 'Customer Edit';
export const CUSTOMER_EDIT_MODAL_TITLE = 'Would you like to apply the edits made to the customer?';
export const CUSTOMER_EDIT_MODAL_SUBTITLE = 'Any changes not applied to the customer will be lost.';
export const CUSTOMER_EDIT_MODAL_CANCEL_BUTTON = 'NO, RETURN TO CUSTOMER EDIT';
export const CUSTOMER_EDIT_MODAL_CONFIRM_BUTTON = 'YES, APPLY CUSTOMER EDITS';

export const REACTIVATE_VEHICLE_MODAL = 'REACTIVATE VEHICLE';
export const REACTIVATE_VEHICLE_MODAL_TITLE = 'Would you like to reactivate this vehicle?';
export const REACTIVATE_VEHICLE_MODAL_CANCEL_BUTTON = 'NO, RETURN TO VEHICLES';
export const REACTIVATE_VEHICLE_MODAL_CONFIRM_BUTTON = 'YES, REACTIVATE THIS VEHICLE';

export const DEACTIVATE_VEHICLE_MODAL = 'REACTIVATE VEHICLE';
export const DEACTIVATE_VEHICLE_MODAL_TITLE = 'Would you like to deactivate this vehicle?';
export const DEACTIVATE_VEHICLE_MODAL_CANCEL_BUTTON = 'NO, RETURN TO EDIT VEHICLE';
export const DEACTIVATE_VEHICLE_MODAL_CONFIRM_BUTTON = 'YES, DEACTIVATE THIS VEHICLE';

export const TAX_CONFIRMATION_MODAL_TITLE = 'Tax Exemption Signature';
export const TAX_CONFIRMATION_MODAL_SUBTITLE = 'Please confirm if the customer would like to sign the Avalara Tax Request';
export const TAX_CONFIRMATION_MODAL_CANCEL_BUTTON = 'Cancel';
export const TAX_CONFIRMATION_MODAL_CONFIRM_BUTTON = 'Continue';

// Radio Form Type
export const RADIO_FORM_ADDRESS_VERIFY = 'Address Radio Form';
export const RADIO_FORM_GENERIC_VEHICLE = 'Generic Vehicle Form';
export const RADIO_FORM_ADDRESS_ONE_OPTION = 'One Option Form';

// Customer flags
export const MY_ACCOUNT_FLAG = 'My Account';
export const AR_CHARGE_FLAG = 'AR Charge Not Allowed';
export const DTCC_FLAG = 'DTCC';
export const MILITARY_FLAG = 'Military';
export const AAA_FLAG = 'AAA Member';
export const TEXT_FLAG = 'Text';
export const CALL_FLAG = 'Call';
export const EMAIL_FLAG = 'Email';
export const ACCOUNT_STATUS = 'Acct. Closed';


// Vehicle
export const VEHICLE_VIEW_ALL_VEHICLES = 'View All Vehicles';
export const VEHICLE_COLOR_LABEL = 'Color';
export const VEHICLE_LICENSE_PLATE_LABEL = 'License plate';
export const VEHICLE_FACTORY_TIRE_SIZE_LABEL = 'Factory tire size';
export const VEHICLE_FACTORY_TIRE_SIZE_FRONT_LABEL = 'Factory front tire';
export const VEHICLE_FACTORY_TIRE_SIZE_REAR_LABEL = 'Factory rear tire';
export const VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_LABEL = 'Optional tire size';
export const VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_FRONT_LABEL = 'Optional front tire';
export const VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_REAR_LABEL = 'Optional rear tire';
export const VEHICLE_TIRE_WHEEL_LABEL = 'Tire/Wheel size';
export const VEHICLE_VIN_LABEL = 'VIN';
export const VEHICLE_STATE_LABEL = 'LIC PLATE STATE';
export const VEHICLE_COUNTRY_LABEL = 'COUNTRY';
export const VEHICLE_LICENSE_PLATE_NUMBER_LABEL = 'LICENSE PLATE #';
export const VEHICLE_WHEELS_LABEL = 'Wheels';
export const VEHICLE_TIRE_PRESSURE_LABEL = 'Tire pressure';
export const VEHICLE_BOLT_PATTERN_LABEL = 'Bolt pattern';
export const VEHICLE_LUG_NUT_SIZE_LABEL = 'Lug nut size';
export const VEHICLE_TORQUE_LABEL = 'Torque';
export const VEHICLE_HUB_BORE_LABEL = 'Hub bore';
export const VEHICLE_WHEEL_WIDTH_OFFSET_LABEL = 'Wheel width / Offset';
export const VEHICLE_WHEEL_WIDTH_LABEL = 'Wheel width';
export const VEHICLE_OFFSET_LABEL = 'Offset';
export const VEHICLE_SELECTED_VEHICLE_LABEL = 'Selected Vehicle';
export const INCOMPLETE_VEHICLE_LABEL = 'Incomplete';
export const VEHICLE_SELECTED_VEHICLE_EDIT_LABEL = 'edit';
export const VEHICLE_OE_LABEL = 'O.E.';
export const VEHICLE_WTR_LABEL = 'WNTR';
export const VEHICLE_INVLAID_VIN = ['I', 'Q', 'O'];
export const DEACTIVATE_VEHICLE_LABEL = 'deactivate vehicle';
export const GENERIC_VEHICLE_LABEL = 'GENERIC VEHICLE';
export const CARRYOUT = 'CARRYOUT';


// Tax Exemption
export const TITLE_STATUS = 'Status';
export const TITLE_STATE = 'State';
export const TITLE_EXPIRATION = 'Expiration';
export const TITLE_EXEMPT_CATEGORY = 'Category';
export const TITLE_CERTIFICATE_NUMBER = 'Tax Certificate Number';
export const NO_EXEMPTION = 'No valid tax exemption certificate on record - Contact the tax department at ext. 66708 with any questions.';
export const TITLE_NOTES = 'Notes';
export const INVALID_STATE_RES = 'getTaxExemptInfo';

// Measurements
export const PSI = 'psi';
export const MILLIMETERS = 'mm';
export const FT_LBS = 'ft-lbs';
export const INCHES_ABBR = '"';

// Miscellaneous
export const NOT_APPLICABLE = 'N/A';
export const CURRENCY = '$';
export const CURRENCY_TYPE = 'USD';
export const NUMBER_STYLE = 'currency';
export const DEFAULT = 'default';
export const CUSTOM = 'custom';
export const UPPERCASE = 'uppercase';
export const CHECKBOX = 'checkbox';
export const CLICK = 'click';
export const SCROLL = 'scroll';
export const FOCUS = 'focus';
export const BLUR = 'blur';
export const STATUS_NEW = 'NEW';
export const STATUS_UPDATE = 'UPDATE';
export const NO_CENTS = 'formatNoCents';
export const LOOSE = 'formatLoosely';
export const STRICT = 'formatStrictly';
export const LOCALE = 'en-US';
export const ENTER_KEY_CODE = 13;
export const ENTER_SPACE_CODE = 32;
export const TAB_KEY_CODE = 9;
export const ESCAPE_KEY_CODE = 27;
export const UP_ARROW_KEY_CODE = 38;
export const DOWN_ARROW_KEY_CODE = 40;
export const MOUSEDOWN = 'mousedown';
export const KEYDOWN = 'keydown';
export const TEXTAREA_DEFAULT_HEIGHT = '80';
export const NO_RESULT_FOUND = 'No results found';
export const RETURN_TO_RESULTS = 'NO, RETURN TO RESULTS';
export const RETURN_TO_SEARCH_ADD_NEW_VEHICLE = 'Return to edit your search criteria or enter the vehicle manually.';
export const BEGIN_NEW_SEARCH = 'YES, BEGIN NEW SEARCH';
export const YES = 'Yes';
export const NO = 'No';
export const LEFT = 'Left';
export const RIGHT = 'Right';
export const PRIOR_1982_MESSAGE = 'VEHICLE\'S PRIOR TO 1982';
export const EMPTY_STRING = '';
export const PLUS = '+';
export const MINUS = '-';
export const ASSEMBLY_TIRECONSTRUCTION = 'PAS';

// Table Types
export const CUSTOMER_RESULTS_TABLE = 'CustomerResultsTable';
export const VEHICLES_RESULTS_TABLE = 'VehicleResultsTable';
export const VTV_RESULTS_TABLE = 'VTVResultsTable';
export const AIRCHECK_RESULTS_TABLE = 'AircheckResultsTable';
export const DEACTIVATED_VEHICLES_TABLE = 'DeactivatedVehiclesTable';
export const ACTIVE_VEHICLES_TABLE = 'ActiveVehiclesTable';
export const ADDITIONAL_ACTIVE_VEHICLES_TABLE = 'AdditionalActiveVehiclesTable';
export const CUSTOMER_SEARCH_RESULTS_HEADERS = ['Customer Name / Company Name', 'Phone Number', 'Email Address', 'My Account', 'Fleet #'];
export const VTV_SEARCH_RESULTS_HEADERS = ['Customer Name / Company Name', 'Vehicle', 'License Plate', 'Sales Associate', 'VTV Time Completed'];
export const AIRCHECK_SEARCH_RESULTS_HEADERS = ['Customer Name / Company Name', 'Vehicle', 'License Plate', 'Sales Associate', 'Aircheck Time Completed'];
export const CUSTOMER_SEARCH_DETAILS_TABLE_HEADERS = ['Address Line 1', 'Address Line 2', 'City', 'State', 'Zip Code', 'Country', 'Purchasing Contact'];
export const CUSTOMER_DEACTIVATED_VEHICLES = ['Deactivated Vehicles', 'Generic Vehicle', 'Color', 'License Plate'];
export const CUSTOMER_ACTIVE_VEHICLES = ['Vehicle', 'Generic Vehicle', 'Color', 'License Plate'];
export const CUSTOMER_ADDITIONAL_ACTIVE_VEHICLES = ['Additional Active Vehicles', 'Color', 'License Plate'];
export const VEHICLE_SEARCH_RESULTS_HEADERS = ['Customer Name / Company Name', 'Vehicle', 'Vehicle VIN', 'Vehicle Plate #', 'State', 'Country', 'My Account'];
export const BREADCRUMB_YEAR = 'Year';
export const BREADCRUMB_MAKE = 'Make';
export const BREADCRUMB_MODEL = 'Model';
export const BREADCRUMB_TRIM = 'Trim';
export const BREADCRUMB_TIRE_SIZE = 'Tire Size';
export const EXPAND_ALL = 'Expand all';
export const COLLAPSE_ALL = 'Collapse all';

// Add New Vehicle Labels
export const ADD_NEW_VEHICLE_WHAT_YEAR = 'What year is the vehicle?';
export const ADD_NEW_VEHICLE_WHAT_MAKE = 'What make is the vehicle?';
export const ADD_NEW_VEHICLE_WHAT_MODEL = 'What model is the vehicle?';
export const ADD_NEW_VEHICLE_WHAT_TRIM = 'What trim is the ';
export const ADD_NEW_VEHICLE_WHAT_TIRE_SIZE = 'What\'s the original tire size for the vehicle?';
export const ADD_NEW_VEHICLE_WHAT_TIRE_SIZE_SUBTEXT = 'This vehicle has multiple original tire sizes. Selecting one below will determine the optional sizes available.';
export const ADD_NEW_VEHICLE_AFTER_MARKET_TIRE_SIZES = 'Aftermarket Tire Sizes';
export const ADD_NEW_VEHICLE_AFTER_MARKET_SUBTEXT = 'To ensure a safe fit, we need to know the size of the tires that originally came on your vehicle before we can show you optional sizes.';
export const ADD_NEW_VEHICLE_AFTER_MARKET_SELECT_WHEEL_SIZE = 'Select a wheel size below to see the tires based on that size.';
export const ADD_NEW_VEHICLE_ONE_TIME_USE = 'Is this a One time use vehicle:';
export const ADD_NEW_VEHICLE_WINTER_USE = 'Is this a Winter/Snow Set:';
export const ADD_NEW_VEHICLE_CARRY_OUT = 'Carry Out:';
export const ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL = 'These tire sizes do not fit the original wheel diameter of your vehicle and require a custom wheel size.';
export const ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL_UNDERSIZE = 'These smaller tire sizes match your original wheel diameter but may require new wheels due to the narrower tire width, and may impact speedometer accuracy/vehicle handling.';
export const ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL_OVERSIZE = 'These larger tire sizes match your original wheel diameter but may require new wheels or fender modifications to prevent rubbing due to the tire height, and may impact speedometer accuracy/vehicle handling.';
export const ADD_NEW_ASSEMBLY = 'add new assembly';
export const ADD_NEW_ASSEMBLY_AFTER_MARKET_UNDERSIZE = 'undersize';
export const ADD_NEW_ASSEMBLY_AFTER_MARKET_MATCH = 'match';
export const ADD_NEW_ASSEMBLY_AFTER_MARKET_OVERSIZE = 'oversize';
export const ADD_NEW_ASSEMBLY_TIRE_CONSTRUCTION_RADIAL = 'R';

// Add Generic Vehicle Labels
export const GENERIC_VEHICLE_WHEEL_DIAMETER = 'Diameter: ';
export const GENERIC_VEHICLE_SUBTITLE = 'Enter generic vehicle info below to add to the customer.';
export const GENERIC_VEHICLE_REQUIRED_FIELDS = 'fields required to complete vehicle';
export const GENERIC_VEHICLE_VEHICLE_CATEGORY = 'Vehicle Category';
export const GENERIC_VEHICLE_SUB_CATEGORY = 'Vehicle Sub-Category';
export const GENERIC_VEHICLE_INFO_TITLE = 'Vehicle Information';
export const GENERIC_VEHICLE_ASSEMBLY_TITLE = 'Assembly Information';
export const GENERIC_VEHICLE_TIRE_TITLE = 'Tire Information:';
export const GENERIC_VEHICLE_WHEEL_TITLE = 'Wheel Information:';
export const GENERIC_VEHICLE_FRONT_WHEEL_TITLE = 'Front Wheel Information:';
export const GENERIC_VEHICLE_REAR_WHEEL_TITLE = 'Rear Wheel Information:';
export const GENERIC_VEHICLE_FRONT_TIRES = 'Front Tire Size:';
export const GENERIC_VEHICLE_REAR_TIRES = 'Rear Tire Size:';

export const ASSEMBLY_VALUES = [
  {
    value: 'standard',
    label: 'Standard',
  },
  {
    value: 'dually',
    label: 'Dually',
  },
  {
    value: 'staggered',
    label: 'Staggered',
  },
];

// Wizard Types
export const WIZARD_TYPE_ADD_NEW_VEHICLE = 'Add New Vehicle';

// Keys and Mouse Events
export const KEYS_ENTER = 'Enter';
export const MOUSE_OVER = 'mouseover';
export const MOUSE_ENTER = 'mouseenter';
export const MOUSE_LEAVE = 'mouseleave';
export const MOUSE_OUT = 'mouseout';

// Window Events
export const MOUSE_EVENT = 'MouseEvent';
export const KEY_EVENT = 'KeyboardEvent';

// VTV Tire Information DropDown Values
export const ROTATION_PATTERN_DD_VALUES = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Standard',
    label: 'Standard',
  },
  {
    value: 'Straight',
    label: 'Straight',
  },
  {
    value: 'Side to Side',
    label: 'Side to Side',
  },
  {
    value: '5 Tire',
    label: '5 Tire',
  },
];


export const ROTATION_PATTERN_DUALLY_DD_VALUES = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Dually',
    label: 'Dually',
  },
];

export const TPMS_DD_VALUES = [
  {
    value: 'off',
    label: 'Off',
  },
  {
    value: 'flash',
    label: 'Flash',
  },
  {
    value: 'solid',
    label: 'Solid',
  },
];


export const YES_NO_VALUES = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];
export const SPARE_USED_DD_VALUES = [
  {
    value: false,
    label: 'N/A',
  },
  {
    value: true,
    label: 'Yes',
  },
];
/* Going to keep these here because it may change in the future, depending on business
export const SPARE_USED_DD_VALUES = [
  {
    value: false,
    label: 'N/A',
  },
  {
    value: 'LF',
    label: 'LF',
  },
  {
    value: 'RF',
    label: 'RF',
  },
  {
    value: 'LR',
    label: 'LR',
  },
  {
    value: 'RR',
    label: 'RR',
  },
];
export const SPARE_USED_DUALLY_DD_VALUES = [
  {
    value: false,
    label: 'N/A',
  },
  {
    value: 'LF',
    label: 'LF',
  },
  {
    value: 'RF',
    label: 'RF',
  },
  {
    value: 'ILR',
    label: 'ILR',
  },
  {
    value: 'OLR',
    label: 'OLR',
  },
  {
    value: 'IRR',
    label: 'IRR',
  },
  {
    value: 'ORR',
    label: 'ORR',
  },
];
*/
// Input Types
export const TELEPHONE = 'tel';
export const EMAIL = 'email';
export const TEXT = 'text';
export const NUMBER = 'number';
export const PHONE_NUMBER_MASK = '(___) ___-____';

// VTV/Aircheck fieldnames
export const ROTATION_PATTERN = 'Rotation Pattern';
export const TPMS = 'TPMS';
export const SPARE_USED = 'Spare Used';
export const DOT_OPT_IN_LABEL = 'DOT Opt-In:';
export const WHEEL_LOCK_LABEL = 'Wheel Lock:';
export const CARRY_OUT_LABEL = 'Carry Out:';
export const REPLACE_TIRES_LABEL = 'Replace Tires:';
export const REPLACE_WHEELS_LABEL = 'Replace Wheels:';

// country names
export const USA = 'USA';
export const UNITED_STATES = 'United States';
export const CANADA = 'Canada';
export const MEXICO = 'Mexico';
export const US = 'US';
export const CA = 'CA';
export const CAN = 'CAN';
export const MX = 'MX';

// phone country code
export const CANADA_PHONE_CODE = '1';
export const MEXICO_PHONE_CODE = '52';

// Customer Labels
export const PHONE_LABEL = 'Phone #:';
export const HOME_LABEL = 'Home #:';
export const MOBILE_LABEL = 'Mobile #:';
export const WORK_LABEL = 'Work #:';
export const CONTACT_MOBILE_LABEL = 'Mobile:';
export const CONTACT_HOME_LABEL = 'Home:';
export const CONTACT_WORK_LABEL = 'Work:';
export const EMAIL_LABEL = 'Email:';
export const BUSINESS_CONTACT_LABEL = 'Business Contact';
export const FLEET_AGENT_LABEL = 'Fleet Agent:';

export const TITLE_PROVINCE = 'Province';
export const ZIP_CODE_LABEL = 'Zip Code';
export const POSTAL_CODE_LABEL = 'Postal Code';
export const COUNTRY = 'country';
export const PHONE_COUNTRY = 'phoneCountryCode';
export const PHONE_TYPE = 'phoneType';
export const ADDRESS_VALIDATED = 'isAddressValidated';


// error message
export const NO_CUSTOMER_FOUND = 'No Customer Record found - try different search criteria or create a new customer';
export const IHS_NO_MATCH = 'Review VIN/License Plate for accuracy or remove both to proceed';
export const NO_VEHICLE_FOUND = 'No Customer Record found - try different search criteria';
export const INVALID_EMAIL = 'Email Address is Invalid';
export const INVALID_PHONE_NUMBER = 'Phone Number is Invalid';
export const NAMES_REQUIRED = 'First and Last Name are required to search';
export const ERROR_MESSAGE_VIN = 'Enter a valid VIN to proceed';
export const RESULT_EXCEED_LIMIT = 'Result limit of 200 has been exceeded - filter your search results above or return to search screen to enter additional search criteria.';
export const CUSTOMER_ALREADY_EXISTS = 'Customer already exists in the database';
export const NO_RESULTS_SEARCH = 'No Results Found.';
export const FLEET_REQUIRED = 'Enter a valid Fleet Number (5 digits) to refine your search';
export const EMPLOYEEID_REQUIRED = 'Enter a valid Employee ID (6 digits) to refine your search';
export const FIRST_NAME_LENGTH = 'Must be at least 2 characters';
export const LAST_NAME_LENGTH = 'Must be at least 2 characters';
export const PHONE_LENGTH = 'Must be at least 10 characters';
export const ZIP_LENGTH = 'Must be at least 5 characters';
export const ADDRESS_LENGTH = 'Must be at least 2 characters';
export const CITY_LENGTH = 'Must be at least 2 characters';


export const ERROR = 'error';
export const WARNING = 'warning';
export const RESULTS_LIMIT = 199;
export const MAX_TREAD_DEPTH = '32';

// Current View Elements
export const VIEW_ELEMENT_SEARCH = 'current-view-search';
export const VIEW_ELEMENT_MODAL_NEW = 'current-view-modal-new';
export const VIEW_ELEMENT_MODAL_GENERIC = 'current-view-modal-generic';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_EXPIRED = 'EXPIRED';
export const STATUS_APPROVED = 'APPROVED';
export const STATUS_PENDING = 'PENDING';
export const STATUS_UNKNOWN = 'UNKNOWN';
export const STATUS_STATE_MISMATCH = 'MISMATCH';

export const ENABLED = 'enabled';
export const DISABLED = 'disabled';

// Table Column Data Types
export const COLUMN_DATA_TYPE_STRING = 'String';
export const COLUMN_DATA_TYPE_TIME = 'Time';
export const COLUMN_DATA_TYPE_NUMBER = 'Number';

export const AAA_EXCLUSION_SITE_ID = ['1026', '1562', '9999', '2147', '2092', '1610', '1260'];
export const SRPMIC_EXCLUSION_SITE_ID = ['1026', '9999', '1467', '2147', '2092', '1562', '1041', '2324',  '1610', '1260'];

export const SRPMIC_LABEL = 'SRPMIC Member';

// Arizona Store Default
export const SITE_STORE = 'Arizona';

// Customer Types
export const BUSINESS_CUSTOMER_TYPE = 'B2B';
export const STANDARD_CUSTOMER_TYPE = 'B2C';
export const BUSINESS_COMPANY_CUSTOMER = 'CUSTOMER';

// Vehicle Type
export const VEHICLE_TYPE_GENERIC = 'Generic';
export const VEHICLE_TYPE_FITMENT = 'Fitment';
export const VEHICLE_TYPE_GENERIC_VEHICLE = 'Generic Vehicle';
export const VEHICLE_GENERIC_ASSEMBLY_TYPE = 'GEN';
export const VEHICLE_OE_ASSEMBLY_TYPE = 'OE';
export const VEHICLE_WTR_ASSEMBLY_TYPE = 'WTR';
export const VEHICLE_ALT_ASSEMBLY_TYPE = 'ALT';

// Phone Types
export const HOME_PHONE = 'HOME PHONE';
export const MOBILE_PHONE = 'MOBILE PHONE';
export const WORK_PHONE = 'WORK PHONE';

// phoneKeys
export const HOME = 'phone';
export const WORK = 'work';
export const MOBILE = 'mobile';
export const TYPE_EMAIL = 'EMAIL';
export const USAGE_TYPE = 'Personal';

export const EDIT_FORM = 'EDIT';


// VTV tire configurations
export const STANDARD_VTV = ['LF', 'LR', 'RF', 'RR', 'SP'];
export const DUALLY_VTV  = ['LF', 'ILR', 'OLR',  'RF', 'IRR', 'ORR', 'SP'];
export const TREADS  = ['Inner', 'Middle', 'Outer'];
export const MIDDLE_TREAD  = 'M';

// API Error modal messaging
export const VEHICLE_ADD_ERROR = 'ERROR: addCustomerVehicle';
export const VEHICLE_ADD_ERROR_TITLE = 'Failed to successfully add vehicle from inspection import, invalid vehicle info';
export const VEHICLE_ADD_ERROR_SUBTITLE = 'Go back to results to try another selection or manually enter inspection for vehicle';

export const CUSTOMER_NOT_FOUND = 'ERROR: getCustomer';
export const CUSTOMER_NOT_FOUND_TITLE = 'Customer was not found, with that phone number';
export const CUSTOMER_NOT_FOUND_SUBTITLE = 'Please return to search/create customer to continue with inspection';
export const CUSTOMER_NOT_FOUND_BUTTON = 'BACK TO SEARCH';


export const HOLDING_INSPECTION_MODAL = 'inspectionOverride';
export const HOLDING_INSPECTION_MODAL_TITLE = 'You currently have an inspection (VTV/Aircheck) Selected:';
export const HOLDING_INSPECTION_MODAL_SUBTITLE = 'Selected Inspection (VTV/Aircheck) with vehicle: ';
export const HOLDING_INSPECTION_MODAL_CANCEL_BUTTON = 'BACK TO CUSTOMER RESULTS, SELECT DIFFERENT CUSTOMER';
export const HOLDING_INSPECTION_MODAL_CONFIRM_BUTTON = 'CONTINUE INSPECTION, WITH SELECTED CUSTOMER';

// Inspection reasons
export const VTV_REASON = 'service';
export const AIRCHECK_REASON = 'aircheck';

// Tax Signxature Exclusion States
export const TAX_SIGNATURE_EXLCLUSION_STATES = ['NM', 'FL'];

// Loader Messages
export const LOADER_PIN_PAD_WAIT = 'Please wait for response from pin pad';
export const LOADER_TAX_WAIT_FOR_CLOSE = 'To continue using CVM, please close the Avalara window';

// IHS STATUS MESSAGES
export const IHS_STATUS_MESSAGE_FAILED_TO_FIND_MATCH = 'Fitment Vehicle Matches failed to find a match.';

// Calendar Constants
export const CALENDAR_FROM_DATE = 'Select From Date';
export const CALENDAR_TO_DATE = 'Select To Date';
export const DATE_FORMAT = 'MM/dd/yyyy';
export const TO_TEXT = 'To';

export const DEFAULT_SCHEMA_VERSION = '1.0.5';
export const DEFAULT_VEHICLE_IMAGE =
  'https://discounttire.scene7.com/is/content/discounttire/cvm/vehicle_category_classic-car_1000.svg';

