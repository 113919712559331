import React from 'react';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import Table from '../../../UIComponents/Table/Table';

const ResultsContainer = styled.div`
  width: 1570px;
`;

const DeactivatedVehicles = props => {
  const { vehicles } = props;

  return (
    <ResultsContainer>
      <Table
        headers={constants.CUSTOMER_DEACTIVATED_VEHICLES}
        dataResults={vehicles}
        filter
        hasSearch
        tableType={constants.DEACTIVATED_VEHICLES_TABLE}
      />
    </ResultsContainer>
  );
};

export default DeactivatedVehicles;
