import { FaRegCalendarDays } from 'react-icons/fa6';
import DatePicker from '../../helpers/esm-datepicker-components'; // Assuming you've named your file containing the workaround as MyDatePicker.js
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import { RequiredSpan } from '../Input/Input';

export const StyledContainer = styled.div`
`;

export const StyledDatePicker = styled(DatePicker)`
  /* Add your custom styles here */
`;

export const CalendarRequiredSpan = styled(RequiredSpan)`
  position: absolute;
  z-index:11;
  top:1.2rem;
  left:1.5rem;
  background-color: white;
`;
export const CalendarRequiredLabel = styled.div`
  position: absolute;
  z-index:20;
  top:1rem;
  left:2rem;
  background-color: white;
  padding:0.2rem;
  font-size: ${theme.fontSize.medium2};
`;

export const CustomCalendarInput = styled.input`
  /* Add your custom styles here */
  width: 16rem;
  height: 3.3rem;
  border: 1px solid ${theme.colors.obsidian};
  padding: 5px 10px;
  font-size: ${theme.fontSize.semiLarge};
  font-family: ${theme.fontFamily.normal};
  font-weight: ${theme.fontWeight.bold};
  color: 1px solid ${theme.colors.obsidian};
  position: relative;

  /* Hide the default arrow icon */
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
export const StyledCalendarIcon = styled(FaRegCalendarDays)`
  position:absolute;
  left:15rem;
  top: 2.5rem;
  z-index:20;
  color: ${theme.colors.blue};
  cursor: pointer;
  pointer-events: none; /* Make the icon transparent to pointer events */
`;


export const CustomMonthContainer = styled.div`
  top: -0.6rem;
  left:1.8rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1); /* Adjust values as needed */
    /* Style header */
    .react-datepicker__header {
    padding:0;
    background-color: white;
  }
    /* Style month */
  .react-datepicker__current-month{
    z-index:30;
    display:absolute;
    height:2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.veryLightGray};
  }
  /* Style individual day names */
  .react-datepicker__day-names {
    z-index:50;
    display: flex;
    justify-content: space-evenly;
    font-size: 14px;
    color: #333;
    background-color: white;
    border-bottom: 1px solid black;
    margin-left:0.2rem;
    margin-right:0.2rem;
  }
  .react-datepicker__current-month {
    font-weight: ${theme.fontWeight.bold};
  }
  /* Style day */
  .react-datepicker__day {
    margin: 0.5rem;
    padding: 0;
    color: ${theme.colors.blue};
    font-size: ${theme.fontSize.large};
    font-family: ${theme.fontFamily.normal};
  }
  /* Style day displayed from prev/next month*/
  .react-datepicker__day--outside-month {
    color: ${theme.colors.blueGray};
  }
  /* Style day hover*/
  .react-datepicker__day:hover {
    border-radius: 50%;
    background-color: ${theme.colors.gray};
  }
   /* Style day selected*/
  .react-datepicker__day--selected {
    border-radius: 100px;
    border: 2px solid ${theme.colors.titleBlack};
    background-color: transparent;
  }
   /* Style day disabled*/
  .react-datepicker__day--disabled {
    color: gray;
    pointer-events: none;
  }
  /* Style day default selected*/
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
`;
