import * as constants from '../constants/Constants';

export const isEmptyObject = function (obj) {
  return Object.keys(obj).length === 0;
};

export const isEmptyArray =  (array) => (!!((array === undefined || array?.length === 0)));

export const isNullOrUndefined = (...args) => {
  let res = false;
  for (let i = 0; i < args.length; i++) {
    if (args[i] === null || args[i] === undefined || args[i] === constants.EMPTY_STRING || Number.isNaN(args[i])) {
      res = true;
      break;
    }
  }
  return res;
};

export const capitalizeWord = (word) => {
  if (typeof word !== 'string') return word;

  const lowerCaseWord = word?.toLowerCase();
  const firstLetter = lowerCaseWord.charAt(0).toUpperCase();

  return firstLetter + lowerCaseWord.slice(1);
};

export const isNullEmptyUndefined = (value) => {
  if (value === null || value === undefined || value === constants.EMPTY_STRING || value.toString().replace(/ /g, constants.EMPTY_STRING) === constants.EMPTY_STRING) {
    return true;
  }
  return false;
};
export const removeLeadingZeros = (value) => {
  if (!value) return;
  return value.replace(/\b0+/g, '');
};
export const formatOnlyOneZero = (value) => {
  return value?.replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '');
};
export const checkPhoneNumber = (value) => {
  return value.toString().match(/\d+/g)?.join(constants.EMPTY_STRING).length === 10;
};

export const removePhoneMask = (value) => {
  return value?.toString().match(/\d+/g)?.join(constants.EMPTY_STRING);
};

export const formatNumeric = (value) => {
  if (!value) {
    return constants.EMPTY_STRING;
  }
  return value.replace(/[^0-9\s]/g, constants.EMPTY_STRING);
};
export const formatNumericDecimal = (value) => {
  return value.replace(/[^0-9.]/g, constants.EMPTY_STRING).replace(/\.(?=.*\.)/g, constants.EMPTY_STRING);
};
export const formatNumericOneDecimal = (value) => {
  if (!value) return;
  const inputValue = (value.indexOf('.') >= 0)
    ? formatNumericDecimal(value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 2).replace(/[^1-9.]/g, constants.EMPTY_STRING))
    : formatNumericDecimal(value);
  if (inputValue.includes('.')) {
    // Use expression to match the fractional part after the decimal point
    const fractionalPart = inputValue.split('.')[1];
    if (fractionalPart && fractionalPart.trim().length > 0) {
      return inputValue;
    }
    return inputValue.split('.')[0];
  }
  return inputValue;
};
export const formatAlphaNumeric = (value) => {
  return value.replace(/[^a-zA-Z0-9]/g, constants.EMPTY_STRING);
};
export const formatAlphaNumericAndSpace = (value) => {
  return value.replace(/[^a-zA-Z0-9 ]/g, constants.EMPTY_STRING).replace(/  +/g, ' ').replace(/^\s+/g, '');
};
export const formatNoSpaces = (value) => {
  return value.replace(/ /g, constants.EMPTY_STRING);
};
export const formatLetters = (value) => {
  return value.replace(/[^A-Za-z]/g, constants.EMPTY_STRING);
};
export const formatLettersAndSpace = (value) => {
  return value.replace(/[^A-Za-z ]/g, constants.EMPTY_STRING);
};
export const formatFirstAndLastName = (value) => { // formatting includes  letters, accents, tildes, spaces and hyphens
  let parts = value.split('*');
  parts = parts.map((part) => {
    return part.replace(/[^-~'A-Za-zÀ-ÖØ-öø-ÿ\s]/g, constants.EMPTY_STRING);
  });

  return parts.join('*');
};
export const formatCompanyName = (value) => { // formatting includes  letters, spaces, accents, tildes and hyphens
  let parts = value.split('*');
  parts = parts.map((part) => {
    return part.replace(/[^-~'A-Z0-9a-zÀ-ÖØ-öø-ÿ\s]/g, constants.EMPTY_STRING);
  });

  return parts.join('*');
};

export const checkEmailAddress = (value) => {
  return value.toString().toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const checkForEmptyObject = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }

  let isObjectEmpty = false;
  const isObjectKeysPopulated = Object.values(obj).find((x) => x);
  if (!obj || Object.keys(obj).length === 0 || !isObjectKeysPopulated) {
    isObjectEmpty = true;
  }

  return isObjectEmpty;
};

export const validateVIN = (vin) => {
  vin = vin.toUpperCase();
  return (vin.length === 17 && !constants.VEHICLE_INVLAID_VIN.some(r => vin.includes(r)));
};
export const upperCaseFirstLetter = (value) => {
  return value?.toLowerCase().replace(/(^\w)|([-\s]\w)/g, match => match.toUpperCase());
};
export const isEmptyCityStateZip = (obj) => {
  let res = true;
  for (const key in obj) {
    if (!isNullOrUndefined(obj[key])) {
      switch (key) {
        case 'city':
        case 'zip':
          res = res && (isNullEmptyUndefined(obj[key]));
          break;
        case 'state':
          res = res && (isNullEmptyUndefined(obj[key]) || obj[key].value === constants.EMPTY_STRING);
          break;
      }
    }
  }
  return res;
};

export const checkIfAnyFieldIsFilled = (obj) => {
  const res = [];
  const keys = Object.keys(obj);
  keys.forEach(key => {
    if (!isNullEmptyUndefined(obj[key])
      && key !== constants.COUNTRY
      && key !== constants.ADDRESS_VALIDATED
      && key !== constants.PHONE_COUNTRY
      && key !== constants.PHONE_TYPE
      && obj[key].value !== constants.EMPTY_STRING
      && obj[key] !== constants.PHONE_NUMBER_MASK) {
      res.push(key);
    }
  });
  return res;
};

export const checkRequiredFields = (obj, formType, isB2B) => {
  let requiredFields = [];
  const requiredNumbers = !isNullEmptyUndefined(removePhoneMask(obj?.mobileNumber)) || !isNullEmptyUndefined(removePhoneMask(obj?.workNumber)) || !isNullEmptyUndefined(removePhoneMask(obj?.phoneNumber));
  switch (formType) {
    case constants.MODULE_CREATE_CUSTOMER:
      requiredFields = ['firstName', 'lastName', 'country', 'address1', 'phoneNumber', 'state', 'zip'];
      break;
    case 'Edit Customer Information':
      requiredFields = ['firstName', 'lastName'];
      break;
    case 'Contact Info':
    default:

      requiredFields = ['address1', 'zip', 'city', 'state', 'country'];
      break;
  }

  const result = requiredFields.every((field) => {
    const value = obj?.[field];

    if (typeof value === 'string') {
      return !!value.trim();
    } if (typeof value === 'object' && value !== null) {
      return !!value.value && !!value.value.trim();
    }
    return false;
  });
  return result && (!isB2B ? requiredNumbers : true);
};

export const checkIfObjectHasKey = (obj, key) => {
  if (isNullOrUndefined(obj)) return false;
  return Object.keys(obj).includes(key);
};

export const formatNumericValue = (value, isCurrency, editableField = false, typed = false) => {
  if (!value && typeof value !== constants.NUMBER) {
    return constants.EMPTY_STRING;
  }
  // Add currency symbol and decimal point
  if (isCurrency) {
    if (editableField && typed) {
      if (value.length > 13) {
        value = value.slice(0, value.length - 1);
      }
      if (value.length === 1) {
        value = `.0${value}`;
      } else if (value.length === 2) {
        value = `.${value}`;
      } else {
        value = value.toString().replace('.', constants.EMPTY_STRING);
        value = `${value.slice(0, value.length - 2)}.${value.slice(value.length - 2)}`;
      }
    }

    return Number(value).toLocaleString(constants.LOCALE, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: constants.NUM_PRECISION,
      minimumFractionDigits: 2,
    });
  }

  // If not currency, just format the number
  return Number(value).toLocaleString(constants.LOCALE);
};

export const formErrorControl = (obj) => {
  const fields = checkIfAnyFieldIsFilled(obj);
  let error = constants.EMPTY_STRING;
  fields.forEach(field => {
    switch (field) {
      case 'lastName':
        if (fields.length === 1) error = 'additional search criteria';
        break;
      case 'address1':
        if (isEmptyCityStateZip(obj)) error = 'City, State or Zip Code';
        break;
      case 'address2':
        if (isNullEmptyUndefined(obj.address1)) error = 'Address Line 1';
        break;
      case 'city':
        if (isNullEmptyUndefined(obj.address1) && isNullEmptyUndefined(obj?.firstName) && isNullEmptyUndefined(obj.zip)) {
          error = 'Address, First Name, Last Name';
        }
        break;
      case 'zip':
        if (!isNullEmptyUndefined(obj?.firstName) && !isNullEmptyUndefined(obj?.lastName)) {
          break;
        } else if (isNullEmptyUndefined(obj?.address1) && isNullEmptyUndefined(obj?.firstName) && isNullEmptyUndefined(obj?.lastName)) {
          error = 'Address, First Name, Last Name';
        }
        break;
      case 'state':
        if (!isNullEmptyUndefined(obj?.firstName) && !isNullEmptyUndefined(obj?.lastName)) {
          break;
        } else if (isNullEmptyUndefined(obj?.address1) && isNullEmptyUndefined(obj?.city) && isNullEmptyUndefined(obj?.zip)) {
          error = 'Address, City or Zip Code';
        }
        break;
      case 'firstName':
      case 'phoneNumber':
      case 'email':
        break;
      default:
        error = null;
    }
  });
  return error;
};

export const equalsIgnoringCase = (str1, str2) => {
  return str1.localeCompare(str2, 'en', { sensitivity: 'base' }) === 0;
};
// Replaces all matches with  param string
export const replaceObjKeysWithStr = (str, obj) => {
  const replacingValue = new RegExp(Object.keys(obj).join('|'), 'gi');
  return str.replace(replacingValue, (matched) => {
    return obj[matched.toLowerCase()];
  });
};

export const formatPhoneDisplay = (value) => {
  const cleaned = (`${value}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const phoneCustomerCycle = (query, customer) => {
  const homeNum = customer.phoneCollection?.filter((x) => x.phoneItem.phoneType === constants.HOME_PHONE)?.map((p) => p.phoneItem.phoneNumber)[0];
  const mobileNum = customer.phoneCollection?.filter((x) => x.phoneItem.phoneType === constants.MOBILE_PHONE)?.map((p) => p.phoneItem.phoneNumber)[0];
  const workNum = customer.phoneCollection?.filter((x) => x.phoneItem.phoneType === constants.WORK_PHONE)?.map((p) => p.phoneItem.phoneNumber)[0];
  if (query.phoneNumber) {
    if (formatPhoneDisplay(mobileNum) === query.phoneNumber || mobileNum === query.phoneNumber) {
      return `M: ${formatPhoneDisplay(mobileNum)}`;
    } if (formatPhoneDisplay(homeNum) === query.phoneNumber || homeNum === query.phoneNumber) {
      return customer.customerType === 'B2B' ? `P: ${formatPhoneDisplay(homeNum)}` : `H: ${formatPhoneDisplay(homeNum)}`;
    } if (formatPhoneDisplay(workNum) === query.phoneNumber || workNum === query.phoneNumber) {
      return `W: ${formatPhoneDisplay(workNum)}`;
    }
  } else {
    if (mobileNum) {
      return `M: ${formatPhoneDisplay(mobileNum)}`;
    } if (homeNum) {
      return customer.customerType === 'B2B' ? `P: ${formatPhoneDisplay(homeNum)}` : `H: ${formatPhoneDisplay(homeNum)}`;
    } if (workNum) {
      return `W: ${formatPhoneDisplay(workNum)}`;
    } return `${constants.NOT_APPLICABLE}`;
  }
};

export const getPrimaryInfo = (collection) => {
  let primaryInfo;
  if (collection?.length > 1) {
    collection.forEach(collectionObj => {
      const key = Object.keys(collectionObj)[0];
      if ((collectionObj[key]?.isSinglePointOfContactPurchasing && !primaryInfo) || collectionObj[key]?.isDefault) {
        primaryInfo = collectionObj[key];
      }
    });
  }
  if ((!primaryInfo && collection?.length)) {
    const key = Object.keys(collection[0]);
    primaryInfo = collection[0][key];
  }
  return primaryInfo;
};

export const customerRestructure =  (c) => {
  let customer = { ...c };
  const isB2B = customer?.customerType === 'B2B';

  /* ADDRESS CUSTOMER STRUCTURE */
  customer?.addressCollection?.forEach((addressObj, i) => {
    let { addressItem } = addressObj;
    // This is the defaulter. If at the end of the length of the array will set the first
    if (((i + 1) === customer.addressCollection.length && !customer.addressId)
      || (customer.addressId !== customer.addressCollection[0].addressItem.addressId)) {
      customer.addressCollection[0].addressItem.isDefault = true;
      addressItem = customer.addressCollection[0].addressItem;
    }
    const zipTrim = (addressItem?.postalCode || '').slice(0, 5);
    const addressFormat = {
      addressId: addressItem?.addressId,
      address1: addressItem?.addressLine1,
      address2: addressItem?.addressLine2,
      city: addressItem?.city,
      state: addressItem?.region,
      country: addressItem?.country,
      zip: addressItem?.country === constants.UNITED_STATES ? zipTrim :  addressItem?.postalCode,
      zipExt: addressItem?.postalCodeExtension,
      isAddressValidated: addressItem?.isAddressValidated || false,

    };
    if (addressItem.isDefault && !customer.addressId) {
      customer = { ...customer, ...addressFormat };
    } else if (!customer.alternate?.addressId && !isB2B) {
      customer.alternate = { ...customer.alternate, ...addressFormat };
    }
  });

  if (isB2B) {
    // TODO: Setter afor customer values if B2B, B2B has multiples
    // no way of differentiate any of contacts, displaying first for now
    // This really wont be needed if each time the root has the first and last name
    const contacts = customer?.contactCollection;
    if (contacts?.some((c) => c.contactItem.isSinglePointOfContactPurchasing)) {
      customer = { ...customer, ...getPrimaryInfo(c.contactCollection) };
    } else {
      // NOTE: at times the root has random values set from random collections in the customer
      customer.firstName = constants.EMPTY_STRING;
      customer.lastName = constants.EMPTY_STRING;
      customer.email = constants.EMPTY_STRING;
      customer.phone = constants.EMPTY_STRING;
      customer.title = constants.EMPTY_STRING;
    }

    if (contacts?.length) {
      contacts.forEach((contact, i) => {
        contact = contact?.contactItem;
        // Missing alternate
        switch (contact?.title) {
          case 'Fleet  Contact':
            customer[`fleetAgent${i}`] = contact;
            break;
          case 'AP':
            customer[`apContact${i}`] = contact;
            break;
          case null:
          default:
            customer[`businessContact${i}`] = contact;
            break;
        }
      });
    }
    customer.phoneNumber = customer.phoneCollection?.filter((x) => x.phoneItem.phoneType === constants.HOME_PHONE)[0]?.phoneItem?.phoneNumber;
    customer.mobileNumber = customer.phoneCollection?.filter((x) => x.phoneItem.phoneType === constants.MOBILE_PHONE)[0]?.phoneItem?.phoneNumber;
  } else {
    customer.firstName = upperCaseFirstLetter(customer?.firstName);
    customer.lastName = upperCaseFirstLetter(customer?.lastName);

    /* PHONES CUSTOMER STRUCTURE */

    // TO DO: workaround until add sticks values
    delete customer.phoneNumber;
    delete customer.mobileNumber;
    delete customer.workNumber;

    customer?.phoneCollection?.forEach((phoneObj) => {
      const { phoneItem } = phoneObj;
      let phoneKey;
      switch (phoneItem.phoneType) {
        case constants.MOBILE_PHONE:
          phoneKey = constants.MOBILE;
          break;
        case constants.WORK_PHONE:
          phoneKey = constants.WORK;
          break;
        case constants.HOME_PHONE:
          phoneKey = constants.HOME;
          break;
        default:
          break;
      }
      if (phoneItem?.isDefault && !customer?.[`${phoneKey}Id`]) {
        customer[`${phoneKey}Number`] = formatPhoneDisplay(phoneItem.phoneNumber);
        phoneKey === constants.WORK ? customer[`${phoneKey}Ext`] = phoneItem?.phoneExtension : null;
        customer[`${phoneKey}CountryCode`] = phoneItem.internationalCode;
        customer[`${phoneKey}Id`] = phoneItem.phoneId;
      } else if (!customer?.[`${phoneKey}Id`] || !customer?.alternate?.[`${phoneKey}Id`]) {
        if (!customer?.[`${phoneKey}Id`]) {
          customer[`${phoneKey}Number`] = formatPhoneDisplay(phoneItem.phoneNumber);
          phoneKey === constants.WORK ? customer[`${phoneKey}Ext`] = phoneItem?.phoneExtension : null;
          customer[`${phoneKey}CountryCode`] = phoneItem.internationalCode;
          customer[`${phoneKey}Id`] = phoneItem.phoneId;
        } else if (!customer?.alternate?.[`${phoneKey}Id`]) {
          customer.alternate = { ...customer.alternate };
          customer.alternate[`${phoneKey}Number`] = formatPhoneDisplay(phoneItem.phoneNumber);
          customer.alternate[`${phoneKey}CountryCode`] = phoneItem.internationalCode;
          phoneKey === constants.WORK ? customer.alternate[`${phoneKey}Ext`] = phoneItem?.phoneExtension : null;
          customer.alternate[`${phoneKey}Id`] = phoneItem.phoneId;
        }
      }
    });
    if (customer?.middleName) {
      customer.middleName = customer?.middleName?.substr(0, 1);
    }
    if (customer.alternateIdentifierCollection) {
      customer.AAA = customer?.alternateIdentifierCollection?.filter((p) => p?.alternateIdentifierItem?.name === 'AAA')[0]?.alternateIdentifierItem?.value || constants.EMPTY_STRING;
    }

    /* EMAIL CUSTOMER STRUCTURE */
    customer?.emailCollection?.forEach((emailObj, i) => {
      let { emailItem } = emailObj;
      if (((i + 1) === customer.emailCollection.length && !customer.emailId)
      || (customer.emailId !== customer.emailCollection[0].emailItem.emailId)) {
        customer.emailCollection[0].emailItem.isDefault = true;
        emailItem = customer.emailCollection[0].emailItem;
      }
      if (emailItem?.isDefault && !customer.emailId) {
        customer = { ...customer, email: emailItem.email, emailId: emailItem.emailId };
      } else if (!customer.alternate?.emailId) {
        customer.alternate = { ...customer.alternate, email: emailItem.email, emailId: emailItem.emailId };
      }
    });
  }
  return customer;
};

