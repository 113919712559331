export const searchMetaData = {
  customerSearch: {
    fields: {
      firstName: {
        caseInsensitive: true,
        fuzzy: false,
      },
      lastName: {
        caseInsensitive: true,
        fuzzy: true,
      },

    },
  },
  vehicleSearch: {
    fields: {
      license: {
        caseInsensitive: true,
        fuzzy: false,
      },
    },
  },
};
