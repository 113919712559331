import { React } from 'react';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';
import SelectButton from '../../UIComponents/Button/SelectButton';

const ButtonDiv = styled(SelectButton)`
  text-transform: uppercase;
  border: 0;
  color: ${theme.colors.obsidian};
  ${({ buttonName }) => (buttonName === constants.SELECT_CUSTOMER || buttonName === constants.APPLY_CUSTOMER_VEHICLE
    ? 'width: 200px;'
    : (buttonName === constants.EDIT_SEARCH || buttonName === constants.EXIT_SEARCH)
      ? 'width: 140px;'
      : (buttonName === constants.CLEAR_SEARCH
        ? 'width: 300px;'
        : 'width: 165px;'))};
  font-family: ${theme.fontFamily.bold};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  display: block;
  margin: 0 0 20px 20px;
  -webkit-user-select: none;
  user-select: none;
  ${({ isDisabled, buttonName }) => (buttonName === constants.SELECT_CUSTOMER || buttonName === constants.APPLY_CUSTOMER_VEHICLE
    || buttonName === constants.REACTIVATE_VEHICLE)
    && !isDisabled
    ? `
      background-color: ${theme.colors.green};
      color: white`
    : `
      background-color: ${theme.colors.gray}`};
`;

const HeaderButton = props => {
  const { buttonName, buttonClick, className, isDisabled, isVisible } = props;

  return (
    <ButtonDiv
      buttonType={constants.DEFAULT}
      buttonName={buttonName}
      isDisabled={isDisabled}
      isVisible={isVisible}
      className={className}
      onButtonClick={buttonClick}
    >
      {buttonName}
    </ButtonDiv>
  );
};

export default HeaderButton;
