import { React, Fragment, forwardRef } from 'react';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import Checkbox from '../../UIComponents/Checkbox/Checkbox';
import CreateButton from './Search/CreateButton';
import CustomerDetail from './Search/CustomerDetail';
import * as constants from '../../constants/Constants';
import { isNullEmptyUndefined } from '../../helper/validationHelper';


export const DetailVBar = styled.div`
  margin: auto;
  height: 100%;
  width: 0;
  border-left: 1px solid ${theme.colors.lightGray};
`;
const DetailWrapper = styled.div``;
const DetailTitleWrapper = styled.div`
  margin: ${({ isAddNewVehicle }) => (isAddNewVehicle ? '24px 0 0 10px' : '24px 0 0 20px')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const DetailSectionTitle = styled.div`
  font-family: ${theme.fontFamily.black};
  font-size: ${({ isAddNewVehicle }) => (isAddNewVehicle ? theme.fontSize.modalTitle : theme.fontSize.xLarge)};
  font-weight: ${theme.fontWeight.xBold};
  color: ${theme.colors.titleBlack};
  white-space: nowrap;
  letter-spacing: -0.1px;
`;
export const TitleVBar = styled(DetailVBar)`
  height: 25px;
  margin: 0 26.98px 0 29px;
  ${({ noVBar }) => noVBar && 'border-left: 1px transparent; margin:0 15px 0 16px'}
`;
const DetailSectionSeparator = styled.hr`
  width: 98%;
  border: 1px solid ${theme.colors.darkGray};
  margin: 8px auto 0 auto;
`;
const DetailSectionContent = styled.div`
  max-width: 1570px;
  padding: 25px 0 35px 85px;
  position: relative;
  ${({ hasVerticalScroll }) => hasVerticalScroll
    && 'max-height: 680px; overflow-y: auto; overflow-x: hidden; margin:0;'};
  //Styling for different detail sections
  ${({ manual }) => (manual ? 'padding: 25px 0 40px 30px;' : '')}
  ${({ isAddNewVehicle }) => (isAddNewVehicle && 'padding: 25px 0 40px 40px;')}
  ${({ tax }) => (tax ? 'padding: 25px 0 20px 25px;' : '')}
  ${({ info }) => (info ? 'padding: 25px 0 0 55px;' : '')}
  ${({ vehicles }) => vehicles && 'padding: 25px 0 0 10px;'}
  ${({ custoEdit }) => custoEdit && 'padding: 25px 0 0 30px;'}
  ${({ isVTVAircheck }) => isVTVAircheck && 'display:flex; padding:21px 0px 0 0px; height:auto;'};
  ${({ noPadding }) => noPadding && 'padding:0;'};
  ${({ isHidden }) => isHidden && 'color: grey; cursor: not-allowed;'}
`;
export const CheckboxWrapper = styled.div`
  margin-left: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:5%;
`;
export const DetailSection = forwardRef((props, ref) => {
  const {
    title,
    children,
    className,
    titleButtonName,
    titleButtonType,
    onButtonClick,
    manual,
    titleButtonsArr,
    tax,
    info,
    vehicles,
    custoEdit,
    hasVerticalScroll,
    isVTVAircheck,
    isAddNewVehicle,
    noVBar,
    noPadding,
    checkbox,
    checkboxLabel,
    checked,
    setChecked,
    fields,
    onButtonClick2,
    isInspection,
    isBlocked,
  } = props;
  const getTitleButtonName = (title, key) => {
    return (
      <Fragment key={key}>
        <TitleVBar noVBar={noVBar}/>
        {(titleButtonName || title?.buttonName) && (
        <CreateButton
          buttonName={titleButtonName || title?.buttonName}
          buttonType={titleButtonType || title?.buttonType}
          buttonClick={onButtonClick || title?.buttonClick}
          isBlocked={title?.buttonName !==  constants.VIEW_DEACTIVATED_VEHICLES && isBlocked}
        />
      )}
      {titleButtonName === constants.BUTTON_EDIT_CUSTOMER && isInspection && (
        <>
          <TitleVBar noVBar={noVBar}/>
          <CreateButton
            buttonName={constants.BUTTON_VIEW_CUSTOMER}
            buttonClick={title?.buttonName === constants.BUTTON_EDIT_CUSTOMER ? onButtonClick : onButtonClick2}
            />
        </>
      )}
    </Fragment>
  );
};
  const formNumber = title === constants.CUSTOMER_EDIT_PRIMARY_FORM ? 1 : 2;
  const getCheckBox = (currentFields) => {
      if (formNumber === 2 && isNullEmptyUndefined(currentFields?.address1)) {
        return null;
      } else {
        return(
      <CheckboxWrapper onClick={() => setChecked(currentFields, `contactCheckBox${formNumber}`)} >
        <Checkbox checked={checked}/>
        <CustomerDetail small infoName={checkboxLabel} />
      </CheckboxWrapper>
    );
   }
  };
  const getTitleButtonNames = () => {
    return titleButtonsArr.map((item, key) => {
      return getTitleButtonName(item, key);
    });
  };
  /* NOTE className required so styled(DetailSection) works properly */
  return (
    <DetailWrapper className={className}>
      { (title || titleButtonName || titleButtonsArr) && (
      <DetailTitleWrapper isAddNewVehicle={isAddNewVehicle}>
        <DetailSectionTitle isAddNewVehicle={isAddNewVehicle}>{title}</DetailSectionTitle>
        {titleButtonName && getTitleButtonName()}
        {titleButtonsArr && getTitleButtonNames()}
        {checkbox && getCheckBox(fields)}
      </DetailTitleWrapper>
      )}
      {(title || titleButtonName || titleButtonsArr) && <DetailSectionSeparator />}
      <DetailSectionContent
        isVTVAircheck={isVTVAircheck}
        hasVerticalScroll={hasVerticalScroll}
        isAddNewVehicle={isAddNewVehicle}
        tax={tax}
        manual={manual}
        info={info}
        vehicles={vehicles}
        custoEdit={custoEdit}
        noPadding={noPadding}
        ref={ref}
      >
        {children}
      </DetailSectionContent>
    </DetailWrapper>
  );
});

export default DetailSection;
