import styled from '../../../../helpers/esm-styled-components';
import { theme } from '../../../../helpers/GlobalStyles';
import * as constants from '../../../../constants/Constants';
import Dropdown from '../../../Dropdown/Dropdown';
import Input from '../../../Input/Input';
import Toggle from '../../../Toggle/Toggle';
import { HorizontalGenericDivider, VehicleInfoLabel } from '../../ModalUtils';
import { colorOptions } from '../../../../helper/baseDataHelper';
import Message from '../../../Error/Message';

const VehicleInfoContainer = styled.div`
  height:auto;
  padding-right:20px;
`;
const VehicleInfoGrid = styled.div`
  display: grid;
  height: auto;
  grid-gap: 16px;
  margin-bottom: 20px;
  grid-template-columns: 125px repeat(2,180px) 155px;
  grid-template-rows: 3;
  grid-template-areas:
  'year make model trim'
  'color licenseplate licensestate licensecountry'
  'vin vin onetime onetime';
  justify-content: center;
`;
const YearInput = styled(Input)`
grid-area: year;
`;
const MakeInput = styled(Input)`
grid-area: make;
`;
const ModelInput = styled(Input)`
grid-area: model;
`;
const TrimInput = styled(Input)`
grid-area: trim;
`;
const ColorDropdown = styled(Dropdown)`
grid-area: color;
`;
const LicensePlate = styled(Input)`
grid-area: licenseplate;
`;
const LicenseState = styled(Dropdown)`
grid-area: licensestate;

`;
const LicenseCountry = styled(Dropdown)`
grid-area: licensecountry;
z-index:15;

`;
const VINInput = styled(Input)`
grid-column: span 2;
`;
const OneTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 250px;
`;
const OneTimeLabel = styled.label`
text-align: right;
font-family: ${theme.fontFamily.bold};
`;

const ToggleWrapper = styled.div`
  display:flex;
  position: relative;
  align-items: center;
`;
const OneTimeToggle = styled(Toggle)`
`;

const VINContainer = styled.div`
  position: relative;
  grid-area: vin;
`;

const GenericVehicleInfo = (props) => {
  // props
  const {
    handleInput, fields,
    toggleClick,
    stateDropdownProps, isEdit,
    countryDropdownProps, arChargeFields, updateColor, errorMessage,
  } = props;

  //
  // Properties for the Color Dropdown
  //
  const colorDropdownProps = {
    fieldName: 'color',
    placeholder: 'Color',
    options: colorOptions,
    onChange: updateColor,
    selectedOption: fields.color,
    value: fields.color,
    required: false,
    positionStyle: 'absolute',
  };

  return (
    <VehicleInfoContainer>
      <VehicleInfoLabel>{constants.GENERIC_VEHICLE_INFO_TITLE}</VehicleInfoLabel>
      <HorizontalGenericDivider generic/>
      <VehicleInfoGrid >
        <YearInput
          type={constants.TEXT}
          fieldName="year"
          inputValue={fields.year}
          label="Year"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          maxLength={4}
          tabIndex={0}
          required={arChargeFields?.map(f => f.field).includes('VEHICLE YEAR')}
        />
        <MakeInput
          type={constants.TEXT}
          fieldName="make"
          inputValue={fields.make}
          label="Make"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={0}
          required={arChargeFields?.map(f => f.field).includes('VEHICLE MAKE')}
        />
        <ModelInput
          type={constants.TEXT}
          fieldName="model"
          inputValue={fields.model}
          label="Model"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={0}
          required={arChargeFields?.map(f => f.field).includes('VEHICLE MODEL')}
        />
        <TrimInput
          type={constants.TEXT}
          fieldName="trim"
          inputValue={fields.trim}
          label="Trim"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          required={arChargeFields?.map(f => f.field).includes('VEHICLE TRIM')}
          tabIndex={0}/>
        <ColorDropdown
          {...colorDropdownProps}
        />
        <LicensePlate
          required={arChargeFields?.map(f => f.field).includes('LICENSE PLATE #')}
          type={constants.TEXT}
          fieldName="licensePlate"
          inputValue={fields.isCarryOut ? 'CarryOut' : fields.licensePlate}
          label="License Plate"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={fields.isCarryOut}
          maxLength={10}
          tabIndex={0}
        />
        <LicenseState {...stateDropdownProps} />
        <LicenseCountry {...countryDropdownProps}/>
        <VINContainer>
          <VINInput
            type={constants.TEXT}
            fieldName="vin"
            inputValue={fields.isCarryOut ? 'CarryOut' : fields.vin}
            label="VIN"
            errorMessages={[]}
            warningMessages={[]}
            inputHandler={handleInput}
            isDisabled={fields.isCarryOut}
            maxLength={17}
            tabIndex={0}
            required={arChargeFields?.map(f => f.field).includes('VIN #')}
          />
          <Message errorMessages={errorMessage} type={constants.ERROR} />
        </VINContainer>
        <OneTimeWrapper>
          {!isEdit && (
            <ToggleWrapper>
              <OneTimeLabel>{constants.ADD_NEW_VEHICLE_ONE_TIME_USE}</OneTimeLabel>
              <OneTimeToggle
                wrapperWidth={100}
                active={fields.oneTimeVehicle}
                position={constants.LEFT}
                size={constants.TOGGLE_SIZE_SMALL}
                name={constants.YES}
                toggleClick={(e) => toggleClick(e, 'OneTime')}
                margin="0 0 0 250px"
          />
              <OneTimeToggle
                wrapperWidth={100}
                active={!fields.oneTimeVehicle}
                position={constants.RIGHT}
                size={constants.TOGGLE_SIZE_SMALL}
                name={constants.NO}
                toggleClick={(e) => toggleClick(e, 'OneTime')}
                margin="0 0 0 250px"
          />
            </ToggleWrapper>
          )}
          <ToggleWrapper>
            <OneTimeLabel>{constants.ADD_NEW_VEHICLE_CARRY_OUT}</OneTimeLabel>
            <OneTimeToggle
              toggleName="CarryOut"
              wrapperWidth={100}
              active={fields.isCarryOut}
              position={constants.LEFT}
              size={constants.TOGGLE_SIZE_SMALL}
              name={constants.YES}
              toggleClick={(e) => toggleClick(e, 'CarryOut')}
              margin="0 0 0 250px"

          />
            <OneTimeToggle
              toggleName="OneTime"
              wrapperWidth={100}
              active={!fields.isCarryOut}
              position={constants.RIGHT}
              size={constants.TOGGLE_SIZE_SMALL}
              name={constants.NO}
              toggleClick={(e) => toggleClick(e, 'CarryOut')}
              margin="0 0 0 250px"
          />
          </ToggleWrapper>
        </OneTimeWrapper>
      </VehicleInfoGrid>
    </VehicleInfoContainer>
  );
};

export default GenericVehicleInfo;
