import React, { useContext, useEffect } from 'react';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import SelectVTVAirCheck from './SelectVTVAirCheck';
import SearchByVehicle from './SearchByVehicle';
import { theme } from '../../../helpers/GlobalStyles';
import CustomerForm from './SearchByCustomer';
import { CVMContext } from '../../CVM/CVM';

const SearchModule = styled.div`
  height: 725px;
  width: 1570px;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  margin: 0 auto;
  border-radius: 5px;
`;

const Search = (props) => {
  const { setCurrentViewElement } = useContext(CVMContext);
  const { fieldErrors, setFieldErrors } = props;

  useEffect(() => {
    setCurrentViewElement(constants.VIEW_ELEMENT_SEARCH);
  }, []);

  return (
    <SearchModule className="current-view-search">
      <SelectVTVAirCheck />
      <CustomerForm
        detailTitle={constants.TITLE_SEARCH_BY_CUSTOMER}
        titleButtonType={constants.BUTTON_TYPE_CREATE}
        titleButtonName={constants.TITLE_CUSTOMER_CREATE}
        formButtonName={constants.TITLE_SEARCH_BY_CUSTOMER}
        fieldErrors={fieldErrors}
        setFieldErrors={setFieldErrors}
      />
      <SearchByVehicle />
    </SearchModule>
  );
};

export default Search;
