import styled from '../../helpers/esm-styled-components';
import { GiFlatTire } from 'react-icons/gi';
import { BiCopy } from 'react-icons/bi';
import { theme } from '../../helpers/GlobalStyles';
import * as constants from '../../constants/Constants';
import SelectButton from '../../UIComponents/Button/SelectButton';
import { useContext } from 'react';
import { CVMContext } from '../CVM/CVM';
import { copyToClipboard } from '../../helper/simulateActions';

const Wrapper = styled.div`
  background-color: #e8e8e8;
  min-height: 802px;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const Content = styled.div`
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
`;
const ErrorIcon = styled(GiFlatTire)`
  font-size: 150px;
  color: ${theme.colors.titleBlack};
`;
const ContentText = styled.p`
  font-size: 25px;
  color: ${theme.colors.red};
`;
const ButtonGroup = styled.div`
  display: flex;
  text-align: center;
`;
const IgnoreButton = styled(SelectButton)`
  background-color: #fff;
  color: #444;
  margin: 25px 15px;
`;
const ReloadButton = styled(SelectButton)`
  background-color: #444;
  color: #fff;
  margin: 25px 15px;
`;
const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CopyText = styled.p`
  font-size: 18px;
`;
const CopyIcon = styled(BiCopy)`
  font-size: 30px;
  cursor: pointer;
`;

const ErrorWrapper = ({ errorStack, children }) => {

  // context
  const { setToastrOn, setApplicationError } = useContext(CVMContext);

  return (
    <Wrapper>
      <Content>
        <ErrorIcon />
        <ContentText>
          {children}
        </ContentText>
        <CopyWrapper>
          <CopyText>Copy Error</CopyText>
        <CopyIcon onClick={() => copyToClipboard(errorStack, setToastrOn, true)} />
        </CopyWrapper>
        <ButtonGroup>
          <IgnoreButton buttonType={constants.DEFAULT} buttonName={'Ignore'} isDisabled={false} onButtonClick={() => setApplicationError({})} />
          <ReloadButton buttonType={constants.DEFAULT} buttonName={'Reload CVM'} isDisabled={false} onButtonClick={() => window?.location.reload()} />
        </ButtonGroup>
      </Content>
    </Wrapper>
  );
};

export default ErrorWrapper;
