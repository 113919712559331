import { useContext } from 'react';
import Input from './Input';
import SearchButton from '../Button/SearchButton';
import * as constants from '../../constants/Constants';
import styled from '../../helpers/esm-styled-components';
import Message from '../Error/Message';
import { CVMContext } from '../../components/CVM/CVM';

const SearchInput = styled(Input)`
  font-size: 12px;
`;

const VINField = styled(SearchInput)`
  grid-area: vin;
`;

const SearchByVIN = styled(SearchButton)`
  grid-area: vinsearch;
`;

const VINContainer = styled.div`
  position: relative;
`;
const VINSearch = ({
  vehicleFields, handleInput, searchBy,
}) => {

  const { errorMessageObj } = useContext(CVMContext);
  return (
    <>
      <VINContainer>
        <VINField
          type={String}
          required={false}
          fieldName="vin"
          inputValue={vehicleFields?.vin?.toUpperCase()}
          label="VIN"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={15 || null}
          maxLength={17}
          capsLock
        />
        {(errorMessageObj?.type === constants.ERROR) && <Message errorMessages={errorMessageObj?.message} type={constants.ERROR} />}
      </VINContainer>
      <SearchByVIN
        buttonName={constants.TITLE_SEARCH_BY_VIN}
        onButtonClick={searchBy}
        tabIndex={16 || null}
        isDisabled={vehicleFields?.vin?.length !== 17}
      />
    </>
  );
};
export default VINSearch;
