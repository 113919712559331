import React from 'react';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';

const InputComponent = styled.input`
  border-width: 1px 1px 1px 1px;
  outline: 0;
  border-color: ${theme.colors.lightGray};
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.normal};
  width:${({ width }) => (width || ' 390px;')};
  padding: 6px 8px;
  padding-right: 50px;
`;

const SearchInput = ({ onSearch, width }) => {
  return <InputComponent width={width} name="search" onInput={(event) => onSearch(event)} placeholder="Search" />;
};

export default SearchInput;
