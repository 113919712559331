import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';

const AssemblyContainer = styled.div`
  border: ${({ selected }) => (selected ? `2px solid ${theme.colors.editBlue}` : `1px solid ${theme.colors.mediumGray}`)};
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 9px;
  padding: ${({ isStaggered }) => (isStaggered ? '0 9px' : '5px 7px')};
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 3px;
  display: inline-flex;
  margin: 7px 14px 3px 0;
`;

const StandardAssemblyContainer = styled(AssemblyContainer)`
  width: fit-content;
  height: 35px;
  grid-template-columns: repeat(3, auto);
  grid-template-areas:
    'tireSize separator wheelSize';
  ${({ isStaggered }) => isStaggered && 'border: 0;'}
`;

const StaggeredAssemblyWrapper = styled.div`
  border: ${({ selected }) => (selected ? `2px solid ${theme.colors.editBlue}` : `1px solid ${theme.colors.mediumGray}`)};
  width: fit-content;
  height: 92px;
  display: inline-block;
  border-radius: 3px;
  margin: 5px;
  cursor: pointer;
`;

const TireSizeInfo = styled.p`
  margin: 0;
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.darkGray};
`;

const WheelSizeInfo = styled.p`
  margin-top: -3px;
  font-family: ${theme.fontFamily.black};
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSize.large};
`;

const WheelSizeInfoFront = styled.p`
  margin-top: 5px;
  font-family: ${theme.fontFamily.black};
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSize.large};
`;

const WheelSizeInfoBack = styled.p`
  margin-top: 5px;
  font-family: ${theme.fontFamily.black};
  color: ${theme.colors.darkGray};
  font-size: ${theme.fontSize.large};
`;

const WheelsLabel = styled.p`
  font-family: ${theme.fontFamily.medium};
  font-size: ${theme.fontSize.xSmall2};
  color: ${theme.colors.lightGray};
  margin-top: -2px;
`;

const SmallVerticalBar = styled.div`
  height: 21px;
  border-right: 1px solid ${theme.colors.mediumGray};
  display: inline-flex;
  grid-area: separator;
`;

const SmallVerticalBarStaggered = styled.div`
  height: 21px;
  border-right: 1px solid ${theme.colors.mediumGray};
  display: inline-flex;
  margin-top: 10px;
  grid-area: separator;
`;

const HorizontalDivider = styled.p`
  background-color: ${theme.colors.mediumGray};
  margin: 0 5px;
  height: 0.5px;
`;

const FrontLabel = styled.p`
  font-size: ${theme.fontSize.xSmall2};
  color: ${theme.colors.lightGray};
  font-family: ${theme.fontFamily.medium};
  margin: 0;
`;

const BackLabel = styled.p`
  font-size: ${theme.fontSize.xSmall2};
  color: ${theme.colors.lightGray};
  font-family: ${theme.fontFamily.medium};
  margin: 0;
`;

const Assembly = (props) => {
  const { assemblies, selectTireAssembly, standardVehicleFields } = props;

  const handleKeyDown = (event, c) => {
    // Check if the pressed key is "Enter" (key code 13) or "Space" (key code 32)
    if (
      (event?.keyCode && event.keyCode === constants.ENTER_KEY_CODE) ||
      (event?.keyCode && event.keyCode === constants.ENTER_SPACE_CODE)
    ) {
      selectTireAssembly(event, c);
    }
  };

  const uniqueAssemblySizes = new Set();
  return assemblies
    .filter((c) => {
      const assembly = Array.isArray(c.assembly)
        ? `${c.assembly[0].tireSize}-${c.assembly[0].wheelSize}-${c.assembly[1].tireSize}-${c.assembly[1].wheelSize}`
        : `${c.assembly.tireSize}-${c.assembly.wheelSize}`;

      if (uniqueAssemblySizes.has(assembly)) {
        return false;
      }
      uniqueAssemblySizes.add(assembly);
      return true;
    })
    .map((c, index) => {
      return Array.isArray(c.assembly) ? (
        <StaggeredAssemblyWrapper
          tabIndex={0}
          onKeyDown={(e) => handleKeyDown(e, c)}
          selected={
            standardVehicleFields.selectedAssembly[0]?.frontTireAspectRatio ===
              c.assembly[0].aspectRatio &&
            standardVehicleFields.selectedAssembly[1]?.rearTireAspectRatio ===
              c.assembly[1].aspectRatio &&
            standardVehicleFields.selectedAssembly[0]?.frontTireWidth ===
              c.assembly[0].crossSection &&
            standardVehicleFields.selectedAssembly[1]?.rearTireWidth ===
              c.assembly[1].crossSection &&
            standardVehicleFields.selectedAssembly[0]?.frontTireDiameter ===
              c.assembly[0].wheelSize &&
            standardVehicleFields.selectedAssembly[1]?.rearTireDiameter ===
              c.assembly[1].wheelSize &&
            standardVehicleFields.selectedAssembly[0].assemblyCode ===
              c.assembly[0].assemblyCode &&
              standardVehicleFields.selectedAssembly[1].assemblyCode ===
                c.assembly[1].assemblyCode
          }
          key={index}
          onClick={(e) => selectTireAssembly(e, c)}
        >
          <StandardAssemblyContainer isStaggered={Array.isArray(c.assembly)}>
            <TireSizeInfo>
              <FrontLabel>FRONT</FrontLabel>
              {c.assembly[0].tireSize}
            </TireSizeInfo>
            <SmallVerticalBarStaggered />
            <WheelSizeInfoFront>
              {c.assembly[0].wheelSize}
              {constants.INCHES_ABBR}
              <br />
              <WheelsLabel>Wheels</WheelsLabel>
            </WheelSizeInfoFront>
          </StandardAssemblyContainer>
          <HorizontalDivider>&nbsp;</HorizontalDivider>
          <StandardAssemblyContainer isStaggered={Array.isArray(c.assembly)}>
            <TireSizeInfo>
              <BackLabel>REAR</BackLabel>
              {c.assembly[1].tireSize}
            </TireSizeInfo>
            <SmallVerticalBarStaggered />
            <WheelSizeInfoBack>
              {c.assembly[1].wheelSize}
              {constants.INCHES_ABBR}
              <br />
              <WheelsLabel>Wheels</WheelsLabel>
            </WheelSizeInfoBack>
          </StandardAssemblyContainer>
        </StaggeredAssemblyWrapper>
      ) : (
        <StandardAssemblyContainer
          tabIndex={0}
          onKeyDown={(e) => handleKeyDown(e, c)}
          selected={
            Object.keys(standardVehicleFields.selectedAssembly).length > 0 &&
            standardVehicleFields.selectedAssembly.frontTireAspectRatio ===
              c.assembly.aspectRatio &&
            standardVehicleFields.selectedAssembly.frontTireWidth ===
              c.assembly.crossSection &&
            standardVehicleFields.selectedAssembly.wheelSize ===
              c.assembly.wheelSize &&
            standardVehicleFields.selectedAssembly.assemblyCode ===
              c.assembly.assemblyCode
          }
          key={index}
          onClick={(e) => selectTireAssembly(e, c)}
        >
          <TireSizeInfo>{c.assembly.tireSize}</TireSizeInfo>
          <SmallVerticalBar />
          <WheelSizeInfo>
            {c.assembly.wheelSize}
            {constants.INCHES_ABBR}
            <br />
            <WheelsLabel>Wheels</WheelsLabel>
          </WheelSizeInfo>
        </StandardAssemblyContainer>
      );
    });
};

export default Assembly;
