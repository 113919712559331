import React from 'react';
import Head from '../helpers/next-head';
import CVM from '../components/CVM/CVM';
import IndexGlobalStyle, { Theme } from '../helpers/GlobalStyles';

export default function App() {
  return (
    <>
      <Head>
        <title>CVM - MVP</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"/>
      </Head>
      <IndexGlobalStyle/>
      <Theme>
        <CVM />
      </Theme>
    </>
  );
}
