import React, { useContext } from 'react';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';
import { CVMContext } from '../CVM/CVM';
import SearchHeader from './SearchHeader';
import CustomerCreateHeader from './CustomerCreateHeader';
import CustomerSearchHeader from './CustomerSearchHeader';
import CustomerSummaryHeader from './CustomerSummaryHeader';
import CustomerVehiclesHeader from './CustomerVehiclesHeader';
import CustomerEditHeader from './CustomerEditHeader';

const HeaderContainer = styled.div`
  background-color: ${theme.colors.background};
`;
const HeaderDiv = styled.div`
  color: ${theme.colors.white};
  margin: 0 auto;
`;

const Header = () => {
  //TODO: refactor to deactivated vehicles module to render vehicle header but summary details.
  const { moduleName, showDeactivatedVehicles } = useContext(CVMContext);
  const moduleHeader = () => {
    switch (moduleName) {
      case constants.MODULE_SEARCH:
        return <SearchHeader />;
      case constants.MODULE_CREATE_CUSTOMER:
        return <CustomerCreateHeader />;
      case constants.MODULE_CUSTOMER_SEARCH:
        return <CustomerSearchHeader />;
      case constants.MODULE_CUSTOMER_SUMMARY:
        return showDeactivatedVehicles ? <CustomerVehiclesHeader /> : <CustomerSummaryHeader />;
      case constants.MODULE_CUSTOMER_VEHICLES:
        return <CustomerVehiclesHeader />;
      case constants.MODULE_VEHICLE_SEARCH:
        return <CustomerSearchHeader />;
      case constants.MODULE_EDIT_CUSTOMER:
        return <CustomerEditHeader />;
      default:
        return null;
    }
  };

  return (
    <HeaderContainer>
      <HeaderDiv>{moduleHeader()}</HeaderDiv>
    </HeaderContainer>
  );
};

export default Header;
