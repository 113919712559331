//  Workaround for styled-components not properly behaving as a true ECMA Module
//  https://github.com/styled-components/styled-components/issues/3437

import styled from 'styled-components';

export * from 'styled-components';

const defaultStyled = typeof styled === 'function' ? styled : styled.default;

export { defaultStyled as default };
