import { React, useContext } from 'react';
import * as constants from '../../constants/Constants';
import { CVMContext } from '../CVM/CVM';

import SearchHeader from './SearchHeader';

const CustomerSearchHeader = () => {
  const { searchResults, searchOrigin, previousOrigin } = useContext(CVMContext);

  let preFixTitle = constants.EMPTY_STRING;
  switch (searchOrigin || previousOrigin?.origin) {
    case constants.SEARCH_ORIGIN_CUSTOMER:
      preFixTitle = constants.TITLE_CUSTOMER_SEARCH;
      break;
    case constants.SEARCH_ORIGIN_VTV:
      preFixTitle = constants.TITLE_VTV_SEARCH;
      break;
    case constants.SEARCH_ORIGIN_AIRCHECK:
      preFixTitle = constants.TITLE_AIRCHECK_SEARCH;
      break;
    case constants.SEARCH_ORIGIN_LICENSE_PLATE:
      preFixTitle = constants.TITLE_VEHICLE_SEARCH;
      break;
    case constants.SEARCH_ORIGIN_VIN:
      preFixTitle = constants.TITLE_VEHICLE_SEARCH;
      break;
    default:
      break;
  }
  const title = `${preFixTitle} - ${searchResults
    ? (searchResults.length > 1 || searchResults.length === 0
      ? `${searchResults.length} Results`
      : `${searchResults.length} Result`)
    : 0
  }`;

  return <SearchHeader title={title} />;
};

export default CustomerSearchHeader;
