import React from 'react';
import * as constants from '../../constants/Constants';
import SearchHeader from './SearchHeader';

const CustomerEditHeader = () => {
  const title = constants.TITLE_CUSTOMER_EDIT;
  return (
    <SearchHeader title={title}/>
  );
};

export default CustomerEditHeader;
