import { useEffect, useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import styled from '../../../../helpers/esm-styled-components';
import * as constants from '../../../../constants/Constants';
import { theme } from '../../../../helpers/GlobalStyles';
import { OEBadge, OEBadgeWrapper } from '../../../../components/Details/Vehicle/VehicleDetails';
import Assembly from '../../../Assembly/Assembly';
import { SeeMoreSizesLabel } from '../BreadCrumbs/BreadCrumbTireSize';

const WheelSizeOptionsWrapper = styled.div`
  border: 1px solid ${theme.colors.titleBlack};
  background-color: ${theme.colors.opaque};
`;

const WheelSizeOptionsContainer = styled.div`
  padding: 15px;
`;

const BadgeWrapper = styled(OEBadgeWrapper)`
  margin: 6px 9px 0 0;
  position: relative;
  width: 35px;
  height: 27px;
  text-align: center;
  display: inline-block;
`;

const Badge = styled(OEBadge)`
  padding: 6px 0;
  margin: 0;
  font-size: ${theme.fontSize.medium};
`;

const TiresDoNotFitLabel = styled.p`
  color: ${theme.colors.lightGray};
  font-size: ${theme.fontSize.medium2};
  font-family: ${theme.fontFamily.normal};
  display: inline-flex;
  margin-bottom: 15px;
`;

const NoAssembliesLabel = styled.p`
  font-size: ${theme.fontSize.medium};
  color: ${theme.colors.titleBlack};
  font-family: ${theme.fontFamily.normal};
`;

const AdditionalWheelSizeOptionsDivider = styled.hr`
  margin: 0 15px;
`;

const AdditionalWheelSizeOptions = (props) => {
  // props
  const {
    additionalTireSizeOptions, isDually, standardVehicleFields, setStandardVehicleFields, plusSize
  } = props;

  // states
  const [underSizeOpen, setUnderSizeOpen] = useState(false);
  const [overSizeOpen, setOverSizeOpen] = useState(false);
  const [showUnderSizeSection, setShowUnderSizeSection] = useState(false);
  const [showOverSizeSection, setShowOverSizeSection] = useState(false);
  const [underSizeAssemblies, setUnderSizeAssemblies] = useState([]);
  const [matchAssemblies, setMatchAssemblies] = useState([]);
  const [overSizeAssemblies, setOverSizeAssemblies] = useState([]);

  // set all of the states on load if there is a change in additional tire size options
  useEffect(() => {
    setUnderSizeOpen(false);
    setOverSizeOpen(false);
    setShowUnderSizeSection(additionalTireSizeOptions.filter(a => (Array.isArray(a.assembly) ? a.assembly[0].matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_UNDERSIZE : a.assembly.matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_UNDERSIZE))?.length > 0);
    setShowOverSizeSection(additionalTireSizeOptions.filter(a => (Array.isArray(a.assembly) ? a.assembly[0].matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_OVERSIZE : a.assembly.matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_OVERSIZE))?.length > 0);
    setUnderSizeAssemblies(additionalTireSizeOptions.filter(a => (Array.isArray(a.assembly) ? a.assembly[0].matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_UNDERSIZE : a.assembly.matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_UNDERSIZE)));
    setMatchAssemblies(additionalTireSizeOptions.filter(a => (Array.isArray(a.assembly) ? a.assembly[0].matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_MATCH : a.assembly.matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_MATCH)));
    setOverSizeAssemblies(additionalTireSizeOptions.filter(a => (Array.isArray(a.assembly) ? a.assembly[0].matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_OVERSIZE : a.assembly.matchQualifier === constants.ADD_NEW_ASSEMBLY_AFTER_MARKET_OVERSIZE)));
  }, [additionalTireSizeOptions]);

  /**
   * Click handler for selecting an additional assembly
   * @param {Event} e
   * @param {Object} value
   */
  const selectTireAssembly = (e, value) => {
    const { assemblyCollection } = standardVehicleFields;
    let existingALTAssemblyId;

    // remove existing OE in the list if selecting a new one
    assemblyCollection.forEach((ac, index) => {
      if (ac.assemblyItem.assemblyType === constants.VEHICLE_ALT_ASSEMBLY_TYPE) {
        existingALTAssemblyId = ac.assemblyItem.assemblyId;
        !ac.assemblyItem.isWinterUse && assemblyCollection.splice(index, 1);
      }
    });

    // if assembly is staggered
    if (Array.isArray(value.assembly)) {
      // update the assemblyCollection
      assemblyCollection.push({
        assemblyItem: {
          assemblyCode: value.assembly[0].assemblyCode,
          assemblyId: existingALTAssemblyId,
          assemblyType: constants.VEHICLE_ALT_ASSEMBLY_TYPE,
          fitmentId: standardVehicleFields.selectedAssembly[0].fitmentId || constants.EMPTY_STRING,
          frontRimSize: value.assembly[0].wheelSize?.toString(),
          frontTireAspectRatio: value.assembly[0].aspectRatio,
          frontTireDiameter: value.assembly[0].wheelSize?.toString(),
          frontTireWidth: value.assembly[0].crossSection,
          rearRimSize: value.assembly[1].wheelSize?.toString(),
          rearTireAspectRatio: value.assembly[1].aspectRatio,
          rearTireDiameter: value.assembly[1].wheelSize?.toString(),
          rearTireWidth: value.assembly[1].crossSection,
          sizeQualifier: value.assembly[0].sizeQualifier >= 0 ? `+${value.assembly[0].sizeQualifier}"` : `${value.assembly[0].sizeQualifier}`,
          tireSize: value.assembly[0].tireSize,
          wheelSize: value.assembly[0].wheelSize,
          isOE: false,
          isAfterMarket: true
        }
      });
      setStandardVehicleFields({
        ...standardVehicleFields,
        assemblyCollection,
        selectedAssembly: [
          {
            assemblyCode: value.assembly[0].assemblyCode,
            assemblyId: existingALTAssemblyId,
            assemblyType: constants.VEHICLE_ALT_ASSEMBLY_TYPE,
            fitmentId: standardVehicleFields.selectedAssembly[0].fitmentId || constants.EMPTY_STRING,
            frontRimSize: value.assembly[0].wheelSize?.toString(),
            frontTireAspectRatio: value.assembly[0].aspectRatio,
            frontTireDiameter: value.assembly[0].wheelSize?.toString(),
            frontTireWidth: value.assembly[0].crossSection,
            rearRimSize: value.assembly[1].wheelSize?.toString(),
            rearTireAspectRatio: value.assembly[1].aspectRatio,
            rearTireDiameter: value.assembly[1].wheelSize?.toString(),
            rearTireWidth: value.assembly[1].crossSection,
            tireSize: value.assembly[0].tireSize,
            wheelSize: value.assembly[0].wheelSize,
            sizeQualifier: value.assembly[0].sizeQualifier >= 0 ? `+${value.assembly[0].sizeQualifier}"` : `${value.assembly[0].sizeQualifier}`,
            isOE: false,
            isAfterMarket: true,
          },
          {
            assemblyCode: value.assembly[0].assemblyCode,
            assemblyId: existingALTAssemblyId,
            assemblyType: constants.VEHICLE_ALT_ASSEMBLY_TYPE,
            fitmentId: standardVehicleFields.selectedAssembly[1].fitmentId || constants.EMPTY_STRING,
            frontRimSize: value.assembly[0].wheelSize?.toString(),
            frontTireAspectRatio: value.assembly[0].aspectRatio,
            frontTireDiameter: value.assembly[0].wheelSize?.toString(),
            frontTireWidth: value.assembly[0].crossSection,
            rearRimSize: value.assembly[1].wheelSize?.toString(),
            rearTireAspectRatio: value.assembly[1].aspectRatio,
            rearTireDiameter: value.assembly[1].wheelSize?.toString(),
            rearTireWidth: value.assembly[1].crossSection,
            tireSize: value.assembly[1].tireSize,
            wheelSize: value.assembly[1].wheelSize,
            sizeQualifier: value.assembly[1].sizeQualifier >= 0 ? `+${value.assembly[1].sizeQualifier}"` : `${value.assembly[1].sizeQualifier}`,
            isOE: false,
            isAfterMarket: true,
          }
        ],
        chassisId: standardVehicleFields.chassisId
      });
    } else {
      // update the assemblyCollection
      assemblyCollection.push({
        assemblyItem: {
          assemblyCode: value.assembly.assemblyCode,
          assemblyId: existingALTAssemblyId,
          assemblyType: constants.VEHICLE_ALT_ASSEMBLY_TYPE,
          fitmentId: standardVehicleFields.selectedAssembly.fitmentId || constants.EMPTY_STRING,
          frontRimSize: value.assembly.wheelSize?.toString(),
          frontTireAspectRatio: value.assembly.aspectRatio,
          frontTireDiameter: value.assembly.wheelSize?.toString(),
          frontTireWidth: value.assembly.crossSection,
          rearRimSize: value.assembly.wheelSize?.toString(),
          rearTireDiameter: value.assembly.wheelSize?.toString(),
          rearTireAspectRatio: value.assembly.aspectRatio,
          rearTireWidth: value.assembly.crossSection,
          sizeQualifier: value.assembly.sizeQualifier >= 0 ? `+${value.assembly.sizeQualifier}"` : `${value.assembly.sizeQualifier}`,
          tireSize: value.assembly.tireSize,
          wheelSize: value.assembly.wheelSize,
          isOE: false,
          isAfterMarket: true
        }
      });
      setStandardVehicleFields({
        ...standardVehicleFields,
        assemblyCollection,
        selectedAssembly: {
          assemblyCode: value.assembly.assemblyCode,
          assemblyId: existingALTAssemblyId,
          assemblyType: constants.VEHICLE_ALT_ASSEMBLY_TYPE,
          fitmentId: standardVehicleFields.selectedAssembly.fitmentId || constants.EMPTY_STRING,
          frontRimSize: value.assembly.wheelSize?.toString(),
          frontTireAspectRatio: value.assembly.aspectRatio,
          frontTireDiameter: value.assembly.wheelSize?.toString(),
          frontTireWidth: value.assembly.crossSection,
          rearRimSize: value.assembly.wheelSize?.toString(),
          rearTireDiameter: value.assembly.wheelSize?.toString(),
          rearTireAspectRatio: value.assembly.aspectRatio,
          rearTireWidth: value.assembly.crossSection,
          tireSize: value.assembly.tireSize,
          wheelSize: value.assembly.wheelSize,
          sizeQualifier: value.assembly.sizeQualifier >= 0 ? `+${value.assembly.sizeQualifier}"` : `${value.assembly.sizeQualifier}`,
          isOE: false,
          isAfterMarket: true,
        },
        chassisId: standardVehicleFields.chassisId
      });
    }
  };

  const handleKeyDown = (event, c) => {
    // Check if the pressed key is "Enter" (key code 13) or "Space" (key code 32)
    if (
      (event?.keyCode && event.keyCode === constants.ENTER_KEY_CODE) ||
      (event?.keyCode && event.keyCode === constants.ENTER_SPACE_CODE)
    ) {
      if (showUnderSizeSection) { setUnderSizeOpen(c); }
      if (showOverSizeSection) { setOverSizeOpen(c); }
    }
  };

  return (
    <>
      <WheelSizeOptionsWrapper>
        <WheelSizeOptionsContainer>
          <BadgeWrapper>
            <Badge name={constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL}>{plusSize > 0 ? `+${plusSize}` : plusSize}</Badge>
          </BadgeWrapper>
          <TiresDoNotFitLabel>{constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL}</TiresDoNotFitLabel>
          {additionalTireSizeOptions.length > 0
            ?
              <Assembly setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields} isDually={isDually} assemblies={matchAssemblies} selectTireAssembly={(e, c) => selectTireAssembly(e, c, 'after-market')} />
            :
              <NoAssembliesLabel>None</NoAssembliesLabel>}
        </WheelSizeOptionsContainer>
        {
          // Undersize Section
          showUnderSizeSection && (
          <>
            <AdditionalWheelSizeOptionsDivider />
            <WheelSizeOptionsContainer>
              <SeeMoreSizesLabel tabIndex={0} onKeyDown={(e) => handleKeyDown(e, !underSizeOpen)} onClick={() => setUnderSizeOpen(!underSizeOpen)}>
                undersized tires&nbsp;&nbsp;{(underSizeOpen ? <AiOutlineMinus /> : <AiOutlinePlus />)}
              </SeeMoreSizesLabel>
              { underSizeOpen && (
                <>
                  <TiresDoNotFitLabel>{constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL_UNDERSIZE}</TiresDoNotFitLabel>
                  <Assembly setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields} isDually={isDually} assemblies={underSizeAssemblies} selectTireAssembly={(e, c) => selectTireAssembly(e, c, 'after-market')} />
                </>
              )}
            </WheelSizeOptionsContainer>
          </>
          )
}
        {
          // Oversize Section
          showOverSizeSection && (
          <>
            <AdditionalWheelSizeOptionsDivider />
            <WheelSizeOptionsContainer>
              <SeeMoreSizesLabel tabIndex={0} onKeyDown={(e) => handleKeyDown(e, !overSizeOpen)} onClick={() => setOverSizeOpen(!overSizeOpen)}>
                oversized tires&nbsp;&nbsp;{(overSizeOpen ? <AiOutlineMinus /> : <AiOutlinePlus />)}
              </SeeMoreSizesLabel>
              { overSizeOpen && (
                <>
                  <TiresDoNotFitLabel>{constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL_OVERSIZE}</TiresDoNotFitLabel>
                  <Assembly setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields} isDually={isDually} assemblies={overSizeAssemblies} selectTireAssembly={(e, c) => selectTireAssembly(e, c, 'after-market')} />
                </>
              )}
            </WheelSizeOptionsContainer>
          </>
          )
}
      </WheelSizeOptionsWrapper>
    </>
  );
};

export default AdditionalWheelSizeOptions;
