import { React, useContext } from 'react';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';
import { CVMContext } from '../CVM/CVM';
import Search from './Search/Search';
import CustomerCreate from './Search/CustomerCreate';
import CustomerSearchResults from './Search/CustomerSearchResults';
import CustomerSummary from './Search/CustomerSummary';
import { isNullOrUndefined } from '../../helper/validationHelper';

const DetailsContainer = styled.div`
  min-width: 1570px;
  max-width: 1570px;
  min-height: 725px;
  max-height: 725px;
  // TODO: temp commenting horizontal line for resolution issue CAVA-763
  // overflow-y: ${({ hideOverflow }) => (hideOverflow ? 'hidden;' : 'auto;')};
  overflow-x: hidden;
  //Change according to screen requirements
  margin: 0 auto;
  background-color: ${theme.colors.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  // TODO: temp commenting horizontal line for resolution issue CAVA-763
  // overflow-y: ${({ hideOverflow }) => (hideOverflow ? 'hidden;' : 'auto;')};
  padding: 20px 0;
`;

const Details = () => {
  const { moduleName, searchResults, customerInfo } = useContext(CVMContext);
  // const isOverflowHiddenModule = moduleName === constants.MODULE_CUSTOMER_SEARCH
  // || moduleName === constants.MODULE_VEHICLE_SEARCH
  // || moduleName === constants.MODULE_CUSTOMER_VEHICLES;
  const isBlocked = !isNullOrUndefined(customerInfo.blockedReasonCode) && !isNullOrUndefined(customerInfo.postingBlock);
  const renderModuleDetails = () => {
    switch (moduleName) {
      case constants.MODULE_SEARCH:
        return <Search />;
      case constants.MODULE_CREATE_CUSTOMER:
        return <CustomerCreate />;
      case constants.MODULE_CUSTOMER_SEARCH:
      case constants.MODULE_VEHICLE_SEARCH:
        return <CustomerSearchResults searchResults={searchResults} />;
      case constants.MODULE_CUSTOMER_SUMMARY:
      case constants.MODULE_EDIT_CUSTOMER:
      case constants.MODULE_CUSTOMER_VEHICLES:
        return <CustomerSummary isBlocked={isBlocked} />;
      default:
        return null;
    }
  };

  document.body.setAttribute('moduleName', moduleName);

  // TODO: temp removing hideOverflow={isOverflowHiddenModule} in ContentWrapper for resolution issue CAVA-763

  return (
    <DetailsContainer >
      <ContentWrapper>
        {renderModuleDetails()}
      </ContentWrapper>
    </DetailsContainer>
  );
};

export default Details;
