import React, { useContext } from 'react';
import { GrClose } from 'react-icons/gr';
import styled from '../../../helpers/esm-styled-components';
import { theme } from '../../../helpers/GlobalStyles';
import SelectButton from '../../Button/SelectButton';
import * as constants from '../../../constants/Constants';
import { CVMContext } from '../../../components/CVM/CVM';

const ModalWrapper = styled.div``;
const ModalContainer = styled.div`
  background-color: ${theme.colors.white};
  position: -webkit-sticky;
  position: absolute;
  top: 50%;
  height: 177px;
  width: 768px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  text-align: right;
  ${({ subtitle }) => subtitle && 'height:210px;'};
  ${({ body }) => body && 'height:326px;'};
`;
const ContentContainer = styled.div`
  text-align: left;
  padding: 0 29px 35px 29px;
  background-color: ${theme.colors.white};
`;
const CloseIcon = styled(GrClose)`
  position: relative;
  height: 18px;
  width: 12px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  top: 16px;
  right: 20px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
`;
const ModalTitle = styled.h3`
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.modalTitle};
  font-weight: ${theme.fontWeight.xBold};
  color: ${theme.colors.obsidian};
  line-height: 24px;
  ${({ body }) => body && 'margin-bottom:0'}
`;
const ModalSubTitle = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.titleBlack};
  height: 47px;
  width: 687px;
  margin: 0;
`;
const ModalBody = styled.div`
  text-align: initial;
  margin-left: 15%;
  padding-bottom: 4%;
`;
const ModalOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #3333334d;
  z-index: 100;
`;
const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'cancel confirm';
  grid-gap: 29px;
  ${({ oneButton }) => oneButton && 'grid-template-columns:none;'};
`;
const CancelButton = styled(SelectButton)`
  border: 0;
  grid-area: cancel;
  color: ${theme.colors.obsidian};
`;
const ConfirmButton = styled(CancelButton)`
  background-color: ${theme.colors.red};
  color: ${theme.colors.white};
  grid-area: confirm;
`;

const ImportedVehicleModal = props => {
  const {
    title,
    subtitle,
    returnToModule,
    modalAction,
    cancelButtonName,
    confirmButtonName,
    modalBody,
  } = props;

  const { setIsModalOn } = useContext(CVMContext);

  return (
    <>
      <ModalOverlay />
      <ModalWrapper>
        <ModalContainer subtitle={subtitle} body={modalBody}>
          <CloseIcon onClick={() => setIsModalOn(false)} />
          <ContentContainer>
            <ModalTitle body={modalBody}>{title}</ModalTitle>
            {subtitle && <ModalSubTitle>{subtitle}</ModalSubTitle>}
            {modalBody && <ModalBody>{modalBody}</ModalBody>}
            <ButtonContainer oneButton={!cancelButtonName?.length}>
              { cancelButtonName?.length && (
              <CancelButton
                buttonType={constants.DEFAULT}
                onButtonClick={returnToModule}
                buttonName={cancelButtonName}
              />
              )}
              <ConfirmButton
                buttonType={constants.DEFAULT}
                onButtonClick={modalAction}
                buttonName={confirmButtonName}
              />
            </ButtonContainer>
          </ContentContainer>
        </ModalContainer>
      </ModalWrapper>
    </>
  );
};

export default ImportedVehicleModal;
