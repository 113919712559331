/**
 * @overview Module navigation constants supporting URL/postMessage routing.
 * @copyright Copyright (C) 2022 Discount Tire Company. All rights reserved.
 */

// Map of applicaton modules that can be targeted by URL or postMessage params.
// https://discounttire.atlassian.net/wiki/spaces/OI/pages/5079072875/CVM+URL+parameters
export const NavigationMap = {
  'search': {
    'fields': {
      'phone_number': false,
      'email_address': false,
      'first_name': false,
      'last_name': false,
      'address_line_1': false,
      'address_line_2': false,
      'city': false,
      'state': false,
      'zipcode': false,
      'country': false,
      'company_name': false,
      'fleet_number': false,
      'employee_id': false,
      'vin': false,
      'license_plate': false,
      'license_state': false,
      'license_country': false,
    }
  },
  'customer_search': {
    'min_fields': 1,
    'fields': {
      'phone_number': false,
      'email_address': false,
      'first_name': false,
      'last_name': false,
      'company_name': false,
      'fleet_number': false,
      'employee_id': false,
      'customer_id': false,       // hidden (not on form)
    }
  },
  'customer_summary': {
    'fields': {
      'customer_id': true,
      'vehicle_id': false,
      'vin': false,
    }
  },
  'customer_add': {
    'fields': {
      'requestor': false,
    }
  },
  'customer_edit': {
    'fields': {
      'customer_id': true,
      'requestor': false,
    }
  },
  'customer_vehicles': {
    'fields': {
      'customer_id': true,
      'requestor': false,
    }
  },
  'vehicle_search': {
    'min_fields': 1,
    'fields': {
      'vin': false,
      'license_plate': false,
      'license_state': false,
      'license_country': false,
    }
  },
  'vehicle_select': {
    'fields': {
      'customer_id': true,
      'vehicle_id': true,
      'vin': false,
      'requestor': false,
    }
  },
  'vehicle_edit': {
    'fields': {
      'customer_id': true,
      'vehicle_id': true,
      'vin': false,
      'requestor': false,
    }
  }
};

// Valid URL and/or postMessage parameter name listing.
// Parameter names here will be preserved across rewriting of the URL by
// Okta and will be reconstituted as 'supplied_params' after redirect.
export const NavigationParams = [
  //  All CVM modules
  'uuid',
  'module',

  'error',      // from Okta and potentially other sources

  'schema_version',

  'store_code',
  'store_id',
  'site_id',
  'SRPMIC',
  'AAA',

  // customer search
  'phone_number',
  'email_address',
  'first_name',
  'last_name',
  'company_name',
  'fleet_number',
  'employee_id',

  // vehicle search
  'vin',
  'license_plate',
  'license_state',
  'license_country',

  // search (customer and vehicle search plus...)
  'address_line_1',
  'address_line_2',
  'city',
  'state_code',
  'zipcode',
  'country',

  //  VTV search
  'inspection_id',

  // byId additions
  'customer_id',
  'vehicle_id',
];

// URL parameter name to CVM query parameter name map.
// Used to transform URL parameters into searchQuery terms.
export const NavigationQueryMap = {
  // visible fields on the customer search interface
  phone_number: 'phoneNumber',
  email_address: 'email',
  first_name: 'firstName',
  last_name: 'lastName',
  company_name: 'companyName',
  fleet_number: 'fleetNumber',
  employee_id: 'employeeID',

  address_line_1: 'address1',
  address_line_2: 'address2',
  state_code: 'state',
  zipcode: 'zip',

  // license fields visible in the license search interface
  vin: 'vin',
  license_plate: 'licensePlate',
  license_state: 'licenseState',
  license_country: 'licenseCountry',

  // invisible... rely on manual API invocations
  inspection_id: 'inspectionId',
  customer_id: 'customerId',
  vehicle_id: 'vehicleId',
};

/**
 * @method isOktaRedirect
 * @summary Returns whether or not we're being redirected from Okta.
 * @returns {Boolean} Whether or not we're being redirected from Okta.
 */
export const isOktaRedirect = (aURL) => {
  const urlParams = new URL(aURL).searchParams;

  // 'code' and 'state' are the standard OAuth redirect parameter names. if
  // those are the only two then this is a pure okta redirect.
  return urlParams.size === 2 && urlParams.has('code') && urlParams.has('state');
};

