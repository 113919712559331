import {
  useContext, useEffect, useState, useRef
} from 'react';
import styled from '../../../../helpers/esm-styled-components';
import { theme } from '../../../../helpers/GlobalStyles';
import * as constants from '../../../../constants/Constants';
import BreadCrumbContent from '../BreadCrumbContent';
import { CVMContext } from '../../../../components/CVM/CVM';
import { checkIfObjectHasKey, isNullEmptyUndefined } from '../../../../helper/validationHelper';
import { VehicleData } from '../../../../Data/vehicleData';
import {
  OEBadgeWrapper, OEBadge, FactoryTireSize, FactoryTireSizeWrapper, FactoryTireSizeLabel, SmallVerticalBar, WheelSize, WheelSizeLabel
} from '../../../../components/Details/Vehicle/VehicleDetails';
import Toggle from '../../../Toggle/Toggle';
import AssemblyDropdown from '../../../Dropdown/AssemblyDropdown';
import { breadCrumbs } from '../../../../helper/baseDataHelper';

export const VehicleBaseInfoContainer = styled.div`
  text-align: left;
  padding-left: 2px;
  display: inline-flex;
  // margin-bottom: ${({ isEdit }) => (isEdit ? '25px' : 0)};
  margin-bottom: ${({ isStaggered }) => (isStaggered ? 0 : '25px')};
  flex-direction: column;
`;

export const VehicleBaseInfoYearMake = styled.p`
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.modalTitle};
  margin-bottom: 0;
  text-transform: uppercase;
  color: ${theme.colors.darkGray};
`;

export const VehicleBaseInfoModel = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.large};
  margin-top: 0;
  color: ${theme.colors.darkGray};
  margin-bottom: 6px;
`;

export const VehicleBaseInfoTrim = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.darkGray};
  margin-top: 6px;
`;

export const TireInfoContainer = styled.div`
  position: relative;
  display: inline-flex;
  height: 48px;
  margin-right: 30px;
  margin-top: 5px;
  margin-bottom: ${({ isStaggered }) => (isStaggered ? 0 : '15px')};
`;
export const BadgeWrapper = styled(OEBadgeWrapper)`
  margin: 6px 9px 0 0;
  position: relative;
  width: ${({ isEdit }) => (isEdit ? '31.47' : '42px')};
  height: ${({ isEdit }) => (isEdit ? '24.19' : '27px')};
  text-align: center;
`;
export const Badge = styled(OEBadge)`
  padding: ${({ isEdit }) => (isEdit ? '1px 7px' : '6px 0')};
  margin: 0;
  font-size: ${({ isEdit }) => (isEdit ? '10.75px' : theme.fontSize.medium)};
`;
export const TireSizeWrapper = styled(FactoryTireSizeWrapper)`
  margin: 0;
`;
export const TireSize = styled(FactoryTireSize)`
  margin: 0;
  font-size: ${({ isEdit }) => (isEdit ? '16.13px' : theme.fontSize.infoSubtitle)};
`;
export const TireSizeLabel = styled(FactoryTireSizeLabel)`
  margin: ${({ isEdit }) => (isEdit ? '-5px 0' : 0)};
  font-size: ${({ isEdit }) => (isEdit ? '11.65px' : theme.fontSize.medium2)};
`;
export const SmVerticalBar = styled(SmallVerticalBar)`
  position:relative;
  margin: ${({ isEdit }) => (isEdit ? '6px 12px' : '0 12px')};
  height: ${({ isEdit }) => (isEdit ? '26.88px' : '40px')};
  position: inherit;
`;
export const WSize = styled(WheelSize)`
  margin: 0;
  font-size: ${({ isEdit }) => (isEdit ? '16.13px' : theme.fontSize.infoSubtitle)};
`;
export const WSizeLabel = styled(WheelSizeLabel)`
  margin: ${({ isEdit }) => (isEdit ? '-5px 0' : 0)};
  font-size: ${({ isEdit }) => (isEdit ? '11.65px' : theme.fontSize.medium2)};
`;

export const VehicleQuestionContainer = styled.div`
  text-align: right;
  right: 25px;
  float: right;
  margin-top: ${({ isStaggered, isEdit }) => (isStaggered ? '-130px' : (isEdit ? '-105px' : '-115px'))};
  ${({ isStaggered, isEdit }) => (isStaggered && isEdit) && 'margin-top: -200px'};
`;

export const OneTimeUseQuestionLabel = styled.p`
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.bold};
  color: ${theme.colors.darkGray};
  margin-right: 115px;
`;

export const WinterUseQuestionLabel = styled.p`
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.bold};
  color: ${theme.colors.darkGray};
  margin-right: 115px;
`;

export const ToggleWrapper = styled.div`
  top: -5px;
  width: fit-content;
  height: 50px;
  border-radius: 8px;
  padding: 0px;
  background-color: ${theme.colors.offWhite};
  position: absolute;
  right: 100px;
`;

export const ToggleLabelContainer = styled.div`
  z-index: 2;
  margin: 0 0 30px 0;
  // margin: ${({ isStaggered }) => (isStaggered ? '20px' : '33px')} 0 30px 0;
  display: block;
  position: relative;
`;

const WizardContainer = styled.div`
  margin: 0 auto;
`;

export const LightHorizontalDivider = styled.hr`
  border: 1px solid ${theme.colors.opaque};
  margin: 0;
  width: 100%;
`;


const VehicleWizard = (props) => {
  // props
  const {
    standardVehicleFields, setStandardVehicleFields, arChargeFields, isEdit, errorMessage, revertBackToManualEntry,
  } = props;

  // context
  const {
    vehicleBreadCrumbs, setVehicleBreadCrumbs, currentBreadCrumb, setCurrentBreadCrumb
  } = useContext(CVMContext);

  // states
  const [showAdditionalVehicleInfo, setShowAdditionalVehicleInfo] = useState(false);
  const isAssemblyStaggered = Array.isArray(standardVehicleFields.selectedAssembly);

  /**
   * Click handler for toggling OneTime and Winter Use
   * @param {Event} event
   * @param {String} name
   */
  const toggleClick = (event, name) => {
    const selection = event.target.getAttribute('name');
    let restructuredSelectedAssembly = Array.isArray(standardVehicleFields.selectedAssembly) ? [] : {},
      restructuredAssemblyCollection = Array.isArray(standardVehicleFields.assemblyCollection) ? [] : {};
    switch (name) {
      case constants.ADD_NEW_VEHICLE_ONE_TIME_USE:
        setStandardVehicleFields({
          ...standardVehicleFields,
          isOneTime: selection === constants.YES
        });
        break;
      case constants.ADD_NEW_VEHICLE_WINTER_USE:
        // toggling this to yes, creates a WTR assembly type of the assembly that was selected
        // toggling this to no, deletes the WTR assembly from the list and re-assigns the previous selected assembly
        restructuredSelectedAssembly = JSON.parse(JSON.stringify(standardVehicleFields.selectedAssembly));
        restructuredAssemblyCollection = JSON.parse(JSON.stringify(standardVehicleFields.assemblyCollection));

        if (selection === constants.YES) {
          // grab the assemblyId of the previous WTR assembly (if existing)
          const existingWinterAssembly = restructuredAssemblyCollection.filter(rac => rac.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE)[0];

          // remove any existing WTR from Assembly Collection
          restructuredAssemblyCollection = restructuredAssemblyCollection.filter(rac => rac.assemblyItem.assemblyType !== constants.VEHICLE_WTR_ASSEMBLY_TYPE);

          // add necessary fields for selectedAssembly
          if (Array.isArray(restructuredSelectedAssembly)) {
            restructuredSelectedAssembly[0].isWinterUse = selection === constants.YES;
            restructuredSelectedAssembly[0].assemblyType = constants.VEHICLE_WTR_ASSEMBLY_TYPE;
            restructuredSelectedAssembly[0].assemblyId = existingWinterAssembly ? existingWinterAssembly.assemblyItem.assemblyId : constants.EMPTY_STRING;
            restructuredSelectedAssembly[0].wheelSize = restructuredSelectedAssembly[0].frontTireDiameter;
            restructuredSelectedAssembly[0].currentAssembly = true;

            restructuredSelectedAssembly[1].isWinterUse = selection === constants.YES;
            restructuredSelectedAssembly[1].assemblyType = constants.VEHICLE_WTR_ASSEMBLY_TYPE;
            restructuredSelectedAssembly[1].assemblyId = existingWinterAssembly ? existingWinterAssembly.assemblyItem.assemblyId : constants.EMPTY_STRING;
            restructuredSelectedAssembly[1].wheelSize = restructuredSelectedAssembly[1].rearTireDiameter;
            restructuredSelectedAssembly[1].currentAssembly = true;
          } else {
            restructuredSelectedAssembly.isWinterUse = selection === constants.YES;
            restructuredSelectedAssembly.assemblyType = constants.VEHICLE_WTR_ASSEMBLY_TYPE;
            restructuredSelectedAssembly.assemblyId = existingWinterAssembly ? existingWinterAssembly.assemblyItem.assemblyId : constants.EMPTY_STRING;
            restructuredSelectedAssembly.currentAssembly = true;
          }

          restructuredAssemblyCollection.unshift({
            assemblyItem: Array.isArray(restructuredSelectedAssembly) ? restructuredSelectedAssembly.filter((rac, i) => {
              if (i !== 0) return;
              return ({ ...rac });

            })[0] : restructuredSelectedAssembly
          });
        } else {
          restructuredAssemblyCollection.forEach((ac, i) => {
            if (ac.assemblyItem.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE) {
              if (ac.assemblyItem.sizeQualifier === '0' || isNullEmptyUndefined(ac.assemblyItem.sizeQualifier)) {
                restructuredSelectedAssembly = restructuredAssemblyCollection.filter(rac => rac.assemblyItem.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE)[0].assemblyItem;
              } else if (!isNullEmptyUndefined(ac.assemblyItem.isAfterMarket) && ac.assemblyItem.isAfterMarket !== '0') {
                restructuredSelectedAssembly = restructuredAssemblyCollection.filter(rac => rac.assemblyItem.assemblyType === constants.VEHICLE_ALT_ASSEMBLY_TYPE)[0].assemblyItem;
              }

              if (restructuredSelectedAssembly.frontTireWidth !== restructuredSelectedAssembly.rearTireWidth) {
                restructuredSelectedAssembly = [JSON.parse(JSON.stringify(restructuredSelectedAssembly)), JSON.parse(JSON.stringify(restructuredSelectedAssembly))];
              }

              // add necessary fields for selectedAssembly
              if (Array.isArray(restructuredSelectedAssembly)) {
                restructuredSelectedAssembly.forEach((rsa, i) => {
                  if (i === 0) {
                    rsa.tireSize = `${rsa.frontTireWidth}/${rsa.frontTireAspectRatio} R${rsa.frontTireDiameter}`;
                    rsa.wheelSize = rsa.frontTireDiameter;
                  }

                  if (i === 1) {
                    rsa.tireSize = `${rsa.rearTireWidth}/${rsa.rearTireAspectRatio} R${rsa.rearTireDiameter}`;
                    rsa.wheelSize = rsa.rearTireDiameter;
                  }

                  rsa.isOE = ac.assemblyItem.sizeQualifier === '0';
                  rsa.isAfterMarket = ac.assemblyItem.sizeQualifier !== '0';
                  rsa.assemblyType = ac.assemblyItem.sizeQualifier === '0' ? constants.VEHICLE_OE_ASSEMBLY_TYPE : constants.VEHICLE_ALT_ASSEMBLY_TYPE;
                  rsa.isWinterUse = false;
                  rsa.sizeQualifier = ac.assemblyItem.sizeQualifier;
                  rsa.currentAssembly = true;
                });
              } else {
                restructuredSelectedAssembly.tireSize = `${ac.assemblyItem.frontTireWidth}/${ac.assemblyItem.frontTireAspectRatio} R${ac.assemblyItem.frontTireDiameter}`;
                restructuredSelectedAssembly.wheelSize = ac.assemblyItem.frontTireDiameter;
                restructuredSelectedAssembly.isOE = ac.assemblyItem.sizeQualifier === '0';
                restructuredSelectedAssembly.isAfterMarket = ac.assemblyItem.sizeQualifier !== '0';
                restructuredSelectedAssembly.assemblyType = ac.assemblyItem.sizeQualifier === '0' ? constants.VEHICLE_OE_ASSEMBLY_TYPE : constants.VEHICLE_ALT_ASSEMBLY_TYPE;
                restructuredSelectedAssembly.isWinterUse = false;
                restructuredSelectedAssembly.sizeQualifier = ac.assemblyItem.sizeQualifier;
                restructuredSelectedAssembly.currentAssembly = true;
              }

            }
          });
        }
        setStandardVehicleFields({
          ...standardVehicleFields,
          assemblyCollection: restructuredAssemblyCollection,
          selectedAssembly: restructuredSelectedAssembly
        });
        break;
      case constants.ADD_NEW_VEHICLE_CARRY_OUT:
        setStandardVehicleFields({
          ...standardVehicleFields,
          isCarryOut: !standardVehicleFields.isCarryOut,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // set if assembly is staggered

    const tempArr = [...vehicleBreadCrumbs];

    // reset breadcrumbs
    tempArr.map(c => {
      c.active = false;
      c.filled = false;
    });

    // establishes breadcrumb status based on filled out data from add new vehicle fields object
    let found = false;
    tempArr.forEach((c, index) => {
      if (found) {
        tempArr[index].active = false;
      } else if (!checkIfObjectHasKey(standardVehicleFields, c.name.toLowerCase().replace(' ', ''))) {
        if (c.name === 'Tire Size' && Object.keys(standardVehicleFields.selectedAssembly).length > 0) {
          tempArr[index].filled = true;
          tempArr[index].active = false;
        } else {
          tempArr[index].active = true;
          found = true;
        }
      } else {
        tempArr[index].filled = true;
        tempArr[index].active = false;
      }
    });

    // if everything filled out and user hasn't advanced to last screen
    if (!found && !showAdditionalVehicleInfo) {
      tempArr[4].filled = true;
      tempArr[4].active = false;
    } else if (found) {
      setShowAdditionalVehicleInfo(false);
    }

    // checks if trim has lift
    if (checkIfObjectHasKey(standardVehicleFields, 'trim') &&
      !checkIfObjectHasKey(standardVehicleFields, 'trimLift') &&
      VehicleData[0].vehicles.filter(c => c.year === standardVehicleFields.year?.label &&
        c.make === standardVehicleFields.make?.label &&
        c.model === standardVehicleFields.model?.label &&
        c.trim === standardVehicleFields.trim?.name?.label &&
        checkIfObjectHasKey(c, 'trimLift')).length > 0) {
      tempArr.forEach(c => {
        if (c.name === constants.BREADCRUMB_TRIM) {
          c.active = true;
        } else {
          c.active = false;
        }
      });
    }
    setVehicleBreadCrumbs(tempArr);
  }, [currentBreadCrumb, showAdditionalVehicleInfo]);

  return (
    <WizardContainer >
      <VehicleBaseInfoContainer isStaggered={isAssemblyStaggered} isEdit={isEdit}>
        <VehicleBaseInfoYearMake >
          {`${standardVehicleFields.year?.label || constants.EMPTY_STRING} ${standardVehicleFields.make?.label || constants.EMPTY_STRING}`}
        </VehicleBaseInfoYearMake>
        <VehicleBaseInfoModel>
          {`${standardVehicleFields.model?.label || constants.EMPTY_STRING} ${standardVehicleFields.trim || constants.EMPTY_STRING}`}
        </VehicleBaseInfoModel>
        {standardVehicleFields.trimLift && <LightHorizontalDivider />}
        <VehicleBaseInfoTrim>
          {standardVehicleFields.trimLift}
        </VehicleBaseInfoTrim>
        {(currentBreadCrumb === 5 && !isEdit) &&
              (
                isAssemblyStaggered
                  ? (
                    <>
                      <TireInfoContainer isStaggered={isAssemblyStaggered}>
                        <BadgeWrapper>
                          <Badge name={standardVehicleFields.selectedAssembly[0]?.isWinterUse ? constants.VEHICLE_WTR_LABEL : standardVehicleFields.selectedAssembly[0]?.isAfterMarket ? constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL : constants.VEHICLE_OE_LABEL}>{standardVehicleFields.selectedAssembly[0]?.isWinterUse ? constants.VEHICLE_WTR_LABEL : standardVehicleFields.selectedAssembly[0]?.isAfterMarket ? standardVehicleFields.selectedAssembly[0]?.sizeQualifier : constants.VEHICLE_OE_LABEL}</Badge>
                        </BadgeWrapper>
                        <TireSizeWrapper>
                          <TireSize>{standardVehicleFields.selectedAssembly[0].tireSize}</TireSize>
                          <TireSizeLabel>{constants.VEHICLE_FACTORY_TIRE_SIZE_FRONT_LABEL}</TireSizeLabel>
                        </TireSizeWrapper>
                        <SmVerticalBar>&nbsp;</SmVerticalBar>
                        <TireSizeWrapper>
                          <WSize>{standardVehicleFields.selectedAssembly[0].wheelSize}{constants.INCHES_ABBR}</WSize>
                          <WSizeLabel>{constants.VEHICLE_WHEELS_LABEL}</WSizeLabel>
                        </TireSizeWrapper>
                      </TireInfoContainer>
                      <TireInfoContainer>
                        <BadgeWrapper>
                          <Badge name={standardVehicleFields.selectedAssembly[1]?.isWinterUse ? constants.VEHICLE_WTR_LABEL : standardVehicleFields.selectedAssembly[1]?.isAfterMarket ? constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL : constants.VEHICLE_OE_LABEL}>{standardVehicleFields.selectedAssembly[1]?.isWinterUse ? constants.VEHICLE_WTR_LABEL : standardVehicleFields.selectedAssembly[1]?.isAfterMarket ? standardVehicleFields.selectedAssembly[1]?.sizeQualifier : constants.VEHICLE_OE_LABEL}</Badge>
                        </BadgeWrapper>
                        <TireSizeWrapper>
                          <TireSize>{standardVehicleFields.selectedAssembly[1].tireSize}</TireSize>
                          <TireSizeLabel>{constants.VEHICLE_FACTORY_TIRE_SIZE_REAR_LABEL}</TireSizeLabel>
                        </TireSizeWrapper>
                        <SmVerticalBar>&nbsp;</SmVerticalBar>
                        <TireSizeWrapper>
                          <WSize>{standardVehicleFields.selectedAssembly[1].wheelSize}{constants.INCHES_ABBR}</WSize>
                          <WSizeLabel>{constants.VEHICLE_WHEELS_LABEL}</WSizeLabel>
                        </TireSizeWrapper>
                      </TireInfoContainer>
                    </>
                  )
                  : (
                    <TireInfoContainer>
                      <BadgeWrapper>
                        <Badge name={standardVehicleFields.selectedAssembly.isWinterUse ? constants.VEHICLE_WTR_LABEL : standardVehicleFields.selectedAssembly.isAfterMarket ? constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL : constants.VEHICLE_OE_LABEL}>{standardVehicleFields.selectedAssembly.isWinterUse ? constants.VEHICLE_WTR_LABEL : standardVehicleFields.selectedAssembly.isAfterMarket ? standardVehicleFields.selectedAssembly.sizeQualifier : constants.VEHICLE_OE_LABEL}</Badge>
                      </BadgeWrapper>
                      <TireSizeWrapper>
                        <TireSize>{standardVehicleFields.selectedAssembly.tireSize}</TireSize>
                        <TireSizeLabel>{constants.VEHICLE_FACTORY_TIRE_SIZE_LABEL}</TireSizeLabel>
                      </TireSizeWrapper>
                      <SmVerticalBar>&nbsp;</SmVerticalBar>
                      <TireSizeWrapper>
                        <WSize>{standardVehicleFields.selectedAssembly.wheelSize}{constants.INCHES_ABBR}</WSize>
                        <WSizeLabel>{constants.VEHICLE_WHEELS_LABEL}</WSizeLabel>
                      </TireSizeWrapper>
                    </TireInfoContainer>
                  )
              )}
      </VehicleBaseInfoContainer>
      {
            currentBreadCrumb === 5 && isEdit && <AssemblyDropdown setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields} isEdit={isEdit} isStaggered={isAssemblyStaggered} isDually={standardVehicleFields.isDually} />
          }
      {currentBreadCrumb === 5 && (
        <VehicleQuestionContainer isStaggered={isAssemblyStaggered} isEdit={isEdit}>
          {!isEdit && (
          <ToggleLabelContainer isStaggered={isAssemblyStaggered}>
            <OneTimeUseQuestionLabel>{constants.ADD_NEW_VEHICLE_ONE_TIME_USE}</OneTimeUseQuestionLabel>
            <ToggleWrapper data-testid="oneTimeUseLabel">
              <Toggle
                wrapperWidth={100}
                active={standardVehicleFields.isOneTime}
                name={constants.YES}
                position={constants.LEFT}
                size={constants.TOGGLE_SIZE_SMALL}
                toggleClick={(e) => toggleClick(e, constants.ADD_NEW_VEHICLE_ONE_TIME_USE)}
                    />
              <Toggle
                wrapperWidth={100}
                active={!standardVehicleFields.isOneTime}
                name={constants.NO}
                position={constants.RIGHT}
                size={constants.TOGGLE_SIZE_SMALL}
                toggleClick={(e) => toggleClick(e, constants.ADD_NEW_VEHICLE_ONE_TIME_USE)}
                    />
            </ToggleWrapper>
          </ToggleLabelContainer>
          )}
          <ToggleLabelContainer>
            <WinterUseQuestionLabel>{constants.ADD_NEW_VEHICLE_WINTER_USE}</WinterUseQuestionLabel>
            <ToggleWrapper data-testid="winterUseLabel">
              <Toggle
                wrapperWidth={100}
                active={Array.isArray(standardVehicleFields.selectedAssembly) ? standardVehicleFields.selectedAssembly[0].isWinterUse : standardVehicleFields.selectedAssembly.isWinterUse}
                name={constants.YES}
                position={constants.LEFT}
                size={constants.TOGGLE_SIZE_SMALL}
                toggleClick={(e) => toggleClick(e, constants.ADD_NEW_VEHICLE_WINTER_USE)}
                  />
              <Toggle
                wrapperWidth={100}
                active={Array.isArray(standardVehicleFields.selectedAssembly) ? !standardVehicleFields.selectedAssembly[0].isWinterUse : !standardVehicleFields.selectedAssembly.isWinterUse}
                name={constants.NO}
                position={constants.RIGHT}
                size={constants.TOGGLE_SIZE_SMALL}
                toggleClick={(e) => toggleClick(e, constants.ADD_NEW_VEHICLE_WINTER_USE)}
                  />
            </ToggleWrapper>
          </ToggleLabelContainer>
          <ToggleLabelContainer>
            <WinterUseQuestionLabel>{constants.ADD_NEW_VEHICLE_CARRY_OUT}</WinterUseQuestionLabel>
            <ToggleWrapper data-testid="carryOutLabel">
              <Toggle
                wrapperWidth={100}
                active={standardVehicleFields.isCarryOut}
                name={constants.YES}
                position={constants.LEFT}
                size={constants.TOGGLE_SIZE_SMALL}
                toggleClick={(e) => toggleClick(e, constants.ADD_NEW_VEHICLE_CARRY_OUT)}
              />
              <Toggle
                wrapperWidth={100}
                active={!standardVehicleFields.isCarryOut}
                name={constants.NO}
                position={constants.RIGHT}
                size={constants.TOGGLE_SIZE_SMALL}
                toggleClick={(e) => toggleClick(e, constants.ADD_NEW_VEHICLE_CARRY_OUT)}
              />
            </ToggleWrapper>
          </ToggleLabelContainer>
        </VehicleQuestionContainer>
      )}
      {!isEdit && <LightHorizontalDivider />}
      <BreadCrumbContent arChargeFields={arChargeFields} setCurrentBreadCrumb={setCurrentBreadCrumb} errorMessage={errorMessage} revertBackToManualEntry={revertBackToManualEntry} isEdit={isEdit} setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields}/>
    </WizardContainer>
  );
};

export default VehicleWizard;
