import { useContext, useEffect, useRef, useState } from 'react';
import { CVMContext } from '../../components/CVM/CVM';
import styled from '../../helpers/esm-styled-components';

const ToastrWrapper = styled.div``;

const Toastr = (e) => {
  const toastrRef = useRef();
  const { setToastrOn } = useContext(CVMContext);

  useEffect(() => {
    if (toastrRef?.current) toastrRef.current.classList.add('toastr-bottom');

    const stop = false;
    const opacityInterval = setInterval(() => {
      if(window?.getComputedStyle(toastrRef?.current)?.bottom === "100px" && !stop) {
        toastrRef.current.classList.add('toastr-opacity');
        setTimeout(() => {
          if(toastrRef.current) {
            toastrRef.current.classList.remove('toastr-bottom');
            toastrRef.current.classList.remove('toastr-opacity');
            toastrRef.current.classList.add('toastr');
            clearInterval(opacityInterval);
            setToastrOn(false);
          }
        }, 1500);
      }
    }, 10);
  }, []);

  return (
    <ToastrWrapper ref={toastrRef}>
      Copied to clipboard
    </ToastrWrapper>
  );
};

export default Toastr;
