import { useContext } from 'react';
import { BreadCrumbQuestion } from '../../ModalUtils';
import * as constants from '../../../../constants/Constants';
import Dropdown from '../../../Dropdown/Dropdown';
import styled from '../../../../helpers/esm-styled-components';
import { CVMContext } from '../../../../components/CVM/CVM';
import { VehicleErrorText } from '../BreadCrumbContent';

const YearDropDown = styled(Dropdown)`
  width: 335px;
  position: absolute;
`;

const BreadCrumbYear = (props) => {
  // context
  const { setCurrentBreadCrumb } = useContext(CVMContext);

  // props
  const {
    vehicleErrors, years, standardVehicleFields, setStandardVehicleFields
  } = props;

  /**
  * Dropdown selection handler for Year
  * @param {Object} value
  */
  const updateYear = (value) => {
    const item = years.filter((option) => option.label === value.label);
    if (item) {
      delete standardVehicleFields.make;
      delete standardVehicleFields.model;
      delete standardVehicleFields.trim;
      delete standardVehicleFields.trimId;
      setStandardVehicleFields({ ...standardVehicleFields, year: item[0] });
      setCurrentBreadCrumb(1);
    }
  };

  // Properties for the Year dropdown
  const yearDropdownProps = {
    fieldName: 'year',
    placeholder: 'Year',
    options: years,
    onChange: updateYear,
    selectedOption: standardVehicleFields.year || constants.EMPTY_STRING,
    value: standardVehicleFields.year,
    required: false,
    dropdownHeight: '165',
    isVehicleModal: true
  };

  return (
    vehicleErrors ? <VehicleErrorText>{vehicleErrors.error}</VehicleErrorText> : (
      <>
        <BreadCrumbQuestion>{constants.ADD_NEW_VEHICLE_WHAT_YEAR}</BreadCrumbQuestion>
        <YearDropDown {...yearDropdownProps} />
      </>
    )
  );
};

export default BreadCrumbYear;
