// Error Page Types
export const ERROR_PAGE_TYPE_OKTA = 'Okta';
export const ERROR_PAGE_TYPE_C360 = 'C360';

// Error Page Sub-Types
export const ERROR_PAGE_SUB_TYPE_C360_FAILED_TO_FETCH = 'C360-Failed-To-Fetch';
export const ERROR_PAGE_SUB_TYPE_C360_MISSING_KEYS = 'C360-Missing-Keys';
export const ERROR_PAGE_SUB_TYPE_C360_BLACKLIST_PHONE_NUMBER = 'C360-Bad-Request';

// Error Page Messages
export const ERROR_PAGE_MESSAGE_DEFAULT = 'Oops! Something went wrong';
export const ERROR_PAGE_MESSAGE_C360_FAILED_TO_FETCH = 'Failed to talk to the backend. Please verify you\'re on the Discount Tire network.';
export const ERROR_PAGE_MESSAGE_C360_MISSING_KEYS = 'There was an issue passing data to the backend. Please contact the help desk.';
export const ERROR_PAGE_MESSAGE_C360_BAD_REQUEST = 'Bad request! Please ensure all entered data is correct and valid.';
export const ERROR_PAGE_MESSAGE_OKTA = 'Something went wrong with Okta';

// API Messages
export const API_MESSAGE_FAILED_TO_FETCH = 'Failed to fetch';
export const API_MESSAGE_MISSING_KEYS = 'Incomplete customer spec. Missing key(s).';
export const API_MESSAGE_BAD_REQUEST = "bad request (400).";
