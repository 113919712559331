import React, { useState, useEffect } from 'react';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import * as constants from '../../constants/Constants';
import {
  calculateTread, maxTreadDepthHandler, validateDOTNumber, calculateAge,
} from '../../helper/vehicleDetailsHelper';
import Input from '../Input/Input';
import { isNullOrUndefined, } from '../../helper/validationHelper';

const TireTreadContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction === 'reverse' ? 'row-reverse;' : constants.EMPTY_STRING)};
  align-items: center;
  position: relative;
  margin-top: 20px;
  ${({ position }) => {
    switch (position) {
      case 'LR':
      case 'RR':
        return 'margin-top:95px';
      case 'SP':
        return 'position: absolute;';
      case 'ORR':
      case 'OLR':
        return 'margin:10px 0 0 0';

      case 'IRR':
      case 'ILR':
        return 'margin:30px 0 0 0';
      default:
        return constants.EMPTY_STRING;
    }
  }}
`;
const IMODOTContainer = styled.div`
  height: 50px;
  width: 153px;
`;
const TireTreadAge = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 10px 0 10px;
`;
const IMOContainer = styled.div`
  height: 25px;
  width: inherit;
  display: flex;
  justify-content: space-between;
`;
const IMOLabel = styled.label`
  display: flex;
  line-height: 30px;
  color: ${theme.colors.lightGray};
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.infoSubtitle};
  align-self: center;
  margin-right: 5px !important;
  margin-left: -8px;
`;
const IMOText = styled.div`
  font-family: ${theme.fontFamily.normal};
  color: ${theme.colors.lightGray2};
  margin-left: 5px;
`;
const IMOInput = styled(Input)`
  width: 30px;
  z-index: 2;
  margin: 0 0 8px 2px;
`;
const DOTContainer = styled.div`
  font-size: ${theme.fontSize.dot};
  font-family: ${theme.fontFamily.normal};
  color: ${theme.colors.lightGray};
  box-sizing: border-box;
  height: 25px;
  line-height: 22px;
  width: 145px;
  border: 1px solid #666666;
  border-radius: 2px;
  background-color: #ffffff;
  margin: 6px 0 0 2px;
  display: flex;
  justify-content: center;
  text-align: left;
  margin-left: 9px;
  padding-left: 3px;
`;
const DOTInput = styled(Input)`
  color: ${theme.colors.lightGray};
  font-family: ${theme.fontFamily.normal};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  border: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
`;

// TireStatus
const MinimumTireTread = styled.h1`
  box-sizing: border-box;
  height: 58.5px;
  width: 58.5px;
  border: 3px solid;
  font-family: ${theme.fontFamily.bold};
    ${(props) => (props.color ? props.color : theme.colors.darkGray)};
  color: ${(props) => (props.color ? props.color : theme.colors.darkGray)};
  background-color: #ffffff;
  border-radius: 100%;
  text-align: center;
  padding-top: 5px;
  margin: 0;
`;
const TireAge = styled.div`
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.normal};
  color: ${theme.colors.lightGray};
  text-align: center;
  margin-top: 3px;
`;
const Age = styled.label`
  color: ${theme.colors.darkGray};
  font-family: ${theme.fontFamily.bold};
`;
const Joiner = styled.hr`
  position: absolute;
  width: 33px;
  top: 36px;
  border: 1px solid ${theme.colors.darkGray};
  background-color: ${theme.colors.darkGray};
  ${({ position }) => {
    switch (position) {
      case 'LF':
        return 'left: 220px;';
      case 'RF':
        return 'left: -86px;';
      case 'LR':
        return 'left:220px;';
      case 'RR':
        return 'left:-86px;';
      case 'SP':
        return 'top:-8px; right:130px; width:60px;';
      case 'OLR':
        return 'top: 40px;left: 220px; width:25px;';
      case 'IRR':
        return 'top: 45px;left: -108px; width:55px;';
      case 'ILR':
        return 'top: 45px;left: 220px; width:55px;';
      case 'ORR':
        return 'top: 40px;left:  -80px;  width:25px;';
      default:
        return constants.EMPTY_STRING;
    }
  }}
`;
const VerticalJoiner = styled.hr`
  position: absolute;
  height: 28px;
  width: 2px;
  border: 1.5px solid ${theme.colors.darkGray};
  ${({ position }) => {
    switch (position) {
      case 'SP':
        return 'left:40px; top:-8px; height:16px;';
      case 'OLR':
        return 'left:245px; top:30px; height: 10px;';
      case 'IRR':
        return 'top: 45px;left: -108px; height:16px';
      case 'ILR':
        return 'top: 45px;left: 274px; height:16px';
      case 'ORR':
        return 'top: 30px;left: -80px;height: 10px;';
      default:
        return constants.EMPTY_STRING;
    }
  }}
`;

export const TireTread = (props) => {
  const {
    position, treads, age, setTreadDetails, tireIndex, dot
  } = props;
  const treadLocations = ['Inner', 'Middle', 'Outer'];
  const getTreadReading = (index) => {
    return treads?.filter(tread => tread.treadDepthItem.location === treadLocations[index])[0]?.treadDepthItem?.reading || constants.EMPTY_STRING;
  };
  const tireTread = {
    inner: getTreadReading(0) || constants.EMPTY_STRING,
    middle: getTreadReading(1) || constants.EMPTY_STRING,
    outer: getTreadReading(2) || constants.EMPTY_STRING,
  };
  const [minTread, setMinTread] = useState({
    min: null, color: constants.EMPTY_STRING
  });
  useEffect(() => {
    const {
      inner, middle, outer
    } = tireTread;
    let result = !isNullOrUndefined(inner, middle, outer);
    result ? result = calculateTread(inner, middle, outer)
      : result = setMinTread({ min: null, color: constants.EMPTY_STRING });
    result?.minimum && setMinTread({ min: result.minimum.min, color: result.minimum.color });
    if (dot?.length > 3 && validateDOTNumber(dot)) {
      setTreadDetails(calculateAge(dot).toString(), `${tireIndex}tireAge`);
    } else {
      setTreadDetails(constants.EMPTY_STRING, `${tireIndex}tireAge`);
    }
  }, [tireTread.inner,
    tireTread.middle,
    tireTread.outer, dot]);

  const getTreadIndex = (tread, i) => {
    const treadLocations = ['Inner', 'Middle', 'Outer'];
    if (tread.treadDepthItem.location === treadLocations[0]
      || tread.treadDepthItem.location === treadLocations[1]
            || tread.treadDepthItem.location === treadLocations[2]) return i;
  };

  const TreadDotSize = (dot) => {
    if (!dot) return '14px';
    if (dot.length >= 8 && dot.length <= 12) return '10px';
    if (dot.length >= 13 && dot.length <= 15) return '9px';
    if (dot.length > 15) return '5.5px';
    return '14px';
  };

  return (
    <TireTreadContainer
      direction={
        position === 'SP'
          || position === 'RF'
          || position === 'RR'
          || position === 'IRR'
          || position === 'ORR'
          ? 'reverse'
          : constants.EMPTY_STRING
      }
      position={position}
    >
      <IMODOTContainer>
        <IMOContainer>
          <IMOLabel>
            {treads?.map((tread, i) => {
              const indexInObj = getTreadIndex(tread, i);
              return   (
                <>
                  <>
                    <IMOText>{treads[indexInObj].treadDepthItem.location.slice(0, 1).toUpperCase()}:</IMOText>
                  </>
                  <IMOInput
                    fieldName={`${tireIndex}${indexInObj}`}
                    inputValue={treads[indexInObj]?.treadDepthItem.reading}
                    errorMessages={[]}
                    warningMessages={[]}
                    inputHandler={maxTreadDepthHandler(setTreadDetails)}
                    tabIndex={0}
                    height="30px"
                    textAlign="center"
                    padding="0"
                    maxLength={2}
              />
                </>
              );
            })}
          </IMOLabel>
        </IMOContainer>
        <DOTContainer>
          DOT#
          <DOTInput
            fieldName={`${tireIndex}dotNumber`}
            inputValue={dot}
            errorMessages={[]}
            warningMessages={[]}
            inputHandler={setTreadDetails}
            tabIndex={0}
            height="17px"
            padding="11px 0px 9px 2px"
            fontSize={TreadDotSize(dot)}
            border="none"
            maxLength={17}
            noFocusOutline
            />
        </DOTContainer>
      </IMODOTContainer>
      <TireTreadAge>
        <MinimumTireTread position={position} color={minTread?.color}>
          {minTread?.min}
        </MinimumTireTread>
        <TireAge position={position}>
          {!isNullOrUndefined(age) ? (
            <>
              Age: <Age>{age}</Age>
            </>
          ) : (
            <>
              <Age>&nbsp;</Age>
            </>
          )}
        </TireAge>
      </TireTreadAge>
      <Joiner position={position} />
      {(position === 'SP'
        || position === 'OLR'
        || position === 'ILR'
        || position === 'IRR'
        || position === 'ORR') && <VerticalJoiner position={position} />}
    </TireTreadContainer>
  );
};

export default TireTread;
