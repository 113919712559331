import {
  AddAdditionalVehicleInfo, ContentContainer, HorizontalDivider, ReturnToCustomerSummary, WizardButtons
} from '../../ModalUtils';
import styled from '../../../../helpers/esm-styled-components';
import { theme } from '../../../../helpers/GlobalStyles';
import * as constants from '../../../../constants/Constants';
import { isNullEmptyUndefined } from '../../../../helper/validationHelper';

const VehVerTitle = styled.p`
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.modalTitle};
  color: ${theme.colors.obsidian};
`;

const VehVerSubTitle = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.titleBlack};
  margin-bottom: 25px;
`;

const VehVerContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 25px;
  align-items: center;
`;

const VehVerContentLabel = styled.p`
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.titleBlack};
`;

const VehVerEntered = styled.div``;

const VehVerIHS = styled.div``;

const VehVerWizardButtons = styled(WizardButtons)`
  grid-gap: 0;
`;

const VehVerKeepVehicleButton = styled(ReturnToCustomerSummary)`
  grid-area: cancel;
  margin-left: 5px;
`;

const VehVerContinueIHSButton = styled(AddAdditionalVehicleInfo)`
  grid-area: confirm;
`;

const VehVerYear = styled.p`
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.xLarge};
  margin-bottom: 0;
  text-transform: uppercase;
  color: ${theme.colors.darkGray};
  background-color: ${({ hasCorrection }) => hasCorrection && 'yellow'};
  width: fit-content;
  display: inline-flex;
  margin-top: 0;
  margin-right: 5px;
`;

const VehVerMake = styled.p`
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.xLarge};
  margin-bottom: 0;
  text-transform: uppercase;
  color: ${theme.colors.darkGray};
  background-color: ${({ hasCorrection }) => hasCorrection && 'yellow'};
  display: inline-flex;
  margin-top: 0;
  width: fit-content;
`;

const VehVerModel = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.infoSubtitle};
  background-color: ${({ hasCorrection }) => hasCorrection && 'yellow'};
  margin-top: 0;
  color: ${theme.colors.titleBlack};
  margin-bottom: 6px;
  display: inline-flex;
  margin-right: 5px;
  width: fit-content;
`;

const VehVerTrim = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.infoSubtitle};
  background-color: ${({ hasCorrection }) => hasCorrection && 'yellow'};
  margin-top: 0;
  color: ${theme.colors.titleBlack};
  margin-bottom: 6px;
  display: inline;
  width: fit-content;
`;

const VehVerVIN = styled.p`
  &:before {
    content: "VIN: ";
    font-weight: normal;
  };
  font-weight: bold;
  margin-bottom: 5px;
  background-color: ${({ hasCorrection }) => hasCorrection && 'yellow'};
`;

const VehVerLicense = styled.p`
  &:before {
    content: "License plate: ";
    font-weight: normal;
  };
  font-weight: bold;
  margin-top: 0;
  display: ${({ show }) => (show ? 'inline-block' : 'none')};
`;

const LineBreak = styled.br``;

const VehFitmentMatchError = styled.div`
  width: fit-content;
  font-weight: ${theme.fontWeight.bold};
  color: transparent;
`;

const VehicleIHSVerification = (props) => {
  // props
  const {
    isEdit, standardVehicleFields, correctedVehicle, keepVehicleAsEnteredHandler, ihsSave, continueWithIHS,
  } = props;
  const titleIhsFound = 'Please consider the IHS vehicle suggestion below';
  const titleIhsNotFound = 'Vehicle could not be validated by VIN or License Plate How would you like to proceed';
  return (
    <ContentContainer>
      <VehVerTitle>Vehicle Verification</VehVerTitle>
      <VehVerSubTitle>{correctedVehicle?.vehicle ? titleIhsFound : titleIhsNotFound}</VehVerSubTitle>
      <VehVerContent>
        <VehVerEntered>
          <VehVerContentLabel>Vehicle as entered:</VehVerContentLabel>
          <VehVerYear>{standardVehicleFields.year?.label}</VehVerYear>
          <VehVerMake>{standardVehicleFields.make?.label}</VehVerMake>
          <LineBreak />
          <VehVerModel>{standardVehicleFields.model?.label}</VehVerModel>
          <VehVerTrim>{standardVehicleFields.trim}</VehVerTrim>
          <VehVerVIN>{standardVehicleFields.vin?.toString().toUpperCase() || constants.NOT_APPLICABLE}</VehVerVIN>
          <VehVerLicense show={!isNullEmptyUndefined(standardVehicleFields.licensePlate)}>{standardVehicleFields.licensePlate?.toString().toUpperCase() || constants.EMPTY_STRING}</VehVerLicense>
        </VehVerEntered>
        { !correctedVehicle.vehicle ? <VehFitmentMatchError>{correctedVehicle.error}</VehFitmentMatchError> : (
          <VehVerIHS>
            <VehVerContentLabel>IHS Corrected Vehicle:</VehVerContentLabel>
            <VehVerYear hasCorrection={correctedVehicle.correction().includes('year')}>{correctedVehicle.vehicle.year}</VehVerYear>
            <VehVerMake hasCorrection={correctedVehicle.correction().includes('make')}>{correctedVehicle.vehicle.make}</VehVerMake>
            <LineBreak />
            <VehVerModel hasCorrection={correctedVehicle.correction().includes('model')}>{`${correctedVehicle.vehicle.model}`}</VehVerModel>
            <VehVerTrim>{`${correctedVehicle.vehicle.trim || correctedVehicle.vehicle.trimDescription || constants.EMPTY_STRING}`}</VehVerTrim>
            <VehVerVIN>{correctedVehicle.vehicle.vin?.toString().toUpperCase()}</VehVerVIN>
            <VehVerLicense show={!isNullEmptyUndefined(standardVehicleFields.licensePlate)}>{standardVehicleFields.licensePlate?.toString().toUpperCase()}</VehVerLicense>
          </VehVerIHS>
        ) }
      </VehVerContent>
      <HorizontalDivider lightGray />
      {correctedVehicle?.vehicle ? (
        <VehVerWizardButtons margin="35px 0 0 0">
          <VehVerKeepVehicleButton buttonType={constants.CANCEL_BUTTON} buttonName={isEdit ? constants.BUTTON_EDIT_VEHICLE_AS_ENTERED : constants.BUTTON_KEEP_VEHICLE_AS_ENTERED} isDisabled={false} onButtonClick={keepVehicleAsEnteredHandler} />
          <VehVerContinueIHSButton buttonType={constants.DEFAULT} buttonName={isEdit ? constants.BUTTON_SAVE_WITH_CORRECTED_VEHICLE : constants.BUTTON_CONTINUE_WITH_CORRECTED_VEHICLE} isDisabled={!correctedVehicle.vehicle} onButtonClick={!correctedVehicle.vehicle ? () => {} : () => continueWithIHS()} />
        </VehVerWizardButtons>
      ) : (
        <VehVerWizardButtons margin="35px 0 0 0">
          <VehVerKeepVehicleButton buttonType={constants.CANCEL_BUTTON} buttonName={constants.BUTTON_EDIT_VIN_LICENSE_PLATE} isDisabled={false} onButtonClick={keepVehicleAsEnteredHandler} />
          <VehVerContinueIHSButton buttonType={constants.DEFAULT} buttonName={constants.BUTTON_KEEP_VEHICLE_AS_ENTERED} onButtonClick={ihsSave} />
        </VehVerWizardButtons>
      )}

    </ContentContainer>
  );
};

export default VehicleIHSVerification;
