import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';

const WheelContainer = styled.div`
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -1px;
  ${({ selected }) => (selected
    ?
    `border-left: 1px solid ${theme.colors.titleBlack};
      border-top: 1px solid ${theme.colors.titleBlack};
      border-right: 1px solid ${theme.colors.titleBlack};
      border-bottom: 1px solid ${theme.colors.opaque};`
    :
    `border-left: 1px solid ${theme.colors.titleBlack};
      border-top: 1px solid ${theme.colors.titleBlack};
      border-right: 1px solid ${theme.colors.titleBlack};
      border-bottom: 1px solid ${theme.colors.titleBlack};`)
};
  height: 34px;
  width: 80px;
  text-align: center;
  display: inline-flex;
  grid-template-rows: 1fr 1fr;
  justify-content: space-around;
  margin-right: 2px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? theme.colors.opaque : theme.colors.white)};
`;

const WheelSizeLabel = styled.p`
  font-size: ${theme.fontSize.dot};
  color: ${theme.colors.darkGray};
  font-family: ${theme.fontFamily.bold};
  margin: 6px 0;
`;


const Wheel = (props) => {
  const {
    selected, wheelSize, sizeQualifier, loadAdditionalWheelSizeOptions
  } = props;

  const handleKeyDown = (event, c) => {
    // Check if the pressed key is "Enter" (key code 13) or "Space" (key code 32)
    if (
      (event?.keyCode && event.keyCode === constants.ENTER_KEY_CODE) ||
      (event?.keyCode && event.keyCode === constants.ENTER_SPACE_CODE)
    ) {
      loadAdditionalWheelSizeOptions(event, c);
    }
  };

  return (
    <WheelContainer tabIndex={0} onKeyDown={(e) => handleKeyDown(e, sizeQualifier)} selected={selected} onClick={(e) => loadAdditionalWheelSizeOptions(e, sizeQualifier)}>
      <WheelSizeLabel>{wheelSize}{constants.INCHES_ABBR}</WheelSizeLabel>
    </WheelContainer>
  );
};

export default Wheel;
