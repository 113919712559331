import React, {
  useContext, useEffect,
} from 'react';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import { CVMContext } from '../../CVM/CVM';
import { DetailSection, DetailVBar } from '../DetailSection';
import VINSearch from '../../../UIComponents/Input/VINSearch';
import LicenseSearch from '../../../UIComponents/Input/LicenseSearch';
import { handleInput, searchBy } from '../../../UIComponents/Input/InputHelper';
import Message from '../../../UIComponents/Error/Message';

const DetailGrid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, 335px);
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'vin vsep plate state'
    'vinsearch vsep country platesearch';
`;

const VerticalSeparator = styled(DetailVBar)`
  grid-area: vsep;
`;
const SearchByVehicle = () => {
  const {
    setSearchOrigin,
    setModuleName,
    setSearchQuery,
    vehicleFields,
    setVehicleFields,
    setErrorMessageObj,
    errorMessageObj,
  } = useContext(CVMContext);

  useEffect(() => {
    if (vehicleFields.vin === constants.EMPTY_STRING) {
      setErrorMessageObj({});
    }
    if (vehicleFields.licenseCountry === constants.EMPTY_STRING) {
      setVehicleFields({
        ...vehicleFields,
        licenseCountry: {
          value: 'USA',
          label: 'United States',
        },
      });
    }
  }, [setVehicleFields, vehicleFields]);

  const getErrorMessage = () => (errorMessageObj.searchBy === constants.SEARCH_ORIGIN_LICENSE_PLATE ||
    errorMessageObj.searchBy === constants.SEARCH_ORIGIN_VIN) &&
    (<Message errorMessages={errorMessageObj.message} type={constants.WARNING} />);
  return (
    <>
      <DetailSection title={constants.TITLE_SEARCH_BY_VEHICLE}>
        {getErrorMessage()}
        <DetailGrid>
          <VINSearch vehicleFields={vehicleFields} handleInput={(value, fieldName) => handleInput(vehicleFields, setVehicleFields, value, fieldName)} searchBy={(e, buttonName) => searchBy(e, buttonName, vehicleFields, setSearchQuery, setModuleName, setSearchOrigin, null, setErrorMessageObj)} />
          <VerticalSeparator />
          <LicenseSearch fields={vehicleFields} setFields={setVehicleFields} handleInput={(value, fieldName) => handleInput(vehicleFields, setVehicleFields, value, fieldName)} searchBy={(e, buttonName) => searchBy(e, buttonName, vehicleFields, setSearchQuery, setModuleName, setSearchOrigin)} />
        </DetailGrid>
      </DetailSection>
    </>
  );
};

export default SearchByVehicle;
