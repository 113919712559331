
export const VehicleData = [
  {
    vehicles: [
      {
        year: 1966,
        make: 'Ford',
        model: 'Mustang',
        trim: {
          name: '2+2'
        },
        isDually: false,
        isStaggered: true
      },
      {
        year: 2016,
        make: 'Honda',
        model: 'Accord',
        trim: {
          name: 'LX'
        },
        vin: '1C3LC56K57N545672',
        license: 'VBM5211',
        licenseState: 'AZ',
        licenseCountry: 'United States',
        isDually: false,
        isStaggered: false
      },
      {
        year: 2016,
        make: 'Jeep',
        model: 'Wrangler',
        trim: {
          name: 'Sport'
        },
        isDually: false,
        isStaggered: false
      },
      {
        year: 2016,
        make: 'Jeep',
        model: 'Wrangler',
        trim: {
          name: 'Altitude'
        },
        isDually: false,
        isStaggered: false
      },
      {
        year: 2021,
        make: 'Chevrolet',
        model: 'Silverado',
        trim: {
          name: 'Extended Cab'
        },
        isDually: true,
        isStaggered: false
      },
      {
        year: 2022,
        make: 'Toyota',
        model: '4Runner',
        trim: {
          name: '4WD',
          details: '2"/4" Drop'
        },
        isDually: false,
        isStaggered: false
      },
      {
        year: 2022,
        make: 'Dodge',
        model: 'Challenger',
        trim: {
          name: 'SRT'
        },
        isDually: false,
        isStaggered: false
      }
    ]
  }
];
