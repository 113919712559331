import {
  useState, useContext, useEffect, useRef
} from 'react';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import { AiOutlinePlus } from 'react-icons/ai';
import { constant } from 'lodash';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';
import {
  OEBadgeWrapper, OEBadge, FactoryTireSize, FactoryTireSizeWrapper, FactoryTireSizeLabel, SmallVerticalBar, WheelSize, WheelSizeLabel,
} from '../../components/Details/Vehicle/VehicleDetails';
import { CVMContext } from '../../components/CVM/CVM';

export const EditAssemblyWrapper = styled.div`
  min-width: 40%;
  width: fit-content;
  position: relative;
  z-index: 200;
  margin-bottom: 50px;
  max-height: 52px;
  margin-top: ${({ isStaggered }) => (isStaggered ? '73px' : '-15px')};
  &:focus {outline:none; border: 1.5px solid ${theme.colors.lightBlue};}
  ${({ isOpen }) => (isOpen && '&:focus {outline:none; border:none;}')}
  background-color: white;
`;

const TireInfoContainer = styled.div`
  position: relative;
  display: inline-flex;
  margin-left: 25px;
  margin-right: 30px;
  margin-top: 5px;
`;
const BadgeWrapper = styled(OEBadgeWrapper)`
  margin: ${({ isWinter }) => (isWinter ? '8px 12px -10px 0' : '8px 9px 0 0')};
  position: relative;
  width: 42px;
  height: 24.19px;
  text-align: center;
`;
const Badge = styled(OEBadge)`
  padding: 5px 7px;
  margin: 0;
  width: fit-content;
  font-size: 10.75px;
`;
const TireSizeWrapper = styled(FactoryTireSizeWrapper)`
  margin: 0;
`;
const TireSize = styled(FactoryTireSize)`
  margin: 3px 0;
  font-size: 16.13px;
`;
export const TireSizeLabel = styled(FactoryTireSizeLabel)`
  margin: -5px 0;
  font-size: 11.65px;
`;
export const SmVerticalBar = styled(SmallVerticalBar)`
  margin: 6px 12px;
  height: 26.88px;
  position:relative;
`;
const WSize = styled(WheelSize)`
  margin: 3px 10px;
  font-size: 16.13px;
`;
const WSizeLabel = styled(WheelSizeLabel)`
  margin: -5px 10px;
  font-size: 11.65px;
`;

export const ArrowIcon = styled.span`
  top:30%;
  right:10px;
  position: absolute;
  color: ${theme.colors.lightGray};
  font-size: 16px;
  text-align: right;
  float: right;
`;

export const DropdownPlaceholder = styled.span`
  position: absolute;
  top: -11px;
  left: 11px;
  font-size:13px;
  background: white;
  padding: 0px 10px;
  letter-spacing: -0.15px;
  line-height: 22px;
  letter-spacing: -0.15px;
`;

export const DropdownItems = styled.ul`
  background-color: white;
  border: 1px solid ${theme.colors.lightGray};
  position: absolute;
  top: 32px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 152px;
  width: 100%;
  padding-left: 0;
  z-index: 10;
  display: ${({ isOpen }) => (isOpen ? 'inherit' : 'none')};
  text-align: left;
`;

export const DropdownItem = styled.li`
min-height: 3rem;
  width: 100%;
  text-align: center;
  list-style: none;
  outline:none;
  height: 50px;
  border: 1px solid ${theme.colors.lightGray};
  background-color:white;
  cursor: pointer;
  ${({ isOpen }) => (!isOpen && 'display: none; outline: none;')}
  ${({ isSelected }) => (isSelected && `border: 1.5px solid ${theme.colors.lightBlue}; outline: none;`)}
  ${({ isStaggered }) => (isStaggered && 'display:flex')}

`;

const AddNewAssemblyLabel = styled.p`
  color: ${theme.colors.editBlue};
  display:flex;
  align-items: center;
  gap: 0.5rem;
  font-size: ${theme.fontSize.medium2};
  justify-content: center;
`;

const AssemblyAddIcon = styled(AiOutlinePlus)`
`;

const AssemblyDropdown = (props) => {
  // props
  const { setStandardVehicleFields, standardVehicleFields, isEdit } = props;

  // context
  const { setCurrentBreadCrumb } = useContext(CVMContext);

  // states
  const { selectedAssembly, assemblyCollection } = standardVehicleFields;

  const isStaggered = Array.isArray(selectedAssembly);
  const [isOpen, setIsOpen] = useState(false);

  // Local dropdown options from assemblies for selection
  const [focusedIndex, setFocusedIndex] = useState(0);
  const dropdownAssemblies = [...assemblyCollection];
  const indexOfCurrent = dropdownAssemblies.findIndex(assembly => (!isStaggered ? assembly.assemblyItem.assemblyId === selectedAssembly.assemblyId
    : assembly.assemblyItem.assemblyId === selectedAssembly[0].assemblyId));
  // Places the current assembly in the beginning of the array for UI purposes
  dropdownAssemblies.splice(indexOfCurrent, 1);
  dropdownAssemblies.unshift({ ...assemblyCollection[indexOfCurrent] });
  dropdownAssemblies.push({ value: constants.ADD_NEW_ASSEMBLY });

  // update the selected assembly
  //
  const updateSelectedAssembly = (event, assembly) => {
    let restructuredAssembly = [];
    // staggered. split into an array for front and rear
    if (assembly.frontTireWidth !== assembly.rearTireWidth) {
      restructuredAssembly.push({
        assemblyCode: assembly.assemblyCode,
        assemblyId: assembly.assemblyId,
        assemblyType: assembly.assemblyType,
        frontRimSize: assembly.frontRimSize,
        frontTireAspectRatio: assembly.frontTireAspectRatio,
        frontTireDiameter: assembly.frontTireDiameter,
        frontTireWidth: assembly.frontTireWidth,
        rearRimSize: assembly.rearRimSize,
        rearTireAspectRatio: assembly.rearTireAspectRatio,
        rearTireDiameter: assembly.rearTireDiameter,
        rearTireWidth: assembly.rearTireWidth,
        sizeQualifier: assembly.sizeQualifier,
        tireSize: assembly.tireSize[0],
        wheelSize: assembly.wheelSize[0],
        isOE: assembly.isOE || false,
        isAfterMarket: assembly.isAfterMarket || false,
        isWinterUse: assembly.isWinterUse || false,
      },
      {
        assemblyCode: assembly.assemblyCode,
        assemblyId: assembly.assemblyId,
        assemblyType: assembly.assemblyType,
        frontRimSize: assembly.frontRimSize,
        frontTireAspectRatio: assembly.frontTireAspectRatio,
        frontTireDiameter: assembly.frontTireDiameter,
        frontTireWidth: assembly.frontTireWidth,
        rearRimSize: assembly.rearRimSize,
        rearTireAspectRatio: assembly.rearTireAspectRatio,
        rearTireDiameter: assembly.wheelSize[1],
        rearTireWidth: assembly.rearTireWidth,
        sizeQualifier: assembly.sizeQualifier,
        tireSize: assembly.tireSize[1],
        wheelSize: assembly.wheelSize[1],
        isOE: assembly.isOE || false,
        isAfterMarket: assembly.isAfterMarket || false,
        isWinterUse: assembly.isWinterUse || false,
      });
    } else {
      restructuredAssembly = {
        assemblyCode: assembly.assemblyCode,
        assemblyId: assembly.assemblyId,
        assemblyType: assembly.assemblyType,
        frontRimSize: constants.EMPTY_STRING,
        frontTireAspectRatio: assembly.frontTireAspectRatio,
        frontTireDiameter: assembly.frontTireDiameter,
        frontTireWidth: assembly.frontTireWidth,
        rearRimSize: constants.EMPTY_STRING,
        rearTireAspectRatio: assembly.rearTireAspectRatio,
        rearTireDiameter: assembly.rearTireDiameter,
        rearTireWidth: assembly.rearTireWidth,
        sizeQualifier: assembly.sizeQualifier,
        tireSize: assembly.tireSize,
        wheelSize: assembly.wheelSize,
        isOE: assembly.isOE || false,
        isAfterMarket: assembly.isAfterMarket || false,
        isWinterUse: assembly.isWinterUse || false,
      };
    }
    setStandardVehicleFields({
      ...standardVehicleFields,
      selectedAssembly: restructuredAssembly
    });
  };
  const handleKeyDown = (event) => {
    event.preventDefault();
    // Event key listeners for selecting the assembly
    if (event.keyCode === constants.ENTER_KEY_CODE) {
      if (focusedIndex === dropdownAssemblies.length - 1) { setCurrentBreadCrumb(4); return; }
      if (focusedIndex !== 0) {
        updateSelectedAssembly(event, dropdownAssemblies[focusedIndex].assemblyItem);
      }
      setFocusedIndex(0);
      setIsOpen(!isOpen);
    }
    if (event.keyCode === constants.DOWN_ARROW_KEY_CODE && isOpen) {
      if (focusedIndex === dropdownAssemblies.length - 1) return;
      setFocusedIndex(focusedIndex + 1);
    }
    if (event.keyCode === constants.UP_ARROW_KEY_CODE && isOpen) {
      setFocusedIndex(focusedIndex !== 0  ? focusedIndex - 1 : 0);
    }
    if (event.keyCode === constants.TAB_KEY_CODE) {
      // Move focus to the next focusable element in the normal tab order
      const focusableElements = document.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const currentIndex = Array.from(focusableElements).indexOf(document.activeElement);
      const nextIndex = (currentIndex + 1) % focusableElements.length;
      const nextFocus = focusableElements[nextIndex];
      nextFocus.focus();
    }
  };
  const hasEqualAssemblyObjects = (firstAssembly, secondAssembly) => firstAssembly.assemblyItem.rearTireWidth === secondAssembly.rearTireWidth
                  && firstAssembly.assemblyItem.rearTireAspectRatio === secondAssembly.rearTireAspectRatio
                  && firstAssembly.assemblyItem.rearTireDiameter === secondAssembly.rearTireDiameter
                  && firstAssembly.assemblyItem.assemblyCode === secondAssembly.assemblyCode;

  return (
    <EditAssemblyWrapper isStaggered={isStaggered} onClick={() => setIsOpen(!isOpen)} tabIndex={0} onKeyDown={handleKeyDown} isOpen={isOpen}>
      {
            dropdownAssemblies?.length > 0 && dropdownAssemblies?.map((c, index) => {
              if (index === dropdownAssemblies.length - 1) {
                return         (
                  <DropdownItem
                    onClick={() => setCurrentBreadCrumb(4)}
                    isSelected={index === focusedIndex && isOpen}
                    isOpen={isOpen}
                    >
                    <AddNewAssemblyLabel>
                      <AssemblyAddIcon />
                      {constants.ADD_NEW_ASSEMBLY}
                    </AddNewAssemblyLabel>
                  </DropdownItem>
                );
              }
              /* NOTE: Extracted the match of assembly information  */
              const assembly = c.assemblyItem;
              assembly.isOE = assemblyCollection.some(a => hasEqualAssemblyObjects(a, assembly)) && assembly.assemblyType === constants.VEHICLE_OE_ASSEMBLY_TYPE;
              assembly.isAfterMarket = assemblyCollection.some(a => hasEqualAssemblyObjects(a, assembly)) && assembly.assemblyType === constants.VEHICLE_ALT_ASSEMBLY_TYPE;
              assembly.isWinterUse = assembly.assemblyType === constants.VEHICLE_WTR_ASSEMBLY_TYPE;
              assembly.isStaggered = assembly.frontTireWidth !== assembly.rearTireWidth;
              assembly.tireSize = assembly.frontTireWidth === assembly.rearTireWidth
                ? `${assembly.frontTireWidth}/${assembly.frontTireAspectRatio} R${assembly.frontTireDiameter}`
                : [`${assembly.frontTireWidth}/${assembly.frontTireAspectRatio} R${assembly.frontTireDiameter}`, `${assembly.rearTireWidth}/${assembly.rearTireAspectRatio} R${assembly.rearTireDiameter}`];
              assembly.wheelSize = assembly.frontTireWidth === assembly.rearTireWidth ? assembly.frontTireDiameter : [assembly.frontTireDiameter, assembly.rearTireDiameter];
              return (
                isStaggered
                  ? (
                    <DropdownItem
                      key={`assemblyOption${index + 1}`}
                      onClick={(e) => updateSelectedAssembly(e, assembly)}
                      isSelected={(index === focusedIndex && isOpen)}
                      isOpen={isOpen || index === 0}
                      >
                      <TireInfoContainer isStaggered={isStaggered} isEdit={isEdit}>
                        <BadgeWrapper isEdit={isEdit}>
                          {
                            !assembly.isAfterMarket
                              ? <Badge isEdit={isEdit} name={!assembly.isWinterUse ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}>{!assembly.isWinterUse ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}</Badge>
                              : assembly.isWinterUse
                                ? <Badge isEdit={isEdit} name={constants.VEHICLE_WTR_LABEL}>{constants.VEHICLE_WTR_LABEL}</Badge>
                                : <Badge name={constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL}>{assembly.sizeQualifier}</Badge>
                          }
                        </BadgeWrapper>
                        <TireSizeWrapper isEdit={isEdit}>
                          <TireSize isEdit={isEdit}>{assembly.tireSize[0]}</TireSize>
                          <TireSizeLabel isEdit={isEdit}>{assembly.isAfterMarket ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_FRONT_LABEL : constants.VEHICLE_FACTORY_TIRE_SIZE_FRONT_LABEL}</TireSizeLabel>
                        </TireSizeWrapper>
                        <SmVerticalBar isEdit={isEdit}>&nbsp;</SmVerticalBar>
                        <TireSizeWrapper isEdit={isEdit}>
                          <WSize isEdit={isEdit}>{assembly.wheelSize[0]}{constants.INCHES_ABBR}</WSize>
                          <WSizeLabel isEdit={isEdit}>{constants.VEHICLE_WHEELS_LABEL}</WSizeLabel>
                        </TireSizeWrapper>
                      </TireInfoContainer>
                      <TireInfoContainer isStaggered={isStaggered} isEdit={isEdit}>
                        <BadgeWrapper isEdit={isEdit}>
                          {
                            !assembly.isAfterMarket
                              ? <Badge isEdit={isEdit} name={!assembly.isWinterUse ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}>{!assembly.isWinterUse ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}</Badge>
                              : c.isWinterUse
                                ? <Badge isEdit={isEdit} name={constants.VEHICLE_WTR_LABEL}>{constants.VEHICLE_WTR_LABEL}</Badge>
                                : <Badge name={constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL}>{assembly.sizeQualifier}</Badge>
                          }
                        </BadgeWrapper>
                        <TireSizeWrapper isEdit={isEdit}>
                          <TireSize isEdit={isEdit}>{assembly.tireSize[1]}</TireSize>
                          <TireSizeLabel isEdit={isEdit}>{assembly.isAfterMarket ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_REAR_LABEL : constants.VEHICLE_FACTORY_TIRE_SIZE_REAR_LABEL}</TireSizeLabel>
                        </TireSizeWrapper>
                        <SmVerticalBar isEdit={isEdit}>&nbsp;</SmVerticalBar>
                        <TireSizeWrapper isEdit={isEdit}>
                          <WSize isEdit={isEdit}>{assembly.wheelSize[1]}{constants.INCHES_ABBR}</WSize>
                          <WSizeLabel isEdit={isEdit}>{constants.VEHICLE_WHEELS_LABEL}</WSizeLabel>
                        </TireSizeWrapper>
                      </TireInfoContainer>
                    </DropdownItem>
                  )
                  : (
                    <DropdownItem
                      key={index}
                      onClick={(e) => updateSelectedAssembly(e, assembly)}
                      isSelected={(index === focusedIndex && isOpen)}
                      isOpen={isOpen || index === 0}
                      >
                      <TireInfoContainer isEdit={isEdit}>
                        <BadgeWrapper isEdit={isEdit}>
                          {
                            !assembly.isAfterMarket
                              ? <Badge isEdit={isEdit} name={!assembly.isWinterUse ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}>{!assembly.isWinterUse ? constants.VEHICLE_OE_LABEL : constants.VEHICLE_WTR_LABEL}</Badge>
                              : c.isWinterUse
                                ? <Badge isEdit={isEdit} name={constants.VEHICLE_WTR_LABEL}>{constants.VEHICLE_WTR_LABEL}</Badge>
                                : <Badge name={constants.ADD_NEW_VEHICLE_TIRES_DO_NOT_FIT_LABEL}>{assembly.sizeQualifier || assembly.sizeQualifier}</Badge>
                          }
                        </BadgeWrapper>
                        <TireSizeWrapper isEdit={isEdit}>
                          <TireSize isEdit={isEdit}>{assembly?.tireSize}</TireSize>
                          <TireSizeLabel isEdit={isEdit}>{assembly.isAfterMarket ? constants.VEHICLE_FACTORY_TIRE_SIZE_OPTIONAL_LABEL : constants.VEHICLE_FACTORY_TIRE_SIZE_LABEL}</TireSizeLabel>
                        </TireSizeWrapper>
                        <SmVerticalBar isEdit={isEdit}>&nbsp;</SmVerticalBar>
                        <TireSizeWrapper isEdit={isEdit}>
                          <WSize isEdit={isEdit}>{assembly?.wheelSize}{constants.INCHES_ABBR}</WSize>
                          <WSizeLabel isEdit={isEdit}>{constants.VEHICLE_WHEELS_LABEL}</WSizeLabel>
                        </TireSizeWrapper>
                      </TireInfoContainer>
                    </DropdownItem>
                  )
              );
            })
          }
      <DropdownPlaceholder>
        Vehicle Assembly
      </DropdownPlaceholder>
      <ArrowIcon >{isOpen ? <IoChevronUpOutline /> : <IoChevronDownOutline />}</ArrowIcon>
    </EditAssemblyWrapper>
  );
};

export default AssemblyDropdown;
