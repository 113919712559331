const genericVehicleImageHelper = (value) => {
  let genericSrc = '/GenericVehicleImages/';
  switch (value) {
    case 'trailer':
    case 'utility':
      genericSrc += 'category_utility_trailer.png';
      break;
    case 'recreational vehicle':
    case 'recreational':
      genericSrc += 'category_recreational_trailer.png';
      break;
    case 'motor home':
      genericSrc += 'category_motor_home.png';
      break;
    case 'conversion van':
      genericSrc += 'category_conversion_van.png';
      break;
    case 'classic':
      genericSrc += 'category_classic_car.png';
      break;
    case 'late model':
      genericSrc += 'category_classic_car.png';
      break;
    case 'hd application':
      genericSrc += 'category_fleet_hd.png';
      break;
    // Need the asset for the atv/utv generic
    case 'atv/utv':
      genericSrc += 'category_golf_cart.png';
      break;
    case 'wheelbarrow':
      genericSrc += 'category_wheelbarrow.png';
      break;
    case 'golf cart':
      genericSrc += 'category_golf_cart.png';
      break;
    case 'lawn & golf':
    case 'ride-on mower':
      genericSrc += 'category_ride-on-mower.png';
      break;
    // other
    case 'other':
      genericSrc += 'category_ride-on-mower.png';
      break;
    case 'tractor':
      genericSrc += 'category_tractor.png';
      break;
    // Track and off-road missing
    case 'track':
      genericSrc += 'category_classic_car.png';
      break;
    case 'off-road':
      genericSrc += 'category_classic_car.png';
      break;
    // Equipment missing too
    case 'equipment':
      genericSrc += 'category_utility_trailer.png';
      break;
    default:
      genericSrc += 'category_golf_cart.png';
      break;
  }
  return genericSrc;
};

export default genericVehicleImageHelper;
