import { useContext, useEffect, useState } from 'react';
import styled from '../../../../helpers/esm-styled-components';
import Toggle from '../../../Toggle/Toggle';
import {
  ModalOverlay, ModalSubTitle, ModalContainer, ModalWrapper,
  CloseIcon, ContentContainer, InnerContentContainer, ButtonContainer,
  HorizontalDivider, ModalTitle, DeactivateVehicleWrapper, DeactivateIcon, WizardButtons,
  ReturnToCustomerSummary, AddAdditionalVehicleInfo,
} from '../../ModalUtils';
import * as constants from '../../../../constants/Constants';
import { theme } from '../../../../helpers/GlobalStyles';
import { CVMContext } from '../../../../components/CVM/CVM';
import VehicleInit from './VehicleInit';
import {
  validateVIN, isNullEmptyUndefined, checkIfObjectHasKey,
} from '../../../../helper/validationHelper';
import SelectButton from '../../../Button/SelectButton';
import Wizard from '../../../Wizard/Wizard';
import GenericVehicleInit from '../GenericVehicle/GenericVehicleInit';
import { RequiredSpan } from '../../../Input/Input';
import IsDeactivatedVehicle from '../IsDeactivatedVehicle';
import {
  breadCrumbs, countryCodeOptions, countryOptions, initialStandardVehicleFormData
} from '../../../../helper/baseDataHelper';
import CreateCustomerButton from '../../../../components/Details/Search/CreateButton';
import { DetailVBar } from '../../../../components/Details/DetailSection';
import { incompleteVehicleBreadcrumb, isGenericVehicle } from '../../../../helper/vehicleFormValidation';
import VehicleIHSVerification from './VehicleIHSVerification';
import {
  addUpdateCustomerVehicle, ihsLookUp, reactivateDeactivateVehicle, restructureImportedVehicle,
} from './VehicleHelper';
import { deepObjectCopy } from '../../../../helper/CustomerHelper';
import Message from '../../../Error/Message';
import { gkCustomerPush, prepDataForSend, publishCVData, setCarryOutFields } from '../../../../helper/gkHelper';

const VehicleInnerContentContainer = styled(InnerContentContainer)`
   ${({ isFitmentVehicle }) => isFitmentVehicle && 'max-height:650px;'};
`;

const ToggleWrapper = styled.div`
  left: 0;
  right: 0;
  position: relative;
  width: 401px;
  margin: 0 auto 10px auto;
  height: 50px;
  border-radius: 15px;
  padding: 0px;
  background-color: ${theme.colors.offWhite};
`;
const Prior1982Label = styled.p`
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.semiBold};
  color: ${theme.colors.blue};
  text-align: center;
  margin: 32px 0 0 0;
  cursor: pointer;
  width:209px;
  height:19px;
`;
const RequiredLabel = styled.label`
  font-size: ${theme.fontSize.medium2};
  text-align: right;
  display:flex;
  justify-content: flex-end;
  margin:0 15px 6px 0;
  padding-right: 40px;
  width:100%;
`;
const SearchErrorMessageLabel = styled.p`
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.modalTitle};
  color: ${theme.colors.obsidian};
  margin: 25px 0;
`;

const ReturnToSearchLabel = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.titleBlack};
  margin-bottom: 25px;
`;

const EditSearchButton = styled(SelectButton)`
  margin: 25px auto 0 auto;
  color: ${theme.colors.obsidian};
  border: 0;

`;
const EnterVehicleManually = styled(EditSearchButton)`
  margin: 25px auto 0 auto;
  border: 0;
  background-color: ${theme.colors.red};
  color: ${theme.colors.white};
`;
const AddVehicleFooterContainer = styled.div`
  padding: 0px 108px 5px 124px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'cancel confirm';
  justify-content: center;
  justify-items: center;
`;
const TitleVBar = styled(DetailVBar)`
  height: 25px;
  margin: 30px 66px 0px 85px;
  ${({ noVBar }) => noVBar && 'border-left: 1px transparent; margin:0 15px 0 16px'}
`;
const AddNewVehicleManually = styled(CreateCustomerButton)`
  margin: 32px 0px 0px 0px;
  width:175px;
  height:19px;
`;

const VehicleModal = (props) => {
  // props
  const {
    subtitle,
    modalBody,
    returnToModule,
    vehicle,
    useImportedVehicle,
  } = props;

  // context
  const {
    aircheckSelected,
    csrSelected,
    currentBreadCrumb,
    customerInfo,
    deactivatedVehicles,
    errorMessageObj,
    genericVehicleToggle,
    importedVehicle,
    moduleName,
    selectedVehicle,
    setAircheckSelected,
    applyCustomerVehicleError,
    setApplyCustomerVehicleError,
    setCurrentBreadCrumb,
    setCurrentViewElement,
    setDeactivatedVehicles,
    setErrorMessageObj,
    setGenericVehicleToggle,
    setImportedVehicle,
    setIsModalOn,
    setLoader,
    setModuleName,
    setSelectedVehicle,
    setShouldRunOnSelectedVehicleChange,
    setShowDeactivatedVehicles,
    setShowViewAllVehiclesLink,
    setVehicleBreadCrumbs,
    setVehicles,
    setVehicleToggle,
    setVtvSelected,
    transactionCustomer,
    vehSelected,
    vehicleBreadCrumbs,
    vehicles,
    vehicleToggle,
    vtvSelected,
  } = useContext(CVMContext);
  // standard/overall states
  const [searchErrorMessage, setSearchErrorMessage] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [standardVehicleFields, setStandardVehicleFields] = useState(!isGenericVehicle(vehicle?.vehicleDetails) && initialStandardVehicleFormData(vehicle?.vehicleDetails, vehicle?.tireDetails));
  const [isStandard] = useState(!isGenericVehicle(vehicle?.vehicleDetails));
  const [isEdit, setIsEdit] = useState((vehicle && !isStandard) || (vehicle && isStandard));
  const [isDeactivate, setIsDeactivate] = useState(false);
  const [correctedVehicle, setCorrectedVehicle] = useState({});
  const [arChargeFields, setArChargeFields] = useState([]);
  const [ihsEdit, setIsIhsEdit] = useState(false);
  // generic states
  const [genericFields, setGenericFields] = useState();
  const [genericTireFields, setGenericTireFields] = useState();
  const inspectionVehicle = vehicle?.vehicleDetails?.isInspectionVehicle ? vehicle.vehicleDetails : null;

  useEffect(() => {
    if (standardVehicleFields.vin === constants.EMPTY_STRING) {
      setErrorMessage(null);
    }
    setCurrentViewElement(constants.VIEW_ELEMENT_MODAL_NEW);

    // if you have an imported vehicle from PB, but opt to not use it
    if (!vehicle && importedVehicle && !useImportedVehicle) {
      setVehicleToggle(importedVehicle?.type === constants.VEHICLE_TYPE_FITMENT);
      setGenericVehicleToggle(importedVehicle?.type === constants.VEHICLE_TYPE_GENERIC);
    } else if (importedVehicle && useImportedVehicle) {
      // if you have an imported vehicle and want to use it. Now decide whether to go the fitment or generic route.
      if (importedVehicle.type === constants.VEHICLE_TYPE_FITMENT) {
        restructureImportedVehicle(importedVehicle, 'object');
        setStandardVehicleFields(importedVehicle);
        setCurrentBreadCrumb(5);
      } else {
        setVehicleToggle(false);
        setGenericVehicleToggle(true);
      }
    } else if (vehicle && !isStandard) {
      setCurrentViewElement(constants.VIEW_ELEMENT_MODAL_GENERIC);
      setGenericVehicleToggle(true);
      setVehicleToggle(false);
    } else if (vehicle && isStandard && vehicle?.vehicleDetails?.isIncomplete) {
      setVehicleToggle(true);
      const [index, breadcrumbArr] = incompleteVehicleBreadcrumb(vehicle.vehicleDetails, deepObjectCopy(breadCrumbs));
      setVehicleBreadCrumbs(breadcrumbArr);
      setCurrentBreadCrumb(index);
    } else if (vehicle && isStandard && isEdit) {
      setVehicleToggle(true);
      const tempArr = JSON.parse(JSON.stringify(vehicleBreadCrumbs));
      tempArr.forEach(c => {
        if (c.name === constants.BREADCRUMB_YEAR || c.name === constants.BREADCRUMB_MAKE || c.name === constants.BREADCRUMB_MODEL) {
          c.disabled = true;
          c.filled = true;
        }
      });

      setVehicleBreadCrumbs(tempArr);
      setCurrentBreadCrumb(5);
    } else {
      setVehicleBreadCrumbs(JSON.parse(JSON.stringify(breadCrumbs)));
    }
    return (() => {
      restructureImportedVehicle(importedVehicle, 'string');
      setIsEdit(false);
      setVehicleToggle(true);
      setGenericVehicleToggle(false);
      setCurrentBreadCrumb(0);
      setApplyCustomerVehicleError(false);
    });
  }, []);

  // check against required fields for GK transaction
  useEffect(() => {
    // set Required Fields for add/edit
    const req = ['LIC PLATE STATE', 'LICENSE PLATE #', 'VEHICLE MAKE', 'VEHICLE MODEL', 'VEHICLE YEAR', 'VIN #'];
    const arFields = customerInfo?.characteristicCollection?.filter(cc => req.includes(cc.characteristicItem.description))?.map(fcc => {
      return {
        field: fcc.characteristicItem?.description,
        value: () => {
          switch (fcc.characteristicItem.description) {
            case req[0]:
              return vehicleToggle ? standardVehicleFields.licenseState : genericFields.licenseState;
            case req[1]:
              return vehicleToggle ? standardVehicleFields.licensePlate : genericFields.licensePlate;
            case req[2]:
              return vehicleToggle ? standardVehicleFields.make : genericFields.make || genericFields.overrideMake;
            case req[3]:
              return vehicleToggle ? standardVehicleFields.model : genericFields.model || genericFields.overrideModel;
            case req[4]:
              return vehicleToggle ? standardVehicleFields.year : genericFields.year || genericFields.overrideYear;
            case req[5]:
              return vehicleToggle ? standardVehicleFields.vin : genericFields.vin || genericFields.vin;
          }
        }
      };
    });

    setArChargeFields(arFields);
  }, [standardVehicleFields, genericFields]);


  /**
   * Click handler for the toggle between Fitment and Generic Vehicle
   * @param {Event} e
   * @param {String} name
   */
  const toggleClick = (e, name) => {
    switch (name) {
      case constants.TOGGLE_TITLE_ADD_NEW_VEHICLE:
        setCurrentViewElement(constants.VIEW_ELEMENT_MODAL_NEW);
        setVehicleToggle(true);
        setGenericVehicleToggle(false);
        setCurrentBreadCrumb(0);
        setStandardVehicleFields(
          !isGenericVehicle(vehicle?.vehicleDetails) && initialStandardVehicleFormData(vehicle?.vehicleDetails, vehicle?.tireDetails)
        );
        setSearchErrorMessage({});
        setErrorMessage(null);
        break;
      case constants.TOGGLE_TITLE_ADD_GENERIC_VEHICLE:
        setCurrentViewElement(constants.VIEW_ELEMENT_MODAL_GENERIC);
        setGenericVehicleToggle(true);
        setVehicleToggle(false);
        break;
      default:
        break;
    }
  };

  /**
   * Search by VIN/License Plate at the start of the vehical modal
   * Looks in C360 first and then checks IHS for the vehicle
   * @param {Event} e
   * @param {String} buttonName
   * @returns
   */
  const searchByVINAndLicense = async (e, buttonName) => {
    const tempArr = JSON.parse(JSON.stringify(vehicleBreadCrumbs));

    if (!validateVIN(standardVehicleFields.vin) && buttonName === constants.TITLE_SEARCH_BY_VIN) {
      setErrorMessageObj({
        message: constants.ERROR_MESSAGE_VIN,
        type: constants.ERROR
      });
      return;
    }
    setErrorMessageObj({});

    let params;
    if (buttonName === constants.TITLE_SEARCH_BY_VIN) {
      params = { vin: standardVehicleFields.vin, license: null };
    } else {
      params = {
        vin: null,
        license: {
          licensePlateNumber: standardVehicleFields?.licensePlate,
          licensePlateStateCode: standardVehicleFields.licenseState.value,
          licensePlateCountryCode: standardVehicleFields.licenseCountry.value
        }
      };
    }

    const res = await ihsLookUp(params, setLoader);
    const vehicle = res?.vehicle;

    if (res.search === 'vin') {
      if (!isNullEmptyUndefined(res?.vehicle?.year) && !isNullEmptyUndefined(res?.vehicle?.make) && !isNullEmptyUndefined(res?.vehicle?.model)) {
        setSearchErrorMessage({
          message: `Your VIN search of "${standardVehicleFields.vin.toUpperCase()}" returned an incomplete vehicle`,
          type: 'warning'
        });
        // Removed the trim tempArray per business should be allowed to choose trim
        tempArr.forEach(c => {
          if (c.name === 'Year' || c.name === 'Make' || c.name === 'Model') {
            c.disabled = true;
          } else {
            c.disabled = false;
          }
        });
        setVehicleBreadCrumbs(tempArr);
        if (res.loc === 'c360') {
          if (vehicle.licensePlateStateCode?.toString().indexOf('-') > -1) {
            vehicle.licensePlateStateCode = vehicle.licensePlateStateCode?.split('-')[1];
          }
          if (!isNullEmptyUndefined(vehicle.color)) {
            vehicle.color = vehicle.color?.slice(0, 1).toString().toUpperCase() + vehicle.color?.slice(1);
          }
          vehicle.licensePlate = vehicle.licensePlateNumber ? vehicle.licensePlateNumber : constants.EMPTY_STRING;
          vehicle.licenseState = vehicle.licensePlateStateCode ? { value: vehicle.licensePlateStateCode, label: vehicle.licensePlateStateCode } : constants.EMPTY_STRING;
          vehicle.licenseCountry = vehicle.licensePlateCountryCode === 'US' ? { value: 'USA', label: 'United States' } : { value: vehicle.licensePlateCountryCode, label: vehicle.licensePlateCountryCode };

          delete vehicle.licensePlateNumber;
          delete vehicle.licensePlateStateCode;
          delete vehicle.licensePlateCountryCode;

          setStandardVehicleFields({
            ...vehicle,
            year: { label: parseInt(vehicle.year, 10), value: parseInt(vehicle.year, 10) },
            make: { label: vehicle.make, value: vehicle.make },
            model: { label: vehicle.model, value: vehicle.model },
            color: vehicle.color ? { label: vehicle.color, value: vehicle.color } : constants.EMPTY_STRING,
            vin: standardVehicleFields.vin,
            isManual: !vehicle.isIhs,
            isWizard: true,
            selectedAssembly: {},
            relationshipId: null,
            vehicleId: null,
          });
        } else if (res.loc === 'ihs') {
          setStandardVehicleFields({
            ...vehicle,
            year: { label: parseInt(vehicle.year, 10), value: parseInt(vehicle.year, 10) },
            make: { label: vehicle.make, value: vehicle.make },
            model: { label: vehicle.model, value: vehicle.model },
            color: vehicle.color ? { label: vehicle.color, value: vehicle.color } : constants.EMPTY_STRING,
            vin: standardVehicleFields.vin,
            isWizard: true,
            isManual: false,
            selectedAssembly: {}
          });
        }
        setCurrentBreadCrumb(3);
      } else {
        setSearchErrorMessage({
          message: `No results were found for your VIN search of "${standardVehicleFields.vin.toUpperCase()}"`,
          type: 'error'
        });
      }
    } else if (!isNullEmptyUndefined(res?.vehicle?.year) && !isNullEmptyUndefined(res?.vehicle?.make) && !isNullEmptyUndefined(res?.vehicle?.model)) {
      setSearchErrorMessage({
        message: `Your License plate search of "${standardVehicleFields.licensePlate.toUpperCase()}" returned an incomplete vehicle`,
        type: 'warning'
      });

      tempArr.forEach(c => {
        if (c.name === 'Year' || c.name === 'Make' || c.name === 'Model') {
          c.disabled = true;
        } else {
          c.disabled = false;
        }
      });

      setVehicleBreadCrumbs(tempArr);

      if (res.loc === 'c360') {
        if (vehicle.licensePlateStateCode?.toString().indexOf('-') > -1) {
          vehicle.licensePlateStateCode = vehicle.licensePlateStateCode?.split('-')[1];
        }
        if (!isNullEmptyUndefined(vehicle.color)) {
          vehicle.color = vehicle.color?.slice(0, 1).toString().toUpperCase() + vehicle.color?.slice(1);
        }

        vehicle.licensePlate = vehicle.licensePlateNumber;
        vehicle.licenseState = { value: vehicle.licensePlateStateCode, label: vehicle.licensePlateStateCode };
        vehicle.licenseCountry = { value: vehicle.licensePlateCountryCode, label: vehicle.licensePlateCountryCode };

        delete vehicle.licensePlateNumber;
        delete vehicle.licensePlateStateCode;
        delete vehicle.licensePlateCountryCode;

        setStandardVehicleFields({
          ...vehicle,
          year: { label: parseInt(vehicle.year, 10), value: parseInt(vehicle.year, 10) },
          make: { label: vehicle.make, value: vehicle.make },
          model: { label: vehicle.model, value: vehicle.model },
          color: vehicle.color ? { label: vehicle.color, value: vehicle.color } : constants.EMPTY_STRING,
          vin: vehicle.vin,
          isManual: !vehicle.isIhs,
          isWizard: true,
          selectedAssembly: {},
          relationshipId: null,
          vehicleId: null,
        });
      } else if (res.loc === 'ihs') {
        setStandardVehicleFields({
          ...standardVehicleFields,
          ...vehicle,
          year: { label: parseInt(vehicle.year, 10), value: parseInt(vehicle.year, 10) },
          make: { label: vehicle.make, value: vehicle.make },
          model: { label: vehicle.model, value: vehicle.model },
          color: vehicle.color ? { label: vehicle.color, value: vehicle.color } : constants.EMPTY_STRING,
          vin: vehicle.vin,
          isWizard: true,
          isManual: false,
          selectedAssembly: {}
        });
      }
      setCurrentBreadCrumb(3);
    } else {
      setSearchErrorMessage({
        message: `No results were found for your License plate search of "${standardVehicleFields.licensePlate.toUpperCase()}"`,
        type: 'error'
      });
    }
  };
  /**
   * Re-checks C360/IHS for the vehicle before adding/editing vehicle
   * @param {Event} e
   * @param {Boolean} isActive
   */
  const recheckIHS = async (e, isActive, publishCVDataCallBack) => {
    setErrorMessage(null);
    // validate the vin entered in the Additional Vehicle Information before posting
    if (standardVehicleFields.vin?.length > 0) {
      if (!validateVIN(standardVehicleFields.vin)) {
        setErrorMessage({
          message: constants.ERROR_MESSAGE_VIN,
          type: constants.ERROR
        });

      } else {
        // if vin entered in additional vehicle info breadcrumb, check against IHS
        // eslint-disable-next-line no-lonely-if
        if (standardVehicleFields.isManual) {
          // if there is a vin and it's a manual add, re-check that vin to make sure it matches

          const res = await ihsLookUp({
            vin: standardVehicleFields.vin.toUpperCase(),
            license: null
          }, setLoader);
          const { vehicle } = res;
          if (res.error || vehicle === null) {
            setCorrectedVehicle({
              show: true,
              correction: null,
              vehicle: null,
              error: res.error,
            });
          } else if (vehicle) {
            // found a vehicle
            let anyFieldsNotMatch;
            if (vehicle.trim?.length > 0 || vehicle.trimDescription?.length > 0) {
              anyFieldsNotMatch = parseInt(vehicle.year, 10) !== parseInt(standardVehicleFields.year?.label, 10)
              || vehicle.make.toLowerCase() !== standardVehicleFields.make?.label.toLowerCase()
              || vehicle.model.toLowerCase() !== standardVehicleFields.model?.label.toLowerCase()
              || (vehicle.trim?.toLowerCase() || vehicle.trimDescription?.toLowerCase()) !== standardVehicleFields.trim?.toLowerCase();
            } else {
              anyFieldsNotMatch = parseInt(vehicle.year, 10) !== parseInt(standardVehicleFields.year?.label, 10)
              || vehicle.make.toLowerCase() !== standardVehicleFields.make?.label.toLowerCase()
              || vehicle.model.toLowerCase() !== standardVehicleFields.model?.label.toLowerCase();
            }

            if (anyFieldsNotMatch) {
              setCorrectedVehicle({
                show: anyFieldsNotMatch,
                correction: () => {
                  const corrections = [];
                  parseInt(vehicle.year, 10) !== parseInt(standardVehicleFields.year?.label, 10) && corrections.push('year');
                  vehicle.make.toLowerCase() !== standardVehicleFields.make?.label.toLowerCase() && corrections.push('make');
                  vehicle.model.toLowerCase() !== standardVehicleFields.model?.label.toLowerCase() && corrections.push('model');
                  return corrections;
                },
                vehicle
              });
            } else {
              const additionalFieldsToSend = [{
                fieldName: 'vin',
                value: vehicle.vin,
              }];
              setErrorMessage(null);
              addUpdateCustomerVehicle(e, isActive, res.loc === 'ihs' || vehicle.isIhs, customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected || vtvSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFields, isEdit, additionalFieldsToSend, (vtvSelected || aircheckSelected), inspectionVehicle?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, null, null, publishCVDataCallBack);
            }
          } else {
            // no vehicle found. Go ahead with add
            // remove vin and license fields since no vehicle could be validated
            const additionalFieldsToSend = [
              {
                fieldName: 'vin',
                delete: true,
              },
              {
                fieldName: 'licensePlateNumber',
                delete: true,
              },
              {
                fieldName: 'licensePlateStateCode',
                delete: true,
              },
            ];
            setErrorMessage(null);
            addUpdateCustomerVehicle(e, isActive, false, customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFields, isEdit, additionalFieldsToSend, (vtvSelected || aircheckSelected), inspectionVehicle?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, null, null, publishCVDataCallBack);
          }
        } else {
          setErrorMessage(null);
          addUpdateCustomerVehicle(e, isActive, true, customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFields, isEdit, null, (vtvSelected || aircheckSelected), inspectionVehicle?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, null, null, publishCVDataCallBack);
        }
      }
    } else if (standardVehicleFields?.licensePlate?.length > 0) {
      setErrorMessage(null);
      const res = await ihsLookUp({
        vin: null,
        license: {
          licensePlateNumber: standardVehicleFields?.licensePlate,
          licensePlateStateCode: standardVehicleFields.licenseState.value,
          licensePlateCountryCode: standardVehicleFields.licenseCountry.value
        }
      }, setLoader);
      const { vehicle } = res;
      if (res.error || vehicle === null) {
        setCorrectedVehicle({
          show: true,
          correction: null,
          vehicle: null,
          error: res.error,
        });
      } else if (vehicle) {
        // found a vehicle
        let anyFieldsNotMatch;
        if (vehicle.trim?.length > 0 || vehicle.trimDescription?.length > 0) {
          anyFieldsNotMatch = parseInt(vehicle.year, 10) !== parseInt(standardVehicleFields.year?.label, 10)
          || vehicle.make.toLowerCase() !== standardVehicleFields.make?.label.toLowerCase()
          || vehicle.model.toLowerCase() !== standardVehicleFields.model?.label.toLowerCase()
          || (vehicle.trim?.toLowerCase() || vehicle.trimDescription?.toLowerCase()) !== standardVehicleFields.trim?.toLowerCase();
        } else {
          anyFieldsNotMatch = parseInt(vehicle.year, 10) !== parseInt(standardVehicleFields.year?.label, 10)
          || vehicle.make.toLowerCase() !== standardVehicleFields.make?.label.toLowerCase()
          || vehicle.model.toLowerCase() !== standardVehicleFields.model?.label.toLowerCase();
        }

        if (anyFieldsNotMatch) {
          setCorrectedVehicle({
            show: anyFieldsNotMatch,
            correction: () => {
              const corrections = [];
              parseInt(vehicle.year, 10) !== parseInt(standardVehicleFields.year?.label, 10) && corrections.push('year');
              vehicle.make.toLowerCase() !== standardVehicleFields.make?.label.toLowerCase() && corrections.push('make');
              vehicle.model.toLowerCase() !== standardVehicleFields.model?.label.toLowerCase() && corrections.push('model');
              return corrections;
            },
            vehicle
          });
        } else {
          const additionalFieldsToSend = [{
            fieldName: 'vin',
            value: vehicle.vin,
          }];
          setErrorMessage(null);
          addUpdateCustomerVehicle(e, isActive, res.loc === 'ihs' || vehicle.isIhs,  customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFields, isEdit, additionalFieldsToSend, (vtvSelected || aircheckSelected), inspectionVehicle?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, null, null, publishCVDataCallBack);
        }
      } else {
        // no vehicle found. Go ahead with add
        // remove vin and license fields since no vehicle could be validated
        const additionalFieldsToSend = [
          {
            fieldName: 'vin',
            delete: true,
          },
          {
            fieldName: 'licensePlateNumber',
            delete: true,
          },
          {
            fieldName: 'licensePlateStateCode',
            delete: true,
          },
        ];
        setErrorMessage(null);
        addUpdateCustomerVehicle(e, isActive, false,  customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFields, isEdit, additionalFieldsToSend, (vtvSelected || aircheckSelected), inspectionVehicle?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, null, null, publishCVDataCallBack);
      }
    }
    // if no vin or license was entered, just add/edit the vehicle
    else {
      setErrorMessage(null);
      addUpdateCustomerVehicle(e, isActive, false, (Object.keys(customerInfo)?.length > 0) ? customerInfo : csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFields, isEdit, null, (vtvSelected || aircheckSelected), inspectionVehicle?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, null, null, publishCVDataCallBack);
    }
  };
  /**
  * Click handler for canceling the deactivate modal
  */
  const cancelDeactivate = () => {
    setIsDeactivate(false);
    setCurrentBreadCrumb(5);
  };

  /**
  * Click handler for starting the manual vehicle entry wizard
  */
  const enterVehicleManually = () => {
    setCurrentBreadCrumb(0);
    setVehicleBreadCrumbs(JSON.parse(JSON.stringify(breadCrumbs)));
    setSearchErrorMessage({});
    setStandardVehicleFields({
      ...standardVehicleFields,
      isWizard: true,
      licensePlate: constants.EMPTY_STRING,
      licenseState: constants.EMPTY_STRING,
      licenseCountry: countryOptions[0],
      isManual: true,
    });
  };

  /**
  * Click handler for ignoring IHS recommendation and adding the vehicle with the entered information
  */
  const keepVehicleAsEntered = async () => {
    setIsIhsEdit(true);
    setCurrentBreadCrumb(5);
    setCorrectedVehicle({});

    // TODO: commenting for now for CAVA-816 ticket.
    // Added new object declaration, previous async call to set state was letting post with previous fields to being "emptied"
    // const standardVehicleFieldsNonIHS = {
    //   ...standardVehicleFields,
    //   vin: constants.EMPTY_STRING,
    //   licensePlate: constants.EMPTY_STRING,
    //   licenseState: constants.EMPTY_STRING,
    //   licenseCountry: countryOptions[0],
    //   isManual: true
    // };
    // if (isEdit || !isNullEmptyUndefined(standardVehicleFieldsNonIHS)) {
    // setCurrentBreadCrumb(5);
    // setCorrectedVehicle({});
    // } else {
    // await addUpdateCustomerVehicle(null, true, false,  customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFieldsNonIHS, isEdit, null,  (vtvSelected || aircheckSelected), inspectionVehicle?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle);
    // }
  };

  const publishCVDataCallBack = (selectedVehRefetch) => {
    const carryOutFieldsCallback = (carryOutVehicle) => {
      publishCVData(null, { customerInfo, selectedVehicle: carryOutVehicle }, customerInfo, carryOutVehicle, vtvSelected, aircheckSelected, transactionCustomer, standardVehicleFields, isEdit, setIsModalOn, setSelectedVehicle, countryCodeOptions, setApplyCustomerVehicleError);
    };
    setCarryOutFields(standardVehicleFields, selectedVehRefetch || selectedVehicle, isEdit, setSelectedVehicle, carryOutFieldsCallback);
  };

  const ihsSave = async () => {
    let cachedIhsVehicleDetails;
    setShouldRunOnSelectedVehicleChange(false);
    const standardVehicleFieldsNonIHS = deepObjectCopy(standardVehicleFields);
    cachedIhsVehicleDetails = {
      ...standardVehicleFields,
      vin: constants.EMPTY_STRING,
      licensePlate: constants.EMPTY_STRING,
      licenseState: constants.EMPTY_STRING,
      licenseCountry: constants.EMPTY_STRING,
    };
    await addUpdateCustomerVehicle(null, true, false,  customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, cachedIhsVehicleDetails, isEdit, null,  (vtvSelected || aircheckSelected), inspectionVehicle?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, cachedIhsVehicleDetails, standardVehicleFieldsNonIHS, null);
  };

  const checkIfSubmitDisabled = () => {
    if (!arChargeFields) {
      return false;
    }

    if (standardVehicleFields.isCarryOut) {
      let disabled = false;
      for (let i = 0; i < arChargeFields.length; i++) {
        if (arChargeFields[i].field !== 'VIN #' && arChargeFields[i].field !== 'LIC PLATE STATE' && arChargeFields[i].field !== 'LICENSE PLATE #') {
          disabled = (isNullEmptyUndefined(arChargeFields[i].value()));

          if (disabled) {
            break;
          }
        }
      }

      return disabled;
    }
    return arChargeFields?.some(f => isNullEmptyUndefined(f.value()));

  };

  /**
   * Click handler for keeping the IHS recommendation and adding the vehicle
   */
  const continueWithIHS = () => {
    const ihsCorrectedVeh = deepObjectCopy(standardVehicleFields);

    delete ihsCorrectedVeh.trim;
    delete ihsCorrectedVeh.trimId;
    delete ihsCorrectedVeh.imageUrl;
    delete ihsCorrectedVeh.isDually;

    setStandardVehicleFields({
      ...ihsCorrectedVeh,
      year: { label: parseInt(correctedVehicle.vehicle.year, 10), value: parseInt(correctedVehicle.vehicle.year, 10) },
      make: { label: correctedVehicle.vehicle.make, value: correctedVehicle.vehicle.make },
      model: { label: correctedVehicle.vehicle.model, value: correctedVehicle.vehicle.model },
      vin: correctedVehicle.vehicle.vin,
      isManual: false,
    });
    setCurrentBreadCrumb(3);
    setCorrectedVehicle({});
    setErrorMessage(null);
  };

  /**
   * Start the fitment vehicle wizard for vehicles prior to 1982
   */

  const isCarryOutOnly = standardVehicleFields?.color?.label?.toLowerCase() === selectedVehicle?.vehicleDetails.color?.toLowerCase() && standardVehicleFields?.isCarryOut; //color changes is carryout

  const carryOutColorNa = isNullEmptyUndefined(standardVehicleFields?.color?.label) && isNullEmptyUndefined(selectedVehicle?.vehicleDetails?.color); //no color

  const isCarryOutAssemb = selectedVehicle?.vehicleDetails?.assemblyCollection.find(a => a.assemblyItem.currentAssembly == true)?.assemblyItem?.assemblyType;
  const carryoutDifferential = isEdit && (standardVehicleFields?.vin === "CARRYOUT" || standardVehicleFields?.isCarryOut) && (standardVehicleFields?.selectedAssembly[0]?.assemblyType === isCarryOutAssemb || standardVehicleFields?.selectedAssembly.assemblyType === isCarryOutAssemb) && (isCarryOutOnly || carryOutColorNa);
  const carryoutColorChange = !isCarryOutOnly && standardVehicleFields?.isCarryOut;

  const setPrior1982 = () => {
    setStandardVehicleFields({
      ...standardVehicleFields,
      prior1982: true,
      isWizard: true,
      isManual: true,
    });
  };

  /**
   * Click handler for entering a vehicle manually if no vehicle was found via license or vin search
   */
  const revertBackToManualEntry = () => {
    const svfTemp = JSON.parse(JSON.stringify(standardVehicleFields));
    delete svfTemp.year;
    delete svfTemp.make;
    delete svfTemp.model;
    delete svfTemp.trim;

    setStandardVehicleFields({
      ...svfTemp,
      isWizard: false,
      isManual: true
    });

    if (standardVehicleFields.vin?.length > 0) {
      setSearchErrorMessage({
        message: `No results were found for your VIN search of "${standardVehicleFields.vin.toUpperCase()}"`,
        type: 'error'
      });
    } else {
      setSearchErrorMessage({
        message: `No results were found for your License plate search of "${standardVehicleFields.licensePlate.toUpperCase()}"`,
        type: 'error'
      });
    }
  };

  // Saves the vehicle for Add/Edit
  const saveVehicle = () => {
    // check to see if button click is valid
    if (isNullEmptyUndefined(arChargeFields) || !checkIfSubmitDisabled()) {
      // if VIN/License entered manually and it's not carry-out, re-check IHS
      if (standardVehicleFields.isManual && !standardVehicleFields?.isCarryOut) {
        return recheckIHS(null, true, publishCVDataCallBack);
      }

      // Carry-out only with no additional edits
      if(carryoutDifferential) {
        // If Apply/Customer Vehicle Clicked before
        if (applyCustomerVehicleError) {
          publishCVDataCallBack(selectedVehicle);
          setIsModalOn(false);
        }
        // If Edit clicked before
        else {
          setCarryOutFields(standardVehicleFields, selectedVehicle, isEdit, setSelectedVehicle);
          setIsModalOn(false);
        }
      }
      // Carry-out with additional vehicle changes
      else if (carryoutColorChange) {
        addUpdateCustomerVehicle(null, true, true, customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFields, isEdit, null, (vtvSelected || aircheckSelected), vehicle?.vehicleDetails?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, null, null, publishCVDataCallBack);
      }
      // no carry-out, normal add/edit
      else {
        addUpdateCustomerVehicle(null, true, true, customerInfo || csrSelected, vehSelected || vtvSelected || aircheckSelected, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, selectedVehicle, standardVehicleFields, isEdit, null, (vtvSelected || aircheckSelected), vehicle?.vehicleDetails?.isInspectionVehicle, setVtvSelected, setAircheckSelected, setImportedVehicle, useImportedVehicle, null, null, publishCVDataCallBack);
      }
    }
  };

  /**
   *
   * @returns the subtitle based on if the wizard is fitment or generic vehicle
   */
  const getSubtitle = () => {
    if (!vehicleToggle) {
      return (
        <>
          {(subtitle && !isEdit) && <ModalSubTitle height="35px" margin="0" align="center">{constants.GENERIC_VEHICLE_SUBTITLE}</ModalSubTitle>}
          <RequiredLabel><RequiredSpan required>*</RequiredSpan>
            {constants.TITLE_REQUIRED_FIELDS_VEHICLE}
          </RequiredLabel>
          <HorizontalDivider generic />
        </>
      );
    }
    return isEdit ? <></> : (<ModalSubTitle align="center">{!standardVehicleFields.isWizard ? subtitle : (<>{constants.ADD_NEW_VEHICLE_MANUAL_ENTRY_SUBTITLE}<br />{constants.ADD_NEW_VEHICLE_MANUAL_ENTRY_SUBTITLE2}</>)}</ModalSubTitle>);
  };
  return (
    <>
      <ModalOverlay />
      <ModalWrapper>
        {isDeactivate
          && (
            <IsDeactivatedVehicle
              vehicle={vehicle.vehicleDetails} returnToModule={returnToModule}
              cancelDeactivate={cancelDeactivate}
              // Note: customerInfo is getting refactored since selected will not always be the case, i.e create customer
              deactivateVehicle={
                () => (!vehicleToggle
                  ? reactivateDeactivateVehicle(csrSelected || vehSelected ||
                    customerInfo, vehicle.vehicleDetails,
                  vehicle.vehicleDetails.vehicleId,
                  vehicle.vehicleDetails.relationshipId, 'Generic',
                  'deactivate', setLoader, selectedVehicle, setSelectedVehicle, setModuleName,
                  setIsModalOn, vehicles, deactivatedVehicles, setVehicles,
                  setDeactivatedVehicles, setShowViewAllVehiclesLink)
                  : reactivateDeactivateVehicle(csrSelected || vehSelected ||
                    customerInfo, standardVehicleFields,
                  standardVehicleFields.vehicleId,
                  standardVehicleFields.relationshipId, 'Fitment',
                  'deactivate', setLoader, selectedVehicle, setSelectedVehicle, setModuleName,
                  setIsModalOn, vehicles, deactivatedVehicles, setVehicles,
                  setDeactivatedVehicles, setShowViewAllVehiclesLink))
} />
          )}
        {!isDeactivate && (
          <ModalContainer modalType={vehicleToggle
            ? constants.MODAL_TYPE_ADD_NEW_VEHICLE : constants.MODAL_TYPE_GENERIC_VEHICLE} subtitle={subtitle} body={modalBody}>
            <CloseIcon onClick={returnToModule} />
            { !correctedVehicle?.show ? (
              <>
                <ContentContainer className="current-view-modal-new" padding={!vehicleToggle && '0 0 15px 20px'}>
                  {!isEdit && (
                  <ToggleWrapper>
                    <Toggle
                      wrapperWidth={401}
                      active={vehicleToggle}
                      position={constants.LEFT}
                      size={constants.TOGGLE_SIZE_LARGE}
                      name={constants.TOGGLE_TITLE_ADD_NEW_VEHICLE}
                      toggleClick={(e) => toggleClick(e, constants.TOGGLE_TITLE_ADD_NEW_VEHICLE)}
                    />
                    <Toggle
                      wrapperWidth={401}
                      active={genericVehicleToggle}
                      position={constants.RIGHT}
                      size={constants.TOGGLE_SIZE_LARGE}
                      name={constants.TOGGLE_TITLE_ADD_GENERIC_VEHICLE}
                      toggleClick={(e) => toggleClick(e, constants.TOGGLE_TITLE_ADD_GENERIC_VEHICLE)}
                    />
                  </ToggleWrapper>
                  )}
                  {isEdit && <ModalTitle margin="5px 0 15px 0px">Edit Vehicle</ModalTitle>}
                  {isEdit && ihsEdit && <Message Message errorMessages={constants.IHS_NO_MATCH} type={constants.WARNING} manualWidth="94%" />}

                  {getSubtitle()}

                  <VehicleInnerContentContainer isFitmentVehicle={vehicleToggle}>
                    {!vehicleToggle ? (
                      <>
                        <GenericVehicleInit
                          arChargeFields={arChargeFields}
                          returnToCustomerSummary={() => setIsModalOn(false)}
                          customerId={vehicle?.customerId || csrSelected?.id || vehSelected?.customerId}
                          vehicleDetails={vehicle ? vehicle?.vehicleDetails : importedVehicle && useImportedVehicle ? importedVehicle : null}
                          tireDetails={vehicle ? vehicle?.tireDetails : importedVehicle && useImportedVehicle ? importedVehicle : null}
                          fields={genericFields}
                          setFields={setGenericFields}
                          tireFields={genericTireFields}
                          setTireFields={setGenericTireFields}
                          onSubmit
                          useImportedVehicle={useImportedVehicle}
                      />
                      </>
                    )
                      : !standardVehicleFields.isWizard
                        ? !checkIfObjectHasKey(searchErrorMessage, 'type') || Object.keys(errorMessageObj).length > 0
                          ? (
                            <>
                              <VehicleInit errorMessage={errorMessageObj} setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields} searchBy={(e, buttonName) => searchByVINAndLicense(e, buttonName)} setVinError={setErrorMessageObj} />
                              <HorizontalDivider />
                              <AddVehicleFooterContainer>
                                <AddNewVehicleManually
                                  buttonName={constants.BUTTON_NEW_VEHICLE}
                                  buttonType={constants.BUTTON_TYPE_CREATE}
                                  buttonClick={enterVehicleManually}
                              />
                                <TitleVBar />
                                <Prior1982Label onClick={setPrior1982}>{constants.PRIOR_1982_MESSAGE}</Prior1982Label>
                              </AddVehicleFooterContainer>
                            </>
                          )
                          : (
                            <>
                              <HorizontalDivider />
                              <SearchErrorMessageLabel>
                                { searchErrorMessage.message }
                              </SearchErrorMessageLabel>
                              <ReturnToSearchLabel>
                                { constants.RETURN_TO_SEARCH_ADD_NEW_VEHICLE }
                              </ReturnToSearchLabel>
                              <HorizontalDivider />
                              <ButtonContainer>
                                <EditSearchButton buttonType={constants.DEFAULT} buttonName={constants.EDIT_SEARCH} onButtonClick={() => setSearchErrorMessage({})} />
                                <EnterVehicleManually buttonType={constants.DEFAULT} buttonName={constants.BUTTON_ENTER_VEHICLE_MANUALLY} isDisabled={false} onButtonClick={enterVehicleManually} />
                              </ButtonContainer>
                            </>
                          )
                        : (
                          isStandard
                        && (
                          <>
                            { (searchErrorMessage?.type === 'warning' && currentBreadCrumb !== 5) && (
                              <>
                                <HorizontalDivider />
                                <SearchErrorMessageLabel>
                                  { searchErrorMessage.message }
                                </SearchErrorMessageLabel>
                                <ReturnToSearchLabel>
                                  { constants.RETURN_TO_SEARCH_ADD_NEW_VEHICLE }
                                </ReturnToSearchLabel>
                              </>
                            )}
                            {/* Wizard Content */}
                            <HorizontalDivider />
                            <Wizard arChargeFields={arChargeFields} breadCrumbs={vehicleBreadCrumbs} setBreadCrumbs={setVehicleBreadCrumbs} revertBackToManualEntry={revertBackToManualEntry} errorMessage={errorMessage} isEdit={isEdit} setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields} />
                            <HorizontalDivider />
                            <WizardButtons>
                              <ReturnToCustomerSummary buttonType={constants.DEFAULT} buttonName={constants.BUTTON_RETURN_TO_CUSTOMER_SUMMARY} isDisabled={false} onButtonClick={() => setIsModalOn(false)} />
                              {
                                currentBreadCrumb === 5
                                  ? (
                                    <AddAdditionalVehicleInfo
                                      buttonType={constants.DEFAULT}
                                      buttonName={isEdit ? constants.BUTTON_SAVE_VEHICLE_EDITS : constants.BUTTON_ADD_VEHICLE_TO_CUSTOMER}
                                      isDisabled={checkIfSubmitDisabled()}
                                      onButtonClick={saveVehicle}
/>
                                  )
                                  : (
                                    <AddAdditionalVehicleInfo
                                      buttonType={constants.DEFAULT}
                                      buttonName={constants.BUTTON_ADD_ADDITIONAL_VEHICLE_INFO}
                                      isDisabled={Object.keys(standardVehicleFields.selectedAssembly).length === 0 || isNullEmptyUndefined(standardVehicleFields.year) || isNullEmptyUndefined(standardVehicleFields.make) || isNullEmptyUndefined(standardVehicleFields.model) || isNullEmptyUndefined(standardVehicleFields.trim)}
                                      onButtonClick={Object.keys(standardVehicleFields.selectedAssembly).length > 0 && !isNullEmptyUndefined(standardVehicleFields.year) && !isNullEmptyUndefined(standardVehicleFields.make) && !isNullEmptyUndefined(standardVehicleFields.model) && !isNullEmptyUndefined(standardVehicleFields.trim)
                                        ? () => setCurrentBreadCrumb(5) : () => {}}
                                    />
                                  )
                              }
                            </WizardButtons>
                          </>
                        )
                        )}
                  </VehicleInnerContentContainer>
                  {
                  isEdit && isStandard
                    ? isStandard && currentBreadCrumb === 5 && (!vtvSelected && !aircheckSelected)
                    && <DeactivateVehicleWrapper onClick={() => setIsDeactivate(true)}><DeactivateIcon />{constants.DEACTIVATE_VEHICLE_LABEL}</DeactivateVehicleWrapper>
                    : isEdit && !isStandard && (!vtvSelected && !aircheckSelected)
                    && <DeactivateVehicleWrapper onClick={() => setIsDeactivate(true)}><DeactivateIcon />{constants.DEACTIVATE_VEHICLE_LABEL}</DeactivateVehicleWrapper>
                }
                </ContentContainer>
              </>
            )
              :
              <VehicleIHSVerification isEdit={isEdit} setCurrentBreadCrumb={setCurrentBreadCrumb} setStandardVehicleFields={setStandardVehicleFields} standardVehicleFields={standardVehicleFields} correctedVehicle={correctedVehicle} keepVehicleAsEnteredHandler={keepVehicleAsEntered} continueWithIHS={continueWithIHS} ihsSave={ihsSave} />}
          </ModalContainer>
        )}
      </ModalWrapper>
    </>
  );
};

export default VehicleModal;
