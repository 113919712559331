import React, { useEffect, useState } from 'react';
import styled from '../../helpers/esm-styled-components';
import * as constants from '../../constants/Constants';
import { theme } from '../../helpers/GlobalStyles';
import SelectButton from '../Button/SelectButton';
import Radio from './Radio';

const RadioButtons = styled.div`
display:flex;
flex-direction:row;
gap:140px;
margin-bottom:27px;
${({ generic }) => generic && 'gap:31px; margin-bottom:0;'};
${({ isOneOption }) => isOneOption && 'justify-content:center; gap:0;'};
`;
const ConfirmButton = styled(SelectButton)`
  border: 0;
  height: 50px;
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.red};
  background-color: ${theme.colors.red};
  color: ${theme.colors.white};
  grid-area: confirm;
  margin-left: 190px;
`;

const RadioForm = (props) => {
  const {
    formType, handleChange, onSubmit, values, generic, isOneOption, returnToModule, onEdit, checked,
  } = props;
  const [selectedOption, setSelectedOption] = useState();
  useEffect(() => {
    if (values[0]?.value) {
      setSelectedOption(values[0].value);
    }
    if (values[0] === null) {
      setSelectedOption(values[1]);
    } else {
      setSelectedOption(values[0]);
    }
  }, [values]);

  const onRadioEdit = () => {
    onEdit && onEdit();
    returnToModule();
  };
  const submit = () => {
    if (constants.RADIO_FORM_ADDRESS_VERIFY) {
      onSubmit(selectedOption);
    }
  };
  const onChange = (e) => {
    setSelectedOption(values[e.target.value]);
  };
  const radioButtons = () => {
    const corrected = values[0];
    const entered = values[1];
    switch (formType) {
      case constants.RADIO_FORM_ADDRESS_VERIFY:
        return (
          <>
            <Radio
              name={constants.RADIO_FORM_ADDRESS_VERIFY}
              defaultChecked
              label={constants.ADDRESS_VERIFICATION_MODAL_USPS}
              value={0}
              title={`${corrected.address1}${corrected.address2 && `, ${corrected.address2}`}`}
              subtitle={`${corrected.city}, ${corrected.state} ${corrected.zip}`}/>
            <Radio
              name={constants.RADIO_FORM_ADDRESS_VERIFY}
              label={constants.ADDRESS_VERIFICATION_MODAL_ENTERED}
              value={1}
              title={`${entered.address1}${entered.address2 && `, ${entered.address2}`}`}
              subtitle={`${entered.city}, ${entered.state} ${entered.zip}`}
              editAction={onRadioEdit}
              isEdit
            />
          </>
        );
      case constants.RADIO_FORM_GENERIC_VEHICLE:
        return (
          <>
            <Radio
              name={constants.RADIO_FORM_GENERIC_VEHICLE}
              label={values[0].label}
              value={values[0].value}
              title={values[0].value}
              defaultChecked={checked === 0}
              generic
             />
            <Radio
              name={constants.RADIO_FORM_GENERIC_VEHICLE}
              label={values[1].label}
              value={values[1].value}
              title={values[1].value}
              defaultChecked={checked === 1}
              generic
      />
            <Radio
              name={constants.RADIO_FORM_GENERIC_VEHICLE}
              label={values[2].label}
              value={values[2].value}
              title={values[2].value}
              defaultChecked={checked === 2}
              generic
            />
          </>
        );
      case constants.RADIO_FORM_ADDRESS_ONE_OPTION:
        return (
          <Radio
            name={constants.RADIO_FORM_ADDRESS_ONE_OPTION}
            label={constants.ADDRESS_ONE_OPTION_MODAL_ENTERED}
            value={1}
            title={`${entered.address1}${entered.address2 && `, ${entered.address2}`}`}
            subtitle={`${entered.city}, ${entered.state} ${entered.zip}`}
            editAction={onRadioEdit}
            isEdit
            isOneOption
        />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <RadioButtons isOneOption={isOneOption} generic={generic} onChange={handleChange || onChange}>
        {radioButtons()}
      </RadioButtons>
      {(formType === constants.RADIO_FORM_ADDRESS_VERIFY
      || formType === constants.RADIO_FORM_ADDRESS_ONE_OPTION)
        && (
        <ConfirmButton
          onButtonClick={submit}
          buttonName={
            formType === constants.RADIO_FORM_ADDRESS_VERIFY
              ? constants.ADDRESS_VERIFICATION_MODAL_CONFIRM_BUTTON
              : constants.ADDRESS_ONE_OPTION_MODAL_CONFIRM_BUTTON
}/>
        )}

    </>
  );
};

export default RadioForm;
