import { GrClose } from 'react-icons/gr';
import { MdHighlightOff } from 'react-icons/md';
import styled from '../../helpers/esm-styled-components';

import { theme } from '../../helpers/GlobalStyles';
import * as constants from '../../constants/Constants';
import SelectButton from '../Button/SelectButton';

export const ModalWrapper = styled.div``;

export const ModalTitle = styled.h3`
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.modalTitle};
  font-weight: ${theme.fontWeight.xBold};
  color: ${theme.colors.obsidian};
  line-height: 24px;
  ${({ body }) => body && 'margin-bottom:0'}
  ${({ margin }) => margin && `margin:${margin}`}
`;

export const ModalSubTitle = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.titleBlack};
  height: 47px;
  width: 687px;
  ${({ height }) => (height ? `height: ${height}` : 'height: 47px')};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ margin }) => (margin ? `margin: ${margin}` : 'margin: 0 0 15px 0')};
`;

export const CloseIcon = styled(GrClose)`
  position: relative;
  height: 18px;
  width: 12px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 18px;
  top: 16px;
  right: 20px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color:#4c47478f;
  z-index: 200;
`;

export const ModalContainer = styled.div`
  background-color: ${theme.colors.white};
  position: -webkit-sticky;
  position: absolute;
  top: ${({ modalType }) => {
    switch (modalType) {
      case constants.MODAL_TYPE_ADD_NEW_VEHICLE:
        return '13%';
      case constants.MODAL_TYPE_GENERIC_VEHICLE:
        return '13%';
      case constants.MODAL_TYPE_DEACTIVATE_VEHICLE:
        return '27%';
      default:
        return '50%';
    }
  }};
  height: 177px;
  width: 768px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
  text-align: right;
  ${({ subtitle }) => subtitle && 'height:210px;'};
  ${({ body }) => body && 'height:326px;'};
`;

export const ContentContainer = styled.div`
  text-align: left;
  ${({ padding }) => (padding ? `padding: ${padding}` : 'padding: 0 29px 15px 29px;')};
  background-color: ${theme.colors.white};
`;

export const InnerContentContainer = styled.div`
width:100%;
max-height:600px;
overflow-y: auto;
overflow-x: hidden;
`;

export const ModalBody = styled.div`
  text-align: initial;
  margin-left: 15%;
  padding-bottom: 4%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'cancel confirm';
  grid-gap: 42px;
`;

export const BreadCrumbQuestion = styled.p`
  font-family: ${theme.colors.titleBlack};
  font-family: ${theme.fontFamily.black};
  font-size: ${theme.fontSize.large};
`;

export const BreadCrumbSubText = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.medium2};
  color: ${theme.colors.lightGray};
  margin-bottom: 20px;
  margin-right: 20px;
`;
// Should be able to combine these two
export const HorizontalDivider = styled.hr`
  border: 1px solid ${theme.colors.lightGray2};
  margin: 0;
  ${({ generic }) => generic && 'margin: 0 0 0 15px; width:690px;'};
  ${({ margin }) => margin && `margin:${margin}`};
  ${({ width }) => width && `width:${width} `};
  ${({ lightGray }) => lightGray && `border: 1px solid ${theme.colors.searchGray} `};
`;
export const HorizontalGenericDivider = styled(HorizontalDivider)`
  margin: 10px 0 20px 15px;
`;
export const VehicleInfoLabel = styled.label`
margin-top: 10px;
margin-left: 15px;
font-size: ${theme.fontSize.large};
font-family:${theme.fontFamily.bold};
${({ leftAlign }) => leftAlign && 'margin-left:0;'};
`;
export const DeactivateVehicleWrapper = styled.div`
  color:${theme.colors.editBlue};
  font-size: ${theme.fontSize.large};
  display:flex;
  justify-content: center;
  align-items: center;
  gap:2px;
  margin-top:20px;
  cursor: pointer;
`;
// Wizard Buttons
export const WizardButtons = styled(ButtonContainer)`
  margin: 20px 0 0px 0;
  ${({ generic }) => generic && 'margin-left:18px; grid-gap:20px;'};
  ${({ margin }) => margin && `margin:${margin}`}
`;

export const ReturnToCustomerSummary = styled(SelectButton)`
  border: 0;
  color: ${theme.colors.obsidian};
  ${({ margin }) => margin && `margin:${margin}`}

`;

export const AddAdditionalVehicleInfo = styled(SelectButton)`
  border: 0;
  ${({ isDisabled }) => (isDisabled ? `color: ${theme.colors.lightGray}; cursor: inherit` : `color: ${theme.colors.white}; cursor: pointer;`)};
  background-color: ${({ isDisabled }) => (isDisabled ? theme.colors.searchGray : theme.colors.red)};
  ${({ margin }) => margin && `margin:${margin}`}
`;
export const DeactivateIcon = styled(MdHighlightOff)`
  margin-top:2.5px;
`;
