import React from 'react';
import styled from '../../../helpers/esm-styled-components';
import { theme } from '../../../helpers/GlobalStyles';
import { NOT_APPLICABLE } from '../../../constants/Constants';

const Detail = styled.div`
  height: 25px;
  ${({ hasOverflow }) => hasOverflow && 'overflow: hidden;'}
  ${({ infoName, value }) => (infoName?.length > 0 && value !== NOT_APPLICABLE) && 'cursor: pointer;'}
  ${({ emailMaxWidth }) => emailMaxWidth && 'max-width: 345px;'}
  white-space: nowrap;
  position: relative;
  text-align: left;
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.titleBlack};
  margin-bottom: 0.6em;
  ${({ margin }) => margin && `margin:${margin}`}
  ${({ width }) => width && `width:${width}`}
  text-overflow:ellipsis;
  ${({ underline }) => underline && 'border-bottom:1px solid black; height:25px;'}
  ${({ isLabel }) => isLabel && `font-family:${theme.fontFamily.semiBold}`};
  ${({ isBold }) => isBold && `font-family:${theme.fontFamily.bold};`};
  ${({ additional }) => additional && `font-size:${theme.fontSize.large};`};
  ${({ margin }) => margin && `margin:${margin}`}
  ${({ width }) => width && `width:${width}`}
  ${({ small }) => small && `font-size:${theme.fontSize.dot}; margin-top:6px;  white-space: nowrap;`};
`;


const CustomerDetail = (props) => {
  const {
    infoName, testId, isVTVAircheck, emailMaxWidth, isVTVAircheckPhone, isVTVAircheckEmail, isLabel, isBold, additional, margin, width, underline, small, hasOverflow, clickHandler, value
  } = props;
  return (
    <Detail data-testid={`customer_data_${testId}`}
      onClick={clickHandler ? (e) => clickHandler(e, infoName) : () => {}}
      isVTVAircheck={isVTVAircheck}
      emailMaxWidth={emailMaxWidth}
      isVTVAircheckEmail={isVTVAircheckEmail}
      isVTVAircheckPhone={isVTVAircheckPhone}
      hasOverflow={hasOverflow}
      margin={margin}
      width={width}
      isLabel={isLabel}
      isBold={isBold}
      underline={underline}
      additional={additional}
      small={small}
      value={value}
      infoName={infoName}
      title={(hasOverflow && value) || ''}
      >
      {infoName}
    </Detail>
  );
};
export default CustomerDetail;
