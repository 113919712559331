import * as constants from '../constants/Constants';
import {
  numberSort, timeSort, textSort, splitArrayByObjProperty
} from './sortingHelper';
import { isNullEmptyUndefined } from './validationHelper';

// Sorts table data by a column or by groups
export const tableColumnSorting = (data, columnDetails, direction) => {
  let globalArr = [],
    returnArr = [],
    genericNoYMMGrouping,
    genericNoYMMGlobalGrouping = [],
    noYMMGeneric = data?.filter(c => c.overrideDescription === 'Generic' && ((isNullEmptyUndefined(c.year) || c.year === 0) || isNullEmptyUndefined(c.make) || isNullEmptyUndefined(c.model)));
  data = data?.filter(c => c.overrideDescription !== 'Generic' || (c.overrideDescription === 'Generic' && (!isNullEmptyUndefined(c.year) && c.year !== 0) && !isNullEmptyUndefined(c.make) && !isNullEmptyUndefined(c.model)));
  const noYMMGenericColumnDetails = () => {
    return {
      dataType: [constants.COLUMN_DATA_TYPE_STRING, constants.COLUMN_DATA_TYPE_STRING],
      columnPropName: ['sortCategory', 'sortSubCategory']
    };
  };

  // add properties to help sorting algo
  noYMMGeneric?.forEach(c => {
    c.sortCategory = c.overrideVehicleCategory;
    c.sortSubCategory = c?.overrideVehicleSubCategory;
  });

  // sort the generic vehicles that don't have YMM
  if (noYMMGeneric?.length) {
    noYMMGenericColumnDetails().dataType.forEach((c, index) => {
      if (genericNoYMMGrouping) {
        genericNoYMMGlobalGrouping = [];
        genericNoYMMGrouping.forEach(d => {
          genericNoYMMGlobalGrouping.push(textSort(d, noYMMGenericColumnDetails, direction, index));
        });
      } else {
        genericNoYMMGrouping = splitArrayByObjProperty(textSort(genericNoYMMGrouping || noYMMGeneric, noYMMGenericColumnDetails, direction, index), noYMMGenericColumnDetails().columnPropName[index], c);
      }
    });
  }

  // sort vehicles with YMM
  const sortingRoute = (dataType, hasGroupings) => {
    // if sorting contains grouping
    if (hasGroupings) {
      let groupingArr;
      // loop through each data type and then sort by each data value
      dataType?.forEach((c, index) => {
        switch (c) {
          case constants.COLUMN_DATA_TYPE_STRING:
            if (groupingArr) {
              globalArr = [];
              groupingArr.forEach(c => {
                globalArr.push(textSort(c, columnDetails, direction, index));
              });
            } else {
              groupingArr = splitArrayByObjProperty(textSort(groupingArr || data, columnDetails, direction, index), columnDetails().columnPropName[index], c);
            }
            break;
          case constants.COLUMN_DATA_TYPE_NUMBER:
            if (groupingArr) {
              globalArr = [];
              groupingArr.forEach(c => {
                globalArr.push(numberSort(c, columnDetails, direction, index));
              });
            } else {
              groupingArr = splitArrayByObjProperty(numberSort(groupingArr || data, columnDetails, direction, index), columnDetails().columnPropName[index], c);
            }
            break;
          // TODO: not completed as we don't have a grouped sorting column that includes time yet
          case constants.COLUMN_DATA_TYPE_TIME:
            groupingArr = timeSort(groupingArr || data, columnDetails, direction, index);
            break;
          default:
            break;
        }
      });

      // fill return arr with sorted items
      globalArr.forEach(c => {
        c.forEach(d => {
          returnArr.push(d);
        });
      });

      // append the sorted generic vehicles without YMM
      genericNoYMMGlobalGrouping.forEach(c => {
        c.forEach(d => {
          returnArr.push(d);
        });
      });

      return returnArr;
    }
    switch (dataType) {
      case constants.COLUMN_DATA_TYPE_STRING:
        break;
      case constants.COLUMN_DATA_TYPE_NUMBER:
        return numberSort(data, columnDetails, direction);
      case constants.COLUMN_DATA_TYPE_TIME:
        return timeSort(data, columnDetails, direction);
      default:
        break;
    }

  };

  // starting point
  return sortingRoute(columnDetails().dataType, Array.isArray(columnDetails().dataType));
};
