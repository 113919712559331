import styled from '../../../helpers/esm-styled-components';
import Dropdown from '../../../UIComponents/Dropdown/Dropdown';
import * as constants from '../../../constants/Constants';
import { theme } from '../../../helpers/GlobalStyles';
import Toggle from '../../../UIComponents/Toggle/Toggle';

const DetailGrid = styled.div`
  display: grid;
  padding: 2px 35px;
  grid-row-gap: 30px;
  position: relative;
  grid-template-columns: repeat(1, 335px);
  grid-template-rows: min-content;
  align-content: start;
  grid-template-areas:
    'rotPattern'
    'tpms'
    'spareUsed';
`;
const RotationPattern = styled(Dropdown)`
  grid-area: rotPattern;
  width: 220px;
  height: 40px;
`;
const TPMS = styled(Dropdown)`
  grid-area: tpms;
  width: 220px;
  height: 40px;
`;
const SpareUsed = styled(Dropdown)`
  grid-area: spareUsed;
  width: 220px;
  height: 40px;
  margin-bottom: 18px;
`;
const ToggleWrapper = styled.div`
  top: 10px;
  left: 120px;
  position: absolute;
  width: fit-content;
  height: 50px;
  border-radius: 8px;
  padding: 0px;
  background-color: ${theme.colors.offWhite};
  display: inline;
`;
const ToggleLabelContainer = styled.div`
  position: relative;
  z-index: 2;
  margin-top: -30px;
`;
const ToggleLabel = styled.p`
  height: 19px;
  width: max-content;
  color: #333333;
  font-family: ${theme.fontFamily.normal};
  font-size: 16px;
  letter-spacing: -0.07px;
  line-height: 19px;
  float: left;
`;

const TireInformationDetails = props => {
  const {
    setInspectionDetails,
    inspectionDetails,
    isDually,
  } = props;
  const rotationPatternProps = {
    fieldName: 'rotationPattern',
    placeholder: 'Rotation Pattern',
    options: isDually ? constants.ROTATION_PATTERN_DUALLY_DD_VALUES : constants.ROTATION_PATTERN_DD_VALUES,
    onChange: setInspectionDetails,
    selectedOption: isDually ? constants.ROTATION_PATTERN_DUALLY_DD_VALUES.filter(option => option.value === inspectionDetails.rotationPattern)[0]
      : constants.ROTATION_PATTERN_DD_VALUES.filter(option => option.value === inspectionDetails.rotationPattern)[0],
    value: inspectionDetails.rotationPattern,
    required: false,
    positionStyle: 'absolute',
    dropdownHeight: 150,
  };
  const tpmsProps = {
    fieldName: 'tpmsStatus',
    placeholder: constants.TPMS,
    options: constants.TPMS_DD_VALUES,
    onChange: setInspectionDetails,
    selectedOption: constants.TPMS_DD_VALUES.filter(option => option.label === inspectionDetails.tpmsStatus)[0],
    value: inspectionDetails.tpmsStatus,
    required: false,
    positionStyle: 'absolute',
    dropdownHeight: 150,
  };

  const spareUsedProps = {
    fieldName: 'isSpareInUse',
    placeholder: 'Spare Used',
    /* Keeping this here when backend supports specific tires
        options: isDually ? constants.SPARE_USED_DUALLY_DD_VALUES : constants.SPARE_USED_DD_VALUES, */
    options: constants.SPARE_USED_DD_VALUES,
    onChange: setInspectionDetails,
    selectedOption: constants.SPARE_USED_DD_VALUES.filter(option => option.value === inspectionDetails.isSpareInUse)[0],
    value: inspectionDetails.isSpareInUse,
    required: false,
    positionStyle: 'absolute',
    dropdownHeight: 150,
  };
  return (
    <DetailGrid>
      <RotationPattern {...rotationPatternProps} />
      <TPMS {...tpmsProps} />
      <SpareUsed {...spareUsedProps} />
      <ToggleLabelContainer>
        <ToggleLabel>{constants.DOT_OPT_IN_LABEL}</ToggleLabel>
        <ToggleWrapper data-testid={constants.DOT_OPT_IN_LABEL}>
          <Toggle
            wrapperWidth={100}
            active={inspectionDetails.dotCommunicationOptIn}
            name={constants.YES}
            position={constants.LEFT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.dotCommunicationOptIn, 'dotCommunicationOptIn')}
          />
          <Toggle
            wrapperWidth={100}
            active={!inspectionDetails.dotCommunicationOptIn}
            name={constants.NO}
            position={constants.RIGHT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.dotCommunicationOptIn, 'dotCommunicationOptIn')}
          />
        </ToggleWrapper>
      </ToggleLabelContainer>
      <ToggleLabelContainer>
        <ToggleLabel>{constants.WHEEL_LOCK_LABEL}</ToggleLabel>
        <ToggleWrapper data-testid={constants.WHEEL_LOCK_LABEL}>
          <Toggle
            wrapperWidth={100}
            active={inspectionDetails.wheelLock}
            name={constants.YES}
            position={constants.LEFT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.wheelLock, 'wheelLock')}
          />
          <Toggle
            wrapperWidth={100}
            active={!inspectionDetails.wheelLock}
            name={constants.NO}
            position={constants.RIGHT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.wheelLock, 'wheelLock')}
          />
        </ToggleWrapper>
      </ToggleLabelContainer>
      <ToggleLabelContainer>
        <ToggleLabel>{constants.CARRY_OUT_LABEL}</ToggleLabel>
        <ToggleWrapper data-testid={constants.CARRY_OUT_LABEL}>
          <Toggle
            wrapperWidth={100}
            active={inspectionDetails.isCarryOut}
            name={constants.YES}
            position={constants.LEFT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.isCarryOut, 'isCarryOut')}
          />
          <Toggle
            wrapperWidth={100}
            active={!inspectionDetails.isCarryOut}
            name={constants.NO}
            position={constants.RIGHT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.isCarryOut, 'isCarryOut')}
          />
        </ToggleWrapper>
      </ToggleLabelContainer>
      <ToggleLabelContainer>
        <ToggleLabel>{constants.REPLACE_TIRES_LABEL}</ToggleLabel>
        <ToggleWrapper data-testid={constants.REPLACE_TIRES_LABEL}>
          <Toggle
            wrapperWidth={100}
            active={inspectionDetails.replaceAllTires}
            name={constants.YES}
            position={constants.LEFT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.replaceAllTires, 'replaceAllTires')}
          />
          <Toggle
            wrapperWidth={100}
            active={!inspectionDetails.replaceAllTires}
            name={constants.NO}
            position={constants.RIGHT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.replaceAllTires, 'replaceAllTires')}
          />
        </ToggleWrapper>
      </ToggleLabelContainer>
      <ToggleLabelContainer>
        <ToggleLabel>{constants.REPLACE_WHEELS_LABEL}</ToggleLabel>
        <ToggleWrapper data-testid={constants.REPLACE_WHEELS_LABEL}>
          <Toggle
            wrapperWidth={100}
            active={inspectionDetails.replaceAllWheels}
            name={constants.YES}
            position={constants.LEFT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.replaceAllWheels, 'replaceAllWheels')}
          />
          <Toggle
            wrapperWidth={100}
            active={!inspectionDetails.replaceAllWheels}
            name={constants.NO}
            position={constants.RIGHT}
            size={constants.TOGGLE_SIZE_SMALL}
            toggleClick={(e) => setInspectionDetails(!inspectionDetails.replaceAllWheels, 'replaceAllWheels')}
          />
        </ToggleWrapper>
      </ToggleLabelContainer>
    </DetailGrid>
  );
};

export default TireInformationDetails;
