export const AAAStoreCodeMap = {
  'ALB 01': 2199,
  'ALB 03': 2353,
  'ALM 01': 2218,
  'ALM 02': 2282,
  'ALM 03': 2284,
  'ALN 01': 2097,
  'ALN 02': 2268,
  'ALS 01': 2324,
  'CAL 01': 1038,
  'CAL 02': 1039,
  'CAL 03': 1040,
  'CAL 04': 1041,
  'CAL 05': 1042,
  'CAL 06': 1043,
  'CAL 07': 1044,
  'CAL 08': 1045,
  'CAL 09': 1046,
  'CAL 10': 1047,
  'CAL 11': 1048,
  'CAL 12': 1330,
  'CAL 13': 1331,
  'CAL 14': 1383,
  'CAL 15': 1345,
  'CAL 17': 1347,
  'CAL 18': 1348,
  'CAL 19': 1476,
  'CAL 20': 1370,
  'CAL 21': 1539,
  'CAL 22': 1372,
  'CAL 23': 1760,
  'CAL 24': 1526,
  'CAL 25': 1544,
  'CAL 26': 1546,
  'CAL 28': 1548,
  'CAL 29': 1597,
  'CAL 31': 1700,
  'CAL 33': 1709,
  'CAL 35': 1752,
  'CAL 37': 1785,
  'CAL 38': 1813,
  'CAL 40': 1870,
  'CAL 41': 1876,
  'CAL 42': 1892,
  'CAL 43': 1901,
  'CAL 44': 1991,
  'CAL 46': 2046,
  'CAL 47': 2061,
  'CAL 48': 2107,
  'CAL 49': 2248,
  'CAL 50': 2273,
  'CAO 01': 1734,
  'CAO 03': 1821,
  'CAO 05': 1948,
  'CAO 06': 2308,
  'CAS 01': 1065,
  'CAS 02': 1066,
  'CAS 03': 1067,
  'CAS 04': 1068,
  'CAS 05': 1069,
  'CAS 06': 1070,
  'CAS 07': 1355,
  'CAS 08': 1072,
  'CAS 09': 1073,
  'CAS 10': 1074,
  'CAS 11': 1075,
  'CAS 12': 1076,
  'CAS 13': 1077,
  'CAS 14': 1078,
  'CAS 15': 1079,
  'CAS 16': 1080,
  'CAS 17': 1081,
  'CAS 18': 1082,
  'CAS 19': 1083,
  'CAS 20': 1084,
  'CAS 21': 1085,
  'CAS 22': 1086,
  'CAS 23': 1087,
  'CAS 24': 1286,
  'CAS 25': 1458,
  'CAS 27': 1357,
  'CAS 28': 1374,
  'CAS 31': 1385,
  'CAS 32': 1392,
  'CAS 33': 1390,
  'CAS 34': 1417,
  'CAS 35': 1506,
  'CAS 36': 1518,
  'CAS 37': 1521,
  'CAS 38': 1549,
  'CAS 39': 1572,
  'CAS 40': 1639,
  'CAS 41': 1663,
  'CAS 42': 1711,
  'CAS 43': 1841,
  'CAS 44': 1872,
  'CAS 45': 1890,
  'CAS 46': 1879,
  'CAS 47': 1904,
  'CAS 48': 2011,
  'CAS 49': 2081,
  'CAS 50': 2178,
  'CAS 51': 2203,
  'IDB 01': 1921,
  'IDB 03': 1926,
  'IDB 04': 2009,
  'IDB 05': 2039,
  'IDB 06': 2048,
  'IDB 07': 2060,
  'IDN 01': 1791,
  'KSK 01': 1989,
  'KSK 02': 2042,
  'KSK 03': 2055,
  'KSK 04': 2059,
  'KSK 05': 2145,
  'KSK 06': 2322,
  'KST 01': 2360,
  'MOK 01': 1934,
  'MOK 02': 1935,
  'MOK 03': 1957,
  'MOK 05': 2020,
  'MOK 06': 2033,
  'MOK 07': 2177,
  'MON 01': 2049,
  'MOO 01': 2013,
  'MOO 02': 2062,
  'MOO 03': 2157,
  'MOO 04': 2299,
  'MOR 01': 2323,
  'MOS 01': 2231,
  'MOS 02': 2263,
  'MOS 03': 2275,
  'MOS 04': 2286,
  'MOS 05': 2288,
  'MOS 06': 2293,
  'MOS 07': 2314,
  'MOS 08': 2315,
  'MOS 09': 2318,
  'MOS 10': 2337,
  'MOS 12': 2359,
  'NMA 01': 1160,
  'NMA 02': 1161,
  'NMA 03': 1354,
  'NMA 04': 1162,
  'NMA 05': 1163,
  'NMA 06': 1467,
  'NMA 07': 1492,
  'NMA 08': 1671,
  'NMA 09': 1747,
  'NMA 10': 1773,
  'NMA 11': 1908,
  'NMA 12': 2221,
  'NMC 01': 1638,
  'NMF 01': 1650,
  'NMH 01': 1774,
  'NML 01': 1164,
  'NMR 01': 1728,
  'NMS 02': 1743,
  'NMS 03': 2197,
  'ORM 01': 2069,
  'ORM 02': 2317,
  'ORP 01': 1173,
  'ORP 02': 1174,
  'ORP 03': 1175,
  'ORP 04': 1176,
  'ORP 05': 1396,
  'ORP 07': 1634,
  'ORP 08': 1693,
  'ORP 09': 1897,
  'ORP 10': 1903,
  'ORP 11': 2025,
  'ORP 12': 2311,
  'PAC 01': 2348,
  'PAN 01': 2321,
  'PAN 02': 2336,
  'PAW 01': 2186,
  'PAW 02': 2295,
  'TXD 04': 1185,
  'TXD 11': 1306,
  'TXD 25': 1205,
  'TXD 30': 1293,
  'TXD 45': 1443,
  'TXD 79': 1986,
  'TXD 85': 2139,
  'TXD 89': 2172,
  'TXD 93': 2235,
  'TXE 01': 1208,
  'TXE 02': 1209,
  'TXE 03': 1557,
  'TXE 05': 1839,
  'TXE 06': 1995,
  'TXE 07': 2127,
  'TXL 01': 1244,
  'TXL 02': 1245,
  'TXL 03': 1567,
  'TXL 04': 1808,
  'TXL 05': 2277,
  'TXM 01': 1246,
  'TXM 02': 1731,
  'TXM 03': 2201,
  'TXO 01': 1318,
  'TXO 02': 1959,
  'TXW 01': 1255,
  'TXW 02': 1409,
  'TXW 03': 1439,
  'TXW 04': 1523,
  'TXW 05': 1761,
  'TXW 06': 1939,
  'TXW 07': 1936,
  'TXW 08': 2028,
  'TXW 09': 2041,
  'VAB 01': 2057,
  'VAB 02': 2065,
  'VAB 03': 2066,
  'VAB 04': 2074,
  'VAB 05': 2091,
  'VAB 06': 2124,
  'VAB 07': 2207,
  'VAB 08': 2209,
  'VAB 09': 2257,
  'VAF 01': 2230,
  'VAR 01': 2045,
  'VAR 02': 2102,
  'VAR 03': 2109,
  'VAR 04': 2125,
  'VAR 05': 2215,
  'VAR 06': 2229,
  'VAR 07': 2272,
  'VAT 01': 2162,
  'VAW 01': 2185,
  'VAW 02': 2279,
  'WAE 01': 1263,
  'WAE 02': 1264,
  'WAE 03': 1818,
  'WAE 04': 1850,
  'WAS 01': 1265,
  'WAS 02': 1266,
  'WAS 03': 1267,
  'WAS 04': 1268,
  'WAS 05': 1269,
  'WAS 06': 1270,
  'WAS 08': 1272,
  'WAS 09': 1273,
  'WAS 11': 1275,
  'WAS 12': 1276,
  'WAS 13': 1277,
  'WAS 14': 1449,
  'WAS 15': 1589,
  'WAS 17': 1698,
  'WAS 18': 1778,
  'WAS 19': 1683,
  'WAS 20': 1724,
  'WAS 21': 1745,
  'WAS 22': 1759,
  'WAS 23': 1827,
  'WAS 24': 1838,
  'WAS 25': 1855,
  'WAS 26': 1925,
  'WAS 27': 2021,
  'WAS 28': 2129,
  'WAS 29': 2342,
  'WAV 01': 1278,
  'WAV 02': 1905,
  'WAY 01': 2148,
  'WVE 01': 2259,
  'ZCS 02': 7018,
};
