import { formatAlphaNumeric, isNullOrUndefined, validateVIN } from '../../helper/validationHelper';
import * as constants from '../../constants/Constants';

export const handleInput = (fields, setFields, value, fieldName) => {
  if (fieldName === 'licensePlate') {
    value = value.toUpperCase();
  }
  setFields({ ...fields, [fieldName]: formatAlphaNumeric(value) });
};

export const searchBy = (e, buttonName, fields, setSearchQuery, setModuleName, setSearchOrigin, modalType, setErrorMessageObj) => {
  if (buttonName === constants.TITLE_SEARCH_BY_VIN) {
    if (!validateVIN(fields?.vin)) {
      setErrorMessageObj({ message: constants.ERROR_MESSAGE_VIN, type: constants.ERROR });
    }
    if (validateVIN(fields?.vin)) {
      setErrorMessageObj({});
      setSearchQuery({ vin: fields.vin });
      setSearchOrigin(constants.SEARCH_ORIGIN_VIN);
    } else {
      setErrorMessageObj({ message: constants.ERROR_MESSAGE_VIN, type: constants.ERROR });
      return;
    }
  }
  if (buttonName === constants.TITLE_SEARCH_BY_LICENSE
    && fields.licensePlate.length < 11
    && fields.licenseState
    && fields.licenseCountry) {
    const normalizedLicensePlate = fields.licensePlate.toUpperCase();
    setSearchQuery({
      licensePlate: normalizedLicensePlate,
      licenseState: fields.licenseState.value,
      licenseCountry: fields.licenseCountry.value,
    });
    setSearchOrigin(constants.SEARCH_ORIGIN_LICENSE_PLATE);
  }

  if (!isNullOrUndefined(modalType)) {
    // add any additional needed logic here if needed
    switch (modalType) {
      case constants.MODAL_TYPE_ADD_NEW_VEHICLE:
        setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
        break;
    }
  }
};
