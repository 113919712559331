import React from 'react';
import styled from '../../../helpers/esm-styled-components';
import { theme } from '../../../helpers/GlobalStyles';
import {
  CloseIcon, ModalContainer, ContentContainer, ModalTitle, ModalOverlay,
} from '../ModalUtils';
import RadioForm from '../../Radio/RadioForm';

const ModalWrapper = styled.div``;

const CloseIconAddress = styled(CloseIcon)`
  width: 30px;
`;

const ModalSubTitle = styled.p`
  font-family: ${theme.fontFamily.normal};
  font-size: ${theme.fontSize.large};
  color: ${theme.colors.titleBlack};
  width: 687px;
  margin: 15px 0 25px 0;
`;

const AddressModal = props => {
  const {
    type,
    title,
    subtitle,
    returnToModule,
    values,
    onEdit,
    onSubmit,
  } = props;

  return (
    <>
      <ModalOverlay />
      <ModalWrapper>
        <ModalContainer subtitle={subtitle} body={values}>
          <CloseIconAddress onClick={returnToModule} />
          <ContentContainer>
            <ModalTitle body>{title}</ModalTitle>
            {subtitle && <ModalSubTitle>{subtitle}</ModalSubTitle>}
            {values && (
            <RadioForm
              isOneOption={values[0] === null}
              formType={type}
              values={values}
              onEdit={onEdit}
              onSubmit={onSubmit}
              returnToModule={returnToModule}/>
            )}
          </ContentContainer>
        </ModalContainer>
      </ModalWrapper>
    </>
  );
};

export default AddressModal;
