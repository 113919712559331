/**
 * Holds a list of functions that are utilized throughout the standard customer add/edit process
 * */
import moment from 'moment';
import * as constants from '../constants/Constants';
import { APIRouter } from '../Data/APIRouter';
import {
  addUpdateCustomerVehicle,
  deactivateOneTimeUseVehicles,
  isVehicleActive,
  splitActiveInactiveVehicles,
  vtvAircheckAddVehicle
} from '../UIComponents/Modals/VehicleModals/StandardVehicle/VehicleHelper';
import {
  checkIfObjectHasKey,
  isEmptyObject, isNullEmptyUndefined, removeLeadingZeros, removePhoneMask, upperCaseFirstLetter
} from './validationHelper';
import {
  getUpdatedVehicleAssembly, getVehicleAssemblies, getVehicleInfo, isIncompleteVehicle
} from './vehicleFormValidation';
import { formatCustomerRequest } from './baseDataHelper';
import { validateEmailAddress } from './customerFormValidation';

// NOTE: While we get proper error codes error propagation relies on what is returned when invalid most cases (undefined)

/**
 * @param {Object} obj Customer object from C360
 * @returns {Object} containing a deep copy of the Object with no prev Object references */
export const deepObjectCopy = (obj) => JSON.parse(JSON.stringify(obj));

/**
 * @param {Object} obj Customer object from C360
 * @param {Boolean} isTable C360 customer ID
 * @returns {String} containing the customer full name for B2C and company names fleet for B2B */
export const getFullName = (obj, isTable) => {
  if (!obj) return;
  const hideCompanyName = obj?.alternateIdentifierCollection?.find(ai => ai.alternateIdentifierItem?.name === 'EMPLOYEE ID') && obj?.customerType === constants.STANDARD_CUSTOMER_TYPE;
  const isBusiness = obj.customerType === constants.BUSINESS_CUSTOMER_TYPE;
  const fleetInfo = obj?.alternateIdentifierCollection?.find((aii) => aii.alternateIdentifierItem?.name === 'SAP NUMBER')?.alternateIdentifierItem?.value;
  let fullName = !isBusiness
    ? `${upperCaseFirstLetter(obj?.firstName || constants.EMPTY_STRING)} ${obj?.middleName?.toUpperCase() || constants.EMPTY_STRING} ${upperCaseFirstLetter(obj?.lastName || constants.EMPTY_STRING)} ${obj?.suffix || constants.EMPTY_STRING}`
    : `${upperCaseFirstLetter(obj?.companyName || constants.EMPTY_STRING)} ${obj?.companyName2 ? ` / ${upperCaseFirstLetter(obj.companyName2)}` : constants.EMPTY_STRING}`;
  if (!isBusiness && obj?.companyName && !hideCompanyName) {
    fullName += ` / ${upperCaseFirstLetter(obj.companyName)}`;
  }
  if (fleetInfo && !isTable && isBusiness) fullName += `- #${removeLeadingZeros(fleetInfo)}`;
  return fullName;
};

/**
 * @param {String} id C360 customer ID
 * @returns {Object} containing the customer from C360, or empty object if not found */
export const getCustomer = async (id, setLoader) => {
  let customer = await APIRouter('C360', 'getCustomer', id, setLoader);
  customer = customer?.customer;
  if (customer === undefined) throw new Error(constants.GET_CUSTOMER_ERROR);
  return customer;
};

/**
 */
export const processCustomerVehicles = async (vehicles) => {
  vehicles?.forEach(async veh => {
    const isIncomplete = veh.type !== constants.VEHICLE_TYPE_GENERIC ? await isIncompleteVehicle(veh) : false;
    veh.isIncomplete = isIncomplete;
  });

  deactivateOneTimeUseVehicles(vehicles);
};

/**
 * @param {String} id C360 customer ID
 * @param {Object} query
 * @param {Function} deactivateOneTimeUseVehicles TO DO: extract and place the functionality in here as a callback
 * @returns {Object} containing the vehicles, or empty object if not found */
export const getCustomerVehicles = async (id, query, deactivateOneTimeUseVehicles, setLoader) => {
  if (!id) return [[], []];

  const vehicles = await APIRouter('C360', 'getCustomerVehicles', [id, query], setLoader);

  await processCustomerVehicles(vehicles, deactivateOneTimeUseVehicles);

  return splitActiveInactiveVehicles(vehicles);
};

/**
 * @param {Array} vehicles vehicles array from getCustomerVehicles
 * @param {Object} selectedVehicle Object containing the currently selected vehicle
 * @returns {Object} containing the vehicles, or empty object if not found */
export const isViewAllVehiclesEnabled =  (vehicles, selectedVehicle) => {
  if ((vehicles?.filter((v) => isVehicleActive(v)).length > 1 && selectedVehicle)
  || (vehicles?.filter((v) => isVehicleActive(v)).length >= 3 && !selectedVehicle)) {
    return true;
  }
  return false;
};
/**
 * @param {String} id C360 customer ID
 * @returns {Object} containing the tax information, undefined if not found at all, and nested obj with null info for mismatch state */
export const getCustomerTaxInfo = async (id, setLoader) => {
  const storeState = localStorage.getItem('siteState');
  const taxInfo = await APIRouter('TAX', 'getTaxExemptInfo', [[{ customerId: id, state: storeState, customerType: constants.STANDARD_CUSTOMER_TYPE }]], setLoader);
  const expirationDate = moment().endOf('day').add(1, 'day').format('YYYY-MM-DD');
  if (taxInfo?.taxCertificateNumber) {
    if ((taxInfo?.expirationDate && moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(expirationDate)) || taxInfo.status === 'EXPIRED') {
      taxInfo.status = constants.STATUS_EXPIRED;
    }
    return taxInfo;
  }
};

/**
 * @param {Object} details
 * @returns {Object} structured customer for get/add for inspections */
export const getCustomerDetails = (details) => {
  return {
    firstName: details?.firstName || constants.EMPTY_STRING,
    lastName: details?.lastName || constants.EMPTY_STRING,
    phoneNumber: details?.phone ?  details?.phone.replace(/[^0-9]/g, constants.EMPTY_STRING) : constants.EMPTY_STRING,
    email: details?.email || constants.EMPTY_STRING
  };
};

/**
 * @param {Object} query Object containing first & last name, phone, or email
 * @returns {Object} containing the first customer from res or empty object if not found */
export const getCustomerByDetails = async (req, setLoader) => {
  const customer = await APIRouter('C360', 'getCustomers', req, setLoader);
  if (customer[0] === undefined) throw new Error(constants.GET_CUSTOMER_ERROR);
  return customer || {};
};
/**
 * @param {Object} query Object containing first & last name, phone, or email
 * @returns {Object} containing the first customer from res or empty object if not found */
export const addCustomerByDetails = async (req, setLoader) => {
  let customer;
  customer = await APIRouter('C360', 'addCustomer', req, setLoader);
  // Need to see what the actual fail response is then
  if (!isNullEmptyUndefined(customer?.customer?.customerId)) {
    customer = await getCustomer(customer.customer.customerId);
  }
  throw new Error(constants.POST_CUSTOMER_ERROR);
};

// TO DO: getInspection & addVehicleDetails will be removed after C360 ID integration in VTV

/**
 * @param {Object} query Object containing first & last name, phone, or email
 * @returns {Object} containing the first customer from res or empty object if not found */
export const addVehicleByDetails = async (vehicle, customer,  getStateOptions, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, setLoader, setImportedVehicle) => {
  const vehicleDetails = vtvAircheckAddVehicle(vehicle, getStateOptions);
  vehicleDetails[0].assemblyCollection = await getVehicleAssemblies(vehicleParams[0], setLoader);
  if (isEmptyObject(vehicleDetails[0].assemblyCollection)) throw new Error(constants.VEHICLE_ADD_ERROR);
  let assemblyMatch;
  if (!assemblyMatch) throw new Error(constants.VEHICLE_ADD_ERROR);
  vehicleDetails[0] = getUpdatedVehicleAssembly(vehicleDetails[0], assemblyMatch[0]);
  vehicleDetails[0].assemblyCollection = vehicleDetails[0].assemblyCollection?.filter((assembly) => assembly.assemblyItem && assembly.assemblyItem);
  await addUpdateCustomerVehicle(null, true, false, { ...customer }, null, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, vehicleDetails[1], vehicleDetails[0], false, null, null, null, null, null, setImportedVehicle, false);
};

/**
 * @param {String} id VTV inpsection ID
 * @returns {Object} containing the vehicles, or empty object if not found */
export const getCustomerFromInspection = async (inspection, setLoader) => {
  let customer;
  const customerDetails = getCustomerDetails(inspection.customer);
  try {
    customer = await getCustomer(inspection.customer.customerId);
    if (isNullEmptyUndefined(customer)) customer = await getCustomerByDetails(customerDetails, setLoader);
  } catch (e) {
    if (e.message === constants.GET_CUSTOMER_ERROR) {
      if (isNullEmptyUndefined(customer)) customer = await addCustomerByDetails(customerDetails, setLoader);
    } else {
      throw new Error(e.message);
    }
  }
  return customer || {};
};
/**
 * @param {String} id VTV inpsection ID
 * @param {Object} customer VTV inpsection
 * @param {Function} getStateOptions API helper function for getting state format for vehicles
 * @param {Function} setIsModalOn modal setter function for addVehicle function
 * @param {Function} setModuleName module setter function for addVehicle function
 * @param {Function} setSelectedVehicle selected vehicle setter function for addVehicle function
 * @param {Function} setLoader loader setter function for addVehicle function
 * @returns {Object} containing the desired inspection or newly created if customer/vehicle not found, or empty object if cannot create */
export const getInspection = async (id, customer,  getStateOptions, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, setLoader, setImportedVehicle) => {
  let
    customerVehicles,
    inspection = await APIRouter('VTV', 'getVTVInspections', { inspectionId: id }, setLoader);
  if (isEmptyObject(inspection[0])) throw new Error(constants.GET_INSPECTION_ERROR);
  inspection = inspection[0];
  const { vehicle } = inspection;
  try {
    if (!isNullEmptyUndefined(customer)) {
      // TODO: should be able to search by VIN here...
      customerVehicles = await getCustomerVehicles(customer.id, null, null, setLoader);
      const vehicleMatch = customerVehicles; // WIP
      if (isNullEmptyUndefined(vehicleMatch)) {
        await addVehicleByDetails(vehicle, customer,  getStateOptions, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, setLoader, setImportedVehicle);
      }
      let tireDetails = await getVehicleInfo(vehicleMatch, setLoader, true);
      if (tireDetails) {
        tireDetails = tireDetails?.vehicleTireData;
        vehicleMatch.vehicleTireData = tireDetails;
      }
      setSelectedVehicle({
        vehicleDetails: vehicleMatch,
        tireDetails,
        inspection
      });
    }
  } catch (e) {
    console.error(e.message);
  }
};

// FieldError Validation for Create & Edit
export const editFieldLengthMet = (fields) => {
  let errorObject = {};
  if (fields) {
    Object?.keys(fields).forEach(field => {
      switch (field) {
        case 'phoneNumber':
          if ((removePhoneMask(fields.phoneNumber)?.length < 10)) {
            errorObject = { ...errorObject, [field]: constants.PHONE_LENGTH };
          }
          break;
        case 'workNumber':
          if ((removePhoneMask(fields.workNumber)?.length < 10)) {
            errorObject = { ...errorObject, [field]: constants.PHONE_LENGTH };
          }
          break;
        case 'mobileNumber':
          if ((removePhoneMask(fields.mobileNumber)?.length < 10)) {
            errorObject = { ...errorObject, [field]: constants.PHONE_LENGTH };
          }
          break;
        case 'zip':
        case 'postalCode':
          if (fields[field].length < 5) errorObject = { ...errorObject, [field]: constants.ZIP_LENGTH };
          break;
        case 'firstName':
        case 'lastName':
        case 'address1':
        case 'addressLine1':
        case 'city':
          if (fields[field].length < 2) errorObject = { ...errorObject, [field]: 'Must be at least 2 characters' };
          break;
        case 'companyName':
          if (fields[field].length && fields[field].length < 2) errorObject = { ...errorObject, [field]: 'Must be at least 2 characters' };
          break;
        case 'state':
          if ((fields[field]?.length) < 2) {
            errorObject = { ...errorObject, [field]: 'Must be at least 2 characters' };
          } else if ((fields[field]?.value?.length) < 2) {
            errorObject = { ...errorObject, [field]: 'Must be at least 2 characters' };
          }
          break;
        case 'email':
          if (!validateEmailAddress(fields[field])) {
            errorObject = { ...errorObject, [field]: constants.INVALID_EMAIL };
          }
          break;
        default:
          break;
      }
    });
  }
  return errorObject;
};

export const isB2BAddressValid = (obj) =>  !!(obj?.address1 && obj?.state && obj?.zip && obj?.state && obj?.country);
export const clearAlternateInfo = (obj) => {
  return {
    address1: constants.EMPTY_STRING,
    address2: constants.EMPTY_STRING,
    addressId: obj.addressId,
    city: constants.EMPTY_STRING,
    country: constants.EMPTY_STRING,
    email: constants.EMPTY_STRING,
    emailId: obj.emailId,
    isAddressValidated: false,
    mobileCountryCode: constants.EMPTY_STRING,
    mobileId: obj.mobileId,
    mobileNumber: constants.EMPTY_STRING,
    phoneCountryCode: constants.EMPTY_STRING,
    phoneId: obj.phoneId,
    phoneNumber: constants.EMPTY_STRING,
    state: constants.EMPTY_STRING,
    workCountryCode: constants.EMPTY_STRING,
    workExt: constants.EMPTY_STRING,
    workId: obj.workId,
    workNumber: constants.EMPTY_STRING,
    zip: constants.EMPTY_STRING
  };
};

// NOTE: Exported the update data transform for both Modal and Summary update
export const getFormattedUpdateCustomer = (data, initialCustomerInfo) => {
  // converting dropdown object values into strings for API
  if (checkIfObjectHasKey(data?.state, 'value')) {
    data.state = data?.state.value;
  }
  if (checkIfObjectHasKey(data?.country, 'label')) {
    data.country = data?.country.label;
  }
  if (checkIfObjectHasKey(data?.alternate?.state, 'value')) {
    data.alternate.state = data?.alternate?.state?.value;
  }

  if (checkIfObjectHasKey(data?.alternate?.country, 'label')) {
    data.alternate.country = data?.alternate?.country?.label;
  }
  if (checkIfObjectHasKey(data?.suffix, 'value')) {
    data.suffix = data?.suffix?.value;
  }
  // Customer Create Restructure
  return formatCustomerRequest(data, constants.EDIT_FORM, initialCustomerInfo);
};
