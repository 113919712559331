import React, { useState } from 'react';
import moment from 'moment';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import { theme } from '../../../helpers/GlobalStyles';
import { DetailSection } from '../DetailSection';
import { taxStatusColors } from '../../../helper/baseDataHelper';
import Message from '../../../UIComponents/Error/Message';
import { isNullEmptyUndefined } from '../../../helper/validationHelper';

const TaxSection = styled(DetailSection)`
  min-width: 33%;
  ${({ isHidden }) => isHidden && 'color: grey; cursor: not-allowed;'}
`;
const TaxStatusContainer = styled.div`
  display: flex;
  width: 480px;
  flex-direction: column;
`;
const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 60px;
`;
const TaxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const TaxLabel = styled.div`
  display: flex;
  grid-gap: 5px;
  margin-bottom: 11px;
  text-align: left;
  flex-wrap: nowrap;
  font-size: ${theme.fontSize.infoSubtitle};
  color: ${theme.colors.titleBlack};
  margin-bottom: 11px;
`;
const StatusCheck = styled(TaxLabel)`
  font-family: ${theme.fontFamily.semiBold};
  margin: 0;
  ${({ statusColor }) => `
    color: ${theme.colors[statusColor]};
  `}
`;
const Bolded = styled(TaxLabel)`
  font-family: ${theme.fontFamily.semiBold};
  font-weight: ${theme.fontWeight.bold};
  margin: 0;
  ${({ statusColor }) => `
    color: ${theme.colors[statusColor]};
  `}
`;
const Notes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const TaxMessage = styled(Message)`
  padding:10px 30px;
`;
const TaxExemption = (props) => {
  const {
    customerInfo,
    titleButtonType,
    titleButtonName,
    onButtonClick,
    isVTVAircheck,
    isBlocked,
    isBusiness
  } = props;

  const [hasMissingReq, setHasMissingReq] = useState(() => {
    return isNullEmptyUndefined(customerInfo.phoneCollection && customerInfo.addressCollection);
  });

  const isUs = customerInfo.country === constants.UNITED_STATES;

  const tax = { ...customerInfo?.taxExempt };

  const taxExpirationDate = isNullEmptyUndefined(tax) ? null :
    moment(tax?.expirationDate).format('YYYY-MM-DD');
  const isExpired = isNullEmptyUndefined(taxExpirationDate) ? false :
    moment(taxExpirationDate).isBefore();

  let taxStatuses;
  switch (tax.status) {
    case 'ACCEPTED':
      taxStatuses = !isExpired ? constants.STATUS_APPROVED : constants.STATUS_EXPIRED;
      break;
    case constants.STATUS_EXPIRED:
      taxStatuses = constants.STATUS_EXPIRED;
      break;
    case constants.STATUS_REJECTED:
      taxStatuses = constants.STATUS_REJECTED;
      break;
    case constants.STATUS_PENDING:
    case constants.STATUS_UNKNOWN:
      taxStatuses = constants.STATUS_PENDING;
      customerInfo.taxExempt.status = constants.STATUS_PENDING;
      break;
    default:
      taxStatuses = null;
      break;
  }

  if (isBusiness && customerInfo.taxClassificationCode === '0') {
    taxStatuses = constants.STATUS_APPROVED;
  }
  return (
    <TaxSection
      title={constants.TITLE_TAX_EXEMPTION}
      isBlocked={hasMissingReq || isBlocked || isBusiness || !isUs}
      titleButtonType={titleButtonType}
      titleButtonName={taxStatuses !== constants.STATUS_REJECTED && titleButtonName}
      onButtonClick={onButtonClick}
      tax
    >
      <TaxStatusContainer>
        { tax.status &&  (
        <StatusWrapper>
          {tax.status !== constants.STATUS_STATE_MISMATCH ? (
            <>
              <TaxWrapper>
                <TaxLabel data-testid="taxStatus">
                  {`${constants.TITLE_STATUS}: `}
                  <StatusCheck statusColor={taxStatusColors[taxStatuses]}>
                    {taxStatuses}
                  </StatusCheck>
                </TaxLabel>
                {!isVTVAircheck && tax.state && (
                <TaxLabel data-testid="taxState">
                  {`${constants.TITLE_STATE}: `}
                  <Bolded>{tax.state}</Bolded>
                </TaxLabel>
                )}
              </TaxWrapper>
              <TaxWrapper>
                {tax.expirationDate && (
                <TaxLabel data-testid="taxExpiration">
                  {`${constants.TITLE_EXPIRATION}: `}
                  <Bolded>{moment(taxExpirationDate).format('MMMM DD, YYYY')}</Bolded>
                </TaxLabel>
                )}
                {!isVTVAircheck && tax.taxExemptionCategory && (
                <TaxLabel data-testid="taxExemptCategory">
                  {`${constants.TITLE_EXEMPT_CATEGORY}: `}
                  <Bolded>{tax.taxExemptionCategory}</Bolded>
                </TaxLabel>
                )}
              </TaxWrapper>
            </>
          )
            :

            <TaxMessage exemption errorMessages={constants.NO_EXEMPTION} type={constants.WARNING} />}
        </StatusWrapper>
        )}
        {!isVTVAircheck && tax.taxCertificateNumber && (
        <TaxLabel data-testid="taxCertificateNumber">
          {`${constants.TITLE_CERTIFICATE_NUMBER}:  `}
          <Bolded small={tax.taxCertificateNumber.length > 10}>{tax.taxCertificateNumber}</Bolded>
        </TaxLabel>
        )}
        { !isVTVAircheck && tax.notes && (
        <Notes>
          <TaxLabel data-testid="taxNotes">
            {`${constants.TITLE_NOTES}: `}
            <Bolded>{tax.notes}</Bolded>
          </TaxLabel>
        </Notes>
        )}
      </TaxStatusContainer>
    </TaxSection>
  );
};

export default TaxExemption;
