import DetailSection from '../../../../components/Details/DetailSection';
import styled from '../../../../helpers/esm-styled-components';
import { theme } from '../../../../helpers/GlobalStyles';
import * as constants from '../../../../constants/Constants';
import VINSearch from '../../../Input/VINSearch';
import LicenseSearch from '../../../Input/LicenseSearch';
import { handleInput } from '../../../Input/InputHelper';

const VINGrid = styled.div`
  margin-top: 30px;
  margin-left: -38px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(1,335px);
  grid-template-rows: 0;
  grid-template-areas: 'vin vinsearch';
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const LicenseGrid = styled.div`
margin-top: 8px;
margin-left: -34px;
display: grid;
grid-gap: 30px;
grid-template-columns: repeat(4, 335px);
grid-template-rows: 1fr 1fr;
grid-template-areas:
  'plate state'
  'country platesearch';
`;

export const DividerSection = styled.div`
  margin: 25px 7px 20px 7px;
`;

export const LeftHorizontalDivider = styled.hr`
  border: 1px solid ${theme.colors.gray};
  width: 300px;
  float: left;
`;

export const RightHorizontalDivider = styled.hr`
  border: 1px solid ${theme.colors.gray};
  width: 300px;
  float: right;
`;

export const OrLabel = styled.p`
  text-align: center;
  display: inline-flex;
  margin: 0 auto;
  margin-left: 40px;
  margin-top: -15px;
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.bold};
`;

const VehicleInit = (props) => {
  // props
  const {
    searchBy, errorMessage, setVinError, standardVehicleFields, setStandardVehicleFields,
  } = props;

  return (
    <>
      <DetailSection title={constants.TITLE_SEARCH_BY_VIN} isAddNewVehicle>
        <VINGrid>
          <VINSearch errorMessage={errorMessage} vehicleFields={standardVehicleFields} handleInput={(value, fieldName) => handleInput(standardVehicleFields, setStandardVehicleFields, value, fieldName)} searchBy={(e, buttonName) => searchBy(e, buttonName, null, null, null, null, null, setVinError)} />
        </VINGrid>
      </DetailSection>
      <DividerSection>
        <LeftHorizontalDivider />
        <OrLabel>Or</OrLabel>
        <RightHorizontalDivider />
      </DividerSection>
      <DetailSection title={constants.TITLE_SEARCH_BY_LICENSE} isAddNewVehicle>
        <LicenseGrid>
          <LicenseSearch fields={standardVehicleFields} setFields={setStandardVehicleFields} handleInput={(value, fieldName) => handleInput(standardVehicleFields, setStandardVehicleFields, value, fieldName)} searchBy={(e, buttonName) => searchBy(e, buttonName)} />
        </LicenseGrid>
      </DetailSection>
    </>
  );
};

export default VehicleInit;
