import React, { forwardRef, useState } from 'react';
import { IoWarning } from 'react-icons/io5';
import styled from '../../helpers/esm-styled-components';
import { theme } from '../../helpers/GlobalStyles';
import * as constants from '../../constants/Constants';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const MessageComponent = styled.div`
  ${({ messages, type }) => (messages
    ? `
      display: flex;
      align-items: center;
      justify-content: ${type === constants.WARNING ? 'center' : 'initial'};
      min-height: ${type === constants.WARNING ? '35px' : '30px'};
      width: ${type === constants.WARNING && '1065px'};
      padding: ${type === constants.WARNING ? '6px' : '2px'};
      background-color: ${type === constants.WARNING ? theme.colors.white : theme.colors.orange};
      border: 1px solid ${theme.colors.orange};
      border-width: ${type === constants.WARNING ? '2px 0' : 'initial'};
      left: 0;
      `
    : 'display: none;')};
`;
const FieldMessage = styled(MessageComponent)`
  position: absolute;
  ${({ field, fullWidth }) => (field
    ? `width: 159px;
    height: 60px;
    margin-left:${fullWidth && '176px'} `
    : 'width:-webkit-fill-available')};
  margin-bottom: 0;
  ${({ manualTop }) => manualTop && `top: ${manualTop}px`};
  ${({ isPhone }) => isPhone && 'left: -2px; right: -2px;'};
`;
const BannerMessage = styled(MessageComponent)`
  margin-bottom: 20px;
  ${({ fullWidth }) => fullWidth && 'width:1560px;'};
  ${({ exemption }) => exemption && 'width:470px; height:52px'};
  ${({ manualWidth }) => manualWidth && `width:${manualWidth};`};
`;
const WarningIcon = styled.span`
  font-family: 'Material Icons';
  height: 18px;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga';
   ${({ type, message }) => `
    margin-right: ${message === constants.NO_EXEMPTION && '-48px'};
    color: ${type === constants.WARNING ? theme.colors.orange : theme.colors.white};
    font-size: ${type === constants.WARNING ? '18px' : '15px'};
    line-height: ${type === constants.WARNING ? '18px' : '15px'};
    width: ${type === constants.WARNING ? '25px' : '23px'};
  `}
`;
const WarningText = styled.span`
  letter-spacing: 0;
  font-family: ${theme.fontFamily.bold};
  color: ${theme.colors.darkGray};
  line-height: '12px';
  ${({ type }) => `
    font-size: ${type === constants.WARNING ? '14px' : '12px'};
  `}
`;
const Bolded = styled.span`
  font-family: ${theme.fontFamily.black};
  font-weight: ${theme.fontWeight.bold};
  ${({ exemption }) => exemption && 'margin-left:50px'};
`;
const MessageText = styled.span``;

const getMessageText = (message) => {
  const isNoRecordFoundMessage = message === constants.NO_CUSTOMER_FOUND
    || message === constants.NO_VEHICLE_FOUND
    || message === constants.RESULT_EXCEED_LIMIT
    || message === constants.NO_RESULTS_SEARCH
    || message === constants.NO_EXEMPTION;
  if (!isNoRecordFoundMessage) return message;

  // conditional to handle if passing in components for message
  if(Array.isArray(message)) {
    return message.join('');
  }

  const [messageFirstPart, messageSecondPart] = message.split('-');
  return (
    <>
      <Bolded exemption={message === constants.NO_EXEMPTION}>{messageFirstPart}</Bolded>
      {message !== constants.NO_RESULTS_SEARCH && <MessageText>-</MessageText>}
      {message === constants.NO_EXEMPTION && <br/>}
      <MessageText>{messageSecondPart}</MessageText>
    </>
  );
};

const getMessages = (message, type) => {
  return (
    <MessageContainer >
      <WarningIcon type={type} message={message}>
        <IoWarning />
      </WarningIcon>
      <WarningText type={type}>
        {getMessageText(message)}
      </WarningText>
    </MessageContainer>
  );
};
const Message = forwardRef(({ errorMessages, type, fullWidth, field, exemption, manualWidth, isPhone }, ref) => {
  return (
    type === constants.ERROR
      ? (
        <FieldMessage messages={errorMessages} isPhone={isPhone} type={type} field={field} fullWidth={fullWidth} manualTop={ref?.current?.clientHeight}>
          {getMessages(errorMessages, type)}
        </FieldMessage>
      )
      : (
        <>
        <BannerMessage messages={errorMessages} type={type} fullWidth={fullWidth} exemption={exemption} manualWidth={manualWidth}>
          {getMessages(errorMessages, type)}
        </BannerMessage>
        </>

      )
  );
});

export default Message;
