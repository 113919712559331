import styled from '../../../../helpers/esm-styled-components';
import * as constants from '../../../../constants/Constants';
import Input from '../../../Input/Input';
import { HorizontalGenericDivider, VehicleInfoLabel } from '../../ModalUtils';
import RadioForm from '../../../Radio/RadioForm';
import CreateCustomerButton from '../../../../components/Details/Search/CreateButton';
import TextArea from '../../../TextArea/TextArea';
import { CharCountLabel } from '../StandardVehicle/AdditionalVehicleInformation';
import { formatBolt, formatLugNut, formatTireWidth } from '../../../../helper/vehicleFormValidation';
import { theme } from '../../../../helpers/GlobalStyles';
import Dropdown from '../../../Dropdown/Dropdown';

const VehicleInfoContainer = styled.div`
padding-right:20px;
`;
const VehicleLabelWrapper = styled.div`
  display:flex;
  justify-content:space-between;
  align-items: center;
  height:30px;
  padding-right:20px;
`;
const VehicleInfoGrid = styled.div`
  display: grid;
  height: auto;
  grid-gap: 16px;
  margin-bottom: 20px;
  grid-template-columns: repeat(5, 125px);
  grid-template-rows: 2;
  justify-content: center;
  grid-template-areas: ". . . . .";
`;

const AssemblyLabel = styled.div`
  font-size: ${theme.fontSize.tableBadge};
  margin: 0 0 8px 15px;
  ${({ leftAlign }) => leftAlign && 'margin:15px 0 0 2px;'};
  ${({ diameter }) => diameter && `margin: 15px 0 0 8px; font-size:${theme.fontSize.large}`};
`;

const TireWidth = styled(Dropdown)`
`;
const TireRatio = styled(Dropdown)`
`;
const TireDiameter = styled(Dropdown)`
`;
const TireWidthInput = styled(Input)`
`;
const TirePressure = styled(Input)`
`;
const BoltPattern = styled(Input)`
`;
const LugNutSize = styled(Input)`
`;
const Torque = styled(Input)`
`;
const AddNotesButton = styled(CreateCustomerButton)`
  text-transform: none;
  margin: 0 0 20px 15px;
  width:90px;
`;
const Notes = styled(TextArea)`
  width: 690px;
  margin: 0 auto;
`;
export const CharCountLabelGeneric = styled(CharCountLabel)`
margin-right:20px;
`;
const GenericVehicleAssembly = (props) => {

  // props
  const {
    handleInput, handleChange, fields, openNotes, notesOpen, getAssembliesDropdownProps
  } = props;

  // local variables
  const isStaggered = fields.assemblyConfiguration?.value === constants.ASSEMBLY_VALUES[2].value;
  const assembly = fields?.assemblyConfiguration?.value;

  /**
   * Fnc to handle which radio button is selected and which form to display
   * @returns the correct radio button to be selected (standard, dually, or staggered)
   */
  const checked = () => {
    if (assembly === constants.ASSEMBLY_VALUES[0].value) return 0;
    if (assembly === constants.ASSEMBLY_VALUES[1].value) return 1;
    if (assembly === constants.ASSEMBLY_VALUES[2].value) return 2;
    return 0;
  };

  /**
   * Blur handler for formatting input field values
   * @param {Event} evt
   */
  const formatOnBlur = (evt) => {
    let { value, name } = evt.target,
      formattedValue;
    switch (name) {
      case 'wheelBoltPattern':
      case 'rearWheelBoltPattern':
        formattedValue = formatBolt(value);
        break;
      case 'wheelLugNutSize':
      case 'rearWheelLugNutSize':
        formattedValue = formatLugNut(value);
        break;
      case 'wheelTorque':
      case 'rearWheelTorque':
        if (!value) break;
        formattedValue = parseFloat(value);
        if (value < 10) formattedValue = 10;
        break;
      default:
        formattedValue = value;
    }
    handleInput(formattedValue?.toString(), name);
  };

  return (
    <VehicleInfoContainer>
      <VehicleLabelWrapper>
        <VehicleInfoLabel>{constants.GENERIC_VEHICLE_ASSEMBLY_TITLE}</VehicleInfoLabel>
        <RadioForm
          formType={constants.RADIO_FORM_GENERIC_VEHICLE}
          handleChange={handleChange}
          values={constants.ASSEMBLY_VALUES}
          checked={checked()}
          generic
        />
      </VehicleLabelWrapper>
      <HorizontalGenericDivider generic />
      {!isStaggered && (
        <AssemblyLabel>{constants.GENERIC_VEHICLE_TIRE_TITLE}</AssemblyLabel>
      )}
      <VehicleInfoGrid>
        {isStaggered && (
          <AssemblyLabel leftAlign>
            {constants.GENERIC_VEHICLE_FRONT_TIRES}
          </AssemblyLabel>
        )}
        <TireWidth
          {...getAssembliesDropdownProps('tireWidth')}/>
        <TireRatio
          {...getAssembliesDropdownProps('tireRatio')}/>
        <TireDiameter
          {...getAssembliesDropdownProps('tireDiameter')}/>
        <TirePressure
          type={constants.TEXT}
          fieldName="tirePressure"
          inputValue={fields?.tirePressure}
          label={isStaggered ? 'Tire Pressure' : 'Front Pressure'}
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={0}
          onBlur={formatOnBlur} />
        {isStaggered && (
          <>
            <AssemblyLabel leftAlign>
              {constants.GENERIC_VEHICLE_REAR_TIRES}
            </AssemblyLabel>
            <TireWidth
              {...getAssembliesDropdownProps('rearTireWidth')}/>
            <TireRatio
              {...getAssembliesDropdownProps('rearTireRatio')}/>
            <TireDiameter
              {...getAssembliesDropdownProps('rearTireDiameter')}/>
          </>
        )}
        <TirePressure
          type={constants.TEXT}
          fieldName="rearTirePressure"
          inputValue={fields?.rearTirePressure}
          label={isStaggered ? 'Tire Pressure' : 'Rear Pressure'}
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={0}
          onBlur={formatOnBlur} />

      </VehicleInfoGrid>
      {!isStaggered ?
        <AssemblyLabel>{constants.GENERIC_VEHICLE_WHEEL_TITLE}</AssemblyLabel>
        : <AssemblyLabel>{constants.GENERIC_VEHICLE_FRONT_WHEEL_TITLE}</AssemblyLabel>}
      <VehicleInfoGrid>
        <AssemblyLabel diameter name="Wheel Diameter">{constants.GENERIC_VEHICLE_WHEEL_DIAMETER}{fields.tireDiameter}</AssemblyLabel>
        <TireWidthInput
          type={constants.TEXT}
          fieldName="wheelWidth"
          inputValue={fields?.wheelWidth}
          label="Width"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={0}
          maxLength={5}
          onBlur={formatOnBlur} />
        <BoltPattern
          type={constants.TEXT}
          fieldName="wheelBoltPattern"
          inputValue={fields?.wheelBoltPattern}
          label="Bolt Pattern"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={0}
          onBlur={formatOnBlur} />
        <LugNutSize
          type={constants.TEXT}
          fieldName="wheelLugNutSize"
          inputValue={fields?.wheelLugNutSize}
          label="Lug Nut Size"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={0}
          onBlur={formatOnBlur} />
        <Torque
          type={constants.TEXT}
          fieldName="wheelTorque"
          inputValue={fields?.wheelTorque}
          label="Torque"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleInput}
          isDisabled={false}
          tabIndex={0}
          onBlur={formatOnBlur} />
      </VehicleInfoGrid>
      {isStaggered && (
        <>
          <AssemblyLabel>{constants.GENERIC_VEHICLE_REAR_WHEEL_TITLE}</AssemblyLabel>
          <VehicleInfoGrid>
            <AssemblyLabel diameter name="Rear Wheel Diameter">{constants.GENERIC_VEHICLE_WHEEL_DIAMETER}{fields.rearTireDiameter}</AssemblyLabel>
            <TireWidthInput
              type={constants.TEXT}
              fieldName="rearWheelWidth"
              inputValue={fields?.rearWheelWidth}
              label="Width"
              errorMessages={[]}
              warningMessages={[]}
              inputHandler={handleInput}
              isDisabled={false}
              tabIndex={0}
              maxLength={3}
              onBlur={formatOnBlur} />
            <BoltPattern
              type={constants.TEXT}
              fieldName="rearWheelBoltPattern"
              inputValue={fields?.rearWheelBoltPattern}
              label="Bolt Pattern"
              errorMessages={[]}
              warningMessages={[]}
              inputHandler={handleInput}
              isDisabled={false}
              tabIndex={0}
              onBlur={formatOnBlur} />
            <LugNutSize
              type={constants.TEXT}
              fieldName="rearWheelLugNutSize"
              inputValue={fields?.rearWheelLugNutSize}
              label="Lug Nut Size"
              errorMessages={[]}
              warningMessages={[]}
              inputHandler={handleInput}
              isDisabled={false}
              tabIndex={0}
              onBlur={formatOnBlur} />
            <Torque
              type={constants.TEXT}
              fieldName="rearWheelTorque"
              inputValue={fields?.rearWheelTorque}
              label="Torque"
              errorMessages={[]}
              warningMessages={[]}
              inputHandler={handleInput}
              isDisabled={false}
              tabIndex={0}
              onBlur={formatOnBlur} />
          </VehicleInfoGrid>
        </>
      )}
      {!notesOpen && (
        <AddNotesButton
          buttonName={constants.BUTTON_TYPE_ADD_NOTES}
          buttonType={constants.BUTTON_TYPE_CREATE}
          buttonClick={openNotes} />
      )}
      {notesOpen && (
        <>
          <Notes type={String}
            required={false}
            fieldName="notes"
            inputValue={fields?.notes}
            label="Notes"
            errorMessages={[]}
            warningMessages={[]}
            inputHandler={handleInput}
            isDisabled={false}
            tabIndex={0}
            maxLength={255}
            height="80px"
          />
          <CharCountLabelGeneric>
            {fields?.notes?.length}/{255}
          </CharCountLabelGeneric>
        </>
      )}

    </VehicleInfoContainer>
  );
};

export default GenericVehicleAssembly;
